import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MdDevices } from 'react-icons/md';
import { IoVideocamOutline } from 'react-icons/io5';

const VideoUploadModal = ({ isOpen, onRequestClose, selectedVideo, handleFileChange, handleVideoUpload }) => {
    const [fileSize, setFileSize] = React.useState(null);

    React.useEffect(() => {
        const getFileSize = async () => {
            if (selectedVideo) {
                const size = await getMetadata(selectedVideo);
                setFileSize(size);
            } else {
                // If no selected file, set file size to null
                setFileSize(null);
            }
        };

        getFileSize();
    }, [selectedVideo]);

    const getMetadata = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const fileSize = event.target.result.byteLength;
                resolve(fileSize);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const formatFileSize = (size) => {
        if (size === null) {
            return "NA"; // Display "NA" if file size is not available
        } else if (size < 1024) {
            return size + ' bytes';
        } else if (size >= 1024 && size < 1048576) {
            return (size / 1024).toFixed(2) + ' KB';
        } else if (size >= 1048576 && size < 1073741824) {
            return (size / 1048576).toFixed(2) + ' MB';
        } else {
            return (size / 1073741824).toFixed(2) + ' GB';
        }
    };
    return (
        <Modal
            show={isOpen}
            onHide={onRequestClose}
            centered
            style={{ width: '100vw', height: 'auto' }}
        >
            {selectedVideo ? (
                <>

                    <Modal.Body style={{ display: 'flex', paddingTop: "10px", justifyContent: 'center', alignItems: 'center', height: '60vh', maxWidth: '100vw', backgroundColor: '#fff' }}>
                        <div type="button" className="close" aria-label="Close" style={{ position: 'absolute', top: '10px', left: '10px', cursor: 'pointer', fontSize: '2rem' }} onClick={onRequestClose}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                        {/* Display IoVideocamOutline icon wrapped with a green background box */}
                        <div style={{ backgroundColor: '#19AF67', padding: '20px', borderRadius: '10px', width: '350px', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ backgroundColor: "#fff", padding: '20px', borderRadius: '10px', width: '200px', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <IoVideocamOutline size={100} color="#19AF67" />
                            </div>
                        </div>
                        <span style={{ position: 'absolute', bottom: '10px', left: '70px', fontSize: '14px', color: 'gray' }}>{formatFileSize(fileSize)}</span>

                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="primary" style={{ backgroundColor: "#1BB240", padding: "10px 15px", border: 'none', fontSize: '16px', fontWeight: '300' }} onClick={handleVideoUpload}>Upload 1 file</Button>
                        {/* You can add other buttons or content here if needed */}
                    </Modal.Footer>

                </>
            ) : (
                <Modal.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '55vh', border: '1px dashed #dfdfdf', margin: '7px', backgroundColor: '#fff' }}>
                    <div type="button" className="close" aria-label="Close" style={{ position: 'absolute', top: '0px', right: '10px', cursor: 'pointer', fontSize: '2rem' }} onClick={onRequestClose}>
                        <span aria-hidden="true">&times;</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                        <h3 style={{ fontSize:'25px', textAlign: 'center' }}>Drop files or <label htmlFor="browse" style={{ color: "orange", borderBottom: "1px solid orange", cursor: 'pointer' }}>browse files <input id="browse" type="file" accept="video/*" onChange={handleFileChange} style={{ display: 'none' }} /></label></h3>
                        <label htmlFor="file" style={{ cursor: 'pointer', marginTop: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <input id="file" type="file" accept="video/*" onChange={handleFileChange} style={{ display: 'none' }} />
                            <div style={{ backgroundColor: 'orange', width: '30px', height: '30px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '5px' }}>
                                <MdDevices size={20} color="white" />
                            </div>
                            <span style={{ textAlign: 'center' }}>My Device</span>
                        </label>

                    </div>
                    {/* Text for supported file types */}
                    <span style={{ textAlign: 'center', marginTop: 'auto', marginBottom: '10px', fontSize: '14px' }}>Supports MP4, MKV, MOV, AVI, MPEG-2, MXF, SEC and DAV</span>
                    {/* Your other content */}
                </Modal.Body>
            )}
        </Modal>
    );
};

export default VideoUploadModal;
