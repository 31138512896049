import React from "react";
import { useNavigate } from "react-router-dom";
import "../../CSS/TrainingVideos.css";
import userSession from "../../Constants/auth";
import ipadOverview from '../../Assets/trainingVideosImages/IpadThumbnails/ipad overview.png'
import TakeaPayment from '../../Assets/trainingVideosImages/IpadThumbnails/Take a Payment.png'
import LogaDriveOff from '../../Assets/trainingVideosImages/IpadThumbnails/Log a drive off.png'
import LogaIOU from '../../Assets/trainingVideosImages/IpadThumbnails/Log a IOU.png'
import ColorCoedandPrepay from '../../Assets/trainingVideosImages/IpadThumbnails/Color coed & prepay.png'
import ManageEmployeesOrdeFRSCards from '../../Assets/trainingVideosImages/WebPortalThumbnails/Manage Employees & Order FRS cards.png'
import PortalOverview from '../../Assets/trainingVideosImages/WebPortalThumbnails/Portal Overview.png'
import ReceiveInStorePayment from '../../Assets/trainingVideosImages/WebPortalThumbnails/Receive in-store Payment.png'
import ReportIncidents from '../../Assets/trainingVideosImages/WebPortalThumbnails/Report incidents.png'
import ReviewIncident from '../../Assets/trainingVideosImages/WebPortalThumbnails/Review Incident.png'
import ScancamReporting from '../../Assets/trainingVideosImages/WebPortalThumbnails/Scancam reporting.png'
import incidentCancellation from '../../Assets/trainingVideosImages/WebPortalThumbnails/incident Cancellation.png'
import incidentExpiryReopening from '../../Assets/trainingVideosImages/WebPortalThumbnails/incident expiry & reopening.png'


const traing_json = {
  trainingVideos: [
    {
      heading: "iPad",
      Videos: [
        {
          title: "Ipad Overview",
          text: "An overview of the Scancam iPad app used to capture IOU and drive-off incidents.",
          imageSrc: ipadOverview,
          VideoURL: "https://drive.google.com/file/d/1DYUuYI3n95_Hgaq3Y2wmPWuOYIuj3hLn/preview",
        },
        {
          title: "Color Code and Prepay Customer Handling",
          text: "Understand meaning of various cell colors and best practices to handle prepay customers.",
          imageSrc: ColorCoedandPrepay,
          VideoURL: "https://drive.google.com/file/d/1v-_weQlD0tnQhusB8d5gj68VB6Bp_1T4/preview",
        },
        {
          title: "Log a Drive Off",
          text: "Shows how to capture a drive-off incident using the iPad app.",
          imageSrc: LogaDriveOff,
          VideoURL: "https://drive.google.com/file/d/1S8BmbNkm7BeEHPlZRKjL4roTQssWnhx5/preview",
        },
        {
          title: "Log an IOU",
          text: "Shows how to capture an IOU incident using the iPad app.",
          imageSrc: LogaIOU,
          VideoURL: "https://drive.google.com/file/d/1NyjPsVo-UZig5TKGQ76lPBkBST42Mkaf/preview",
        },
        {
          title: "Take a Payment",
          text: "Shows how to take a payment for an incident using the iPad app.",
          imageSrc: TakeaPayment,
          VideoURL: "https://drive.google.com/file/d/1uZraEW4Hbxuq8RXJP5oOKJn43M84jjFb/preview",
        }
      ],
    },
    {
      heading: "Web Portal",
      Videos: [
        {
          title: "Portal Overview",
          text: "An introduction to the portal used to manage IOU and drive-off incidents.",
          imageSrc: PortalOverview,
          VideoURL: "https://drive.google.com/file/d/1a-vdAu-bJ2RMF5_OO8yxO1AGbhu_c93M/preview",
        },
        {
          title: "Manage Employee or Order FRS Cards",
          text: "Shows how to add employee names and order FRS cards.",
          imageSrc: ManageEmployeesOrdeFRSCards,
          VideoURL: "https://drive.google.com/file/d/1BGWV9tt-NyHYBCaMfd8gErn0kpq1iyuh/preview",
        },
        {
          title: "Review Incidents",
          text: "Shows how to review, edit and verify incidents.",
          imageSrc: ReviewIncident,
          VideoURL: "https://drive.google.com/file/d/1aLwvWmy9MYoM7hpc0jFvMM7eZeGNAOGE/preview",
        },
        {
          title: "Report Incident",
          text: "Shows how to report IOU and Drive Off incidents.",
          imageSrc: ReportIncidents,
          VideoURL: "https://drive.google.com/file/d/1OMqN4zwnuFN0tqWg1cuxY9j-3K13Zcv7/preview",
        },
        {
          title: "Receive In Store Payment",
          text: "Shows how to take late payment for an incident using the portal.",
          imageSrc: ReceiveInStorePayment,
          VideoURL: "https://drive.google.com/file/d/1OdC3nrYkfr-djtpRPucd75zL_XValQGM/preview",
        },
        {
          title: "Incident Cancellation",
          text: "Shows how to cancel and incident in the portal.",
          imageSrc: incidentCancellation,
          VideoURL: "https://drive.google.com/file/d/1tY8m0K4nYAT_aFBRRrJn6IK0yuBCx-4V/preview",
        },
        {
          title: "Scancam Reporting",
          text: "An overview of the reports available on the portal for managing and analysing incident data.",
          imageSrc: ScancamReporting,
          VideoURL: "https://drive.google.com/file/d/1EIYv-BQ4DEOqsTvRiEKtx8zdhQIzhadK/preview",
        },
        {
          title: "Incident Expiry and Re-opening",
          text: "Shows how to view and send request to open expired incidents.",
          imageSrc: incidentExpiryReopening,
          VideoURL: "https://drive.google.com/file/d/1G_23f8dsZCLOYgxL3g9__URVFfR3U0tQ/preview",
        },
      ],
    },
    // {
    //   heading: "MxManagement Centre (MxMC)",
    //   Videos: [
    //     {
    //       title: "Coming Soon!",
    //       text: "An introduction to MxManagement Centre used to manage, view and record camera footage.",
    //       imageSrc: ipadOverview,
    //       VideoURL: "",
    //     },
    //     {
    //       title: "Coming Soon!",
    //       text: "How to view and extract footage using MxManagement Centre.",
    //       imageSrc: ipadOverview,
    //       VideoURL: "",
    //     },
    //   ],
    // },
  ],
};

const TrainingVideos = () => {
  const navigate = useNavigate();
  const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
  const handleVideoClick = (videoTitle, videoURL) => {
    const formattedVideoTitle = videoTitle.replace(/ /g, ""); // Remove spaces from videoTitle for the URL
    navigate(`/scancam/training/viewvideo/${formattedVideoTitle}`, {
      state: { VideoURL: videoURL, videoTitle: videoTitle },
    });
  };

  return (
    <div className="trainging-videos-page-box">
      <div className="row training-videos-title-section">
        <div className="col-sm-12 title-container">
          <h2 className="title-traing-videos">Training Videos</h2>
        </div>
      </div>
      <div className="row training-videos-body-section">
        {traing_json.trainingVideos.map((category, index) => (
          <div className="col-sm-12" key={index}>
            <div className="trainging-videos-second-title">
              <h3 className="second-title-trainging-videos">
                {category.heading}
              </h3>
            </div>
            <div className="col-sm-12">
              <div className="row videos-box">
                {category.Videos &&
                  category.Videos.map((video, videoIndex) => {
                    return (
                      <div
                        className="col-lg-3 col-md-6 col-sm-6"
                        key={videoIndex}
                      >
                        {video.VideoURL ? (
                          <div
                            className="card "
                            style={{ width: "90%" }}
                            onClick={() =>
                              handleVideoClick(video.title, video.VideoURL)
                            }
                          >
                            <div className="card-box">
                              <img
                                src={video.imageSrc}
                                className="card-img-top"
                                alt="image_thumbnail"
                              />
                            </div>
                            <div className="card-body">
                              <h5 className="training-title" style={{ color: textAccentColor }}>{video.title}</h5>
                              <p className="training-text">{video.text}</p>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="card card-disabled"
                            style={{ width: "90%" }}
                          >
                            <div className="card-box">
                              <img
                                src={video.imageSrc}
                                className="card-img-top"
                                alt="image_thumbnail"
                              />
                            </div>
                            <div className="card-body">
                              <h5 className="training-title" style={{ color: textAccentColor }}>{video.title}</h5>
                              <p className="training-text">{video.text}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrainingVideos;