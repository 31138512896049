// auth.js

const sessionData = localStorage.getItem("userDataScancamPortal");
const userData = JSON.parse(sessionData);
const token = userData ? userData.token : "N/A";
const site =
  userData && userData.sites && userData.sites.length !== 0
    ? userData.sites[0]
    : "N/A";
const sites = userData ? userData.sites : "N/A";
const CustID = userData ? userData.custID : "N/A";
const authHeader = `Bearer ${token}`;
const username = userData ? userData.username : "N/A";
const userRole = userData ? userData.roleKey : "N/A";
// Parse the theme data and store it in a separate object
const themeData =
  userData && userData.theme ? JSON.parse(userData.theme) : "N/A";

const userSession = {
  authHeader,
  site,
  sites,
  CustID,
  username,
  userRole,
  themeData,
};

export default userSession;
