
import React, { useState } from "react";
import style1 from "../../CSS/notes.module.css";
import stlyes from "../../CSS/Base/Basic.module.css"
import { MdArrowBackIosNew } from "react-icons/md"
import userSession from "../../Constants/auth";
import moment from "moment";

function Records({ sharedData }) {
  const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
  const formatDateTime = (dateTimeString) => {
    // Create a moment object from the input date-time string
    const momentDateTime = moment(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSS");

    // Format the moment object to 12-hour format with AM/PM
    const formattedTime = momentDateTime.format('h:mm A'); // 12-hour format with AM/PM

    // Format the date in the desired format (DD/MM/YYYY)
    const localDateString = momentDateTime.format('DD/MM/YYYY');

    return (
      <>
        {`${localDateString} ${formattedTime.toUpperCase()}`}
      </>
    );
  };
  const handleRowClick = (index) => {
    setSelectedRow(index);

  };

  const handleBackButtonClick = () => {
    setSelectedRow(null);
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const [selectedRow, setSelectedRow] = useState(null);


  return (
    <div className={stlyes.recordsBox}>
      {selectedRow === null ? (
        (
          <table className={style1.notesTable}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Event</th>
                <th>BY</th>
                <th>DATE / TIME</th>
              </tr>
            </thead>
            <tbody>
              {sharedData.histories.map((historyItem, index) => (
                <tr
                  key={index}
                  className={style1.notesRows}
                  onClick={() => handleRowClick(index)}
                >
                  <td style={{ fontSize: '14px', textAlign: 'left' }}>
                    {historyItem.event.length > 35
                      ? `${capitalizeFirstLetter(historyItem.event.substring(0, 15))}...`
                      : capitalizeFirstLetter(historyItem.event)}
                  </td>
                  <td style={{ fontSize: '14px' }}>{capitalizeFirstLetter(historyItem.author)}</td>
                  <td style={{ fontSize: '14px' }}>{formatDateTime(historyItem.timestamp)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      ) : (
        <div>
          <p onClick={handleBackButtonClick} className={style1.notesBackBtn}>
            <span className={style1.backArrowIcon}>
              <MdArrowBackIosNew style={{ color: textAccentColor }} />
            </span>
            <span className={style1.backBtnText} style={{ color: textAccentColor }}>All Events</span>
          </p>

          <div className="row">
            <div className={`col-sm-12 d-flex`}>
              <div className={`col-sm-6 ${style1.notesFullDetailsHeading}`}>BY</div>
              <div className={`col-sm-6 ${style1.notesFullDetailsHeading}`}>DATE / TIME</div>
            </div>
            <div className={`col-sm-12 d-flex`}>
              <div className={`col-sm-6 ${style1.notesFullDetails}`}>{sharedData.histories[selectedRow].author}</div>
              <div className={`col-sm-6 ${style1.notesFullDetails}`}>{formatDateTime(sharedData.histories[selectedRow].timestamp)}</div>
            </div>
            <div className={`col-sm-12 ${style1.notesFullDetailsHeading} ${style1.notesHeading}`}>NOTE</div>
            <div className={`col-sm-12 ${style1.notesFullDetails} ${style1.notesField}`}>{sharedData.histories[selectedRow].event}</div>
          </div>
        </div>
      )}
    </div>

  );

}

export default Records;