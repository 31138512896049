import { orange } from '@mui/material/colors';
import React from 'react';
import Chart from 'react-apexcharts';
import userSession from '../Constants/auth';

const ReportsBarChart = ({ data, label }) => {
  const isHomepage = label === 'homepage';
  const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
  // Create an array of hours (0 to 23) for other labels, or 31 hours for 'homepage' label
  const hours = isHomepage
    ? Array.from({ length: 31 }, (_, index) => index)
    : Array.from({ length: 24 }, (_, index) => index);

  // Map the data to match the hours and set 0 for missing hours
  const chartData = hours.map((hour) => ({
    hour: hour,
    count: data[hour.toString()] || 0,
  }));

  // Set the color based on the label
  const barColor = isHomepage ? '#ffffff' : '#c56b13cf';
  const barHeight = isHomepage ? '120' : '250';

  // Set the columnWidth for all bars (adjust the percentage to reduce width)
  const columnWidth = '30%';

  // Set the columnHeight based on the label
  const columnHeight = isHomepage ? '20%' : '40%';

  const chartOptions = {
    chart: {
      id: 'hourly-bar',
      stacked: false, // Set to false to prevent bars from stacking
      width: '100%', // Set the width of the chart to 100% of the container
    },
    plotOptions: {
      bar: {
        columnWidth: columnWidth, // Adjust the width of the bars (30% of available space)
        columnHeight: columnHeight, // Adjust the height of the bars based on the label
      },
    },
    xaxis: {
      categories: chartData.map((item) => item.hour), // X-axis categories for each hour
      title: {
        text: isHomepage ? '' : 'Time of Day (24hr)', // X-axis label with conditional rendering
        style: {
          color: '#6f7172', // Set the label text color to your desired color
          fontSize: '10px', // Set the label text font size to your desired size
        },
        offsetY: 0, // Offset the label from the X-axis
      },
      labels: {
        show: true,
        trim: false,
        style: {
          colors: isHomepage ? '#ffffff' : orange, // Set the color of X-axis labels
        },
      },
      axisTicks: {
        show: false, // Hide x-axis ticks (lines)
      },
      axisBorder: {
        show: false, // Hide x-axis border (line)
      },
    },

    yaxis: {
      labels: {
        show: false, // Hide Y-axis labels
        style: {
          colors: isHomepage ? '#ffffff' : orange, // Set the color of Y-axis labels
        },
      },
    },
    grid: {
      show: false, // Hide both x and y-axis lines
    },
    markers: {
      show: false, // Hide markers (dots)
    },
    dataLabels: {
      enabled: false, // Hide data labels inside the bars
    },
    fill: {
      colors: [textAccentColor], // Set bar color based on the label
    },
    tooltip: {
      enabled: false, // Disable the tooltip on hover
    },
  };

  const chartSeries = [
    {
      name: 'Hourly Data',
      data: chartData.map((item) => item.count), // Y-axis data (count)
    },
  ];

  return (
    <div>
      <Chart options={chartOptions} series={chartSeries} type="bar" height={barHeight} /> {/* Adjust the height as needed */}
    </div>
  );
};

export default ReportsBarChart;
