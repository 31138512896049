import "../../CSS/ReturnToSite.css";
import styles from "../../CSS/Base/Basic.module.css";
import useDetailsPatchApi from "../../Custom Hooks/useDetailsPatchApi";
import { useState } from "react";
import userSession from "../../Constants/auth";
import { useParams } from "react-router-dom";
import { APP_SERVICES_URL } from "../../Constants/constants";

const ReturnToSite = ({ setIncidentDetails, setActiveLinkBox2 }) => {
    const { CustID, authHeader } = userSession;
    const [buttonActive, setButtonActive] = useState(true);
    const { patchAPI } = useDetailsPatchApi();
    const { pkAlert } = useParams();
    const ctaBackgroudColor = userSession.themeData?.ctaBackgroudColor || '#e27a13';
    const [note, setNote] = useState("");
    const fetchDataAndUpdateIncidentDetails = async () => {
        try {
            // Fetch data from API
            const apiUrl = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident?alertID=${pkAlert}`;
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Authorization": authHeader,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            setIncidentDetails(responseData);
            setActiveLinkBox2('details');
        } catch (error) {
            console.error("Error fetching data from the API:", error);
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonActive(false);
        const payload = {
            status: "Returned To Site",
        };
        let noteWithPrefix = `Returned To Site: ${note}`;

        if (note.trim()) {
            payload.note = noteWithPrefix;
        }

        patchAPI(authHeader, CustID, pkAlert, payload)
            .then(() => {
                // Update data in storage after successful patch operation
                setNote("");
                fetchDataAndUpdateIncidentDetails();
                setButtonActive(true);

            })
            .catch((error) => {
                setButtonActive(true);

                console.error("Error saving note:", error);
            });
    };
    return (
        <div className={styles.incidentReturnToSiteBox}>
            {/* <SimpleBackdrop open={loading} onClose={() => setLoading(false)} /> */}
            <form onSubmit={handleSubmit}>
                <div className="TableEditMainBox">
                    <div className="top-heading-text">Message to Site:</div>
                    <textarea
                        className="TableEditBox"
                        value={note}
                        maxLength={500}
                        required
                        onChange={(e) => setNote(e.target.value)}
                        placeholder="Please enter reason why it's being Returned To Site and instructions to site on what to do next..."
                    />
                    <div className="TableEditButtonBox">
                        {buttonActive && <button className="TableSaveNoteButton" style={{ backgroundColor: ctaBackgroudColor }}>Return To Site</button>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
};
export default ReturnToSite;