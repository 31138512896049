// src/components/HomePage.jsx
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import userSession from "../../Constants/auth";
import useFetchStats from "../../Custom Hooks/useFetchStats";
import StatsWidget from "../../Components/Homepage/StatsWidget";
import InfiniteScrollWidget from "../../Components/Homepage/InfiniteScrollWidget";
import RotatingIcon from "../../Components/Base/RotatingIcon";
import { APP_SERVICES_URL } from "../../Constants/constants";

const HomePage = () => {
  const { authHeader, CustID, username } = userSession;
  const [sortedMostVisitedVehicles, setSortedMostVisitedVehicles] = useState(
    []
  );
  const { data, loading, error } = useFetchStats(
    `${APP_SERVICES_URL}/sn-reports-service/${CustID}/homePage`,
    "POST",
    null,
    authHeader
  );

  // Function to sort and set sortedMostVisitedVehicles state
  const updateSortedMostVisitedVehicles = (data) => {
    const sortedVehicles = Object.entries(data.mostVisitedVehicles)
      .sort(([, a], [, b]) => b - a)
      .map(([vehicle, count]) => ({ vehicle, count }));
    setSortedMostVisitedVehicles(sortedVehicles);
  };

  // Effect to call updateSortedMostVisitedVehicles when data changes
  useEffect(() => {
    if (data) {
      updateSortedMostVisitedVehicles(data);
    }
  }, [data]); // Watch for changes in data

  const {
    numTotalIncidents,
    numIncidentsToReview,
    numKnownOffendersDetected,
    averageLoss,
    preventedTheftAmount,
    recoveredPercentage,
  } = data || {};

  const incidentsDistributionData = data?.incidentsDistributionData ?? {};

  const parseCustomDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    // Convert the month to a number (e.g., "Nov" to 11)
    const monthNumber = new Date(`${month} 1, 2000`).getMonth() + 1;
    return new Date(`${year}-${monthNumber}-${day}`);
  };

  const chartData = Object.entries(incidentsDistributionData)
    .sort(([dateA], [dateB]) => parseCustomDate(dateA) - parseCustomDate(dateB))
    .map(([date, count]) => ({ date, count }));

  return (
    <Box sx={{ backgroundColor: "#e27a13", minHeight: "fit-content", pb: 3 }}>
      <Box sx={{ maxWidth: 1170, mx: "auto", pt: 3 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "70vh", // Set the loading box height to 50vhposition: "absolute",top: "50%",left: "50%",transform: "translate(-50%, -50%)",width: "100%",
            }}
          >
            <RotatingIcon colorValue={"#fff"} />
          </Box>
        ) : error ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "70vh", // Set the loading box height to 50vhposition: "absolute",top: "50%",left: "50%",transform: "translate(-50%, -50%)",width: "100%",
            }}
          >
            <Typography style={{ color: "#fff" }} variant="h4">
              Error loading data
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 2,
                mb: 4,
                color: "#fff",
                minHeight: 70, // Ensures visibility during loading
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bolder", color: "#fff", pb: 2 }}
              >
                Today
              </Typography>
              <Typography variant="h5">
                Hello {username}, here's what you have on today.
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}
            >
              <InfiniteScrollWidget
                sortedMostVisitedVehicles={sortedMostVisitedVehicles}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
            >
              <StatsWidget
                numTotalIncidents={numTotalIncidents}
                numKnownOffendersDetected={numKnownOffendersDetected}
                averageLoss={averageLoss}
                preventedTheftAmount={preventedTheftAmount}
                recoveredPercentage={recoveredPercentage}
                numIncidentsToReview={numIncidentsToReview}
                chartData={chartData}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default HomePage;
