import { useState, useEffect } from "react";
import { APP_SERVICES_URL } from "../Constants/constants";
import userSession from "../Constants/auth";

const useSenapiVehiclesInfo = () => {
  const { CustID, authHeader } = userSession;
  const [colors, setColors] = useState([]);
  const [models, setModels] = useState([]);
  const [shapes, setShapes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if data exists in localStorage
        const localStorageData = localStorage.getItem("vehiclesInfo");
        if (localStorageData) {
          // If data exists, parse and set it
          const parsedData = JSON.parse(localStorageData);
          if (parsedData && parsedData.colors && parsedData.models && parsedData.shapes &&
            parsedData.colors.length > 0 && parsedData.models.length > 0 && parsedData.shapes.length > 0) {
            setColors(parsedData.colors);
            setModels(parsedData.models);
            setShapes(parsedData.shapes);
          }
        } else {
          // If data doesn't exist, fetch from the API
          const url = `${APP_SERVICES_URL}/senapi/${CustID}/vehiclesInfo`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: authHeader,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setColors(data.colors);
            setModels(data.models);
            setShapes(data.shapes);

            // Save fetched data to localStorage
            localStorage.setItem("vehiclesInfo", JSON.stringify(data));
          } else {
            console.error("Failed to fetch vehicles information.");
          }
        }
      } catch (error) {
        console.error("Error fetching vehicles information:", error);
      }
    };

    fetchData();
  }, [CustID, authHeader]);

  return { colors, models, shapes };
};

export default useSenapiVehiclesInfo;
