import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import successEmail from "../../Assets/Success.svg";
const ResetPasswordSuccess = () => {
  const location = useLocation();
  const [showemail, setShowEmail] = useState("");

  useEffect(() => {
    // Parse the query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    setShowEmail(email);
    // Do something with the email, e.g., update state
    // setEmail(email);
  }, [location.search]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message =
        "The page that you're looking for used information that you entered. Returning to that page might cause any action that you took to be repeated. Do you want to continue?";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="h-screen flex items-center justify-center bg-[#363636] font-login">
      <div className="bg-white rounded-md  shadow-md min-h-[540px] max-h-fit w-[400px]">
        <div className="flex items-center justify-center ">
          <div className="flex flex-col justify-center items-center space-y-6 text-[#1E212A] p-10 mt-10 rounded-md ">
            <img
              src={successEmail}
              alt="logoSensen"
              height={88}
              width={88}
              className="text-[#13A688]"
            />
            <h1 className="font-normal text-2xl">Check Your Email</h1>
            <p className="text-sm text-center">
              Please check the email address {showemail} for instructions to
              reset your password.
            </p>
            <Link
              to={"/scancam/forgotpassword"}
              className="w-full flex justify-center items-center py-4 px-4 border border-[#c9cace] rounded-md shadow-sm text-md font-medium text-[#1e212a] hover:text-[#1e212a] bg-white"
            >
              Resend Email
            </Link>
            <Link to="/scancam/" className="mt-4 back-button text-[#21358c] hover:text-[#21358c]">
              Go Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
