import React, { Component } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';

class CSVCourtReport extends Component {
    // Method to preprocess data
    preprocessData = (data) => {
        Object.values(data).forEach((dataArray) => {
            dataArray.forEach((record) => {
                // Remove commas from siteAddress
                if (record.siteAddress) {
                    record.siteAddress = record.siteAddress.replace(/,/g, ' ');
                }
            });
        });
        return data;
    };

    // Method to convert data to CSV
    convertToCSV = (data) => {
        // Preprocess data to remove commas from siteAddress
        data = this.preprocessData(data);

        // CSV header
        let csvContent = 'Date/Time,Date,Time,Licence Plate,Type,Pump Number,Fuel Litres,Fuel Value,Site Name,Site Address\n';

        // Loop through the data and create CSV rows
        Object.values(data).forEach((dataArray) => {
            dataArray.forEach((record) => {
                const detectedDate = new Date(record.detected);
                const date = detectedDate.toISOString().split('T')[0]; // Extract date part
                const time = detectedDate.toISOString().split('T')[1].split('.')[0]; // Extract time part without milliseconds

                // Define an array with the custom headings and corresponding values
                const values = [
                    `${date} ${time}`, // Date/Time
                    date, // Date
                    time, // Time
                    record.ocr || 'NA', // Licence Plate
                    record.type || 'NA', // Type
                    record.pumpNumber || 'NA', // Pump Number
                    record.fuelLitres || 'NA', // Fuel Litres
                    record.fuelvalue || 'NA', // Fuel Value
                    record.siteName || 'NA', // Site Name
                    record.siteAddress || 'NA', // Site Address
                ];

                // Joining the values with commas to form a CSV row
                csvContent += values.join(',') + '\n';
            });
        });

        return csvContent;
    };

    // Method to trigger CSV download
    downloadCSV = (data) => {
        const csv = this.convertToCSV(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        const filename = `${this.props.reportName}.csv`;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    render() {
        return (
            <span style={{ padding: '0px', margin: '0px' }} onClick={() => this.downloadCSV(this.props.data)}>
                <span className=""> <LaunchIcon style={{ fontSize: "18px" }} /> </span> <span>Export Court CSV</span>
            </span>
        );
    }
}

export default CSVCourtReport;
