import React, { Component } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';

class CSVLetterRunButton extends Component {
    formatDate = (dateString, dueDateKey) => {
        // Parse the date string into a Date object
        const date = new Date(dateString);

        // If dueDateKey is true, add 30 days to today's date
        if (dueDateKey) {
            // Create a new Date object for today's date
            const today = new Date();

            // Add 30 days to today's date
            today.setDate(today.getDate() + 30);

            // Format the date as DD/MM/YYYY
            const formattedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

            return formattedDate;
        }

        // If dueDateKey is false or not provided, proceed with the existing formatting logic
        // Format the date as DD/MM/YYYY
        const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

        return formattedDate;
    };


    preprocessData = (data) => {
        console.log({data});
        Object.values(data).forEach((dataArray) => {
            dataArray.forEach((record) => {
                // Remove commas from siteAddress
                if (record.siteAddress) {
                    record.siteAddress = record.siteAddress.replace(/,/g, ' ');
                }
            });
        });
        return data;
    };

    convertToCSV = (data) => {
        let csvContent = 'INCIDENT-ID,INCIDENT-DATE,DATE,PUMP-NUMBER,FUEL-VALUE,SITE,STATION LOCATION,REGO,LASTNAME,FIRSTNAME,Registered Street,Registered Suburb,Registered Postcode,RECOVERY-FEE,AMOUNT,DUE-DATE\n';

        Object.values(data).forEach((dataArray) => {
            dataArray.forEach((record) => {
                const customerDetails = record.customerDetails || {};
                // Extract customer street details
                let street = {};
                if (customerDetails.street) {
                    try {
                        street = typeof customerDetails.street === 'string' ? JSON.parse(customerDetails.street) : customerDetails.street;
                    } catch (e) {
                        console.error('Error parsing street:', e);
                    }
                }

                // Extracting relevant data from the record
                const incidentId = record.alertID || 'NA';
                const incidentDate = record.detected;
                const date = this.formatDate(record.detected);
                const pumpNumber = record.pumpNumber || 'NA';
                const fuelValue = record.fuelvalue || 'NA';
                const site = record.siteName || 'NA';
                const stationLocation = record.siteAddress || 'NA';
                const rego = record.ocr || 'NA';
                const lastName = customerDetails.name || '';
                const firstName = '';
                const registeredStreet = `${street.address2 || ''} ${street.address2 || ''}`
                const registeredSuburb = `${street.city || ''} ${street.state || ''}`.trim();
                const registeredPostcode = street.postCode || '';
                const recoveryFee = record.processingFee || 'NA';
                const amount = (parseInt(record.fuelvalue) || 0) + (parseInt(record.processingFee) || 0); // Total amount
                const dueDate = this.formatDate(record.detected, true);

                // Constructing CSV row
                const values = [
                    incidentId,
                    incidentDate,
                    date,
                    pumpNumber,
                    fuelValue,
                    site,
                    stationLocation,
                    rego,
                    lastName,
                    firstName,
                    registeredStreet,
                    registeredSuburb,
                    registeredPostcode,
                    recoveryFee,
                    amount,
                    dueDate
                ];

                csvContent += values.join(',') + '\n'; // Joining values with commas
            });
        });

        return csvContent;
    };


    downloadCSV = (data) => {
        // Preprocess data before converting to CSV
        const preprocessedData = this.preprocessData(data);
        const csv = this.convertToCSV(preprocessedData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        const filename = `${this.props.reportName}.csv`;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    render() {
        const { reportName, changetext } = this.props;
        return (
            <span style={{ padding: '0px', margin: '0px' }} onClick={() => this.downloadCSV(this.props.data)}>
                <span className=""> <LaunchIcon style={{ fontSize: "18px" }} /> </span>
                <span>{changetext ? 'Export as CSV' : 'Export Letter Run CSV'}</span>
            </span>
        );
    }
}

export default CSVLetterRunButton;