import React, { useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../CSS/AddIncident.css";
import styles from "../../CSS/Base/Basic.module.css";
import useSenapiPumps from "../../Custom Hooks/useSenapiPumps";
import useSenapiVehiclesInfo from "../../Custom Hooks/useSenapiVehiclesInfo";
import createIncidentApi from "../../Custom Hooks/createIncidentApi";
import useSenapiFuelTypes from "../../Custom Hooks/SenapiFuelTypes";
import InputField from "./InputField";
import RadioButton from "./RadioButton";
import Dropdown from "./Dropdown";
import { DRIVE_OFF, IOU } from "../../Constants/constants";
import userSession from "../../Constants/auth";
import SimpleBackdrop from "../Base/SimpleBackdrop";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import ErrorModal from "../../Modals/ErrorModal";
import { FaCalendarAlt } from "react-icons/fa";

const AddIncident = () => {
  const initialIOUDetails = useMemo(
    () => ({
      siteName: "",
      customerName: "",
      customerPhoneNumber: "",
      customerAddress: "",
      ocr: "",
      vehicleColor: "",
      vehicleShape: "",
      vehicleMake: "",
      detected: "",
      fuelType: "",
      fuelValue: "",
      fuelLitres: "",
      fuelReference: "",
      pumpNumber: "",
      note: "",
      type: DRIVE_OFF,
      customerGender: "Others",
    }),
    []
  );

  const [selectedDate, setSelectedDate] = useState(new Date());
  // Function to format date to YYYY-MM-DDThh:mm
  const formatDateForInput = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12 || 12; // Convert 0 to 12 for midnight
    hours = hours.toString().padStart(2, '0'); // Pad single digit hours with a leading zero
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  
  const [loading, setLoading] = useState(false);
  const { authHeader, CustID } = userSession;
  const ctaBackgroundColor =
    userSession.themeData?.ctaBackgroudColor || "#e27a13";
  const location = useLocation();
  const { state } = location;
  const siteName = state ? state.siteName : "";
  const pumps = useSenapiPumps(siteName);
  const { colors, models, shapes } = useSenapiVehiclesInfo();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const ErrorModalHeading = "Incident Creation Error";
  const fuelTypes = useSenapiFuelTypes(siteName);
  const navigate = useNavigate();
  const [incidentDetails, setIncidentDetails] = useState({
    ...initialIOUDetails,
    siteName,
  });
  const inputTagStyle = {
    height: "34px",
    fontSize: "14px",
    padding: "6px 12px",
    lineHeight: "1.42857143",
    color: "#555",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    boxShadow: "inset 0 1px 1px rgba(0, 0, 0, .075)",
  };
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    const restrictedValue =
      name === "customerName"
        ? value.replace(/[^A-Za-z\s]/g, "")
        : name === "ocr"
        ? value
            .replace(/[^A-Za-z0-9]/g, "")
            .slice(0, 10)
            .toUpperCase()
        : name === "fuelLitres" || name === "fuelValue"
        ? value.replace(/[^\d.]/g, "").slice(0, 10)
        : name === "customerPhoneNumber"
        ? `+${value.replace(/[^\d]/g, "").slice(0, 13)}`
        : name === "fuelReference"
        ? value
            .replace(/[^A-Za-z0-9]/g, "")
            .slice(0, 15)
            .toUpperCase()
        : value;

    setIncidentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: restrictedValue,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setError(false);

      try {
        const trimmedNote = incidentDetails.note.trim();
        const hasNonWhitespace = trimmedNote.length > 0;
        const utcTime = moment.utc(selectedDate);
        const localTime = utcTime.local();
        const localTimeWithOffset = localTime.format("YYYY-MM-DDTHH:mm:ss.SSS");

        let updatedDetails;
        if (hasNonWhitespace) {
          updatedDetails = {
            ...incidentDetails,
            detected: localTimeWithOffset,
            note: trimmedNote,
          };
        } else {
          const { note, ...detailsWithoutNote } = incidentDetails;
          updatedDetails = {
            ...detailsWithoutNote,
            detected: localTimeWithOffset,
          };
        }

        const data = await createIncidentApi(
          authHeader,
          CustID,
          updatedDetails
        );
        setLoading(false);
        setError(false);
        navigate(`/scancam/incident/${data.alertID}`);
      } catch (error) {
        setLoading(false);
        setError(true);
        setErrorMessage(
          "Incident creation failed. Please try again or contact support."
        );
        console.error("Error creating incident:", error.message);
      }
    },
    [incidentDetails, selectedDate, authHeader, CustID, navigate]
  );

  const renderTextBasedOnType = useCallback(() => {
    switch (incidentDetails.type) {
      case IOU:
        return (
          <div className="col-6">
            <div className="row">
              <InputField
                label="Name"
                name="customerName"
                value={incidentDetails.customerName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="row">
              <InputField
                label="Phone Number"
                name="customerPhoneNumber"
                value={incidentDetails.customerPhoneNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="row">
              <InputField
                label="Address"
                name="customerAddress"
                value={incidentDetails.customerAddress}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        );
      case DRIVE_OFF:
        return (
          <div className="col-6">
            <div className="row">
              <div className="col-12 d-flex justify-content-between ps-0">
                <div className="col-3 col-3-right text-end">Gender:</div>
                <div className="col-8">
                  <RadioButton
                    name="customerGender"
                    value="male"
                    checked={incidentDetails.customerGender === "male"}
                    onChange={handleChange}
                    label="Male"
                  />
                  <RadioButton
                    name="customerGender"
                    value="female"
                    checked={incidentDetails.customerGender === "female"}
                    onChange={handleChange}
                    label="Female"
                  />
                  <RadioButton
                    name="customerGender"
                    value="Others"
                    checked={incidentDetails.customerGender === "Others"}
                    onChange={handleChange}
                    label="Others"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="col-6">
            <div className="row">
              <div className="col-12 d-flex justify-content-between ps-0">
                <div className="col-3 col-3-right ">Gender:</div>
                <div className="col-8">
                  <h2 className="ps-3">Gender</h2>
                  <RadioButton
                    name="customerGender"
                    value="male"
                    checked={incidentDetails.customerGender === "male"}
                    onChange={handleChange}
                    label="Male"
                  />
                  <RadioButton
                    name="customerGender"
                    value="female"
                    checked={incidentDetails.customerGender === "female"}
                    onChange={handleChange}
                    label="Female"
                  />
                  <RadioButton
                    name="customerGender"
                    value="Others"
                    checked={incidentDetails.customerGender === "Others"}
                    onChange={handleChange}
                    label="Others"
                  />
                </div>
              </div>
            </div>
          </div>
        );
    }
  }, [incidentDetails, handleChange]);

  const handleCloseErrorModal = useCallback(() => {
    setError(false);
  }, []);

  return (
    <div className="container-fluid add-incident-page-main-box">
      {error && (
        <ErrorModal
          show={true}
          onClose={handleCloseErrorModal}
          message={errorMessage}
          heading={ErrorModalHeading}
        />
      )}
      <SimpleBackdrop open={loading} onClose={() => setLoading(false)} />
      <div className="title-section">
        <div className="container-fluid  title-box">
          <div
            className="col-sm-12 d-flex justify-content-between"
            style={{ maxWidth: "1170px", margin: "auto" }}
          >
            <div className="col-sm-12 ">
              <h2 className={styles.Title}>Add Incident</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container add-Incident-body"
        style={{ maxWidth: "1170px", margin: "auto" }}
      >
        <form onSubmit={handleSubmit} id="add-incident-form" method="POST">
          <div className="row">
            <div className="col-6 d-flex justify-content-between ps-0">
              <div className="col-3 col-3-right"></div>
              <div className="col-8 add-incident-heading-row">
                <span className="add-incident-heading ps-0">INCIDENT</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 d-flex justify-content-between ps-0">
              <div className="col-3 col-3-right">Site:</div>
              <div className="col-8">
                <input
                  type="text"
                  id="siteName"
                  name="siteName"
                  value={incidentDetails.siteName}
                  onChange={handleChange}
                  className="col-6 w-100"
                  style={{ height: "34px", paddingLeft: "8px" }}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 d-flex justify-content-between ps-0">
              <div className="col-3 col-3-right">Date and Time:</div>
              <div className="col-8 custom-datetime-local">
                {/* <Datetime
                  value={selectedDate}
                  onChange={(value) => setSelectedDate(value)}
                  id="latepayment-date-input"
                  name="paymentDate"
                  className="calendar-input"
                  inputProps={{
                    style: {
                      height: "35px",
                      backgroundColor: "transparent",
                      color: "#6f7172",
                      border: "1px solid rgb(204, 204, 204)",
                    },
                  }}
                  dateFormat="YYYY-MM-DD"
                  timeFormat={true}
                /> */}
                <input
                  type="datetime-local"
                  value={formatDateForInput(selectedDate)}
                  onChange={(e) => setSelectedDate(new Date(e.target.value))}
                  id="date-input"
                  name="paymentDate"
                  className="calendar-input"
                  style={{
                    height: "35px",
                    backgroundColor: "transparent",
                    color: "#6f7172",
                    border: "1px solid rgb(204, 204, 204)",
                    width: "100%",
                    paddingLeft: "8px",
                  }}
                />
                <div className="calendar-icon-wrapper">
                  <FaCalendarAlt className="calendar-icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 d-flex justify-content-between ps-0">
              <div className="col-3 col-3-right">Type:</div>
              <div
                className="col-8"
                style={{ alignSelf: "center", cursor: "pointer" }}
              >
                <RadioButton
                  name="type"
                  value={DRIVE_OFF}
                  checked={incidentDetails.type === DRIVE_OFF}
                  onChange={handleChange}
                  label={DRIVE_OFF}
                />
                <RadioButton
                  name="type"
                  value={IOU}
                  checked={incidentDetails.type === IOU}
                  onChange={handleChange}
                  label={IOU}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 d-flex justify-content-between ps-0">
              <div className="col-3 col-3-right">Pump Number:</div>
              <div className="col-8">
                <Dropdown
                  className="col-6 w-100"
                  style={inputTagStyle}
                  name="pumpNumber"
                  value={incidentDetails.pumpNumber}
                  options={pumps.map((pump) => ({
                    value: pump.number,
                    label: pump.number,
                  }))}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 d-flex justify-content-between ps-0 mt-4">
              <div className="col-3 col-3-right"></div>
              <div className="col-8 add-incident-heading-row">
                <span className="add-incident-heading ps-0 ">VEHICLE</span>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-between ps-0 mt-4">
              <div className="col-3 col-3-right"></div>
              <div className="col-8 add-incident-heading-row">
                <span className="add-incident-heading ps-0">CUSTOMER</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="row">
                <InputField
                  label="Licence Plate"
                  name="ocr"
                  value={incidentDetails.ocr}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-between ps-0">
                  <div className="col-3 col-3-right">Colour:</div>
                  <div className="col-8 text-left">
                    <Dropdown
                      style={inputTagStyle}
                      className="col-6 w-100"
                      name="vehicleColor"
                      value={incidentDetails.vehicleColor}
                      options={colors.map((color) => ({
                        value: color,
                        label: color,
                      }))}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-between ps-0">
                  <div className="col-3 col-3-right">Make:</div>
                  <div className="col-8 text-left">
                    <Dropdown
                      style={inputTagStyle}
                      className="col-6 w-100"
                      name="vehicleMake"
                      value={incidentDetails.vehicleMake}
                      options={models.map((model) => ({
                        value: model,
                        label: model,
                      }))}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-between ps-0">
                  <div className="col-3 col-3-right">Shape:</div>
                  <div className="col-8 text-left">
                    <Dropdown
                      style={inputTagStyle}
                      className="col-6 w-100"
                      name="vehicleShape"
                      value={incidentDetails.vehicleShape}
                      options={shapes.map((shape) => ({
                        value: shape,
                        label: shape,
                      }))}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            {renderTextBasedOnType()}
          </div>
          <div className="row">
            <div className="col-6 d-flex justify-content-between ps-0 mt-4">
              <div className="col-3 col-3-right"></div>
              <div className="col-8 add-incident-heading-row">
                <span className="add-incident-heading ps-0 pt-1">FUEL</span>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-between ps-0 mt-4">
              <div className="col-3 col-3-right"></div>
              <div className="col-8 add-incident-heading-row">
                <span className="add-incident-heading">
                  NOTES <span style={{ fontWeight: "normal" }}>(optional)</span>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 ps-0">
              <div className="col-12 d-flex justify-content-between ps-0">
                <div className="col-3 col-3-right">Fuel Type:</div>
                <div className="col-8">
                  <Dropdown
                    style={inputTagStyle}
                    className="col-6 w-100"
                    name="fuelType"
                    value={incidentDetails.fuelType}
                    options={fuelTypes.map((fuelType) => ({
                      value: fuelType,
                      label: fuelType,
                    }))}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <InputField
                label="Litres"
                name="fuelLitres"
                value={incidentDetails.fuelLitres}
                onChange={handleChange}
                required
              />
              <InputField
                label="Value $"
                name="fuelValue"
                value={incidentDetails.fuelValue}
                onChange={handleChange}
                required
              />
              <InputField
                label="Reference"
                name="fuelReference"
                value={incidentDetails.fuelReference}
                onChange={handleChange}
              />
            </div>
            <div className="col-6">
              <div className="col-12 d-flex justify-content-between ps-0">
                <div className="col-3 col-3-right"></div>
                <div className="col-8">
                  <textarea
                    id="note"
                    name="note"
                    value={incidentDetails.note}
                    onChange={handleChange}
                    className={`col-12 w-100 ${styles.customTextarea}`}
                    style={{
                      ...inputTagStyle,
                      width: "200px",
                      height: "150px",
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 d-flex justify-content-between ps-0">
              <div className="col-3"></div>
              <div className="col-8">
                {!loading && (
                  <button
                    type="submit"
                    className="add-incident-submit-btn"
                    style={{ backgroundColor: ctaBackgroundColor }}
                  >
                    Add Incident
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddIncident;
