import React from 'react';

const RadioButton = ({ name, value, checked, onChange, label }) => {
  return (
    <label className='pe-4 radio-group'>
      <input
        className='ps-2'
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span style={{ padding: '0px 5px' }}>{label}</span>
    </label>
  );
};

export default RadioButton;
