import React, { useEffect, useRef, useState } from "react";
import "../../CSS/ReportsPage.css";
import styles from "../../CSS/Base/Basic.module.css";
import userSession from "../../Constants/auth";
import MaterialUIMultiSelectCheckmarks from "../../Components/ViewReportPage/MaterialUIMultiSelectCheckmarks";
import UseReportsApi from "../../Custom Hooks/useReportsApi";
import ReportsBarChart from "../../Charts/ReportsBarChart";
import CustomizedTable from "../../Components/ViewReportPage/CustomizedTable";
import CSVDownloadButton from "../../Components/ViewReportPage/CSVDownloadButton";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ErrorModal from "../../Modals/ErrorModal";
import EmailModal from "../../Modals/EmailModal";
import { APP_SERVICES_URL } from "../../Constants/constants";
import PrintIcon from "@mui/icons-material/Print";
import LaunchIcon from "@mui/icons-material/Launch";
import EmailIcon from "@mui/icons-material/Email";
import { useApiDataContext } from "../../ApiDataContext";
import moment from "moment";
import RotatingIcon from "../../Components/Base/RotatingIcon";
import TitileReport from "../../Components/common/TitileReport";

const SiteTraffic = () => {
  const abortControllerRef = useRef(null);
  const { customersList, productsList, statesList } = useApiDataContext();
  const today = new Date();
  const threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  const formattedThreeMonthsAgo = `${threeMonthsAgo.getFullYear()}-${(
    threeMonthsAgo.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [startDate, setStartDate] = useState(formattedThreeMonthsAgo);
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]);
  // Additional states for startMonth and endMonth
  const [startMonth, setStartMonth] = useState(
    formattedThreeMonthsAgo.substring(0, 7)
  ); // YYYY-MM format
  const [endMonth, setEndMonth] = useState(
    today.toISOString().split("T")[0].substring(0, 7)
  ); // YYYY-MM format
  const [groupedData, setGroupedData] = useState([]);

  // Update startMonth and endMonth based on changes in startDate and endDate
  useEffect(() => {
    setStartMonth(startDate.substring(0, 7));
  }, [startDate]);

  useEffect(() => {
    setEndMonth(endDate.substring(0, 7));
  }, [endDate]);
  const [selectedState, setSelectedState] = useState(statesList);
  const [selectedBrand, setSelectedBrand] = useState(customersList);
  const [selectedSite, setSelectedSite] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(productsList);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const fetchData = UseReportsApi();
  const [selectedOption, setSelectedOption] = useState("monthly");
  const { userRole, CustID, sites, authHeader } = userSession;
  const getSitesNames = (sites) => {
    if (!sites || !Array.isArray(sites)) {
      return [];
    }
    return sites.map((site) => site.name);
  };
  const siteNames = getSitesNames(sites);
  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const [emailReport, setEmailReport] = useState(false);
  const openEmailModal = () => {
    setButtonClicked("emailReport");
    setEmailReport(true);
  };

  const closeEmailModal = () => {
    setEmailReport(false);
    setApiResponse(null);
  };
  // Function to process data
  const transformData = (data) => {
    if (!data || !data.periodicDetectionInfo) {
      return [];
    }

    return data.periodicDetectionInfo.map((periodInfo) => {
      const { period, dayTimeInfo } = periodInfo;

      let periodStart, periodEnd;

      // Check the format of the period and parse accordingly
      if (period.match(/^\d{1,2}\s\d{4}$/)) {
        const [month, year] = period.split(" ");
        const monthName = new Date(year, month - 1).toLocaleString("default", {
          month: "short",
        });
        periodStart = `01-${monthName}-${year}`;
        periodEnd = new Date(year, month, 0).toLocaleDateString("default", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      } else if (period.match(/^\d{2}-\w{3}-\d{4}\s+to\s+\d{2}-\w{3}-\d{4}$/)) {
        [periodStart, periodEnd] = period.split(" to ");
      }

      const result = [periodStart, periodEnd];

      dayTimeInfo.forEach((info) => {
        result.push(info.peakTime);
        result.push(info.detectionRate.toString());
      });

      return result;
    });
  };

  const formatDate = (dateString, isStart) => {
    const utcTime = moment.utc(dateString); // Assuming the UTC time is '2024-04-30T05:10:00'
    let localTime = utcTime.local(); // Convert to local time zone

    if (isStart) {
      // Set the time to 00:00:00 for the start date
      localTime.startOf("day");
    } else {
      localTime = localTime.endOf("day");
    }

    const localTimeWithoutOffset = localTime.format("YYYY-MM-DDTHH:mm:ss.SSS"); // Omitting the timezone offset

    return localTimeWithoutOffset;
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  const handleStateChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      if (selectedState.length === statesList.length - 1) {
        setSelectedState([]);
      } else {
        setSelectedState(statesList.slice(1));
      }
    } else {
      setSelectedState(selectedOptions);
    }
  };
  const handleBrandChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      if (selectedBrand.length === customersList.length - 1) {
        setSelectedBrand([]);
      } else {
        setSelectedBrand(customersList.slice(1));
      }
    } else {
      setSelectedBrand(selectedOptions);
    }
  };

  const handleSiteChange = (selectedValues) => {
    if (selectedValues.includes("Select All")) {
      if (selectedSite.length === sites.length) {
        // If all sites are already selected, unselect all
        setSelectedSite([]);
      } else {
        // Select all sites
        setSelectedSite(sites);
      }
    } else {
      setSelectedSite(selectedValues);
    }
  };

  const handleProductChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      if (selectedProduct.length === productsList.length - 1) {
        setSelectedProduct([]);
      } else {
        setSelectedProduct(productsList.slice(1));
      }
    } else {
      setSelectedProduct(selectedOptions);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    let formattedStartDate;
    let formattedEndDate;
    formattedStartDate = formatDate(startDate, true);
    formattedEndDate = formatDate(endDate, false);
    const isMonthly = selectedOption === "monthly";
    const payloadData = {
      to: formattedEndDate,
      from: formattedStartDate,
      states: selectedState,
      brands: selectedBrand,
      products: selectedProduct,
      sites: selectedSite,
    };
    // Check if selectedSite is not blank or has a length greater than 0
    if (selectedSite.length > 0) {
      // Abort the previous request if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // Create a new AbortController
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      try {
        setIsLoading(true);
        setError(false);

        const result = await fetchData(
          authHeader,
          CustID,
          "siteTraffic",
          payloadData,
          isMonthly,
          { signal: abortController.signal }
        );
        setData(result);
        const transformedData = transformData(result);
        setGroupedData(transformedData);
        setShowResult(true);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          setError(true);
          setErrorMessage(
            "Failed to fetch report data. Please try again. If the issue persists, contact support."
          );
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      // Handle the case when selectedSite is blank or has a length less than or equal to 0
      // You can set an error state or display a message to the user.
      setIsLoading(false);
    }
  };

  const handleCloseErrorModal = () => {
    setError(false);
  };

  function generatePDF(filename, emailAddress) {
    let input = document.getElementById("pdf-content");
    const hidden_content = document.getElementById("hidden-content");
    const report_created_time = document.getElementById("report-created-time");
    const pdf = new jsPDF("portrait", "mm", "a4");
  
    html2canvas(input, {
      scale: 6,
      useCORS: true,
      onclone: function (clonedDoc) {
        const cloned_hidden_content = clonedDoc.getElementById("hidden-content");
        const cloned_report_created_time = clonedDoc.getElementById("report-created-time");
  
        if (cloned_hidden_content) {
          console.log('Cloned hidden content found');
          cloned_hidden_content.classList.remove("d-none");
        } else {
          console.log('Cloned hidden content not found');
        }
  
        if (cloned_report_created_time) {
          console.log('Cloned report created time found');
          cloned_report_created_time.classList.remove("d-none");
        } else {
          console.log('Cloned report created time not found');
        }
      }
    }).then((canvas) => {
      hidden_content.classList.add("d-none");
      report_created_time.classList.add("d-none");
  
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width;
      let heightLeft = height;
      let pageHeight = pdf.internal.pageSize.getHeight();
      let position = 10;
      pdf.addImage(imgData, "JPEG", 0, position, width, height);
  
      heightLeft -= pageHeight - position;
      while (heightLeft >= 0) {
        position = heightLeft - height + 10;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, width, height);
        heightLeft -= pageHeight;
      }
  
      if (buttonClicked === "printPDF") {
        pdf.save(`${filename}.pdf`);
      } else if (buttonClicked === "emailReport" && emailAddress) {
        sendPDFToAPI(pdf.output("blob"), emailAddress, filename);
      }
    });
  }
  

  function sendPDFToAPI(pdfBlob, emailAddressToSend, filename) {
    // Create a FormData object to send the PDF as a file attachment
    const mailHeading = "Scancam Report PDF";
    const mailBody = "Please find attached the report you requested.";
    const formData = new FormData();
    formData.append("attachment", pdfBlob, `${filename}.pdf`);

    // Set up the API endpoint and headers
    const endpoint = `${APP_SERVICES_URL}/senapi/${CustID}/mailWithAttachment?subject=${mailHeading}&body=${mailBody}&mailTo=${emailAddressToSend}`;
    setApiResponse(null);
    setEmailReport(false);

    fetch(endpoint, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: authHeader,
      },
    })
      .then((response) => {
        if (response.ok) {
          setApiResponse(true);
          setEmailReport(true);
        } else {
          setApiResponse(false);
          setEmailReport(true);
        }
      })
      .catch((error) => {
        setApiResponse(false);
        setEmailReport(true);
      });
  }

  const simulateFormSubmit = async () => {
    // Create a fake event object with a preventDefault method
    const fakeEvent = {
      preventDefault: () => {},
    };

    // Call handleSubmit with the fake event
    await handleSubmit(fakeEvent);
  };

  useEffect(() => {
    simulateFormSubmit();
    // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    selectedSite,
    selectedBrand,
    selectedProduct,
    selectedState,
    selectedOption,
  ]);

  const handleReportTypeSelection = (reportType, emailAddress) => {
    // Handle the selected report type (detailed or summary) here.
    generatePDF("SitTraffic_Report", emailAddress);
  };

  const showReportsOptions =
    data &&
    data.averageDetectionDistribution &&
    Object.keys(data.averageDetectionDistribution).length > 0 &&
    selectedSite.length > 0 &&
    !error;
  const ErrorReportHeading = "Report Data Fetch Error";

  const formattedDateTime = new Date().toLocaleString("en-US", {
    dateStyle: "medium", // or 'short', 'long', 'full' as needed
    timeStyle: "short", // or 'medium', 'long', 'full' as needed
  });

  const canInteract =
    data &&
    data.averageDetectionDistribution &&
    Object.keys(data.averageDetectionDistribution).length > 0 &&
    selectedSite.length > 0 &&
    !error;

  const buttonClass = canInteract ? "action-btns" : "disable-btns";
  const textColor =
    showReportsOptions && textAccentColor ? textAccentColor : "inherit";

  return (
    <div className="w-dvw min-h-[65vh]">
      <div className="main-box">
        {emailReport && (
          <EmailModal
            show={emailReport}
            onClose={closeEmailModal}
            onSelectReportType={handleReportTypeSelection}
            apiResponse={apiResponse}
            reportName={"SiteTraffic"}
          />
        )}
        {error && (
          <ErrorModal
            show={true}
            onClose={handleCloseErrorModal}
            message={errorMessage}
            heading={ErrorReportHeading}
          />
        )}
        <div className="top-section bg-[#F4F4F4] pb-4">
          <div className="w-[1170px] mx-auto">
            <div className="h-[54px]">
              <TitileReport label={"Site Traffic"} />
            </div>
            <div className="">
              <div className="lg:flex md:flex text-[18px] h-[26px] text-[#B4B4B4] font-extrabold">
                <div className="w-[85%]">Filters</div>
                <div className="w-[15%]">Actions</div>
              </div>
              <div className="">
                <div className="lg:flex md:flex text-[18px] text-[#B4B4B4] font-extrabold">
                  <div className="w-[85%]">
                    <div className="grid grid-cols-6 grid-rows-1 text-[#6f7172]">
                      <div className="text-sm h-[64px] pe-[15px]">
                        <div className="h-[20px]">Cycle</div>
                        <div className="h-[44px] flex">
                          <div className="flex items-center text-sm">
                            <label className="checkbox-container text-sm">
                              Monthly
                              <input
                                className="custom-checkbox"
                                value="monthly"
                                checked={selectedOption === "monthly"}
                                onChange={() => handleOptionChange("monthly")}
                                type="checkbox"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>

                          <div className="ml-4 flex items-center">
                            <label className="checkbox-container">
                              Weekly
                              <input
                                className="custom-checkbox"
                                value="weekly"
                                checked={selectedOption === "weekly"}
                                onChange={() => handleOptionChange("weekly")}
                                type="checkbox"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="text-sm  h-[64px] pe-[15px]">
                        <div className="h-[20px]">Start date</div>
                        <div className="h-[44px]">
                          <input
                            className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                            type="date"
                            id="startDate"
                            value={startDate}
                            onChange={handleStartDateChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="text-sm h-[64px] pe-[15px]">
                        <div className="h-[20px]">End date</div>
                        <div className="h-[44px]">
                          <input
                            className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                            type="date"
                            id="endDate"
                            value={endDate}
                            onChange={handleEndDateChange}
                            required
                          />
                        </div>
                      </div>

                      {userRole !== "site-manager" && (
                        <>
                          <div className="text-sm h-[64px] pe-[15px]">
                            <div className="h-[20px]">State</div>
                            <div className="h-[44px]">
                              <MaterialUIMultiSelectCheckmarks
                                options={statesList}
                                setSelectedStateFunction={setSelectedState}
                                initialStateAllSelected={true}
                              />
                            </div>
                          </div>
                          <div className="text-sm h-[64px] pe-[15px]">
                            <div className="h-[20px]">Brand</div>
                            <div className="h-[44px]">
                              <MaterialUIMultiSelectCheckmarks
                                options={customersList}
                                setSelectedStateFunction={setSelectedBrand}
                                initialStateAllSelected={true}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="text-sm h-[64px] pe-[15px]">
                        <div className="h-[20px]">Sites</div>
                        <div className="h-[44px]">
                          <MaterialUIMultiSelectCheckmarks
                            options={siteNames}
                            setSelectedStateFunction={setSelectedSite}
                            initialStateAllSelected={false}
                          />
                        </div>
                      </div>

                      {userRole !== "site-manager" && (
                        <div className="text-sm h-[64px] pe-[15px]">
                          <div className="h-[20px]">Product</div>
                          <div className="h-[44px]">
                            <MaterialUIMultiSelectCheckmarks
                              options={productsList}
                              setSelectedStateFunction={setSelectedProduct}
                              initialStateAllSelected={true}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-[15%]">
                  <div className="grid grid-rows-3">
                    <div
                      className={`flex items-center space-x-1 text-base ${
                        canInteract ? "rule" : "disable-btns"
                      }`}
                      onClick={
                        canInteract
                          ? () => {
                              setButtonClicked("printPDF");
                              generatePDF("SiteTraffic_Report");
                            }
                          : null
                      }
                      style={{
                        color:
                          canInteract && textAccentColor
                            ? textAccentColor
                            : "inherit",
                        cursor: canInteract ? "pointer" : "not-allowed",
                      }}
                    >
                      <span>
                        <PrintIcon style={{ fontSize: "16px" }} />
                      </span>
                      <span>Print-friendly PDF</span>
                    </div>
                    <div
                      className={`flex items-center space-x-1 text-base ${
                        canInteract ? "rule" : "disable-btns"
                      }`}
                      style={{
                        color:
                          canInteract && textAccentColor
                            ? textAccentColor
                            : "inherit",
                        cursor: canInteract ? "pointer" : "not-allowed",
                      }}
                    >
                      {canInteract ? (
                        <CSVDownloadButton
                          data={groupedData}
                          reportName={"scancam-site-traffic-report"}
                        />
                      ) : (
                        <>
                          <span>
                            <LaunchIcon style={{ fontSize: "16px" }} />
                          </span>
                          <span>Export as CSV</span>
                        </>
                      )}
                    </div>
                    <div
                      className={`flex items-center space-x-1 text-base ${
                        canInteract ? "rule" : "disable-btns"
                      }`}
                      onClick={canInteract ? openEmailModal : null}
                      style={{
                        color:
                          canInteract && textAccentColor
                            ? textAccentColor
                            : "inherit",
                        cursor: canInteract ? "pointer" : "not-allowed",
                      }}
                    >
                      <span>
                        <EmailIcon style={{ fontSize: "16px" }} />
                      </span>
                      <span>Email report</span>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <RotatingIcon colorValue={"grey"} />
        </div>
      ) : (
        <div  id="pdf-content">
          <div className="w-[1170px] mx-auto">
          <div
            id="report-created-time"
            className="text-end mt-2 report-created-time d-none"
          >
            Report created {formattedDateTime} by
            <span className="ps-1 pe-2">{`${userSession.username}`}</span>
          </div>
          <div id="hidden-content" className="d-none">
            <h1 className="report-heading m-1 p-1">Site Traffic Reports</h1>
            <h4 className="m-1 p-1">Detail</h4>
            <div className="mt-3">
              <div className="row ms-1">
                <div className="col-6"> Date range :</div>
                <div className="col-6"> Sites :</div>
              </div>
              <div className="row ms-1 report-range">
                <div className="col-6">
                  {`${startDate}`} - {`${endDate}`}
                </div>
                <div className="col-6"> {`${selectedSite.toString()}`}</div>
              </div>
            </div>
          </div>
          </div>
          {data &&
          data.averageDetectionDistribution &&
          Object.keys(data.averageDetectionDistribution).length > 0 &&
          selectedSite.length > 0 &&
          !error ? (
            <div className="chart-Box">
              <div className="chart-heading">
                Average Vehicle Detections per Hour
              </div>
              <ReportsBarChart
                data={data.averageDetectionDistribution}
                label={"siteTraffic"}
              />
            </div>
          ) : null}
          <div
            className="col-sm-12 d-flex justify-content-center"
            style={{ maxWidth: "1170px", margin: "auto" }}
          >
            {/* json display */}
            {showResult === false ||
            (showResult === true && selectedSite.length === 0) ? (
              <div
                className="col-sm-12 text-center mt-3"
                style={{
                  fontSize: "20px",
                  color: "#6f7172",
                  paddingTop: "50px",
                }}
              >
                <p>At least one Site must be selected.</p>
              </div>
            ) : !data ||
              (Object.keys(data.periodicDetectionInfo).length === 0 &&
                data.periodicDetectionInfo.length === 0) ? (
              <div
                className="col-sm-12 text-center mt-3"
                style={{
                  fontSize: "20px",
                  color: "#6f7172",
                  paddingTop: "50px",
                }}
              >
                <p>There are no incidents which match your search criteria.</p>
              </div>
            ) : (
              <div className="body-content" style={{ width: "1170px" }}>
                <div className="chart-heading">
                Peak Times
                </div>
                <div className="d-flex justify-content-around report-heading-text">
                  <div>MORNING</div>
                  <div>AFTERNOON</div>
                  <div>EVENING</div>
                </div>
                <CustomizedTable
                  heading={[
                    "PERIOD",
                    "Peak Time",
                    "Detections / 15 mins",
                    "Peak Time",
                    "Detections / 15 mins",
                    "Peak Time",
                    "Detections / 15 mins",
                  ]}
                  reportName={"siteTraffic"}
                  data={data}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SiteTraffic;

{
  /* <div className="col-sm-12 second-box">
<div className={`row ${styles.TitleSection}`}>
  <div className="col-sm-12 d-flex">
    <div className="col-sm-12">
      <h2 className={styles.Title}>Site Traffic Reports</h2>
    </div>
  </div>
</div>
<div className="row search-bar pb-4">
  <div className="col-sm-12 d-flex">
    <div className="col-sm-12">
      <div className="col-sm-12 d-flex">
        <div className="col-sm-10 report-filters-actions-heading">
          Filters
        </div>
        <div className="col-sm-2 ps-3 report-filters-actions-heading">
          Export
        </div>
      </div>
      <div className="col-sm-12 d-flex">
        <div className="col-sm-10">
          {/* search code here */
}

//   <div className="row p-0">
//     <form onSubmit={handleSubmit}>
//       <div className="col-sm-12 d-flex">
//         <div className="date-option-buttons div-item">
//           <div className="mb-2">Cycle</div>
//           <label className="radio">
//             <input
//               type="radio"
//               value="monthly"
//               checked={selectedOption === "monthly"}
//               onChange={() => handleOptionChange("monthly")}
//             />
//             <span className="ps-2 pe-2">Monthly</span>
//           </label>
//           <label className="radio">
//             <input
//               type="radio"
//               value="weekly"
//               checked={selectedOption === "weekly"}
//               onChange={() => handleOptionChange("weekly")}
//             />
//             <span className="ps-2 pe-4">Weekly</span>
//           </label>
//         </div>
//         <div className="col-sm-2 div-item ">
//           <div className="col-sm-12">Start Date</div>
//           <div className="col-sm-12">
//             <input
//               className=" report-date-field date-hover"
//               type={
//                 selectedOption === "monthly"
//                   ? "month"
//                   : "date"
//               }
//               style={{
//                 marginTop: "10px",
//                 height: "2.8rem",
//                 fontSize:
//                   selectedOption === "monthly"
//                     ? "14px"
//                     : "19px",
//               }}
//               id="startDate"
//               value={
//                 selectedOption === "monthly"
//                   ? startMonth
//                   : startDate
//               }
//               onChange={handleStartDateChange}
//             />
//           </div>
//         </div>
//         <div className="col-sm-2 div-item">
//           <div className="col-sm-12">End Date</div>
//           <div className="col-sm-12">
//             <input
//               className=" report-date-field date-hover"
//               type={
//                 selectedOption === "monthly"
//                   ? "month"
//                   : "date"
//               }
//               style={{
//                 marginTop: "10px",
//                 height: "2.8rem",
//                 fontSize:
//                   selectedOption === "monthly"
//                     ? "14px"
//                     : "19px",
//               }}
//               id="endDate"
//               value={
//                 selectedOption === "monthly"
//                   ? endMonth
//                   : endDate
//               }
//               onChange={handleEndDateChange}
//             />
//           </div>
//         </div>
//         <div className="col-sm-2 div-item">
//           <div className="col-sm-12 mb-2">Sites</div>
//           <div className="col-sm-12 dropdown-container">
//             <MaterialUIMultiSelectCheckmarks
//               options={siteNames}
//               setSelectedStateFunction={setSelectedSite}
//               initialStateAllSelected={false}
//             />
//           </div>
//         </div>
//         {userRole !== "site-manager" && (
//           <div className="col-sm-2 div-item">
//             <div className="col-sm-12 mb-2">Brand</div>
//             <div className="col-sm-12 dropdown-container">
//               <MaterialUIMultiSelectCheckmarks
//                 options={customersList}
//                 setSelectedStateFunction={setSelectedBrand}
//                 initialStateAllSelected={true}
//               />
//             </div>
//           </div>
//         )}
//       </div>
//       <div className="row">
//         {userRole !== "site-manager" && (
//           <div className="col-sm-2 div-item">
//             <div className="col-sm-12 mb-2">State</div>
//             <div className="col-sm-12 dropdown-container">
//               <MaterialUIMultiSelectCheckmarks
//                 options={statesList}
//                 setSelectedStateFunction={setSelectedState}
//                 initialStateAllSelected={true}
//               />
//             </div>
//           </div>
//         )}
//         {userRole !== "site-manager" && (
//           <div className="col-sm-2 div-item">
//             <div className="col-sm-12 mb-2">Product</div>
//             <div className="col-sm-12 dropdown-container">
//               <MaterialUIMultiSelectCheckmarks
//                 options={productsList}
//                 setSelectedStateFunction={setSelectedProduct}
//                 initialStateAllSelected={true}
//               />
//             </div>
//           </div>
//         )}
//       </div>
//     </form>
//   </div>
// </div>
//         <div className="col-sm-2 ps-4">
//           <span
//             className={`${
//               data &&
//               data.averageDetectionDistribution &&
//               Object.keys(data.averageDetectionDistribution).length >
//                 0 &&
//               selectedSite.length > 0 &&
//               !error
//                 ? "action-btns"
//                 : "disable-btns"
//             } row p-0`}
//             style={{
//               color:
//                 showReportsOptions && textAccentColor
//                   ? textAccentColor
//                   : "inherit",
//             }}
//             onClick={
//               data &&
//               data.averageDetectionDistribution &&
//               Object.keys(data.averageDetectionDistribution).length >
//                 0 &&
//               selectedSite.length > 0 &&
//               !error
//                 ? () => {
//                     setButtonClicked("printPDF"); // Set the buttonClicked state to 'printpdf'
//                     generatePDF("SiteTraffic_Report");
//                   }
//                 : null
//             } // Conditionally call the function
//           >
//             <div className="d-flex ps-0">
//               <span className="pe-1">
//                 <PrintIcon style={{ fontSize: "18px" }} />
//               </span>
//               <span>Print-friendly PDF</span>
//             </div>
//           </span>
//           <span
//             className={`${
//               data &&
//               data.averageDetectionDistribution &&
//               Object.keys(data.averageDetectionDistribution).length >
//                 0 &&
//               selectedSite.length > 0 &&
//               !error
//                 ? "action-btns"
//                 : "disable-btns"
//             } row p-0`}
//             style={{
//               color:
//                 showReportsOptions && textAccentColor
//                   ? textAccentColor
//                   : "inherit",
//             }}
//           >
//             {data &&
//             data.averageDetectionDistribution &&
//             Object.keys(data.averageDetectionDistribution).length >
//               0 &&
//             selectedSite.length > 0 &&
//             !error ? (
//               <CSVDownloadButton
//                 data={groupedData}
//                 reportName={"scancam-site-traffic-report"}
//               />
//             ) : (
//               <div className="d-flex ps-0 disable-btns">
//                 <span className="pe-1">
//                   <LaunchIcon style={{ fontSize: "18px" }} />
//                 </span>
//                 <span>Export as CSV</span>
//               </div>
//             )}
//           </span>
//           <span
//             className={`${
//               data &&
//               data.averageDetectionDistribution &&
//               Object.keys(data.averageDetectionDistribution).length >
//                 0 &&
//               selectedSite.length > 0 &&
//               !error
//                 ? "action-btns"
//                 : "disable-btns"
//             } row p-0`}
//             onClick={
//               data &&
//               data.averageDetectionDistribution &&
//               Object.keys(data.averageDetectionDistribution).length >
//                 0 &&
//               selectedSite.length > 0 &&
//               !error
//                 ? openEmailModal
//                 : null
//             }
//             style={{
//               color:
//                 showReportsOptions && textAccentColor
//                   ? textAccentColor
//                   : "inherit",
//             }}
//           >
//             <div className="d-flex ps-0">
//               <span className="pe-1">
//                 <EmailIcon style={{ fontSize: "18px" }} />
//               </span>
//               <span>Email report</span>
//             </div>
//           </span>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div> */}
