import React from 'react';
import styled from '@emotion/styled';
import '../../CSS/AddIncident.css'

const CustomSelect = ({ label, options, selectedValue, onChange }) => {
    return (
      <div className="select-container">
        <label className="select-label">{label}</label>
        <select className="styled-select" value={selectedValue} onChange={onChange}>
          <option value="" disabled></option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="arrow-icon" />
      </div>
    );
  };
  
  export default CustomSelect;