import React, { createContext, useContext } from 'react';
import useApiData from './Custom Hooks/useApiData'; // Adjust the path accordingly

const ApiDataContext = createContext();

export const useApiDataContext = () => {
    const context = useContext(ApiDataContext);
    if (!context) {
        throw new Error('useApiDataContext must be used within an ApiDataProvider');
    }
    return context;
};

export const ApiDataProvider = ({ children }) => {
    const apiData = useApiData();

    return <ApiDataContext.Provider value={apiData}>{children}</ApiDataContext.Provider>;
};
