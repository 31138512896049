import styles2 from "../../CSS/FAQ.module.css";
import { Link } from "react-router-dom";
import img1 from "../../Assets/FaqImages/faq-obstruction.jpg";
import img2 from "../../Assets/FaqImages/faq-exposure.jpg";
import img3 from "../../Assets/FaqImages/faq-badparking.jpg";
import img4 from "../../Assets/FaqImages/faq-badplate.jpg";
import img5 from "../../Assets/FaqImages/trouble-vault.jpg";
import img6 from "../../Assets/FaqImages/trouble-pepwave.png";
import img7 from "../../Assets/FaqImages/trouble_pepwave_front.png";
import img8 from "../../Assets/FaqImages/trouble-pepwave_back.png";
import troubleNAS from "../../Assets/FaqImages/trouble-NAS.jpg";
import troubleNASPower from "../../Assets/FaqImages/trouble-NAS-power.webp";
import troubleSwitch from "../../Assets/FaqImages/trouble-switch.jpg";
import troubleCameras from "../../Assets/FaqImages/trouble-cameras.jpg";
import { BsArrowRightCircle } from "react-icons/bs";
import { useState } from "react";
import userSession from "../../Constants/auth";
import Accordion from "../../Components/common/Accordion";

const FaqData = ({ activeText }) => {
  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const FaqContentHardware1 = (
    <div>
      <div>
        <p>We call it the Scancam Vault.</p>
        <p>
          Usually located in the manager’s office, the “Vault” is a small rack
          containing the devices which make the Scancam System function.
        </p>
        <p>
          If there is a technical difficulty, our support team may direct you to
          check devices located within the vault (or onsite rack for some sites)
          for troubleshooting. It is important to get to know the components of
          the vault so you may assist support with troubleshooting to quickly
          rectify an issue.
        </p>
      </div>
      <div>
        <img src={img5} alt="trouble_vault" />
      </div>
    </div>
  );

  const FaqContentHardware2 = (
    <div className="col-sm-12 d-flex">
      <div className="col-sm-6">
        <p>
          The Pepwave is a modem/router with a bunch of antennas sticking out
          the back. The Pepwave provides the network for your Scancam System
          including the Wi-Fi connection for the iPad.
        </p>
        <p>
          The Pepwave requires an internet connection for the Scancam system to
          function correctly. If the internet connection is lost then the iPad
          will be unable to display licence plates.
        </p>
      </div>
      <div className="cols-sm-6">
        <img src={img6} alt="trouble-pepwave" />
      </div>
    </div>
  );
  const FaqContentHardware3 = (
    <div>
      <p>
        Occasionally the Pepwave may need a reboot due to a connection error. To
        reboot the modem:
      </p>
      <p>
        1.Remove the cable from the back of the device or unplug the power plug
        labelled Pepwave from the PDU.
      </p>
      <p>
        2.Wait five seconds then plug it back in. The modem usually takes around
        two minutes to reboot.
      </p>
      <p>
        Once the two LED lights on the front of the modem turn green then it is
        ready.
      </p>
      <div>
        <img src={img7} alt="trouble_pepwave_front" />
      </div>
    </div>
  );

  const FaqContentHardware4 = (
    <div className={`card card-body ${styles2.faqDescription}`}>
      <p>On the back of the modem are several ports.</p>
      <p>
        1. Ensure that the cable going into the WAN port is firmly inserted. The
        other end of this cable will connect to your site’s modem, again ensure
        it is connected correctly. This cable provides the internet connection.
      </p>
      <p>
        2. Ensure that the cable going into the LAN port is firmly inserted. The
        other end of this cable will connect to a port on the switch.
      </p>
      <div>
        <img src={img8} alt="trouble-pepwave_back" />
      </div>
    </div>
  );

  const FaqContentHardware5 = (
    <div className="col-sm-12 d-flex">
      <div className="col-sm-6">
        <p>NAS stands for Network Attached Storage.</p>
        <p>
          The NAS stores all the footage from the cameras. It must be ON and
          connected to the Scancam network at all times for the footage to be
          recorded.
        </p>
        <p>
          The STATUS LED will be green when the NAS is functioning correctly.
          The other LED lights labelled DISK 1, DISK 2, DISK 3 and DISK 4 will
          flicker green indicating the hard drives are in use.
        </p>
        <p>The NAS power button will have a blue LED when it is on.</p>
        <div>
          <img src={troubleNASPower} alt="troubleNASPower" />
        </div>
      </div>
      <div className="col-sm-6">
        <img src={troubleNAS} alt="trouble-NAS" />
      </div>
    </div>
  );

  const FaqContentHardware6 = (
    <div>
      <p>
        Press and hold the power button (for up to 10 seconds) on the bottom
        right corner of the NAS until you hear a loud beep. Once you hear the
        beep let go of the button. The beep indicates that the NAS is shutting
        down. Once all LED lights are off the NAS has completed its shut down.
      </p>
      <p>
        To boot the NAS back up press the power button. The NAS usually takes
        about two minutes to boot up.
      </p>
    </div>
  );

  const FaqContentHardware7 = (
    <div className="col-sm-12 d-flex">
      <div className="col-sm-6">
        <p>
          The switch provides the network connections to all devices on the
          system and also powers the cameras and infrared illuminators.
        </p>
        <p>
          The cables from the switch will be connected to a patch panel. If any
          of the cables in the switch or patch panel are loose or not connected
          firmly then there will be a loss of connectivity to a device.
        </p>
        <p>
          Do not move cables from the switch or patch panel unless instructed to
          by Scancam Support.
        </p>
      </div>
      <div className="col-sm-6">
        <img src={troubleSwitch} alt="troubleSwitch" />
      </div>
    </div>
  );

  const FaqContentHardware8 = (
    <div>
      Unplug the power cable marked Netgear switch from the power board (PDU)
      and wait for five seconds before plugging it back in again. The switch
      usually takes around two minutes to reboot.
    </div>
  );

  const FaqContentHardware9 = (
    <div className="col-sm-12 d-flex">
      <div className="col-sm-6">
        <p>Mobotix Cameras</p>
        <p>
          The cameras have a Red and Green LED light on the front. During normal
          operation both of the LED lights will flash intermittently. If there
          are no LED lights active on the camera then email Scancam Support
          (scancam.support@sensen.ai).
        </p>
        <p>
          The cameras used for the Scancam System licence plate capture and CCTV
          surveillance. It is important to ensure the lenses are free of dirt
          and the camera view is not obstructed.
        </p>
      </div>
      <div className="col-sm-6">
        <img src={troubleCameras} alt="troubleCameras" />
      </div>
    </div>
  );
  const faqDataHardware = [
    {
      title: "What is all that stuff in the Scancam cabinet?",
      content: FaqContentHardware1,
    },
    {
      title: "What is a Pepwave Modem?",
      content: FaqContentHardware2,
    },
    {
      title: "How do I reboot the Pepwave Modem?",
      content: FaqContentHardware3,
    },
    {
      title: "How do I Check the Cable Connections on the Pepwave Modem?",
      content: FaqContentHardware4,
    },
    {
      title: "What is a NAS?",
      content: FaqContentHardware5,
    },
    {
      title: "How do I reboot the NAS?",
      content: FaqContentHardware6,
    },
    {
      title: "What is the Switch?",
      content: FaqContentHardware7,
    },
    {
      title: "How do I reboot the Switch?",
      content: FaqContentHardware8,
    },
    {
      title: "How can I tell if the Cameras are Working?",
      content: FaqContentHardware9,
    },
  ];

  // Update hover state for each <p> tag
  const faqDataPortal = [
    {
      title: "I have forgot my password for the Portal, can I reset it?",
      content: `
        Yes, under the login area click “Don’t remember your password” and then enter your email address. Click the 'Send Email' button and you will receive instructions on how to reset your password.
      `,
    },
    {
      title: "Is it ok to verify an incident without the footage?",
      content: `
        <p>No, under no circumstances should you verify any incident without the correct footage. The footage along with the other incident details are used as evidence in the debt recovery process.</p>
        <p>If an incident is verified without the correct footage then the incident will be 'Returned To Site' so it can be corrected.</p>
      `,
    },
    {
      title: "I'm having trouble generating footage?",
      content: `
        If there is a problem generating footage for a particular incident, click the 'Need help' link on the top right on the incident report page and send a help request.
      `,
    },
    {
      title: "How does a customer get their vehicle off the pre-pay list?",
      content: `
        <p>Always direct customers to Fuel Recovery Services (FRS). FRS manage all the vehicles on the pre-pay list and will be able to assist the customer. Even if a vehicle is stolen or if a customer has recently purchased a vehicle that is on the pre pay list, FRS will be able to help them.</p>
        <p>Give the customer an FRS Card which contains all of their contact details. If you don't have a card then the FRS contact phone number is <b>1300 024 851</b> and their website is <b>www.fuelrecoveryservices.com.au</b></p>
      `,
    },
    {
      title: "Can I take payment onsite after 4 days?",
      content: `
        <p>Yes, management can take a late onsite payment via the portal. Late Onsite Payments should only be used as a last resort and are only usually required if a customer demands to leave payment at the site (or refuses to pay via FRS).</p>
        <p>Please note that after the 4 day amnesty period the incident will have been escalated to debt recovery where fees and charges will apply. All Onsite Late Payments fees are paid for by the site.</p>
      `,
    },
    {
      title:
        "I created an IOU incident but it should be a Drive-Off, can I change it?",
      content: `
        <p>No. Once an incident is created it remains either an IOU or Drive-Off. It is not possible to change it from one to the other.</p>
        <p>Scancam recommend that you create a new incident with the same details as the old one and then close the one you no longer need. It's also a good idea to add a note to the incident you want to close so you have a record of why it was closed.</p>
      `,
    },
  ];

  const ipadContenttext1 = (
    <span>
      Follow the
      <span className="ps-1 pe-1">
        <Link to="/scancam/Training/Troubleshooting">iPad Offline</Link>
      </span>
      troubleshooting guide on how to check the iPad's connection.
    </span>
  );
  const ipadContenttext2 = (
    <>
      <p>
        Not 100% of all regos can be read automatically. There are two main
        reasons why a rego may not appear on the iPad.
      </p>
      <p>1. Environmental Condtions</p>
      <div className="col-sm-12 d-flex">
        <div className="col-sm-5">Obstructions</div>
        <div className="col-sm-2"></div>
        <div className="col-sm-5">Exposure</div>
      </div>
      <div className="col-sm-12 d-flex">
        <div className="col-sm-5">
          <img src={img1} alt="obstruction_Image" />
        </div>
        <div className="col-sm-2"></div>
        <div className="col-sm-5">
          <img src={img2} alt="exposure_image" />
        </div>
      </div>
      <div className="col-sm-12 d-flex">
        <div className="col-sm-5">Bad Parking</div>
        <div className="col-sm-2"></div>
        <div className="col-sm-5">Bad Plate</div>
      </div>
      <div className="col-sm-12 d-flex">
        <div className="col-sm-5">
          <img src={img3} alt="bad_parking_image" />
        </div>
        <div className="col-sm-2"></div>
        <div className="col-sm-5">
          <img src={img4} alt="bad_plate_image" />
        </div>
      </div>
      <p>2. Connectivity</p>
      <p>
        <span>
          If you're having issues with the connection follow the
          <span className="ps-1 pe-1">
            <Link to="https://sci-portal.scancam.com.au/Training/Troubleshooting">
              iPad Offline
            </Link>
          </span>
          troubleshooting guide to help diagnose the problem.
        </span>
      </p>
    </>
  );

  const FaqCustomerEnquireiesData1 = (
    <div>
      <p>
        If a debt is within the "4 day amnesty period” any debt can be paid back
        in-store without any fees or charges. The payment should be put through
        the POS and then through the Scancam system via the iPad, under ’Take
        Payment’ feature. If the debt was an 'IOU' then the customer will be
        sent an SMS from Scancam with a payment link to pay online with a
        corresponding processing fee added.
      </p>
      <p className="pt-4">
        If a debt is NOT within the "4 day amnesty period” a recovery fee may
        apply to the customer. Direct all customers to Fuel Recover Services
        (FRS) by handing them an FRS card. Customers can make enquiries and
        payments via
        <b>www.fuelrecoveryservices.com.au</b> or by calling
        <b>1300 024 851.</b>
      </p>
      As a last resort to reduce in-store customer conflict, management can take
      an Onsite Late Payment via the portal- however fees and charges may apply.
    </div>
  );
  if (activeText === "iPad") {
    return (
      <>
        <div className="container mx-auto p-0">
          <Accordion
            title="The network status icon is orange and displays 'Degraded'."
            content="Degarded means that the connection to the network is slower than
            usual. The iPad will continue to display licence plates during the
            degraded state. The issue usually resolves itself once the netowrk
            connection improves."
          />
        </div>
        <div className="container mx-auto p-0">
          <Accordion
            title="The iPad app crashed and is just showing a white screen. How can I fix
          it?"
            content=" Follow the iPad App Crashes/Frozen troubleshooting guide to help
            resolve the issue.."
          />
        </div>

        <div className="container mx-auto p-0">
          <Accordion
            title="Should I accept the iPad update when prompted?"
            content="Yes, always accept updates when requested. Scancam works hard to
            improve the system and continually updates the iPad application,
            always accept both the Apple and Scancam App updates when prompted."
          />
        </div>

        <div className="container mx-auto p-0">
          <Accordion
            title="What is the iPad Passcode?"
            content=" 000000 or 0000 (all zero’s)"
          />
        </div>
        <div className="container mx-auto p-0">
          <Accordion
            title="The iPad isn't charging even though it's plugged in."
            content="Check your charging cable and USB adapter for signs of damage, like
            breakage or bent prongs. Don't use damaged accessories. Use a wall
            power outlet and check for firm connections between your charging
            cable, USB wall adapter, and wall outlet, or try a different outlet.
            Remove any debris from the charging port on the bottom of your
            device, then firmly plug your charging cable into your device. Avoid
            using third party cables and adapters. Always use the genuine
            charger and cable that is supplied with the iPad."
          />
        </div>

        <div className="container mx-auto p-0">
          <Accordion
            title="What is the name of the Wi-Fi network that the iPad needs to connect to?"
            content="SmartFuelSecurity"
          />
        </div>

        <div className="container mx-auto p-0">
          <Accordion
            title="I think my iPad is not connected to the Wi-Fi network?"
            content={ipadContenttext1}
          />
        </div>

        <div className="container mx-auto p-0">
          <Accordion
            title="Why is the iPad not displaying any licence plates?"
            content="This could be due to a poor connection, camera issue or Scancam
            server problem. Follow the iPad Not Showing Plates troubleshooting
            guides to help resolve the issue."
          />
        </div>

        <div className="container mx-auto p-0">
          <Accordion
            title="Sometimes the iPad doesn't show the licence plate but there is a car
          at the bowser. Why is that?"
            content={ipadContenttext2}
          />
        </div>
      </>
    );
  } else if (activeText === "Portal") {
    return (
      <>
        {faqDataPortal.map((content, index) => (
          <div className="container mx-auto p-0" key={index}>
            <Accordion title={content.title} content={content.content} />
          </div>
        ))}
      </>
    );
  } else if (activeText === "Hardware") {
    return (
      <>
        {faqDataHardware.map((faqData, index) => (
          <div className="container mx-auto p-0" key={index}>
            <Accordion title={faqData.title} content={faqData.content} />
          </div>
        ))}
      </>
    );
  } else if (activeText === "Customer Enquiries") {
    return (
      <div className="container mx-auto p-0">
        <Accordion
          title="How does a customer pay for unpaid fuel debts?"
          content={FaqCustomerEnquireiesData1}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default FaqData;
