
import React, { useState } from "react";
import style1 from "../../CSS/notes.module.css"; // Import the CSS file
import { MdArrowBackIosNew } from "react-icons/md"
import { Link, useParams } from "react-router-dom";
import { APP_SERVICES_URL } from "../../Constants/constants";
import userSession from "../../Constants/auth";
import moment from "moment";
// import SimpleBackdrop from '../Base/SimpleBackdrop';
function Notes({ sharedData, setIncidentDetails }) {
  const [buttonActive, setButtonActive] = useState(true);
  const formatDateTime = (dateTimeString) => {
    // Create a moment object from the input date-time string
    const momentDateTime = moment(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSS");

    // Format the moment object to 12-hour format with AM/PM
    const formattedTime = momentDateTime.format('h:mm A'); // 12-hour format with AM/PM

    // Format the date in the desired format (DD/MM/YYYY)
    const localDateString = momentDateTime.format('DD/MM/YYYY');

    return (
      <>
        {`${localDateString} ${formattedTime.toUpperCase()}`}
      </>
    );
  };
  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const handleBackButtonClick = () => {
    setSelectedRow(null);
  };
  const { authHeader, CustID } = userSession;
  const ctaBackgroudColor = userSession.themeData?.ctaBackgroudColor || '#e27a13';
  const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const { pkAlert } = useParams();

  const fetchDataAndUpdateNotes = async () => {
    try {
      // Fetch data from API
      setLoading(true); // Set loading to true before the fetch operation
      const apiUrl = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident?alertID=${pkAlert}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Authorization": authHeader,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setIncidentDetails(responseData);
      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.error("Error fetching data from the API:", error);
    }
  };

  const patchAPI = async (payloadData) => {
    const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident?alertID=${pkAlert}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: authHeader,
    };
    try {
      setButtonActive(false);
      const response = await fetch(url, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(payloadData),
      });

      // Additional handling if needed based on the response status, etc.
      if (response.ok) {
        // Success handling
        fetchDataAndUpdateNotes();
        setButtonActive(true);
      } else {
        // Handle other response statuses
      }
    } catch (error) {
      setButtonActive(true);
      console.error('Error:', error);
    } finally {
      setButtonActive(true);
      console.error('Error:');
    }
  };

  //add new note area
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [newNote, setNewNote] = useState("");

  const handleAddNoteClick = () => {
    setIsAddingNote(true);
  };

  const handleDiscardNoteClick = () => {
    setIsAddingNote(false);
    setNewNote(""); // Clear the new note content
  };

  const handleSaveNoteClick = () => {
    if (newNote.trim().length === 0) {
      setIsAddingNote(false);
      setNewNote(""); // Clear the new note content
      return; // Exit early if the new note is empty or contains only whitespace
    }
    // Assuming you want to save the new note using patchAPI
    const payload = { note: newNote };
    patchAPI(payload)
      .then(() => {
        setIsAddingNote(false);
        setNewNote(""); // Clear the new note content
      })
      .catch((error) => {
        console.error("Error saving note:", error);
      });
  };

  return (
    <div className={style1.notesBox}>
      {/* use if needed to show loader on whole page */}
      {/* <SimpleBackdrop open={loading} onClose={() => setLoading(false)} /> */}
      {isAddingNote ? ( // Display the textarea for adding a new note
        <div className={style1.notesTableEditMainBox}>
          <textarea
            className={style1.notesTableEditBox}
            value={newNote}
            maxLength={500}
            onChange={(e) => setNewNote(e.target.value)}
            placeholder="Enter your new note here"
          />
          <div className={style1.notesTableEditButtonBox}>
            <Link className={style1.noteCancelbtn} onClick={handleDiscardNoteClick}>Discard</Link>
            {buttonActive && <button className={style1.NoteBtn} style={{ backgroundColor: ctaBackgroudColor }} onClick={handleSaveNoteClick}>Save Note</button>}
          </div>
        </div>
      ) : selectedRow === null ? ( // Display the table when not adding a new note
        <div className="col-sm-12 d-flex flex-column justify-content-between" style={{ minHeight: "400px" }}>
          <div className="col-sm-12">
            {loading ? ( // Conditionally render spinner if loading
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <table className={style1.notesTable}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left' }}>NOTE</th>
                    <th>BY</th>
                    <th>DATE / TIME</th>
                  </tr>
                </thead>
                <tbody>
                  {sharedData.notes && sharedData.notes.length > 0 ? (
                    sharedData.notes.map((note, index) => (
                      <tr
                        key={index}
                        className={style1.notesRows}
                        onClick={() => handleRowClick(index)}
                      >
                        <td style={{ fontSize: '14px', textAlign: 'left' }}>
                          {note.note.length > 25
                            ? `${note.note.substring(0, 15)}...`
                            : note.note}
                        </td>
                        <td style={{ fontSize: '14px' }}>{note.author}</td>
                        <td style={{ fontSize: '14px' }}>{formatDateTime(note.timestamp)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ fontSize: '12px', textAlign: 'center' }}>
                        This incident has no notes
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}

          </div>
          <div className="col-sm-2">
            <button
              className={style1.NoteBtn}
              onClick={handleAddNoteClick}
              style={{ backgroundColor: ctaBackgroudColor }}
            >
              Add new note
            </button>
          </div>
        </div>

      ) : ( // Display the selected note details
        <div>
          <p onClick={handleBackButtonClick} className={style1.notesBackBtn}>
            <span className={style1.backArrowIcon} >
              <MdArrowBackIosNew style={{ color: textAccentColor }} />
            </span>
            <span className={style1.backBtnText} style={{ color: textAccentColor }}>All notes</span>
          </p>

          <div className="row">
            <div className={`col-sm-12 d-flex`}>
              <div className={`col-sm-6 ${style1.notesFullDetailsHeading}`}>
                BY
              </div>
              <div className={`col-sm-6 ${style1.notesFullDetailsHeading}`}>
                DATE / TIME
              </div>
            </div>
            <div className={`col-sm-12 d-flex`}>
              <div className={`col-sm-6 ${style1.notesFullDetails}`}>
                {sharedData.notes[selectedRow].author}
              </div>
              <div className={`col-sm-6 ${style1.notesFullDetails}`}>
                {formatDateTime(sharedData.notes[selectedRow].timestamp)}
              </div>
            </div>
            <div
              className={`col-sm-12 ${style1.notesFullDetailsHeading} ${style1.notesHeading}`}
            >
              NOTE
            </div>
            <div
              className={`col-sm-12 ${style1.notesFullDetails} ${style1.notesField}`}
            >
              {sharedData.notes[selectedRow].note}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Notes;