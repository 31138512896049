import React, { useEffect, useRef, useState } from "react";
import "../../CSS/ReportsPage.css";
import styles from "../../CSS/Base/Basic.module.css";
import userSession from "../../Constants/auth";
import MaterialUIMultiSelectCheckmarks from "../../Components/ViewReportPage/MaterialUIMultiSelectCheckmarks";
import UseReportsApi from "../../Custom Hooks/useReportsApi";
import ReportsBarChart from "../../Charts/ReportsBarChart";
import CustomizedTable from "../../Components/ViewReportPage/CustomizedTable";
import CSVDownloadButton from "../../Components/ViewReportPage/CSVDownloadButton";
import ErrorModal from "../../Modals/ErrorModal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import EmailModal from "../../Modals/EmailModal";
import { APP_SERVICES_URL } from "../../Constants/constants";
import PrintIcon from "@mui/icons-material/Print";
import LaunchIcon from "@mui/icons-material/Launch";
import EmailIcon from "@mui/icons-material/Email";
import { useApiDataContext } from "../../ApiDataContext";
import moment from "moment";
import RotatingIcon from "../../Components/Base/RotatingIcon";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import TitileReport from "../../Components/common/TitileReport";

const OffenderDetection = () => {
  const ErrorReportHeading = "Report Data Fetch Error";
  const today = new Date();
  const threeMonthsAgo = new Date(today);
  const { customersList, productsList, statesList } = useApiDataContext();
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  const formattedThreeMonthsAgo = `${threeMonthsAgo.getFullYear()}-${(
    threeMonthsAgo.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [groupedData, setGroupedData] = useState([]);
  const [startDate, setStartDate] = useState(formattedThreeMonthsAgo);
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]);
  // Additional states for startMonth and endMonth
  const [startMonth, setStartMonth] = useState(
    formattedThreeMonthsAgo.substring(0, 7)
  ); // YYYY-MM format
  const [endMonth, setEndMonth] = useState(
    today.toISOString().split("T")[0].substring(0, 7)
  ); // YYYY-MM format
  // Update startMonth and endMonth based on changes in startDate and endDate
  useEffect(() => {
    setStartMonth(startDate.substring(0, 7));
  }, [startDate]);

  useEffect(() => {
    setEndMonth(endDate.substring(0, 7));
  }, [endDate]);
  const [selectedState, setSelectedState] = useState(statesList);
  const [selectedBrand, setSelectedBrand] = useState(customersList);
  const [selectedSite, setSelectedSite] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(productsList);
  const [emailReport, setEmailReport] = useState(false);
  const openEmailModal = () => {
    setButtonClicked("emailReport");
    setEmailReport(true);
  };

  const closeEmailModal = () => {
    setEmailReport(false);
    setApiResponse(null);
  };
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const fetchData = UseReportsApi();
  const [selectedOption, setSelectedOption] = useState("monthly");
  const { userRole, CustID, sites, authHeader } = userSession;
  const getSitesNames = (sites) => {
    if (!sites || !Array.isArray(sites)) {
      return [];
    }
    return sites.map((site) => site.name);
  };
  const siteNames = getSitesNames(sites);
  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";

  const formatDate = (dateString, isStart) => {
    const utcTime = moment.utc(dateString); // Assuming the UTC time is '2024-04-30T05:10:00'
    let localTime = utcTime.local(); // Convert to local time zone

    if (isStart) {
      // Set the time to 00:00:00 for the start date
      localTime.startOf("day");
    } else {
      localTime = localTime.endOf("day");
    }

    const localTimeWithoutOffset = localTime.format("YYYY-MM-DDTHH:mm:ss.SSS"); // Omitting the timezone offset

    return localTimeWithoutOffset;
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  const handleStateChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      if (selectedState.length === statesList.length - 1) {
        setSelectedState([]);
      } else {
        setSelectedState(statesList.slice(1));
      }
    } else {
      setSelectedState(selectedOptions);
    }
  };

  const handleBrandChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      if (selectedBrand.length === customersList.length - 1) {
        setSelectedBrand([]);
      } else {
        setSelectedBrand(customersList.slice(1));
      }
    } else {
      setSelectedBrand(selectedOptions);
    }
  };

  const handleSiteChange = (selectedValues) => {
    if (selectedValues.includes("Select All")) {
      if (selectedSite.length === sites.length) {
        // If all sites are already selected, unselect all
        setSelectedSite([]);
      } else {
        // Select all sites
        setSelectedSite(sites);
      }
    } else {
      setSelectedSite(selectedValues);
    }
  };

  const handleProductChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      if (selectedProduct.length === productsList.length - 1) {
        setSelectedProduct([]);
      } else {
        setSelectedProduct(productsList.slice(1));
      }
    } else {
      setSelectedProduct(selectedOptions);
    }
  };
  const transformData = (data) => {
    if (
      !data ||
      !data.siteWisePeriodicDetectionInfo ||
      Object.keys(data.siteWisePeriodicDetectionInfo).length === 0
    ) {
      return [];
    }

    let transformedData = [];

    Object.keys(data.siteWisePeriodicDetectionInfo).forEach((site) => {
      const siteData = data.siteWisePeriodicDetectionInfo[site];

      siteData.forEach((periodInfo) => {
        const { period, numDetections, numOffenderDetections } = periodInfo;

        let periodStart, periodEnd;

        // Check the format of the period and parse accordingly
        if (period.match(/^\d{1,2}\s\d{4}$/)) {
          const [month, year] = period.split(" ");
          const monthName = new Date(year, month - 1).toLocaleString(
            "default",
            { month: "short" }
          );
          periodStart = `01-${monthName}-${year}`;
          periodEnd = new Date(year, month, 0).toLocaleDateString("default", {
            day: "numeric",
            month: "short",
            year: "numeric",
          });
        } else if (
          period.match(/^\d{2}-\w{3}-\d{4}\s+to\s+\d{2}-\w{3}-\d{4}$/)
        ) {
          const [start, end] = period.split(" to ");
          const [startDay, startMonth, startYear] = start.split("-");
          const [endDay, endMonth, endYear] = end.split("-");

          const startMonthName = new Date(
            `${startMonth} ${startYear}`
          ).toLocaleString("default", { month: "short" });
          const endMonthName = new Date(
            `${endMonth} ${endYear}`
          ).toLocaleString("default", { month: "short" });

          periodStart = `${startDay}-${startMonthName}-${startYear}`;
          periodEnd = `${endDay}-${endMonthName}-${endYear}`;
        }

        transformedData.push([
          site,
          periodStart,
          periodEnd,
          numDetections.toString(),
          numOffenderDetections.toString(),
        ]);
      });
    });

    return transformedData;
  };

  const handleSubmit = async (e) => {
    let formattedStartDate;
    let formattedEndDate;
    formattedStartDate = formatDate(startDate, true);
    formattedEndDate = formatDate(endDate, false);
    const isMonthly = selectedOption === "monthly";
    const payloadData = {
      to: formattedEndDate,
      from: formattedStartDate,
      states: selectedState,
      brands: selectedBrand,
      products: selectedProduct,
      sites: selectedSite,
    };
    // Check if selectedSite is not blank or has a length greater than 0
    if (selectedSite.length > 0) {
      try {
        setData(null);
        setShowResult(false);
        setIsLoading(true);
        setError(false);
        const result = await fetchData(
          authHeader,
          CustID,
          "offenderDetection",
          payloadData,
          isMonthly
        );
        setData(result);
        const transformedData = transformData(result);
        setGroupedData(transformedData);
        setShowResult(true);
      } catch (error) {
        setError(true);
        setErrorMessage(
          "Failed to fetch report data. Please try again. If the issue persists, contact support."
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      // Handle the case when selectedSite is blank or has a length less than or equal to 0
      // You can set an error state or display a message to the user.
      setIsLoading(false);
    }
  };

  const handleCloseErrorModal = () => {
    setError(false);
  };

  function generatePDF(filename, emailAddress) {
    let input = document.getElementById("pdf-content");
    const hidden_content = document.getElementById("hidden-content");
    const report_created_time = document.getElementById("report-created-time");
    const pdf = new jsPDF("portrait", "mm", "a4");

    html2canvas(input, {
      scale: 6,
      useCORS: true,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("hidden-content").classList.remove("d-none");
        clonedDoc
          .getElementById("report-created-time")
          .classList.remove("d-none");
      },
    }).then((canvas) => {
      hidden_content.classList.add("d-none");
      report_created_time.classList.add("d-none");
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width;
      let heightLeft = height;
      let pageHeight = pdf.internal.pageSize.getHeight();
      let position = 10;
      pdf.addImage(imgData, "JPEG", 0, position, width, height);

      heightLeft -= pageHeight - position;
      while (heightLeft >= 0) {
        position = heightLeft - height + 10;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, width, height);
        heightLeft -= pageHeight;
      }
      // Save the PDF or send it to the API based on the buttonClicked state
      if (buttonClicked === "printPDF") {
        pdf.save(`${filename}.pdf`);
      } else if (buttonClicked === "emailReport" && emailAddress) {
        sendPDFToAPI(pdf.output("blob"), emailAddress, filename);
      }
    });
  }

  function sendPDFToAPI(pdfBlob, emailAddressToSend, filename) {
    // Create a FormData object to send the PDF as a file attachment
    const mailHeading = "Scancam Report PDF";
    const mailBody = "Please find attached the report you requested.";
    const formData = new FormData();
    formData.append("attachment", pdfBlob, `${filename}.pdf`);

    // Set up the API endpoint and headers
    const endpoint = `${APP_SERVICES_URL}/senapi/${CustID}/mailWithAttachment?subject=${mailHeading}&body=${mailBody}&mailTo=${emailAddressToSend}`;
    setApiResponse(null);
    setEmailReport(false);

    fetch(endpoint, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: authHeader,
      },
    })
      .then((response) => {
        if (response.ok) {
          setApiResponse(true);
          setEmailReport(true);
        } else {
          setApiResponse(false);
          setEmailReport(true);
        }
      })
      .catch((error) => {
        setApiResponse(false);
        setEmailReport(true);
      });
  }
  const simulateFormSubmit = () => {
    // Replace this with your actual form submission logic
    // For example, if handleSubmit is an async function, you can await it here
    handleSubmit();
  };

  useEffect(() => {
    simulateFormSubmit();
    // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    selectedSite,
    selectedBrand,
    selectedProduct,
    selectedState,
    selectedOption,
  ]);

  const handleReportTypeSelection = (reportType, emailAddress) => {
    // Handle the selected report type (detailed or summary) here.
    generatePDF("OffenderDetection_Report", emailAddress);
  };
  // const hasData = data && Object.keys(data).length > 0;
  const hasPeriodicData =
    data && data.periodicDetectionInfo && data.periodicDetectionInfo.length > 0;
  const hasDetectionData =
    data &&
    data.detectionDistribution &&
    Object.keys(data.detectionDistribution).length > 0;
  const showReports =
    (hasDetectionData || hasPeriodicData) && selectedSite.length > 0;
  const isSiteDataAvailable = data?.siteWisePeriodicDetectionInfo || {};

  // Initialize accordion state
  const [accordionState, setAccordionState] = useState(() => {
    const initialState = {};
    Object.keys(isSiteDataAvailable).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      initialState[formattedKey] = false;
    });
    return initialState;
  });

  // Initialize contentRefs and contentHeights
  const contentRefs = useRef({});
  const [contentHeights, setContentHeights] = useState(() => {
    const heights = {};
    Object.keys(isSiteDataAvailable).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      heights[formattedKey] = 0;
    });
    return heights;
  });

  // Function to open all accordions
  const openAllAccordions = () => {
    const updatedAccordionState = {};
    Object.keys(isSiteDataAvailable).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      updatedAccordionState[formattedKey] = true;
    });
    setAccordionState(updatedAccordionState);
  };

  // Function to close all accordions
  const closeAllAccordions = () => {
    const updatedAccordionState = {};
    Object.keys(isSiteDataAvailable).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      updatedAccordionState[formattedKey] = false;
    });
    setAccordionState(updatedAccordionState);
  };

  // Toggle individual accordion
  const toggleAccordionNew = (accordionName) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [accordionName]: !prevState[accordionName],
    }));
  };

  // Effect to initialize contentRefs
  useEffect(() => {
    const refs = {};
    Object.keys(isSiteDataAvailable).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      refs[formattedKey] = React.createRef();
    });
    contentRefs.current = refs;
  }, [isSiteDataAvailable]);

  // Effect to update content heights
  useEffect(() => {
    const updatedHeights = {};
    Object.keys(contentRefs.current).forEach((key) => {
      if (contentRefs.current[key].current) {
        updatedHeights[key] = contentRefs.current[key].current.scrollHeight;
      }
    });
    setContentHeights(updatedHeights);
  }, [accordionState]);

  const formattedDateTime = new Date().toLocaleString("en-US", {
    dateStyle: "medium", // or 'short', 'long', 'full' as needed
    timeStyle: "short", // or 'medium', 'long', 'full' as needed
  });

  return (
    <div className="w-dvw min-h-[65vh]">
      <div className="main-box">
        {emailReport && (
          <EmailModal
            show={emailReport}
            onClose={closeEmailModal}
            onSelectReportType={handleReportTypeSelection}
            apiResponse={apiResponse}
            reportName={"OffenderDetection"}
          />
        )}
        {error && (
          <ErrorModal
            show={true}
            onClose={handleCloseErrorModal}
            message={errorMessage}
            heading={ErrorReportHeading}
          />
        )}
        <div className="top-section bg-[#F4F4F4] pb-4">
          <div className="w-[1170px] mx-auto">
            <div className="h-[54px]">
              <TitileReport label={"Known Offender Detection"} />
            </div>
            <div className="">
              <div className="lg:flex md:flex text-[18px] h-[26px] text-[#B4B4B4] font-extrabold">
                <div className="w-[85%]">Filters</div>
                <div className="w-[15%]">Actions</div>
              </div>
              <div className="">
                <div className="lg:flex md:flex text-[18px] text-[#B4B4B4] font-extrabold">
                  <div className="w-[85%]">
                    <div className="grid grid-cols-6 grid-rows-1 text-[#6f7172]">
                      <div className="text-sm h-[64px] pe-[15px]">
                        <div className="h-[20px]">Cycle</div>
                        <div className="h-[44px] flex">
                          <div className="flex items-center text-sm">
                            <label className="checkbox-container text-sm">
                              Monthly
                              <input
                                className="custom-checkbox"
                                value="monthly"
                                checked={selectedOption === "monthly"}
                                onChange={() => handleOptionChange("monthly")}
                                type="checkbox"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>

                          <div className="ml-4 flex items-center">
                            <label className="checkbox-container">
                              Weekly
                              <input
                                className="custom-checkbox"
                                value="weekly"
                                checked={selectedOption === "weekly"}
                                onChange={() => handleOptionChange("weekly")}
                                type="checkbox"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="text-sm  h-[64px] pe-[15px]">
                        <div className="h-[20px]">Start date</div>
                        <div className="h-[44px]">
                          <input
                            className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                            type="date"
                            id="startDate"
                            value={startDate}
                            onChange={handleStartDateChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="text-sm h-[64px] pe-[15px]">
                        <div className="h-[20px]">End date</div>
                        <div className="h-[44px]">
                          <input
                            className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                            type="date"
                            id="endDate"
                            value={endDate}
                            onChange={handleEndDateChange}
                            required
                          />
                        </div>
                      </div>

                      {userRole !== "site-manager" && (
                        <>
                          <div className="text-sm h-[64px] pe-[15px]">
                            <div className="h-[20px]">State</div>
                            <div className="h-[44px]">
                              <MaterialUIMultiSelectCheckmarks
                                options={statesList}
                                setSelectedStateFunction={setSelectedState}
                                initialStateAllSelected={true}
                              />
                            </div>
                          </div>
                          <div className="text-sm h-[64px] pe-[15px]">
                            <div className="h-[20px]">Brand</div>
                            <div className="h-[44px]">
                              <MaterialUIMultiSelectCheckmarks
                                options={customersList}
                                setSelectedStateFunction={setSelectedBrand}
                                initialStateAllSelected={true}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="text-sm h-[64px] pe-[15px]">
                        <div className="h-[20px]">Sites</div>
                        <div className="h-[44px]">
                          <MaterialUIMultiSelectCheckmarks
                            options={siteNames}
                            setSelectedStateFunction={setSelectedSite}
                            initialStateAllSelected={false}
                          />
                        </div>
                      </div>

                      {userRole !== "site-manager" && (
                        <div className="text-sm h-[64px] pe-[15px]">
                          <div className="h-[20px]">Product</div>
                          <div className="h-[44px]">
                            <MaterialUIMultiSelectCheckmarks
                              options={productsList}
                              setSelectedStateFunction={setSelectedProduct}
                              initialStateAllSelected={true}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-[15%]">
                    <div className="grid grid-rows-3">
                      <div
                        className={`flex items-center space-x-1 text-base ${
                          showReports ? "rule" : "disable-btns"
                        }`}
                        onClick={
                          showReports
                            ? () => {
                                setButtonClicked("printPDF");
                                generatePDF("OffenderDetection");
                              }
                            : null
                        }
                        style={{
                          color:
                            showReports && textAccentColor
                              ? textAccentColor
                              : "inherit",
                          cursor: showReports ? "pointer" : "not-allowed",
                        }}
                      >
                        <span>
                          <PrintIcon style={{ fontSize: "16px" }} />
                        </span>
                        <span>Print-friendly PDF</span>
                      </div>
                      <div
                        className={`flex items-center space-x-1 text-base ${
                          showReports ? "rule" : "disable-btns"
                        }`}
                        style={{
                          color:
                            showReports && textAccentColor
                              ? textAccentColor
                              : "inherit",
                          cursor: showReports ? "pointer" : "not-allowed",
                        }}
                      >
                        {showReports ? (
                          <CSVDownloadButton
                            data={groupedData}
                            reportName={
                              "scancam-site-offender-detections-report"
                            }
                          />
                        ) : (
                          <>
                            <span>
                              <LaunchIcon style={{ fontSize: "16px" }} />
                            </span>
                            <span>Export as CSV</span>
                          </>
                        )}
                      </div>
                      <div
                        className={`flex items-center space-x-1 text-base ${
                          showReports ? "rule" : "disable-btns"
                        }`}
                        onClick={showReports ? openEmailModal : null}
                        style={{
                          color:
                            showReports && textAccentColor
                              ? textAccentColor
                              : "inherit",
                          cursor: showReports ? "pointer" : "not-allowed",
                        }}
                      >
                        <span>
                          <EmailIcon style={{ fontSize: "16px" }} />
                        </span>
                        <span>Email report</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <RotatingIcon colorValue={"grey"} />
        </div>
      ) : (
        <div id="pdf-content">
          <div
            id="report-created-time"
            className="text-end mt-2 report-created-time d-none"
          >
            Report created {formattedDateTime} by
            <span className="ps-1 pe-2">{`${userSession.username}`}</span>
          </div>
          {hasDetectionData && selectedSite.length > 0 ? (
            <div style={{}}>
              <div className="chart-Box">
                <div id="hidden-content" className="d-none">
                  <h1 className="report-heading m-1 p-1">
                    Known Offender Detection Report
                  </h1>
                  <h4 className="m-1 p-1">Detail</h4>
                  <div className="mt-3">
                    <div className="row ms-1">
                      <div className="col-6"> Date range :</div>
                      <div className="col-6"> Sites :</div>
                    </div>
                    <div className="row ms-1 report-range">
                      <div className="col-6">
                        {`${startDate}`} - {`${endDate}`}
                      </div>
                      <div className="col-6">
                        {`${selectedSite.toString()}`}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="chart-heading">
                  Known Offender Detections by Hour of Day
                </div>
                <ReportsBarChart
                  data={data.detectionDistribution}
                  label={"offenderDetection"}
                />
              </div>
            </div>
          ) : null}

          <div className="row body-content">
            <div
              className="col-sm-12 d-flex"
              style={{ maxWidth: "1170px", margin: "auto" }}
            >
              {/* json display */}
              {showResult === false ||
              (showResult === true &&
                hasPeriodicData &&
                selectedSite.length === 0) ? (
                <div
                  className="col-sm-12 text-center mt-3 "
                  style={{
                    fontSize: "20px",
                    color: "#6f7172",
                    paddingTop: "50px",
                  }}
                >
                  <p>At least one Site must be selected.</p>
                </div>
              ) : !data ||
                (Object.keys(data.detectionDistribution).length === 0 &&
                  data.periodicDetectionInfo.length === 0) ? (
                <div
                  className="col-sm-12 text-center mt-3"
                  style={{
                    fontSize: "20px",
                    color: "#6f7172",
                    paddingTop: "50px",
                  }}
                >
                  <p>
                    There are no incidents which match your search criteria.
                  </p>
                </div>
              ) : (
                <div className="body-content" style={{ width: "1170px" }}>
                  <div className="chart-heading">
                    Total Number of Offender Detections by Period
                  </div>
                  <div
                    className="accordion container-max-width-reports-page"
                    id="accordionPanelsStayOpenExample"
                  >
                    {/* /// */}
                    {Object.entries(isSiteDataAvailable).map(([key, value]) => {
                      const formattedKey = key.replace(/ /g, "_");
                      return (
                        <div
                          key={formattedKey}
                          className="container mx-auto px-0 py-0"
                        >
                          <div className="bg-white border-t border-b border-gray-200">
                            <button
                              type="button"
                              className="w-full px-0 py-3 text-left"
                              onClick={() => toggleAccordionNew(formattedKey)}
                            >
                              <div className="grid grid-cols-6 items-center">
                                <div className="col-span-6 flex items-center">
                                  <svg
                                    className={`w-4 h-4 font-bold text-[#E27A13] transform transition-transform duration-300 ${
                                      accordionState[formattedKey]
                                        ? "rotate-90"
                                        : ""
                                    }`}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path d="M9 5l7 7-7 7"></path>
                                  </svg>
                                  <span className="ml-2 text-[#E27A13] font-bold">
                                    {isSiteDataAvailable[key].count} {key}
                                    <div className="text-sm font-extralight">
                                      {accordionState[formattedKey]
                                        ? "Hide all"
                                        : "Show all"}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </button>
                            <div
                              ref={contentRefs.current[formattedKey]}
                              className="overflow-hidden transition-all duration-300 ease-in-out"
                              style={{
                                maxHeight: accordionState[formattedKey]
                                  ? `${contentHeights[formattedKey]}px`
                                  : "0",
                                opacity: accordionState[formattedKey] ? 1 : 0,
                              }}
                            >
                              <CustomizedTable
                                heading={[
                                  "PERIOD",
                                  "Total # of Detections",
                                  "Total # of Offender Detections",
                                ]}
                                reportName={"offenderDetection"}
                                data={isSiteDataAvailable[key]}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OffenderDetection;
