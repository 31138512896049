import { useMsal } from '@azure/msal-react';

const LoginPage = () => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    await instance.loginPopup();
  };

  return (
    <div>
      <h1>Login Page</h1>
      <button onClick={handleLogin}>Login with Microsoft</button>
    </div>
  );
};

export default LoginPage;
