import React, { useState } from "react";

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        className="w-full flex items-center justify-between text-left  border-transparent hover:border-[#E27A13] transition-colors duration-300 group"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <div className="w-8 h-8 rounded-full border-2 border-[#6f7172] group-hover:border-[#E27A13] flex items-center justify-center mr-3 transition-colors duration-300">
            <svg
              className={`w-5 h-5 text-[#6f7172] group-hover:text-[#E27A13] transition-all duration-300 ${
                isOpen ? "rotate-90" : ""
              }`}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 12H19M19 12L13 6M19 12L13 18"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span className="font-medium text-[18px] text-[#6f7172] group-hover:text-[#E27A13] transition-colors duration-300">
            {title}
          </span>
        </div>
      </button>
      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="p-4 text-base text-[#6F7172]">{content}</div>
      </div>
    </div>
  );
};

export default Accordion;
