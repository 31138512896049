import { MdOutlineEmail } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import userSession from "../../Constants/auth";
import FeedbackModal from "../../Modals/FeedbackModal";
import { APP_SERVICES_URL } from "../../Constants/constants";
import { useEffect, useState } from "react";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { CustID, authHeader } = userSession;
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const supportMail = (subject, message) => {
    // Set up the API endpoint and headers
    const endpoint = `${APP_SERVICES_URL}/senapi/${CustID}/supportMail`;

    const requestBody = {
      subject: subject,
      message: message,
    };

    fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) {
          setApiResponse(true);
          setFeedbackModal(true);
        } else {
          setApiResponse(false);
          setFeedbackModal(true);
        }
      })
      .catch((error) => {
        setApiResponse(false);
        setFeedbackModal(true);
      });
  };

  const openFeedBackModal = () => {
    setFeedbackModal(true);
  };

  const closeFeedBackModal = () => {
    setFeedbackModal(false);
    setApiResponse(null);
  };
  const handleReportTypeSelection = (subject, message) => {
    supportMail(subject, message);
  };
  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const linkName = getLocationLinkName(location.pathname);
    document.title = linkName;
  }, [location]);

  const getLocationLinkName = (pathname) => {
    if (pathname.startsWith("/scancam/incident/")) {
      return "Incident - Scancam";
    }
    switch (pathname) {
      case "/scancam/Training/Videos":
        return "View a Videos - Scancam";
      case "/scancam/":
        return "Scancam";
      case "/scancam/Training/Faqs":
        return "FAQs - Scancam";
      case "/scancam/Training/Troubleshooting":
        return "Troubleshooting - Scancam";
      case "https://scancam.sharepoint.com/Support/Forms/AllItems.aspx?id=%2FSupport%2FOld%20Data%2F9%2E%20External%20Documents%2FOperations%20Manual%20SCI%2Epdf&parent=%2FSupport%2FOld%20Data%2F9%2E%20External%20Documents&p=true&ga=1":
        return "Operations Manual Scancam";
      case "https://sensen.ai/solutions/scancam/":
        return "scancam";
      case "/scancam/reviewincidents":
        return "Review Incidents - Scancam";
      case "/scancam/home":
        return "Home - Scancam";
      case "/scancam/report":
        return "View a Report - Scancam";
      case "/scancam/report/siteOperations":
        return "Site Operations Report - Scancam";
      case "/scancam/report/debtOutstanding":
        return "Debt Outstanding Report - Scancam";
      case "/scancam/report/debtRecovered":
        return "Debt Recovered Report - Scancam";
      case "/scancam/report/advanceReport":
        return "Advance Report Report - Scancam";
      case "/scancam/report/employeePerformance":
        return "Employee Performance Report - Scancam";
      case "/scancam/report/siteTraffic":
        return "Site Traffic Report - Scancam";
      case "/scancam/report/offenderDetection":
        return "Offender Detection Report - Scancam";
      case "/scancam/report/reimbursement":
        return "Reimbursement Report - Scancam";
      case "/scancam/report/precourt":
        return "Pre-Court Report - Scancam";
      case "/scancam/report/iouletterrun":
        return "IOU Letter Run Report - Scancam";
      case "/scancam/report/dotletterrun":
        return "DoT Letter Run Report - Scancam";
      case "/scancam/report/letterrun/wa":
        return "WA Letter Run Report - Scancam";
      case "/scancam/report/letterrun/nsw":
        return "NSW Letter Run Report - Scancam";
      case "/scancam/report/letterrun/vic":
        return "VIC Letter Run Report - Scancam";
      case "/scancam/report/letterrun/qld":
        return "QLD Letter Run Report - Scancam";
      case "/scancam/report/letterrun/nt":
        return "NT Letter Run Report - Scancam";
      case "/scancam/report/letterrun/sa":
        return "SA Letter Run Report - Scancam";
      case "/scancam/report/letterrun/act":
        return "ACT Letter Run Report - Scancam";
      case "/scancam/ManageEmployees":
        return "Manage Employees - Scancam";
      case "/scancam/OrderFrsCards":
        return "Order FRS Cards - Scancam";
      case "/scancam/add":
        return "Add Incident - Scanacm";
      case "/scancam/AddIncident":
        return "Add Incident - Scanacm";
      case "/scancam/regosearch":
        return "Rego Search - Scanacm";
      default:
        return "Scancam";
    }
  };

  return (
    <footer className="min-h-fit text-white mt-auto w-lvw">
        <FeedbackModal
          isOpen={feedbackModal}
          onClose={closeFeedBackModal}
          onSelectReportType={handleReportTypeSelection}
          apiResponse={apiResponse}
          ocr={"NA"}
          detected={"NA"}
          pkAlert={"NA"}
        />

      {/* Top Section */}
      <div className="w-[1170px] mx-auto py-4">
        <div className="flex justify-between items-center text-[#e27a13] font-bold text-2xl border-b-2 pb-4">
          <div>Training Tools</div>
          <div>Support</div>
        </div>
        <div className="flex justify-between items-center ">
          <div className="flex flex-col text-sm text-[#6f7172] pt-4">
            <Link
              className="hover:text-[#6f7172]"
              to={"/scancam/Training/Videos"}
            >
              <span className="hover:border-b-2 hover:border-[#dedede]">
                Training Videos
              </span>
            </Link>
            <Link
              className="hover:text-[#6f7172]"
              to={"/scancam/Training/Faqs"}
            >
              <span className="hover:border-b-2 hover:border-[#dedede]">
                FAQs
              </span>
            </Link>
            <Link
              className="hover:text-[#6f7172]"
              to={"/scancam/Training/Troubleshooting"}
            >
              <span className="hover:border-b-2 hover:border-[#dedede]">
                Troubleshooting
              </span>
            </Link>
            <Link
              className="hover:text-[#6f7172]"
              to={
                "https://scancam.sharepoint.com/Support/Forms/AllItems.aspx?id=%2FSupport%2FOld%20Data%2F9%2E%20External%20Documents%2FOperations%20Manual%20SCI%2Epdf&parent=%2FSupport%2FOld%20Data%2F9%2E%20External%20Documents&p=true&ga=1"
              }
            >
              <span className="hover:border-b-2 hover:border-[#dedede]">
                Operations Manual
              </span>
            </Link>
          </div>
          <div className="flex flex-col items-start text-sm text-[#6f7172]">
            <Link className="hover:text-[#6f7172]">
              <span>Email:</span>
              <span
                className="hover:border-b-2 hover:border-[#dedede]"
                onClick={() =>
                  (window.location = "mailto:scancam.support@sensen.ai")
                }
              >
                scancam.support@sensen.ai
              </span>
            </Link>
            <Link
              className="hover:text-[#6f7172] flex justify-end"
              to={"https://sensen.ai/solutions/scancam"}
            >
              <span className="w-full hover:border-b-2 hover:border-[#dedede]">
                Scancam
              </span>
            </Link>

            <Link className="h-4 invisible"> </Link>
            <Link className="h-4 invisible"> </Link>
          </div>
        </div>
      </div>

      {/* Middle Section */}
      <div className="w-full bg-[#3a3a3a]">
        <div className="w-[1170px] mx-auto flex justify-center items-center text-xl py-6">
          <div>Got some feedback?</div>
          <div
            className="flex justify-center items-center ml-4 mr-2 text-[#e27a13] hover:border-b border-[#e27a13]"
            onClick={openFeedBackModal}
          >
            Let us know your thoughts
          </div>
          <MdOutlineEmail className="text-[#e27a13] font-bold text-3xl" />
        </div>
      </div>

      {/* Bottom Section */}
      <div className="bg-[#e27a13]">
        <div className="w-[1170px] mx-auto text-center py-1">
          Copyright © 2015 - 2024 Scancam Industries Pty Ltd
        </div>
      </div>
    </footer>
  );
};

export default Footer;
