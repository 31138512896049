import React, { useState } from "react";
import "../../CSS/LoginForm.css";
import sensen_Login_Image from "../../Assets/sensen_Login_Image.png";
import { APP_SERVICES_URL } from "../../Constants/constants";
import { useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const LoginForm = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCopyPaste = (event) => {
    event.preventDefault();
  };

  const authenticateForm = async () => {
    const url = `${APP_SERVICES_URL}/sn-auth-service/portalLogin`;
    const credentials = btoa(`${email}:${password}`);
    const headers = {
      Authorization: `Basic ${credentials}`,
    };
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
      });

      if (response.ok) {
        setLoading(false);
        const data = await response.json();
        const contentType = response.headers.get("content-type");
        let responseText;
        localStorage.setItem("userDataScancamPortal", data);
        if (contentType && contentType.includes("application/json")) {
          responseText = data; // Ensure this is the correct data structure
          localStorage.setItem(
            "userDataScancamPortalText",
            JSON.stringify(data)
          );
        } else {
          responseText = await response.text();
        }

        setError(false);

        if (
          typeof responseText === "string" &&
          responseText.includes("/scancam/resetpassword")
        ) {
          // console.log("Navigating to:", responseText);
          window.location.href = responseText;
        } else {
          localStorage.setItem("scancamPortalisLogged", true);
          window.location.href = "/scancam/home";
        }
      } else {
        setError(true);
        setLoading(false);
        console.log("Error:", response.statusText);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
      console.log("Error:", error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      return;
    }

    if (!password) {
      return;
    }

    if (email && password) {
      await authenticateForm();
    }
  };

  const handleForgotPassword = () => {
    navigate("/scancam/forgotpassword");
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="h-screen flex items-center justify-center bg-[#363636] font-login">
      <div className="bg-white rounded-md  shadow-md min-h-[540px] max-h-fit w-[400px]">
        <form
          method="post"
          onSubmit={handleSubmit}
          className="flex flex-col h-full"
        >
          <div className="flex-grow borde">
            <div className="flex flex-col justify-center items-center ps-10 pe-10 pt-16 space-y-4 text-[#1E212A]">
              <img
                src={sensen_Login_Image}
                alt="logoSensen"
                height={52}
                width={168}
              />
              <h1 className="font-normal text-2xl">Welcome</h1>
              <p className="text-sm">
                Log in to SenSen.ai to continue to Scancam Portal.
              </p>
            </div>
            <div className="space-y-1 ps-10 pe-10 pt-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 h-[52px]"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    required
                    // minLength={8}
                    value={password}
                    onChange={handlePasswordChange}
                    onPaste={handleCopyPaste}
                    onCopy={handleCopyPaste}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 pr-10 h-[52px]"
                  />
                  <button
                    type="button"
                    onClick={handleTogglePassword}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 h-[52px] text-[24px]"
                  >
                    {showPassword ? <BsEyeSlash /> : <BsEye />}
                  </button>
                </div>
              </div>
              {error && (
                <p className="text-red-500">
                  Please check your email and password.
                </p>
              )}
            </div>
            <div className="space-y-4 mt-4 ps-10 pe-10">
              <p
                className="text-[#21358C] text-[14px] font-semibold"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </p>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-xl text-base font-medium text-white bg-[#21358C] hover:bg-[#21358C] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
