import React, { useEffect, useState } from "react";
import "../../CSS/ReportsPage.css";
import userSession from "../../Constants/auth";
import ErrorModal from "../../Modals/ErrorModal";
import LaunchIcon from '@mui/icons-material/Launch';
import styles from "../../CSS/Base/Basic.module.css";
import { APP_SERVICES_URL } from "../../Constants/constants";
import UseReportsApi from "../../Custom Hooks/useReportsApi";
import StackedBarChart from "../../Charts/StackedBarChart";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import CustomizedTable from "../../Components/ViewReportPage/CustomizedTable";
import MaterialUIMultiSelectCheckmarks from "../../Components/ViewReportPage/MaterialUIMultiSelectCheckmarks";
import { useApiDataContext } from "../../ApiDataContext";
import moment from "moment";
import ExportDotCSVButton from '../../Components/ViewReportPage/ExportDotCSVButton'
import CSVLetterRunButton from "../../Components/ViewReportPage/CSVLetterRunButton";
import CSVCourtReport from "../../Components/ViewReportPage/CSVCourtReport";
import RotatingIcon from "../../Components/Base/RotatingIcon";
const WALetterRunProcessing = () => {
    const firstDayOfMonth = moment().startOf('month').format('YYYY-MM-01');
    const endTime = moment().format('YYYY-MM-DD');

    const { authHeader, sites, CustID, userRole } = userSession;
    const getSitesNames = (sites) => {
        if (!sites || !Array.isArray(sites)) {
          return [];
        }
        return sites.map((site) => site.name);
      };
    const siteNames = getSitesNames(sites);
    const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
    const chart1Color = userSession.themeData?.chart1Color || '#e27a13';
    const chart2Color = userSession.themeData?.chart2Color || '#ffcb07';
    const { customersList, productsList } = useApiDataContext();
    const [showResult, setShowResult] = useState(false);
    const [startDate, setStartDate] = useState(firstDayOfMonth);
    const [endDate, setEndDate] = useState(endTime);
    const [selectedSite, setSelectedSite] = useState([]);
    // eslint-disable-next-line
    const [selectedStates, setSelectedStates] = useState(['WA']);

    const statuses = { "Pre-Court": [] };
    const types = ['IOU', 'Drive Off'];
    const [selectedBrands, setSelectedBrands] = useState(customersList);
    const [selectedProducts, setSelectedProducts] = useState(productsList);
    const [newdata, setNewData] = useState({});
    // Define state variables for data, loading, and error
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const fetchData = UseReportsApi();
    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    // Generic function to handle selection for different dropdowns
    const handleSelection = (selectedValues, allValues, setSelectedFunction) => {
        if (selectedValues.includes('Select All')) {
            if (selectedValues.length === allValues.length) {
                // If all items are already selected, unselect all
                setSelectedFunction([]);
            } else {
                // Select all items
                setSelectedFunction(allValues);
            }
        } else {
            setSelectedFunction(selectedValues);
        }
    };
    const formatDate = (dateString, isStart) => {
        const utcTime = moment.utc(dateString); // Assuming the UTC time is '2024-04-30T05:10:00'
        let localTime = utcTime.local(); // Convert to local time zone

        if (isStart) {
            // Set the time to 00:00:00 for the start date
            localTime.startOf('day');
        } else {
            localTime = localTime.endOf('day');
        }

        const localTimeWithoutOffset = localTime.format("YYYY-MM-DDTHH:mm:ss.SSS"); // Omitting the timezone offset

        return localTimeWithoutOffset;
    };

    const fetchReportData = async (payloadData) => {

        const { CustID, authHeader } = userSession;
        try {
            // Add additional properties to payloadData
            payloadData.isFuelLitresRequired = true;
            payloadData.isSiteAddressRequired = true;
            payloadData.isProcessingFeeRequired = true;
            // Serialize payloadData into a query string
            setError(false);
            const queryParams = new URLSearchParams({
                limit: 10000,
                offset: 0,
            });
            const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incidentsList?${queryParams.toString()}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authHeader, // Convert to base64 (Basic Auth)
                },
                body: JSON.stringify(payloadData), // Convert payloadData to JSON string
            });

            if (!response.ok) {
                setError(true);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            setError(true);
            setErrorMessage("Failed to fetch report data. Please try again. If the issue persists, contact support.");
            return [];
        }
    };

    const updateDataStateWithSpecificValues = (result) => {
        // Create a dictionary to store the specific values.
        const specificValues = {};
        // Check for the specific values in the result variable.
        for (const key in result) {
            if (result.hasOwnProperty(key)) {
                specificValues[key] = result[key];
            }
        }
        // Update the data state with the specific values.
        setNewData({
            ...specificValues,
        });
    };
                                    // Utility function to extract values from an array of options and remove values containing "*"
                                    const extractOptionValues = (options) => {
                                        return options
                                          .map((option) => option.value)
                                          .filter((value) => value !== "*");
                                      };
    const handleSubmit = async (e) => {
        const formattedStartDate = formatDate(startDate, true);
        const formattedEndDate = formatDate(endDate, false);
        // 
        const payloadData = {
            sites: extractOptionValues(selectedSite), brands: extractOptionValues(selectedBrands), states: selectedStates, products: extractOptionValues(selectedProducts), to: formattedEndDate, from: formattedStartDate, statuses: statuses, types: types,
        };
        if (payloadData.sites.length < 1) {

            return;
        }
        try {
            setIsLoading(true);
            setError(false);
            const result = await fetchData(authHeader, CustID, 'preCourtReports', payloadData);
            setData(result);
            updateDataStateWithSpecificValues(result.sitesInfo);
            setShowResult(true);
        } catch (error) {
            setError(true);
            setErrorMessage("Failed to fetch report data. Please try again. If the issue persists, contact support.");
        } finally {
            setIsLoading(false);
        }
    };

    const PreCourtPayloadGenerator = (label, from, to, sites, brands, products, states, statuses, types) => {
        return {
            sites, from, to, brands, products, states, statuses, types
        };
    };

    // Define a function for fetching and updating report data
    const fetchAndUpdateReportData = async () => {
        const formattedStartDate = formatDate(startDate, true);
        const formattedEndDate = formatDate(endDate, false);
        const allKeys = Object.keys(newdata);

        // Iterate over the keys in the newData state and call the fetchReportData API for each key.
        for (const key of allKeys) {
            // Generate the payload for the API call.
            const payload = PreCourtPayloadGenerator(
                key, formattedStartDate, formattedEndDate, extractOptionValues(selectedSite), extractOptionValues(selectedBrands), extractOptionValues(selectedProducts), selectedStates, statuses, types,

            );

            try {
                payload.isFuelLitresRequired = true;
                payload.isSiteAddressRequired = true;
                payload.isProcessingFeeRequired = true;
                payload.isEmployeeNameRequired = true;
                payload.isSiteTypeRequired = true;
                payload.isFuelTypeRequired = true;
                payload.isStoreIDRequired = true;
                setError(false);
                const result = await fetchReportData(payload, key); // Use the current key as the second argument
                // Update the newData state with the result.
                newdata[key] = result;
            } catch (error) {
                console.error(error);
                setError(true);
                setErrorMessage("Failed to fetch report data. Please try again. If the issue persists, contact support.");
            }
        }

        // Set the new newData state.
        setNewData(newdata);
    };

    useEffect(() => {
        fetchAndUpdateReportData();
        // eslint-disable-next-line
    }, [newdata]);

    // Prepare the chart data based on the values you want to pass
    const properties = ['iouAmount', 'driveOffAmount'];
    const colors = [chart1Color, chart2Color];
    const chartData = [];

    properties.forEach((property, index) => {
        // Use a utility function to safely access nested properties
        const value = getValueSafely(data, property);
        if (value !== undefined && value !== 0) {
            chartData.push({
                data: [value],
                color: colors[index],
            });
        }
    });

    // Utility function to safely access nested properties
    function getValueSafely(obj, property) {
        const properties = property.split('.');
        let value = obj;

        for (let prop of properties) {
            if (value && value.hasOwnProperty(prop)) {
                value = value[prop];
            } else {
                return undefined;
            }
        }

        return value;
    }

    // Watch for changes in the selectedSite array
    useEffect(() => {
        if (selectedSite.length === 0) {
            // Clear your state here, replace with your actual state clearing logic
            setData(null);
            setShowResult(false);
        }
    }, [selectedSite]); // This effect will run whenever selectedSite changes

    const isSiteDataAvailable = data?.sitesInfo || {};
    // Check if isSiteDataAvailable has at least one key
    const hasSiteData = Object.keys(isSiteDataAvailable).length > 0;

    const handleCloseErrorModal = () => {
        setError(false);
    };

    const [accordionState, setAccordionState] = useState(() => {
        const initialState = {};
        Object.keys(isSiteDataAvailable).forEach((key) => {
            const formattedKey = key.replace(/ /g, '_'); // Replace spaces with underscores
            initialState[formattedKey] = false; // Initialize each formatted key with a false state
        });
        return initialState;
    });



    const toggleAccordion = (accordionName) => {
        // Format the accordion name to remove spaces
        const formattedAccordionName = accordionName.replace(/ /g, '_');

        setAccordionState({
            ...accordionState,
            [formattedAccordionName]: !accordionState[formattedAccordionName],
        });
    };

    const simulateFormSubmit = () => {
        // Replace this with your actual form submission logic
        // For example, if handleSubmit is an async function, you can await it here
        handleSubmit();
    };

    useEffect(() => {
        simulateFormSubmit();
        // eslint-disable-next-line
    }, [startDate, endDate, selectedSite, selectedBrands, selectedStates, selectedProducts]);

    const numDriveOffs = (data && data.numDriveOffs) || 0;
    const numIOUs = (data && data.numIOUs) || 0;
    const iouAmount = (data && data.iouAmount) || 0.00;
    const driveOffAmount = (data && data.driveOffAmount) || 0.00;
    const totalDebt = (iouAmount + driveOffAmount).toFixed(2);
    const ErrorReportHeading = 'Report Data Fetch Error';
    const formatDateReport = (date) => {
        const d = new Date(date);
        const day = (`0${d.getDate()}`).slice(-2);
        const month = (`0${d.getMonth() + 1}`).slice(-2);
        const year = d.getFullYear();
        return `${day}${month}${year}`;
    };
    return (
        <div className="Reports-Page">
            {error && <ErrorModal show={true} onClose={handleCloseErrorModal} message={errorMessage} heading={ErrorReportHeading} />}
            <div className="main-box">
                <div className="col-sm-12 container-max-width-reports-page">
                    <div className={`row ${styles.TitleSection}`}>
                        <div className="col-sm-12 d-flex">
                            <div className="col-sm-10">
                                <h2 className={styles.Title}>WA Letter Run Processing</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row search-bar pb-4">
                        <div className="col-sm-12 d-flex">

                            <div className="col-sm-12">
                                <div className="col-sm-12 d-flex">
                                    <div className="col-sm-10 report-filters-actions-heading">
                                        Filters
                                    </div>
                                    <div className="col-sm-2 ps-3 report-filters-actions-heading">
                                        Actions
                                    </div>
                                </div>
                                <div className="col-sm-12 d-flex">
                                    <div className="col-sm-9">
                                        <div className="row p-0">
                                            <form onSubmit={handleSubmit}>
                                                <div className="col-sm-12 d-flex">
                                                    <div className="col-sm-2 div-item ">
                                                        <div className="col-sm-12">Start Date</div>
                                                        <div className="col-sm-12">
                                                            <input
                                                                style={{ marginTop: '10px', height: '2.8rem', fontSize: '16px', paddingLeft: '10px' }}
                                                                className=" report-date-field date-hover"
                                                                type="date"
                                                                id="startDate"
                                                                value={startDate}
                                                                onChange={handleStartDateChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2 div-item">
                                                        <div className="col-sm-12">End Date</div>
                                                        <div className="col-sm-12">
                                                            <input
                                                                style={{ marginTop: '10px', height: '2.8rem', fontSize: '16px', paddingLeft: '10px' }}
                                                                className=" report-date-field date-hover"
                                                                type="date"
                                                                id="endDate"
                                                                value={endDate}
                                                                onChange={handleEndDateChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* states */}
                                                    {(userRole === 'sensenadmin' || userRole === 'frs-reviewer') && (
                                                        <>
                                                            {/* brands */}
                                                            <div className="col-sm-2 div-item">
                                                                <div className="col-sm-12 mb-2">Brands</div>
                                                                <div className="col-sm-12 dropdown-container">
                                                                    <MaterialUIMultiSelectCheckmarks
                                                                        options={customersList ?? []}
                                                                        setSelectedStateFunction={setSelectedBrands}
                                                                        initialStateAllSelected={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="col-sm-2 div-item">
                                                        <div className="col-sm-12 mb-2">Sites</div>
                                                        <div className="col-sm-12 dropdown-container">
                                                            <MaterialUIMultiSelectCheckmarks
                                                                options={siteNames ? siteNames : []}
                                                                setSelectedStateFunction={setSelectedSite}
                                                                initialStateAllSelected={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* product */}
                                                    {(userRole === 'sensenadmin' || userRole === 'frs-reviewer') && (
                                                        <div className="col-sm-2 div-item">
                                                            <div className="col-sm-12 mb-2">Product</div>
                                                            <div className="col-sm-12 dropdown-container">
                                                                <MaterialUIMultiSelectCheckmarks
                                                                    options={productsList ?? []}
                                                                    setSelectedStateFunction={setSelectedProducts}
                                                                    initialStateAllSelected={true}
                                                                />
                                                            </div>
                                                        </div>)}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-2 ps-4">
                                        <span
                                            className={`${hasSiteData ? "action-btns" : "disable-btns"
                                                } row p-0`}
                                            style={{ color: hasSiteData && textAccentColor ? textAccentColor : 'inherit' }}
                                        >
                                            {hasSiteData ? <CSVCourtReport data={newdata} reportName={`scancam-wa-court-csv-export-report-${formatDateReport(startDate)}-to-${formatDateReport(endDate)}`} /> : <div className="d-flex ps-0 disable-btns">
                                                <span className="pe-1"> <LaunchIcon style={{ fontSize: "18px" }} /> </span> <span>Export Court CSV</span>
                                            </div>}
                                        </span>
                                        <span
                                            className={`${hasSiteData ? "action-btns" : "disable-btns"
                                                } row p-0`}
                                            style={{ color: hasSiteData && textAccentColor ? textAccentColor : 'inherit' }}
                                        >
                                            {hasSiteData ? <ExportDotCSVButton data={newdata} reportName={`scancam-wa-dot-csv-export-report-${formatDateReport(startDate)}-to-${formatDateReport(endDate)}`} /> : <div className="d-flex ps-0 disable-btns">
                                                <span className="pe-1"> <LaunchIcon style={{ fontSize: "18px" }} /> </span> <span>Export DoT CSV</span>
                                            </div>}
                                        </span>
                                        <span
                                            className={`${hasSiteData ? "action-btns" : "disable-btns"
                                                } row p-0`}
                                            style={{ color: hasSiteData && textAccentColor ? textAccentColor : 'inherit' }}
                                        >
                                            {hasSiteData ? <CSVLetterRunButton data={newdata} reportName={`scancam-wa-letter-run-csv-export-report-${formatDateReport(startDate)}-to-${formatDateReport(endDate)}`} /> : <div className="d-flex ps-0 disable-btns">
                                                <span className="pe-1"> <LaunchIcon style={{ fontSize: "18px" }} /> </span> <span>Export Letter Run CSV</span>
                                            </div>}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <RotatingIcon colorValue={'grey'} />

                </div>
                : <div id="pdf-content">
                    {showResult === true && data && hasSiteData && (
                        <div style={{ backgroundColor: '#F4F4F4' }}>
                            <div className="accordion accordion-flush container-max-width-reports-page">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" style={{ backgroundColor: '#F4F4F4' }} id="flush-headingStats">
                                        <div className="accordion-button ps-0 pe-0 collapsed row d-flex" type="button"
                                            style={{ backgroundColor: '#F4F4F4' }}
                                            data-bs-toggle="collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                            <div className="col-8 ps-0 counts-heading text-start" style={{ color: '#6f7172' }}>
                                                ${totalDebt} debt from {numIOUs + numDriveOffs} incident{numIOUs + numDriveOffs > 1 ? 's' : ''}
                                            </div>
                                            <div className="col-2 text-end">
                                                <div>
                                                    <div className="totals-value text-end" style={{ color: '#6f7172' }}>
                                                        {numIOUs}
                                                    </div>
                                                    <div className="totals-label text-end" style={{ color: '#b4b4b4' }}>IOUs</div>
                                                </div>
                                            </div>
                                            <div className="col-2 text-end">
                                                <div>
                                                    <div className="totals-value text-end" style={{ color: '#6f7172' }}>
                                                        {numDriveOffs}
                                                    </div>
                                                    <div className="totals-label text-end" style={{ color: '#b4b4b4' }}>Drive Offs</div>
                                                </div>
                                            </div>
                                        </div>
                                    </h2>
                                </div>
                            </div>
                            {/* chart data */}
                            <div className="container-max-width-reports-page">
                                <StackedBarChart chartData={chartData} className='report-bar-chart' />
                            </div>
                            <div className=" accordion accordion-flush container-max-width-reports-page" >
                                <div className="accordion-item">
                                    <h2 className="accordion-header" style={{ backgroundColor: '#F4F4F4' }} id="flush-headingStats">
                                        <div className="accordion-button ps-0 pe-0 collapsed row d-flex" type="button"
                                            style={{ backgroundColor: '#F4F4F4' }}
                                            data-bs-toggle="collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                            <div className="col-3 ps-0">
                                                <div className="counts-heading text-start" style={{ color: '#b4b4b4', padding: '0px' }}>Total</div>
                                            </div>
                                            <div className="col-5"></div>
                                            <div className="col-2 text-end">
                                                <div>
                                                    <div className="totals-value text-end" style={{ color: '#6f7172' }}>
                                                        ${iouAmount.toFixed(2)}
                                                    </div>
                                                    <div className="totals-label text-end" style={{ color: '#b4b4b4' }}><div className="summary-chart-legend-spot" style={{ backgroundColor: chart1Color }}></div>IOUs</div>
                                                </div>
                                            </div>
                                            <div className="col-2 text-end">
                                                <div>
                                                    <div className="totals-value text-end" style={{ color: '#6f7172' }}>
                                                        ${driveOffAmount.toFixed(2)}
                                                    </div>
                                                    <div className="totals-label text-end" style={{ color: '#b4b4b4' }}><div className="summary-chart-legend-spot " style={{ backgroundColor: chart2Color }}></div>DriveOffs
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row body-content">
                        <div className="row ps-0 pe-0 ">
                            <div className="col-sm-12 ps-0 pe-0 d-flex">
                                <div className="col-12 container-max-width-reports-page">
                                    {showResult === false ? (
                                        <div className="row">
                                            <div className="col-sm-12 text-center mt-4" style={{ fontSize: '20px', color: '#6f7172', paddingTop: "50px" }}>
                                                <p>At least one Site must be selected.</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {data && hasSiteData ?
                                                (<>
                                                    {hasSiteData && (
                                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                                            {Object.keys(isSiteDataAvailable).map((key) => {
                                                                const formattedKey = key.replace(/ /g, '_'); // Format the key for state access
                                                                return (
                                                                    <div key={formattedKey} className={`accordion-item ${accordionState[formattedKey] ? 'show' : ''}`}>
                                                                        <h2 className="accordion-header" id={`panelsStayOpen-heading-${formattedKey}`}>
                                                                            <div
                                                                                className="accordion-button  ps-0 pe-0 row d-flex"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={`#panelsStayOpen-collapse-${formattedKey}`}
                                                                                aria-expanded={accordionState[formattedKey]}
                                                                                aria-controls={`panelsStayOpen-collapse-${formattedKey}`}
                                                                                onClick={() => toggleAccordion(formattedKey)}
                                                                            >
                                                                                <div className="col-8 ps-0">
                                                                                    <div style={{ color: "#e27a13", fontWeight: "bolder", fontSize: "14px" }}>
                                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                                            {accordionState[formattedKey] ? <ExpandMoreRoundedIcon style={{ color: textAccentColor }} /> : <ChevronRightRoundedIcon style={{ color: textAccentColor }} />}
                                                                                            <span style={{ marginLeft: "10px", color: textAccentColor }}>{isSiteDataAvailable[key].count} {key}</span>
                                                                                        </div>
                                                                                        <span className="pt-1" style={{ fontSize: '12px', color: textAccentColor, marginLeft: "34px" }}>
                                                                                            {accordionState[formattedKey] ? 'Hide All' : 'Show All'}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-2 text-end">
                                                                                    <div>
                                                                                        <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                            ${(isSiteDataAvailable[key].iouAmount || 0).toFixed(2)}
                                                                                        </div>
                                                                                        <div className="totals-label" style={{ color: '#b4b4b4' }}>Total for IOUs</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-2 pe-0 text-end">
                                                                                    <div>
                                                                                        <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                            ${(isSiteDataAvailable[key].driveOffAmount || 0).toFixed(2)}
                                                                                        </div>
                                                                                        <div className="totals-label" style={{ color: '#b4b4b4' }}>Total for DriveOffs</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </h2>
                                                                        <div className={`accordion-collapse collapse ${accordionState[formattedKey] ? 'show' : ''}`}>
                                                                            <CustomizedTable
                                                                                reportName={"debtOutstanding"}
                                                                                heading={['OCCURRED AT', 'LICENSE PLATE', 'TYPE', 'STATUS', 'SITE', 'PUMP', 'REFERENCE', 'VALUE']}
                                                                                data={newdata[key]}
                                                                            />
                                                                            <div className="row  d-flex me-4  mt-2 mb-2">
                                                                                <div className="col-7"></div>
                                                                                <div className="col-3 text-end" style={{ padding: '0px' }}>
                                                                                    <div>
                                                                                        <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                            ${(isSiteDataAvailable[key].iouAmount || 0).toFixed(2)}
                                                                                        </div>
                                                                                        <div className="totals-label" style={{ color: '#b4b4b4' }}>Total for IOUs</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-2 ps-1 text-end" style={{ padding: '0px' }}>
                                                                                    <div>
                                                                                        <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                            ${(isSiteDataAvailable[key].driveOffAmount || 0).toFixed(2)}
                                                                                        </div>
                                                                                        <div className="totals-label" style={{ color: '#b4b4b4' }}>Total for DriveOffs</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                    <div className="accordion accordion-flush" >
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="flush-headingStats">
                                                                <div className="accordion-button ps-0 pe-0  collapsed row d-flex" type="button"
                                                                    data-bs-toggle="collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                    <div className="col-8 counts-heading" style={{ color: '#b4b4b4', padding: '0px' }}>Total</div>
                                                                    <div className="col-2 text-end">
                                                                        <div>
                                                                            <div className="totals-value" style={{ color: textAccentColor }}>
                                                                                ${iouAmount.toFixed(2)}
                                                                            </div>
                                                                            <div className="totals-label" style={{ color: '#b4b4b4' }}>IOUs</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2 pe-0 text-end">
                                                                        <div>
                                                                            <div className="totals-value" style={{ color: textAccentColor }}>
                                                                                ${driveOffAmount.toFixed(2)}
                                                                            </div>
                                                                            <div className="totals-label" style={{ color: '#b4b4b4' }}>DriveOffs</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </>)
                                                : (
                                                    <div className="row">
                                                        <div className="col-sm-12 text-center mt-4" style={{ fontSize: '20px', color: '#6f7172', paddingTop: "50px" }}>
                                                            <p>There are no incidents which match your search criteria.</p>
                                                        </div>
                                                    </div>
                                                )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    );
};
export default WALetterRunProcessing;