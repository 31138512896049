import React, { useState } from "react";
import "../../CSS/SearchRego.css";
import { Map, Marker, InfoWindow, GoogleApiWrapper } from "google-maps-react";
import { useNavigate } from "react-router-dom";
import { APP_SERVICES_URL } from "../../Constants/constants";
import userSession from "../../Constants/auth";

const TableRow = ({ item, handleRowClick }) => (
  <tr style={{cursor:'pointer'}} onClick={() => handleRowClick(item.alertID)} className="reviewPageIncidentBody">
    <td className="reviewPagedataRows">{item.detected}</td>
    <td className="reviewPagedataRows">{item.ocr}</td>
    <td className="reviewPagedataRows">{item.siteName}</td>
    <td className="reviewPagedataRows">{item.pumpNumber}</td>
    <td className="reviewPagedataRows">Image not avaialable</td>
  </tr>

);

const SearchRego = ({ google }) => {
  const [isInputActive, setIsInputActive] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [displayMode, setDisplayMode] = useState("maps");
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [imageSrc, setimageSrc] = useState("");

  const Navigate = useNavigate();
  const handleRowClick = (id) => {
    Navigate(`/scancam/incident/${id}`);
  };
  const { CustID, authHeader } = userSession;



  const handleInputFocus = () => {
    setIsInputActive(true);
  };

  const handleInputBlur = () => {
    setIsInputActive(false);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value.toUpperCase());
    setErrorMessage("");
    setSearchResult([]);
  };
  const statuses = {
    "Open": [], "Verified": [], "Escalated": [], "Pre-Court": [], "In-Court": [], "Details Received": [], "Recovery In-Progress": [], "Debt Recovered": [], "Debt Paid": [], "Cancelled": [], "Expired": [], "Incomplete Transaction": [], "Returned To Site": []
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (searchQuery.trim() === "") {
      setErrorMessage("Please enter a registration number to search.");
      return;
    }

    try {
      const payload = {
        statuses: statuses,
        partialOCR: searchQuery, // Update partialOCR with searchQuery
      };
          // Construct request body
      const queryParams = new URLSearchParams({
        limit: 10000,
        offset: 0,
      });

      const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incidentsList?${queryParams.toString()}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader,
        },
        body: JSON.stringify(payload), // Convert payloadData to JSON string
      });

      if (!response.ok) {
        throw new Error('Failed to fetch incidents data.');
      }

      const data = await response.json();
      const updatedData = data.map((item) => {
        if (item.location) {
          const [lat, lng] = item.location.split(",");
          return {
            ...item,
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          };
        } else {
          return item; // Return the item unchanged if location is missing
        }
      });


      setSearchResult(updatedData);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  };

  const fetchEventImage = async (eventID) => {
    setimageSrc("");
    const queryParams = new URLSearchParams({
      eventID: eventID,
      type: 'source'
    });
    const url = `${APP_SERVICES_URL}/sn-events-service/${CustID}/eventImage?${queryParams.toString()}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
      },
      responseType: 'arraybuffer'
    });
    let arraybuffer = await response.arrayBuffer();
    let binary = '';
    const bytes = new Uint8Array(arraybuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    let image = 'data:image/jpeg;base64,' + btoa(binary);
    setimageSrc(image);
  }
  // console.log({ searchResult })

  function handleCloseInfoWindow() {
    setSelectedLocation(null);
    setInfoWindowOpen(false);
  }
  return (
    <div className="search-page-box">
      <div className="row">
        <div className="col-sm-12">
          <div className="col-sm-4 search-input-box">
            <input
              type="text"
              className={`${isInputActive ? "input-active" : "search-input-bar"}`}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              placeholder="Search..."
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleFormSubmit(event);
                }
              }}
            />
          </div>
          {searchResult.length === 0 && (
            <div className="row">
              <div className="col-sm-12" style={{ color: "white" }}>
                <div className="col-sm-4 search-no-output-text">
                  {errorMessage}
                </div>
              </div>
            </div>
          )}
          <div className="col-sm-8"></div>
        </div>
      </div>
      <div className="row">
        {searchResult.length === 0 ? (
          <div className="col-sm-12">
            <div className="col-sm-4 search-no-input-text">
              Search for license plate detection.
            </div>
            <div className="col-sm-8"></div>
          </div>
        ) : (
          <div className="col-sm-12" style={{ color: "white" }}>
            <div className="col-sm-4 search-btns-text map-list">
              <span className={displayMode === "maps" ? "active-map map" : "map"} onClick={() => setDisplayMode("maps")}>Maps</span>
              <span className={displayMode === "list" ? "active-map ms-2 list" : "ms-2 list"} onClick={() => setDisplayMode("list")}>List</span>
            </div>
            <div className="col-sm-8"></div>
          </div>
        )}
      </div>
      {searchResult.length > 0 && displayMode === "maps" && (
        <div className="row">
          <div className="col-sm-12" style={{ color: "white" }}>
            <div className="col-sm-8 search-map-view-box">
              <Map
                google={google}
                zoom={4}
                style={{ width: "85%", height: "70vh" }}
                // initialCenter={searchResult[0]}
              >
                {searchResult.map((location, index) => (
                  <Marker
                    title={`Site : ${location.siteName} \nLicense Number : ${location.ocr}\nDetection Date : ${location.detected}  `}
                    icon={{
                      url: "http://maps.google.com/mapfiles/ms/icons/orange-dot.png"
                    }}
                    key={index}
                    position={{ 'lat': location.lat, 'lng': location.lng }}
                    onClick={() => {
                      setInfoWindowOpen(true);
                      setSelectedLocation(location);
                      fetchEventImage(location.eventID);
                    }}
                  />
                ))}
                {infoWindowOpen && selectedLocation && (
                  <InfoWindow
                    marker={selectedLocation}
                    position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
                    visible={infoWindowOpen}
                    onClose={() => handleCloseInfoWindow()}
                  >
                    <div className="info-window-content">
                      {
                        imageSrc && (
                          <img
                            src={imageSrc}
                            alt="eventImage"
                            className="info-window-image"
                          />
                        )
                      }
                      <div className="info-window-text">
                        <div> {selectedLocation.ocr} - {selectedLocation.siteName} </div>
                      </div>
                      <div className="info-window-text">
                        <div> {selectedLocation.detected} </div>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Map>
            </div>
          </div>
        </div>
      )}
      {searchResult.length > 0 && displayMode === "list" && (
        <div className="row">
          <div className="col-sm-12" style={{ color: "white" }}>
            <div className="col-sm-12 search-list-view-box">
              <table className="reviewPageIncidentList">
                <thead className="eviewPageIncidentHeader">
                  <tr>
                    <th>DETECTED ON</th>
                    <th>LICENSE PLATE</th>
                    <th>SITE</th>
                    <th>PUMP</th>
                    <th>DETECTION IMAGE</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResult.map((item) => (
                    <TableRow
                      key={item.alertID}
                      item={item}

                      handleRowClick={handleRowClick}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAezOVoWxU1pzVEeXCW57uOFhxl1ydtagU",
})(SearchRego);