// ResetPasswordPage.js

import React, { useEffect, useState } from "react";
import "../../CSS/LoginForm.css";
import { useParams } from "react-router-dom";
import sensen_Login_Image from "../../Assets/sensen_Login_Image.png";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { custId, name, resetToken } = useParams();
  const [loading, setLoading] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showInvalidLinkMessage, setShowInvalidLinkMessage] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [hasMinimumLength, setHasMinimumLength] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [count, setCount] = useState(5);

  const resetPasswordAuthentication = async (custId, name, resetToken) => {
    const apiUrl = `/sn-auth-service/${custId}/resetPasswordAuthentication/${name}/${resetToken}`;
    setLoading(true);
    try {
      setShowBackdrop(true);
      setShowInvalidLinkMessage(false);
      const response = await fetch(apiUrl, {
        method: "POST",
        // Add any additional configurations as needed
      });

      if (!response.ok) {
        setShowBackdrop(false);
        setLoading(false);
        setShowInvalidLinkMessage(true);
        throw new Error(
          `Failed to authenticate reset password. Status: ${response.status}`
        );
      }

      const data = await response.json();
      setLoading(false);
      setShowBackdrop(false);
      setAuthToken(data);
      // console.log('Response:', data);
    } catch (error) {
      console.error("Error:", error);
      setShowBackdrop(false);
      setLoading(false);
      setShowInvalidLinkMessage(true);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await resetPasswordAuthentication(
          custId,
          name,
          resetToken
        );
        // Handle the response data as needed
      } catch (error) {
        // Handle errors
        console.error("Authentication error:", error);
      }
    };

    // Call the function when the component mounts
    fetchData();
  }, [custId, name, resetToken]);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;

    // Check password conditions
    setHasMinimumLength(newPassword.length >= 8);
    setHasLowerCase(/[a-z]/.test(newPassword));
    setHasUpperCase(/[A-Z]/.test(newPassword));
    setHasNumber(/\d/.test(newPassword));
    // Update password state
    setPassword(newPassword);
  };

  // Update isPasswordValid whenever individual conditions change
  useEffect(() => {
    setIsPasswordValid(
      hasMinimumLength && hasLowerCase && hasUpperCase && hasNumber
    );
  }, [hasMinimumLength, hasLowerCase, hasUpperCase, hasNumber]);

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    // Validate passwords
    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }
    // Check if both passwords satisfy all conditions
    const isNewPasswordValid =
      password.length >= 8 &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /\d/.test(password);

    if (!isNewPasswordValid) {
      // Set error state if passwords do not meet the criteria
      setPasswordsMatch(false);
      return;
    }
    setShowBackdrop(true);
    try {
      // Call the API to reset the password
      const response = await fetch("/sn-auth-service/passwordReset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          custID: custId,
          username: name,
          plainPassword: password,
          token: authToken,
        }),
      });

      // Check the response status
      if (!response.ok) {
        setShowBackdrop(false);
        throw new Error("Failed to reset password.");
      }
      // Convert response body to JSON
      const responseDataApi = await response.json();
      // console.log("Response data:", responseDataApi);
      setShowBackdrop(false);
      // Reset was successful
      setResetSuccess(true);
      setResetError(null);
      // Set timer to run the function after 4 seconds
      setInterval(() => {
        setCount((count) => count - 1);
        // console.log(count);
      }, 1000);

      setTimeout(() => {
        window.location.href = responseDataApi;
      }, 4000); // 4000 milliseconds = 4 seconds
    } catch (error) {
      // Handle errors
      setShowBackdrop(false);
      console.error("Error resetting password:", error);
      setResetError("Failed to reset password. Please try again.");
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleCopyPaste = (e) => {
    e.preventDefault();
  };

  return (
    // <div className="page-container">

    //   {showBackdrop && (
    //     <Backdrop
    //       sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //       open={showBackdrop}
    //       onClick={() => {}}
    //     >
    //       <CircularProgress color="inherit" />
    //     </Backdrop>
    //   )}
    //   {/* //dsfsdfds */}
    // </div>
    <div className="h-screen flex items-center justify-center bg-[#363636] font-login">
      <div className="bg-white rounded-md  shadow-md min-h-[540px] max-h-fit w-[400px]">
        {showInvalidLinkMessage ? (
          <div className="flex items-center justify-center ">
            <div className="flex flex-col justify-center items-center space-y-6 text-[#1E212A] p-10 mt-10 rounded-md ">
              <div className="flex justify-center text-center">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="11"
                    fill="white"
                    stroke="red"
                    stroke-width="1"
                  />
                  <path
                    d="M8 8L16 16M16 8L8 16"
                    stroke="red"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h1 className="font-normal text-2xl">Invalid Link</h1>
              <p className="text-base text-center">
                This link has already been used. To reset your <br /> password,
                return to the login page and select <br /> "Forgot Your
                Password" to send a new email.
              </p>
            </div>
          </div>
        ) : resetSuccess ? (
          <div className="flex items-center justify-center ">
            <div className="flex flex-col justify-center items-center space-y-6 text-[#1E212A] p-10 mt-10 rounded-md ">
              <div className="flex justify-center text-center">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="11"
                    fill="white"
                    stroke="green"
                    stroke-width="1"
                  />
                  <path
                    d="M7 13L10 16L17 9"
                    stroke="green"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h1 className="font-normal text-2xl">Password Changed!</h1>
              <p className="text-base text-center">
                Your Password has been changed successfully. <br />
                Please wait, you will be redirected to login page in {count}
                seconds
              </p>
            </div>
          </div>
        ) : resetError ? (
          <div className="flex items-center justify-center ">
            <div className="flex flex-col justify-center items-center space-y-6 text-[#1E212A] p-10 mt-10 rounded-md ">
              <div className="flex justify-center text-center">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="11"
                    fill="white"
                    stroke="red"
                    stroke-width="1"
                  />
                  <path
                    d="M8 8L16 16M16 8L8 16"
                    stroke="red"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h1 className="font-normal text-2xl">Password Reset Failed</h1>
              <p className="text-base text-center">
                Password reset failed. Please try again.
              </p>
            </div>
          </div>
        ) : (
          <form
            method="post"
            onSubmit={handleSubmit}
            className="flex flex-col h-full"
          >
            <div className="flex-grow borde">
              <div className="flex flex-col justify-center items-center ps-10 pe-10 pt-16 space-y-4 text-[#1E212A]">
                <img
                  src={sensen_Login_Image}
                  alt="logoSensen"
                  height={52}
                  width={168}
                />
                <h1 className="font-normal text-2xl">Change Your Password</h1>
                <p className="text-sm">
                  Enter a new password below to change your password.
                </p>
              </div>
              <div className="space-y-1 ps-10 pe-10 pt-4">
                <div>
                  <FormControl fullWidth variant="outlined">
                    <p className="mt-2">Password</p>
                    <OutlinedInput
                      id="outlined-password-input"
                      type={showPassword ? "text" : "password"}
                      className={`inputField ${
                        !passwordsMatch ? "inputErrorPassword" : ""
                      }`}
                      onCopy={handleCopyPaste}
                      onPaste={handleCopyPaste}
                      value={password}
                      placeholder="New password"
                      onChange={handlePasswordChange}
                      fullWidth
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                <div className="inputContainer w-100">
                  <FormControl fullWidth variant="outlined">
                    <p className="mt-2">Confirm Password</p>
                    <OutlinedInput
                      id="outlined-confirm-password-input"
                      type={showConfirmPassword ? "text" : "password"}
                      className={`inputField ${
                        !passwordsMatch ? "inputErrorPassword" : ""
                      }`}
                      onCopy={handleCopyPaste}
                      onPaste={handleCopyPaste}
                      value={confirmPassword}
                      placeholder="Re-enter new password"
                      onChange={handleConfirmPasswordChange}
                      fullWidth
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="space-y-4 mt-4 mb-4 ps-10 pe-10">
              <div>
                <p className="mb-2 text-gray-700">
                  Your password must contain:
                </p>
                <ul className="list-disc pl-5 space-y-1">
                  <li
                    className={`${
                      hasMinimumLength ? "text-green-600" : "text-[#1e212a]"
                    }`}
                  >
                    At least 8 characters
                  </li>
                  <li
                    className={`${
                      hasLowerCase ? "text-green-600" : "text-[#1e212a]"
                    }`}
                  >
                    Lower case letters (a-z)
                  </li>
                  <li
                    className={`${
                      hasUpperCase ? "text-green-600" : "text-[#1e212a]"
                    }`}
                  >
                    Upper case letters (A-Z)
                  </li>
                  <li
                    className={`${
                      hasNumber ? "text-green-600" : "text-[#1e212a]"
                    }`}
                  >
                    Numbers (0-9)
                  </li>
                </ul>
              </div>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-xl text-base font-medium text-white bg-[#21358C] hover:bg-[#21358C] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Reset password"
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
