import React, { Component } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';

class ExportDotCSVButton extends Component {
    convertToCSV = (data) => {
        let csvContent = '';
        const { reportType } = this.props;

        Object.keys(data).forEach((key) => {
            data[key].forEach((record) => {
                const ocr = record.ocr;
                const detectedDate = new Date(record.detected);
                const day = String(detectedDate.getDate()).padStart(2, '0');
                const month = String(detectedDate.getMonth() + 1).padStart(2, '0');
                const year = detectedDate.getFullYear();
                const formattedDate = `${day}${month}${year}`;
                if (reportType === 'QLD') {
                    csvContent += `${ocr},QLD,,${formattedDate},,13780218,,,\n`;
                } else {
                    csvContent += `${ocr},,${formattedDate}\n`;
                }
            });
        });

        return csvContent;
    };

    downloadCSV = (data) => {
        const csv = this.convertToCSV(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        const filename = `${this.props.reportName}dot-csv-report.csv`;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    render() {
        return (
            <span style={{ padding: '0px', margin: '0px' }} onClick={() => this.downloadCSV(this.props.data)}>
                <span className=""> <LaunchIcon style={{ fontSize: "18px" }} /> </span> <span>Export DoT CSV</span>
            </span>
        );
    }
}

export default ExportDotCSVButton;
