const TitileReport = ({ label }) => {
  return (
    <div className="row bg-[#F4F4F4] h-[64px] flex items-center">
      <div className="col-md-12 ps-0 min-w-[1170px] max-w-[1170px] mx-auto text-[#6F7172] flex justify-start">
        <h2 className="text-3xl">{label} Report</h2>
      </div>
    </div>
  );
};

export default TitileReport;
