import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import './EmailModal.css'; // Import the CSS file
import userSession from "../Constants/auth"

const EmailModal = ({ show, onClose, onSelectReportType, apiResponse, reportName }) => {
  const [reportType, setReportType] = useState('detailed');
  const [emailAddress, setEmailAddress] = useState('');
  const ctaBackgroudColor = userSession.themeData?.ctaBackgroudColor || '#e27a13';
  const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
  const handleReportTypeChange = (event) => {
    setReportType(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handleGenerateReport = () => {
    onSelectReportType(reportType, emailAddress);
    onClose();
  };

  const handleEmailBlur = (event) => {
    setEmailAddress(event.target.value);
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton className="email-modal-header">
        <Modal.Title className="email-modal-title" style={{ color: textAccentColor }}>Email PDF Report</Modal.Title>
      </Modal.Header>

      <Modal.Body className="email-modal-body">
        {apiResponse === null ? (
          <div>
            {reportName !== 'AdvanceReport' && reportName !== 'SiteTraffic' && reportName !== 'OffenderDetection' && (
              <>
                <div className="report-type-container">What type of report would you like to create?</div>
                <div className="text-center">
                  <Form>
                    <div className="email-label">
                      <input
                        type="radio"
                        id="radio-detailed"
                        name="reportType"
                        value="detailed"
                        checked={reportType === 'detailed'}
                        onChange={handleReportTypeChange}
                      />
                      <label htmlFor="radio-detailed">
                        Detail (show all incidents)
                      </label>
                    </div>

                    <div className=" email-label">
                      <input
                        type="radio"
                        id="radio-summary"
                        name="reportType"
                        value="summary"
                        checked={reportType === 'summary'}
                        onChange={handleReportTypeChange}
                      />
                      <label htmlFor="radio-summary" >
                        Summary (show sub-totals only)
                      </label>
                    </div>
                  </Form>
                </div>
              </>
            )}
            <div className="email-input-container">
              <div>Email:</div>
              <div>
                <Form.Control
                  type="email"
                  value={emailAddress}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                  required
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            {apiResponse ? (
              <div className='text-center' style={{ fontSize: '18px', color: 'grey' }}>The report has been emailed to you</div>
            ) : (
              <div className='text-center' style={{ fontSize: '18px', color: 'grey' }}>The report has not been emailed to you. Please contact the support team if the issue persists.</div>
            )}
          </div>
        )}
      </Modal.Body>

      <div>
        {apiResponse === null ? (
          <div className="d-flex col-sm-12 text-center">
            <button style={{ borderColor: 'none', cursor: 'pointer',border:"none" }} className="email-cancel-button col-6" onClick={onClose}>
              Cancel
            </button>
            <button className="email-send-button col-6" style={{ backgroundColor: ctaBackgroudColor, borderColor: 'none', cursor: 'pointer',border:"none" }} onClick={handleGenerateReport}>
              Send
            </button>
          </div>
        ) : (
          <div style={{ borderColor: 'none', cursor: 'pointer',border:"none" }} className="col-12 text-center email-cancel-button" onClick={onClose}>
            Close
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EmailModal;