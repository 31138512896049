import React, { useState } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import styles from "../../CSS/Base/Basic.module.css";
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'; // Import Material Icons for arrows

const VideoSlider = ({ videos, textAccentColor, handleOpenDeleteVideoModal }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleLeftClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleRightClick = () => {
        if (currentIndex < videos.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const currentVideo = videos[currentIndex];

    return (
        <div className={`${styles.incidentShowVideoBox} d-flex justify-content-center align-items-center`}>
            <div className="video-show-box" style={{ maxWidth: 'auto', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                {videos.length > 0 && currentVideo && (
                    <RiDeleteBin6Line
                        onClick={() => handleOpenDeleteVideoModal(currentVideo.frameID)}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            borderRadius: '5px',
                            width: '2rem',
                            height: '2rem',
                            cursor: 'pointer',
                            color: textAccentColor,
                            zIndex: 1
                        }}
                        className="delete-video"
                    />
                )}
                {videos.length === 0 ? (
                    <div className="video-container">
                        <div className="video-text-container">
                            <div className="center-text" style={{ color: textAccentColor,textAlign:'center' }}>
                                Video is currently being uploaded. Please refresh and check again later
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="slider-container" style={{ position: 'relative', maxWidth: '100%', width: 'auto', display: 'flex', justifyContent: 'center' }}>
                        <div onClick={handleLeftClick} style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', cursor: currentIndex === 0 ? 'not-allowed' : 'pointer', opacity: currentIndex === 0 ? 0.5 : 1 }}>
                            <MdChevronLeft style={{ fontSize: '3.5rem', lineHeight: '1.5', fontWeight: 'bolder' }} />
                        </div>
                        <div onClick={handleRightClick} style={{ fontWeight: 'bolder', position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)', cursor: currentIndex === videos.length - 1 ? 'not-allowed' : 'pointer', opacity: currentIndex === videos.length - 1 ? 0.5 : 1 }}>
                            <MdChevronRight style={{ fontSize: '3.5rem', lineHeight: '1.5', fontWeight: 'bolder' }} />
                        </div>

                        {currentVideo && currentVideo.url && (
                            <div key={currentVideo.id} style={{ position: "relative", maxWidth: 'calc(100% - 100px)' }}>
                                <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
                                    <video className="video-element" controls style={{ maxWidth: '100%', height: 'auto' }}>
                                        <source src={currentVideo.url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoSlider;
