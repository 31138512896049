import React from "react";
import { Modal, Form } from "react-bootstrap";
import styles from "../CSS/Base/Basic.module.css";
import style2 from "../CSS/ManageEmployees.module.css";
import userSession from "../Constants/auth";

const EmployeeModals = (props) => {
    const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
    const ctaBackgroudColor = userSession.themeData?.ctaBackgroudColor || '#e27a13';
    const {
        showModal,
        setShowModal,
        editedName,
        setEditedName,
        editedStatus,
        setEditedStatus,
        handleSave,
        isNameValid,
        // eslint-disable-next-line
        setShowErrorText,
        showErrorText,
        newEmployeeName,
        setNewEmployeeName,
        handleSaveNewEmployee,
        setShowAddEmployeeModal,
        // eslint-disable-next-line
        handleCloseModal,
        showAddEmployeeModal,
        setSelectedSite,
        selectedSite,
        setIsSiteValid,
        isSiteValid
    } = props;
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const { sites } = userSession;

    const handleChange = (e) => {
        const inputValue = e.target.value.replace(/[^a-zA-Z\s]/g, ''); // Replace non-alphabetic characters and non-space characters with empty string
        setNewEmployeeName(capitalizeFirstLetter(inputValue.toLowerCase()));
    };

    const handleSiteChange = (e) => {
        setSelectedSite(e.target.value);
    };

    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header className="p-0 ps-2 pe-2" closeButton>
                    <div className={styles.brandColorText} style={{ color: textAccentColor }}>
                        Edit Employee
                    </div>
                </Modal.Header>
                <div style={{ backgroundColor: '#F4F4F4', fontSize: '14px', fontWeight: '400', minHeight: '100px' }}>
                    <Form.Group>
                        <div className="container">
                            <Form.Label className={`mt-2 ${style2.employeeModalSubHeading}`}>
                                Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={editedName}
                                onChange={(e) =>
                                    setEditedName(e.target.value.replace(/[^A-Za-z\s]/g, ''))}
                                className={!isNameValid ? "invalid-input" : ""}
                            />

                            <div className={style2.employeeModalCheckBox}>
                                <Form.Check
                                    type="checkbox"
                                    label="Active"
                                    checked={editedStatus}
                                    onChange={(e) => setEditedStatus(e.target.checked)}
                                />
                            </div>
                        </div>
                    </Form.Group>
                </div >
                <div className='d-flex'>
                    <div className={`col-6 ${style2.btnHover}`} onClick={() => setShowModal(false)} style={{ backgroundColor: '#ffffff', padding: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Cancel
                    </div>
                    <div className={`col-6 ${style2.btnHover}`} onClick={handleSave} style={{ backgroundColor: ctaBackgroudColor, padding: '12px', color: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Save
                    </div>
                </div>
            </Modal>
            <Modal
                show={showAddEmployeeModal}
                onHide={() => setShowAddEmployeeModal(false)}
            >
                <Modal.Header className="p-0 ps-2 pe-2" closeButton>
                    <div className={styles.brandColorText} style={{ color: textAccentColor }}>Add Employee</div>
                </Modal.Header>
                <div style={{ backgroundColor: '#F4F4F4', fontSize: '14px', fontWeight: '400', minHeight: '100px' }}>
                    <Form.Group>
                        <div className="container">
                            <Form.Label className="mt-2">Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={newEmployeeName}
                                onChange={handleChange}
                                className={!isNameValid ? "invalid-input" : ""}
                            />
                        </div>
                        {showErrorText ? (
                            <div>
                                <p style={{ color: "red" }} className="ps-2">
                                    The employee name must be provided
                                </p>
                            </div>
                        ) : (
                            <></>
                        )}

                    </Form.Group>
                    {sites.length > 0 && (
                        <Form.Group>
                            <div className="container">
                                <Form.Label className="mt-2">Site</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedSite}
                                    onChange={handleSiteChange}
                                    className={!isSiteValid ? "invalid-input mb-1" : "mb-3"}
                                >
                                    <option value="">Select a site</option>
                                    {sites.map((site, index) => (
                                        <option key={index} value={site.name}>
                                            {site.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </div>
                            {!isSiteValid && (
                                <div>
                                    <p style={{ color: "red" }} className="ps-2">
                                        The site must be selected
                                    </p>
                                </div>
                            )}
                        </Form.Group>
                    )}
                </div>
                <div className="d-flex" style={{cursor:'pointer'}}>
                    <div
                        className={`col-6 ${style2.btnHover}`}
                        onClick={() => setShowAddEmployeeModal(false)}
                        style={{ backgroundColor: '#ffffff', padding: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Cancel
                    </div>
                    <div className={`col-6 ${style2.btnHover}`} onClick={handleSaveNewEmployee} style={{ backgroundColor: ctaBackgroudColor, padding: '12px', color: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Save
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default EmployeeModals;
