import styles1 from "../../CSS/Base/Basic.module.css";
import styles2 from "../../CSS/FAQ.module.css";
import React, { useState } from "react";
import { TbDeviceIpad } from "react-icons/tb";
import { AiOutlineDesktop } from "react-icons/ai";
import { FiTool } from "react-icons/fi";
import { BsPeople } from "react-icons/bs";

import FaqData from "./FaqData";
import userSession from "../../Constants/auth";
const FaqPage = () => {
  const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
  const [activeText, setActiveText] = useState("iPad");

  const handleClick = (text) => {
    if (activeText === text) {
      setActiveText(text);
    } else {
      setActiveText(text);
    }
  };

  const faqHeadings = [
    { id: 1, iconName: <TbDeviceIpad />, heading: "iPad" },
    { id: 2, iconName: <AiOutlineDesktop />, heading: "Portal" },
    { id: 3, iconName: <FiTool />, heading: "Hardware" },
    { id: 4, iconName: <BsPeople />, heading: "Customer Enquiries" },
  ];

  return (
    <div className='min-h-[60vh]'>
      <div className={styles2.faqTitleBox}>
        <div className={styles2.faqTitle}>
          <h2 className={styles1.Title}>FAQs</h2>
        </div>
      </div>
      <div className={styles2.faqBox}>
        <div className={styles2.faqContainer}>
          {faqHeadings.map((item) => (
            <div
              className={`${styles2.faqText} ${activeText === item.heading ? styles2.faqTextActive : ""
                }`}
              onClick={() => handleClick(item.heading)}
              style={{ color: textAccentColor }}
            >
              <div className={styles2.FaqCircle} style={{ color: textAccentColor }}>{item.iconName}</div>
              <h3 className={styles2.faqRowHeadings} >{item.heading}</h3>
            </div>
          ))}
        </div>
        {activeText && (
          <div className={styles2.faqDetails}>
            {activeText && <FaqData activeText={activeText} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqPage;
