import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BsBarChart,
  BsCreditCard,
  BsTrophy,
  BsPeople,
  BsClock,
  BsShieldX,
  BsEnvelopePaper,
} from "react-icons/bs";
import { SlEvent, SlWallet } from "react-icons/sl";
import { CiSettings } from "react-icons/ci";
import { FiSettings } from "react-icons/fi";
import "../../CSS/reports.css";
import styles from "../../CSS/Base/Basic.module.css";
import data from "../../Constants/reportsData.json";
import userSession from "../../Constants/auth";
// Create a dynamic component for the icons
const IconComponent = ({ iconName, iconSize }) => {
  const icons = {
    BsBarChart: (
      <BsBarChart style={{ fontSize: iconSize, backgroundColor: "#fff" }} />
    ),
    BsCreditCard: (
      <BsCreditCard style={{ fontSize: iconSize, backgroundColor: "#fff" }} />
    ),
    BsTrophy: (
      <BsTrophy style={{ fontSize: iconSize, backgroundColor: "#fff" }} />
    ),
    FiSettings: (
      <FiSettings style={{ fontSize: iconSize, backgroundColor: "#fff" }} />
    ),
    BsPeople: (
      <BsPeople style={{ fontSize: iconSize, backgroundColor: "#fff" }} />
    ),
    BsClock: (
      <BsClock style={{ fontSize: iconSize, backgroundColor: "#fff" }} />
    ),
    BsShieldX: (
      <BsShieldX style={{ fontSize: iconSize, backgroundColor: "#fff" }} />
    ),
    SlWallet: (
      <SlWallet style={{ fontSize: iconSize, backgroundColor: "#fff" }} />
    ),
    SlEvent: (
      <SlEvent style={{ fontSize: iconSize, backgroundColor: "#fff" }} />
    ),
    // SlBriefcase: <SlBriefcase style={{ fontSize: iconSize ,backgroundColor:"#fff" }} />,
    BsEnvelopePaper: (
      <BsEnvelopePaper
        style={{ fontSize: iconSize, backgroundColor: "#fff" }}
      />
    ),
    CiSettings: (
      <CiSettings style={{ fontSize: iconSize, backgroundColor: "#fff" }} />
    ),
  };

  return icons[iconName] || null;
};
const Reports = () => {
  const navigate = useNavigate();
  const cardClasses = "card";
  const iconContainerClasses = "container box";
  const { userRole, themeData } = userSession;
  const ctaBackgroudColor = themeData?.ctaBackgroudColor || "#e27a13";
  const loopLimit =
    userRole === "sensenadmin" ||
    userRole === "frs-reviewer" ||
    userRole === "customeradmin"
      ? 18
      : 7;

  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
    <div className={styles.PageMainBox}>
      <div className={styles.TitleSection}>
        <div className="container-fluid manage-Page-container-max-width manage-Page-body-content">
          <div className="row  title-row">
            <div
              className="col-sm-12 p-0"
              style={{ maxWidth: "1170px", margin: "auto" }}
            >
              <h2 className={styles.Title}>View a Report</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{minHeight:'fit-content'}}>
        <div
          className="container-fluid p-0 pt-4"
          style={{ maxWidth: "1170px", margin: "auto" }}
        >
          <div className="row row-cols-lg-4 row-cols-md-4 row-cols-sm-2">
            {data.slice(0, loopLimit).map((item, index) => (
              <div
                className="col mb-4"
                key={index}
                style={{ cursor: "pointer" }}
              >
                <div
                  onClick={() => handleNavigation(item.link)}
                  style={{ textDecoration: "none" }}
                >
                  <div className={cardClasses} style={{ width: "18rem" }}>
                    <div
                      className="circle"
                      style={{
                        color: ctaBackgroudColor,
                        borderColor: ctaBackgroudColor,
                        borderWidth: "3px",
                        borderStyle: "solid",
                        backgroundColor:"#fff"
                      }}
                    >
                      <div className={iconContainerClasses}>
                        <IconComponent
                          iconName={item.iconName}
                          iconSize={item.iconSize}
                        />
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <h5
                        className="card-title"
                        style={{
                          color: ctaBackgroudColor,
                          fontWeight: "bolder",
                        }}
                      >
                        {item.title}
                      </h5>
                      <p className="card-text">{item.text}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
