// src/hooks/useFetchStats.js
import { useState, useEffect } from "react";
import axios from "axios";

const useFetchStats = (url, method = 'GET', payload = null, authHeader = '') => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          url,
          method,
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
        };

        if (method === 'POST' || method === 'PUT') {
          options.data = payload;
        }

        const response = await axios(options);
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, method, payload, authHeader]);

  return { data, loading, error };
};

export default useFetchStats;
