import React from "react";
import userSession from "../Constants/auth";

const ErrorModal = ({ show, message, onClose, heading }) => {
  console.log({ message });
  console.log({ heading });
  if (!show) return null;
  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const ctaBackgroudColor =
    userSession.themeData?.ctaBackgroudColor || "#e27a13";
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50">
      <div
        className="bg-white shadow-xl  mt-10 min-w-[30rem] max-w-fit min-h-fit overflow-auto relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-3 right-2 text-gray-500 hover:text-gray-700"
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div
          className="border-b py-3 px-3 text-xl font-bold"
          style={{
            color: textAccentColor,
          }}
        >
          {heading}
        </div>
        <div
          className="min-h-fit py-8 text-base bg-[#F4F4F4]"
          style={{
            color: textAccentColor,
          }}
        >
          {/* Content for the middle div */}
          <div className="m-3">{message}</div>
        </div>
        <div
          className="text-center p-3 text-[#fff] cursor-pointer "
          style={{
            backgroundColor: ctaBackgroudColor,
          }}
          onClick={onClose}
        >
          Close
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
