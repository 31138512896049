import React, { useState, useEffect, useRef } from "react";

const MultiSelectDropdown = ({
  options,
  setSelectedStateFunction,
  initialStateAllSelected,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (initialStateAllSelected) {
      setSelectedOptions(["Select all", ...options]);
    }
  }, [initialStateAllSelected, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOption = (option) => {
    let newSelection = [];
    if (option === "Select all") {
      if (selectedOptions.includes("Select all")) {
        newSelection = [];
      } else {
        newSelection = ["Select all", ...options];
      }
    } else {
      if (selectedOptions.includes(option)) {
        newSelection = selectedOptions.filter(
          (item) => item !== option && item !== "Select all"
        );
      } else {
        newSelection = [...selectedOptions, option];
        if (newSelection.length === options.length) {
          newSelection = ["Select all", ...newSelection];
        }
      }
    }
    setSelectedOptions(newSelection);
    setSelectedStateFunction(
      newSelection.filter((item) => item !== "Select all")
    );
  };

  const getDisplayText = () => {
    if (selectedOptions.length === 0) return "None selected";
    if (selectedOptions.includes("Select all"))
      return `All selected ( ${options.length} )`;
    return selectedOptions.join(", ");
  };
  return (
    <div
      className="relative w-full inline-block cursor-pointer"
      ref={dropdownRef}
    >
      <div
        className={`border-1 border-[#adadad] p-2 text-sm cursor-pointer overflow-hidden text-[#333] whitespace-nowrap text-ellipsis font-normal ${
          isOpen ? "bg-[#DFDFDF]" : "#fff"
        } flex justify-between items-center`}
        style={
          isOpen ? { boxShadow: "inset 0 3px 5px rgba(0, 0, 0, 0.125)" } : {}
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        {getDisplayText()}
        <span className="ml-2 w-0 h-0 border-l-[5px] border-l-transparent border-t-[6px] border-t-[#333] border-r-[5px] border-r-transparent"></span>
      </div>
      {isOpen && (
        <ul className="absolute left-0 w-max min-w-full max-w-[450px] mt-1 border border-gray-300 rounded-md bg-white shadow-lg max-h-[450px] overflow-auto z-10 cursor-pointer">
          <li
            className={`py-[3px] px-[20px] hover:bg-[#f4f4f4] active:bg-[#f4f4f4]  min-w-fit max-w-[450px] ${
              selectedOptions.includes("Select all") ? "bg-[#f4f4f4]" : ""
            }`}
          >
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="checkbox"
                checked={selectedOptions.includes("Select all")}
                onChange={() => toggleOption("Select all")}
                className="form-checkbox cursor-pointer"
              />
              <span className="text-sm cursor-pointer">Select all</span>
            </label>
          </li>
          {options.map((option) => (
            <li
              key={option}
              className={`py-[3px] px-[20px] hover:bg-[#f4f4f4] active:bg-[#f4f4f4]  min-w-fit max-w-[450px] ${
                selectedOptions.includes(option) ? "bg-[#f4f4f4]" : ""
              }`}
            >
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => toggleOption(option)}
                  className="form-checkbox cursor-pointer"
                />
                <span className="text-sm cursor-pointer">{option}</span>
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
