import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import frs_card from "../../Assets/frs_card.png";
import style2 from "../../CSS/orderFrsCards.module.css";
import styles from "../../CSS/Base/Basic.module.css";
import { APP_SERVICES_URL } from "../../Constants/constants";
import ErrorModal from "../../Modals/ErrorModal";
import userSession from "../../Constants/auth";
import SimpleBackdrop from "../../Components/Base/SimpleBackdrop";
const OrderFrsCard = () => {
  const ErrorModalHeading = 'FRS Card Order Error';
  const SuccessModalHeading = 'FRS Card Order Successful';
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [activeHeading, setActiveHeading] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false); // New state for success modal
  const { authHeader, CustID, sites } = userSession;
  const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
  const ctaBackgroudColor = userSession.themeData?.ctaBackgroudColor || '#e27a13';

  // Define the styles
  const activeHeadingStyle = {
    color: textAccentColor, // Red color
    borderBottom: `2px solid ${textAccentColor}` // Red border bottom
  };

  const inactiveHeadingStyle = {
    color: '#6F7172', // Black color
    borderBottom: '2px solid #000000' // Black border bottom
  };

  const getSitesNames = (sites) => {
    if (!sites || !Array.isArray(sites)) {
      return [];
    }
    return sites.map((site) => site.name);
  };

  const addAutomaticId = (sites) => {
    if (sites === null || sites === undefined) {
      return [];
    }

    return sites.map((site, index) => ({
      id: index + 1,
      name: site,
    }));
  };

  const siteNames = getSitesNames(sites);

  const sitesWithIds = addAutomaticId(siteNames);
  // console.log({ sites })
  useEffect(() => {
    const pathname = location.pathname;

    setActiveHeading(pathname);
  }, [location]);
  const handleSiteChange = (e) => {
    setSelectedSite(e.target.value);
  };

  const handleQuantityChange = (e) => {
    setSelectedQuantity(e.target.checked ? "50" : "");
  };

  const orderFRSCards = async (siteName, quantity) => {
    try {
      setError(false);
      const url = `${APP_SERVICES_URL}/senapi/${CustID}/frsCardsOrder?siteName=${siteName}&quantity=${quantity}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          'Authorization': authHeader,
        },
      });

      if (!response.ok) {
        setError(true);
        setErrorMessage("Error occurred while ordering FRS cards. Please try again. If the issue persists, contact support.");
      } else {
        setSuccess(true); // Set success to true if the API call is successful
        setErrorMessage("FRS cards ordered successfully. You will receive a confirmation shortly.");
        return true;
      }
    } catch (error) {
      setError(true);
      setErrorMessage("Error occurred while ordering FRS cards. Please try again. If the issue persists, contact support.");
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedSite && selectedQuantity) {
      try {
        setLoading(true);
        const success = await orderFRSCards(selectedSite, selectedQuantity);
        if (success) {
          setError(false);
          setErrorMessage('Your card has been successfully ordered!');
          setSelectedSite("");
          setSelectedQuantity("");
        } else {
          setError(true);
          setErrorMessage('Error in ordering cards. Please try again, and if the issue persists, contact our support team for assistance.');
        }
      } catch (error) {
        setError(true);
        setErrorMessage('An unexpected error occurred. Please try again, and if the issue persists, contact our support team for assistance.');
      } finally {
        setLoading(false);
      }
    } else {
      setError(true);
      setErrorMessage('Please select a site and quantity to proceed.');
    }
  };



  const handleCloseErrorModal = () => {
    setError(false);
  };
  const handleCloseSuccessModal = () => {
    setSuccess(false);
  };

  return (
    <div className={styles.PageMainBoxOther}>
      <SimpleBackdrop open={loading} onClose={() => setLoading(false)} />
      {error && <ErrorModal show={true} onClose={handleCloseErrorModal} message={errorMessage} heading={ErrorModalHeading} />}
      {success && <ErrorModal show={true} onClose={handleCloseSuccessModal} message={'Your card has been successfully ordered!'} heading={SuccessModalHeading} />} {/* Render SuccessModal if success is true */}
      <div className={style2.OrderFrsCardTitleSection}>
        <div
          className={`container-fluid`}
        >
          <div 
            className={`row ${style2.OrderFrsCardPageRow} ${style2.OrderFrsCardtitleRow}`} 
          >
            <div className={style2.OrderFrsCardPagecolSm12} >
              <ul className="nav">
                <li className="Navlinks">
                  <Link
                    className={`${style2.OrderFrsCardHeading} ${activeHeading === "/scancam/ManageEmployees"
                      ? style2.activeHeading
                      : ""
                      }`}
                    to="/scancam/ManageEmployees"
                  >
                    Manage Employees
                  </Link>
                </li>
                <li className="Navlinks">
                  <Link
                    className={`${style2.OrderFrsCardHeading} ${activeHeading === "/scancam/OrderFrsCards"
                      ? style2.activeHeading
                      : ""
                      }`}
                    to="/scancam/OrderFrsCards"
                    style={activeHeading === "/scancam/OrderFrsCards" ? activeHeadingStyle : inactiveHeadingStyle}
                  >
                    Order FRS Cards
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={style2.orderFrsPageBody}>
        <div className="row">
          <div className="col-sm-12 d-flex" style={{maxWidth:'100%',}}>
            <div className="col-sm-6">
              <div className="col-sm-12" id={style2.orderDescription}>
                Here you can order Fuel Recovery Services cards if you are
                running low. Simply specify the quantity, hit submit, and your
                order will be delivered to your site.
              </div>
              <form onSubmit={handleSubmit}>
                <div className="col-sm-12" id={style2.orderSiteContainer}>
                  <div className="col-sm-6">
                    <label htmlFor="site_id" style={{fontWeight:'bold'}}>Site</label>
                  </div>
                  <div className="col-sm-6">
                    <select
                      id={style2.orderFrsSiteId}
                      value={selectedSite}
                      onChange={handleSiteChange}
                    >
                      <option value="">Select a site</option>
                      {sitesWithIds.map((site) => (
                        <option key={site.id} value={site.name}>
                          {site.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12" id={style2.orderQuantityContainer}>
                  <div className="col-sm-6">
                    <label htmlFor="quantity" style={{fontWeight:'bold'}}>Quantity</label>
                  </div>
                  <div className="col-sm-6">
                    <div className="col-sm-2 d-flex w-100">
                      <input
                        type="radio"
                        id="quantity"
                        value="50"
                        checked={selectedQuantity === "50"}
                        onChange={handleQuantityChange}
                      />
                      <label
                        htmlFor="quantity"
                        className={style2.quantitySecondLabel}
                      >
                        1 box (250 cards)
                      </label>
                    </div>
                    <div className="col-sm-10"></div>
                  </div>
                </div>
                <div className="col-sm-12 pt-4">

                  <button
                    type="submit"
                    id={style2.orderButton}
                    style={{ backgroundColor: ctaBackgroudColor, border: 'none', color: '#fff', fontWeight: 'bold' }}
                  >
                    Submit Order
                  </button>

                </div>
              </form>
            </div>
            <div className="col-sm-6 d-flex justify-content-center align-items-center">
              <div className="col-sm-12 d-flex justify-content-center align-items-center">
                <img
                  src={frs_card}
                  alt="frs_cards_image"
                  className={style2.orderFrsCardcustomImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderFrsCard;