import React, { useEffect, useRef, useState } from "react";
import "../../CSS/ReportsPage.css";
import UseReportsApi from "../../Custom Hooks/useReportsApi";
import styles from "../../CSS/Base/Basic.module.css";
import userSession from "../../Constants/auth";
import { APP_SERVICES_URL } from "../../Constants/constants";
import StackedBarChart from "../../Charts/StackedBarChart";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import MaterialUIMultiSelectCheckmarks from "../../Components/ViewReportPage/MaterialUIMultiSelectCheckmarks";
import CustomizedTable from "../../Components/ViewReportPage/CustomizedTable";
import CSVDownloadButton from "../../Components/ViewReportPage/CSVDownloadButton";
import ErrorModal from "../../Modals/ErrorModal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReportTypeModal from "../../Modals/ReportTypeModal";
import EmailModal from "../../Modals/EmailModal";
import PrintIcon from "@mui/icons-material/Print";
import LaunchIcon from "@mui/icons-material/Launch";
import EmailIcon from "@mui/icons-material/Email";
import { useApiDataContext } from "../../ApiDataContext";
import moment from "moment";
import TitileReport from "../../Components/common/TitileReport";

const EmployeePerformance = () => {
  const ErrorReportHeading = "Report Data Fetch Error";
  const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-01");
  const endTime = moment().format("YYYY-MM-DD");

  const { authHeader, sites, CustID, userRole } = userSession;
  const getSitesNames = (sites) => {
    if (!sites || !Array.isArray(sites)) {
      return [];
    }
    return sites.map((site) => site.name);
  };
  const siteNames = getSitesNames(sites);
  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const chart1Color = userSession.themeData?.chart1Color || "#e27a13";
  const chart2Color = userSession.themeData?.chart2Color || "#f7a123";
  const chart3Color = userSession.themeData?.chart3Color || "#ffcb07";
  const chart4Color = userSession.themeData?.chart4Color || "#e4e4e4";
  const { customersList, statesList, productsList } = useApiDataContext();
  const [showResult, setShowResult] = useState(false);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(endTime);
  const [selectedSite, setSelectedSite] = useState([]);
  const [selectedStates, setSelectedStates] = useState(statesList);
  const [selectedBrands, setSelectedBrands] = useState(customersList);
  const [selectedProducts, setSelectedProducts] = useState(productsList);
  const [newdata, setNewData] = useState({});
  const [apiResponse, setApiResponse] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [isReportTypeModalOpen, setIsReportTypeModalOpen] = useState(false);
  const [emailReport, setEmailReport] = useState(false);

  const openEmailModal = () => {
    setButtonClicked("emailReport");
    setEmailReport(true);
  };

  const closeEmailModal = () => {
    setEmailReport(false);
    setApiResponse(null);
  };

  const openReportTypeModal = () => {
    setButtonClicked("printPDF");
    setIsReportTypeModalOpen(true);
  };

  const closeReportTypeModal = () => {
    setIsReportTypeModalOpen(false);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  // Generic function to handle selection for different dropdowns
  const handleSelection = (selectedValues, allValues, setSelectedFunction) => {
    if (selectedValues.includes("Select All")) {
      if (selectedValues.length === allValues.length) {
        // If all items are already selected, unselect all
        setSelectedFunction([]);
      } else {
        // Select all items
        setSelectedFunction(allValues);
      }
    } else {
      setSelectedFunction(selectedValues);
    }
  };

  const formatDate = (dateString, isStart) => {
    const utcTime = moment.utc(dateString); // Assuming the UTC time is '2024-04-30T05:10:00'
    let localTime = utcTime.local(); // Convert to local time zone

    if (isStart) {
      // Set the time to 00:00:00 for the start date
      localTime.startOf("day");
    } else {
      localTime = localTime.endOf("day");
    }

    const localTimeWithoutOffset = localTime.format("YYYY-MM-DDTHH:mm:ss.SSS"); // Omitting the timezone offset

    return localTimeWithoutOffset;
  };

  // Define state variables for data, loading, and error
  const [data, setData] = useState(null);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fetchData = UseReportsApi();

  const fetchReportData = async (payloadData) => {
    const { CustID, authHeader } = userSession;

    try {
      // Serialize payloadData into a query string
      setError(false);
      const queryParams = new URLSearchParams({
        limit: 10000,
        offset: 0,
      });
      const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incidentsList?${queryParams.toString()}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader, // Convert to base64 (Basic Auth)
        },
        body: JSON.stringify(payloadData), // Convert payloadData to JSON string
      });

      if (!response.ok) {
        setError(true);
        setErrorMessage(
          "Failed to fetch report data. Please try again. If the issue persists, contact support."
        );
      }

      const data = await response.json();

      return data;
    } catch (error) {
      setError(true);
      setErrorMessage(
        "Failed to fetch report data. Please try again. If the issue persists, contact support."
      );
    }
  };

  const updateDataStateWithSpecificValues = (result) => {
    // Create a dictionary to store the specific values.
    const specificValues = {};

    // Check for the specific values in the result variable.
    for (const key in result) {
      if (result.hasOwnProperty(key)) {
        specificValues[key] = result[key];
      }
    }

    // Update the data state with the specific values.
    setNewData({
      ...specificValues,
    });
  };

  const handleSubmit = async (e) => {
    const formattedStartDate = formatDate(startDate, true);
    const formattedEndDate = formatDate(endDate, false);

    const payloadData = {
      sites: selectedSite,
      states: selectedStates,
      brands: selectedBrands,
      products: selectedProducts,
      to: formattedEndDate,
      from: formattedStartDate,
    };
    if (payloadData.sites.length < 1) {
      return;
    }
    try {
      setIsLoading(true);
      setError(false);
      const result = await fetchData(
        authHeader,
        CustID,
        "employeePerformance",
        payloadData
      );
      updateDataStateWithSpecificValues(result.employeesInfo);
      setData(result);
      setShowResult(true);
    } catch (error) {
      setError(true);
      setErrorMessage(
        "Failed to fetch report data. Please try again. If the issue persists, contact support."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const EmployeePerformancePayloadGenerator = (
    label,
    from,
    to,
    sites,
    brands,
    products,
    states
  ) => {
    return {
      employeeNames: [label],
      sites,
      from,
      to,
      brands,
      products,
      states,
    };
  };

  // Define a function for fetching and updating report data
  const fetchAndUpdateReportData = async () => {
    const formattedStartDate = formatDate(startDate, true);
    const formattedEndDate = formatDate(endDate, false);
    const allKeys = Object.keys(newdata);

    // Iterate over the keys in the newData state and call the fetchReportData API for each key.
    for (const key of allKeys) {
      // Generate the payload for the API call.
      const payload = EmployeePerformancePayloadGenerator(
        key, // Use the current key as-is
        formattedStartDate,
        formattedEndDate,
        selectedSite,
        selectedBrands,
        selectedProducts,
        selectedStates
      );

      try {
        payload.isFuelLitresRequired = true;
        payload.isSiteAddressRequired = true;
        payload.isProcessingFeeRequired = true;
        payload.isEmployeeNameRequired = true;
        payload.isSiteTypeRequired = true;
        payload.isFuelTypeRequired = true;
        payload.isStoreIDRequired = true;
        setError(false);
        const result = await fetchReportData(payload, key); // Use the current key as the second argument
        // Update the newData state with the result.
        newdata[key] = result;
      } catch (error) {
        setError(true);
        setErrorMessage(
          "Failed to fetch report data. Please try again. If the issue persists, contact support."
        );
      }
    }

    // Set the new newData state.
    setNewData(newdata);
  };

  useEffect(() => {
    fetchAndUpdateReportData();
    // eslint-disable-next-line
  }, [newdata]);

  // Prepare the chart data based on the values you want to pass
  const properties = [
    "numValidDriveOffs",
    "numValidIOUs",
    "numIncomplete",
    "numCancelled",
  ];
  const colors = [chart1Color, chart2Color, chart3Color, chart4Color];
  const chartData = [];

  properties.forEach((property, index) => {
    const value = data ? data[property] : 0;
    if (value > 0) {
      chartData.push({
        data: [value],
        color: colors[index],
      });
    }
  });

  // Watch for changes in the selectedSite array
  useEffect(() => {
    if (selectedSite.length === 0) {
      // Clear your state here, replace with your actual state clearing logic
      setData(null);
      setShowResult(false);
    }
  }, [selectedSite]); // This effect will run whenever selectedSite changes

  const employeesInfo = data?.employeesInfo || {};
  // Check if employeesInfo has at least one key
  const hasEmployeesInfo = Object.keys(employeesInfo).length > 0;

  const handleCloseErrorModal = () => {
    setError(false);
  };

  // Initialize accordion state
  const [accordionState, setAccordionState] = useState(() => {
    const initialState = {};
    Object.keys(employeesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      initialState[formattedKey] = false;
    });
    return initialState;
  });

  // Initialize contentRefs and contentHeights
  const contentRefs = useRef({});
  const [contentHeights, setContentHeights] = useState(() => {
    const heights = {};
    Object.keys(employeesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      heights[formattedKey] = 0;
    });
    return heights;
  });

  // Function to open all accordions
  const openAllAccordions = () => {
    const updatedAccordionState = {};
    Object.keys(employeesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      updatedAccordionState[formattedKey] = true;
    });
    setAccordionState(updatedAccordionState);
  };

  // Function to close all accordions
  const closeAllAccordions = () => {
    const updatedAccordionState = {};
    Object.keys(employeesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      updatedAccordionState[formattedKey] = false;
    });
    setAccordionState(updatedAccordionState);
  };

  // Toggle individual accordion
  const toggleAccordionNew = (accordionName) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [accordionName]: !prevState[accordionName],
    }));
  };

  // Effect to initialize contentRefs
  useEffect(() => {
    const refs = {};
    Object.keys(employeesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      refs[formattedKey] = React.createRef();
    });
    contentRefs.current = refs;
  }, [employeesInfo]);

  // Effect to update content heights
  useEffect(() => {
    const updatedHeights = {};
    Object.keys(contentRefs.current).forEach((key) => {
      if (contentRefs.current[key].current) {
        updatedHeights[key] = contentRefs.current[key].current.scrollHeight;
      }
    });
    setContentHeights(updatedHeights);
  }, [accordionState]);

  const handleReportTypeSelection = (reportType, emailAddress) => {
    // Handle the selected report type (detailed or summary) here.
    if (reportType === "summary") {
      // Call generatePDF for a summary report
      closeAllAccordions();
      setTimeout(() => {
        generatePDF("EmployeePerformance_Summary_Report", emailAddress);
      }, 2000); // 2 seconds delay
    } else if (reportType === "detailed") {
      // Open all accordions, and then call generatePDF after 2 seconds
      openAllAccordions();
      setTimeout(() => {
        generatePDF("EmployeePerformance_Detailed_Report", emailAddress);
        closeAllAccordions();
      }, 2000); // 2 seconds delay
    }
  };

  function generatePDF(filename, emailAddress) {
    let input = document.getElementById("pdf-content");
    const hidden_content = document.getElementById("hidden-content");
    const report_created_time = document.getElementById("report-created-time");
    const pdf = new jsPDF("portrait", "mm", "a4");

    html2canvas(input, {
      scale: 6,
      useCORS: true,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("hidden-content").classList.remove("d-none");
        clonedDoc
          .getElementById("report-created-time")
          .classList.remove("d-none");
      },
    }).then((canvas) => {
      hidden_content.classList.add("d-none");
      report_created_time.classList.add("d-none");
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width;
      let heightLeft = height;
      let pageHeight = pdf.internal.pageSize.getHeight();
      let position = 10;
      pdf.addImage(imgData, "JPEG", 0, position, width, height);

      heightLeft -= pageHeight - position;
      while (heightLeft >= 0) {
        position = heightLeft - height + 10;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, width, height);
        heightLeft -= pageHeight;
      }
      // Save the PDF or send it to the API based on the buttonClicked state
      if (buttonClicked === "printPDF") {
        pdf.save(`${filename}.pdf`);
      } else if (buttonClicked === "emailReport" && emailAddress) {
        sendPDFToAPI(pdf.output("blob"), emailAddress, filename);
      }
    });
  }

  function sendPDFToAPI(pdfBlob, emailAddressToSend, filename) {
    // Create a FormData object to send the PDF as a file attachment
    const mailHeading = "Scancam Report PDF";
    const mailBody = "Please find attached the report you requested.";
    const formData = new FormData();
    formData.append("attachment", pdfBlob, `${filename}.pdf`);

    // Set up the API endpoint and headers
    const endpoint = `${APP_SERVICES_URL}/senapi/${CustID}/mailWithAttachment?subject=${mailHeading}&body=${mailBody}&mailTo=${emailAddressToSend}`;
    fetch(endpoint, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: authHeader,
      },
    })
      .then((response) => {
        if (response.ok) {
          setApiResponse(true);
          setEmailReport(true);
        } else {
          setApiResponse(false);
          setEmailReport(true);
        }
      })
      .catch((error) => {
        setApiResponse(false);
        setEmailReport(true);
      });
  }

  const toggleAccordion = (accordionName) => {
    // Format the accordion name to remove spaces
    const formattedAccordionName = accordionName.replace(/ /g, "_");

    setAccordionState({
      ...accordionState,
      [formattedAccordionName]: !accordionState[formattedAccordionName],
    });
  };

  const simulateFormSubmit = () => {
    // Replace this with your actual form submission logic
    // For example, if handleSubmit is an async function, you can await it here
    handleSubmit();
  };

  useEffect(() => {
    simulateFormSubmit();
    // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    selectedSite,
    selectedBrands,
    selectedStates,
    selectedProducts,
  ]);

  useEffect(() => {
    closeAllAccordions();
    // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    selectedSite,
    selectedBrands,
    selectedStates,
    selectedProducts,
  ]);

  const formattedDateTime = new Date().toLocaleString("en-US", {
    dateStyle: "medium", // or 'short', 'long', 'full' as needed
    timeStyle: "short", // or 'medium', 'long', 'full' as needed
  });

  return (
    <div className="Reports-Page">
      {emailReport && (
        <EmailModal
          show={emailReport}
          onClose={closeEmailModal}
          onSelectReportType={handleReportTypeSelection}
          apiResponse={apiResponse}
        />
      )}
      {isReportTypeModalOpen && (
        <ReportTypeModal
          show={isReportTypeModalOpen}
          onClose={closeReportTypeModal}
          onSelectReportType={handleReportTypeSelection}
        />
      )}
      {error && (
        <ErrorModal
          show={true}
          onClose={handleCloseErrorModal}
          message={errorMessage}
          heading={ErrorReportHeading}
        />
      )}
      <div className="main-box">
        <div className="min-w-[1170px] max-w-[1170px] mx-auto">
          <TitileReport label={"Employee Performance"} />
          <div className="min-w-[1170px] max-w-[1170px] lg:flex md:flex mb-4">
            <div className="flex-grow-[9] flex-shrink-0 basis-[85%]">
              <div className="text-[18px] text-[#B4B4B4] font-bold pt-1 pb-2">
                Filters
              </div>
              <div className="grid grid-cols-6 grid-rows-1 text-[#6f7172]">
                <div className="text-sm  h-[64px] pe-[15px]">
                  <div className="h-[20px]">Start date</div>
                  <div className="h-[44px]">
                    <input
                      className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                      type="date"
                      id="startDate"
                      value={startDate}
                      onChange={handleStartDateChange}
                      required
                    />
                  </div>
                </div>
                <div className="text-sm h-[64px] pe-[15px]">
                  <div className="h-[20px]">End date</div>
                  <div className="h-[44px]">
                    <input
                      className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                      type="date"
                      id="endDate"
                      value={endDate}
                      onChange={handleEndDateChange}
                      required
                    />
                  </div>
                </div>
                {(userRole === "sensenadmin" ||
                  userRole === "frs-reviewer") && (
                  <>
                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">States</div>
                      <div className="h-[44px]">
                        <MaterialUIMultiSelectCheckmarks
                          options={statesList}
                          setSelectedStateFunction={setSelectedStates}
                          initialStateAllSelected={true}
                        />
                      </div>
                    </div>
                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">Brand</div>
                      <div className="h-[44px]">
                        <MaterialUIMultiSelectCheckmarks
                          options={customersList}
                          setSelectedStateFunction={setSelectedBrands}
                          initialStateAllSelected={true}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="text-sm h-[64px] pe-[15px]">
                  <div className="h-[20px]">Sites</div>
                  <div className="h-[44px]">
                    <MaterialUIMultiSelectCheckmarks
                      options={siteNames}
                      setSelectedStateFunction={setSelectedSite}
                      initialStateAllSelected={false}
                    />
                  </div>
                </div>
                {(userRole === "sensenadmin" ||
                  userRole === "frs-reviewer") && (
                  <div className="text-sm h-[64px] pe-[15px]">
                    <div className="h-[20px]">Product</div>
                    <div className="h-[44px]">
                      <MaterialUIMultiSelectCheckmarks
                        options={productsList}
                        setSelectedStateFunction={setSelectedProducts}
                        initialStateAllSelected={true}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex-grow-[1] flex-shrink-0 basis-[15%]">
              <div className="text-[18px] text-[#B4B4B4] font-bold pt-1 pb-2">
                Actions
              </div>
              <div className="grid grid-rows-3 grid-cols-1">
                <div
                  className={`${
                    hasEmployeesInfo ? "action-btns" : "disable-btns"
                  }`}
                  onClick={hasEmployeesInfo ? openReportTypeModal : null}
                  style={{
                    color:
                      hasEmployeesInfo && textAccentColor
                        ? textAccentColor
                        : "inherit",
                  }}
                >
                  <span className="flex fw-bolder">
                    <PrintIcon style={{ fontSize: "18px" }} />
                    <span className="ps-1">Print-friendly PDF</span>
                  </span>
                </div>
                {hasEmployeesInfo ? (
                  <div
                    className="action-btns "
                    style={{ color: textAccentColor || "inherit" }}
                  >
                    <CSVDownloadButton
                      data={newdata}
                      reportName={"scancam-employee-performance-report"}
                    />
                  </div>
                ) : (
                  <div className="d-flex  disable-btns">
                    <LaunchIcon style={{ fontSize: "18px" }} />
                    <span className="ps-1">Export as CSV</span>
                  </div>
                )}
                <div
                  className={`${
                    hasEmployeesInfo ? "action-btns" : "disable-btns"
                  } `}
                  style={{
                    color:
                      hasEmployeesInfo && textAccentColor
                        ? textAccentColor
                        : "inherit",
                  }}
                  onClick={hasEmployeesInfo ? openEmailModal : null}
                >
                  <div className="d-flex ps-0">
                    <EmailIcon style={{ fontSize: "18px" }} />
                    <span className="ps-1">Email report</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="pdf-content">
        <div
          id="report-created-time"
          className="text-end mt-2 report-created-time d-none"
        >
          Report created {formattedDateTime} by
          <span className="ps-1 pe-2">{`${userSession.username}`}</span>
        </div>

        {showResult === true && data && hasEmployeesInfo && (
          <div style={{ backgroundColor: "#F4F4F4" }}>
            <div className="accordion accordion-flush container-max-width-reports-page">
              <div id="hidden-content" className="d-none">
                <h1 className="report-heading m-1 p-1">
                  Employee Performance Reports
                </h1>
                <h4 className="m-1 p-1">Detail</h4>
                <div className="mt-3">
                  <div className="row ms-1">
                    <div className="col-6"> Date range :</div>
                    <div className="col-6"> Sites :</div>
                  </div>
                  <div className="row ms-1 report-range">
                    <div className="col-6">
                      {`${startDate}`} - {`${endDate}`}
                    </div>
                    <div className="col-6"> {`${selectedSite.toString()}`}</div>
                  </div>
                </div>
              </div>

              <div
                className="min-w-[1170px] max-w-[1170px] mx-auto"
                style={{ marginBottom: "8px" }}
              >
                <div id="hidden-content" className="d-none">
                  <h1 className="report-heading m-1 p-1">
                    Debt Recovered Reports
                  </h1>
                  <h4 className="m-1 p-1">Detail</h4>
                  <div className="mt-3">
                    <div className="row ms-1">
                      <div className="col-6"> Date range :</div>
                      <div className="col-6"> Sites :</div>
                    </div>
                    <div className="row ms-1 report-range">
                      <div className="col-6">
                        {`${startDate}`} - {`${endDate}`}
                      </div>
                      <div className="col-6">
                        {`${selectedSite.toString()}`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-[1170px] mx-auto ">
                  <div className="grid grid-cols-6 items-center py-0">
                    <div className="col-span-4 flex items-center text-[#6f7172] text-[18px] font-extrabold">
                      {data && data.recoveredIncidents
                        ? (data.numValidDriveOffs || 0) +
                          (data.numValidIOUs || 0) +
                          (data.numCancelled || 0) +
                          (data.numIncomplete || 0)
                        : 0}
                      <span className="mx-1">incidents from</span>
                      {Object.keys(employeesInfo).length}
                      <span className="ml-1">
                        employee
                        {Object.keys(employeesInfo).length > 1 ? "s" : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* chart data */}
            <div className="w-[1170px] mx-auto">
              <StackedBarChart
                chartData={chartData}
                className="report-bar-chart"
              />
            </div>
            <div
              className="max-w-[1170px] mx-auto"
              style={{ marginTop: "10px" }}
            >
              <div className="grid grid-cols-6 items-center pb-3">
                <div className="col-span-1 flex items-center text-[#B4B4B4] font-extrabold text-sm">
                  Totals
                </div>
                <div className="col-span-1 text-end pe-0">
                  <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                    <span>
                      {(data.numValidDriveOffs || 0) +
                        (data.numValidIOUs || 0) +
                        (data.numCancelled || 0) +
                        (data.numIncomplete || 0)}
                    </span>

                    <span className="col-span-1 text-[#b4b4b4]">Reported</span>
                  </div>
                </div>
                <div className="col-span-1 text-end pe-0">
                  <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                    <span>
                      {data &&
                      data.numValidDriveOffs !== undefined &&
                      data.numValidDriveOffs !== 0
                        ? data.numValidDriveOffs
                        : "0"}
                    </span>
                    <span className="text-[#b4b4b4]">
                      <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#e27a13]"></span>
                      Drive Off
                    </span>
                  </div>
                </div>
                <div className="col-span-1 text-end pe-0">
                  <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                    <span>
                      {data &&
                      data.numValidIOUs !== undefined &&
                      data.numValidIOUs !== 0
                        ? data.numValidIOUs
                        : "0"}
                    </span>
                    <span className="text-[#b4b4b4]">
                      <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#f7a123]"></span>
                      IOUs
                    </span>
                  </div>
                </div>
                <div className="col-span-1 text-end pe-0">
                  <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                    <span>
                      {data &&
                      data.numIncomplete !== undefined &&
                      data.numIncomplete !== 0
                        ? data.numIncomplete
                        : "0"}
                    </span>
                    <span className="text-[#b4b4b4]">
                      <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#ffcb07]"></span>
                      Incomplete
                    </span>
                  </div>
                </div>
                <div className="col-span-1 text-end pe-0">
                  <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                    <span>
                      {data &&
                      data.numCancelled !== undefined &&
                      data.numCancelled !== 0
                        ? data.numCancelled
                        : "0"}
                    </span>
                    <span className="text-[#b4b4b4]">
                      <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#e4e4e4]"></span>
                      Cancelled
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="">
          <div className="w-[1170px] mx-auto ">
            {showResult === false ? (
              <div
                className="col-sm-12 text-center mt-4"
                style={{
                  fontSize: "20px",
                  color: "#6f7172",
                  paddingTop: "50px",
                }}
              >
                <p>At least one Site must be selected.</p>
              </div>
            ) : (
              <div className="">
                {data && hasEmployeesInfo ? (
                  <>
                    {hasEmployeesInfo && (
                      <div
                        className="accordion container-max-width-reports-page"
                        id="accordionPanelsStayOpenExample"
                      >
                        {Object.entries(employeesInfo).map(([key, value]) => {
                          const formattedKey = key.replace(/ /g, "_");
                          return (
                            <div
                              key={formattedKey}
                              className="mx-auto pl-0 py-0"
                            >
                              <div className="bg-white border-t border-b border-gray-200">
                                <button
                                  type="button"
                                  className="w-full px-0 py-3 text-left"
                                  onClick={() =>
                                    toggleAccordionNew(formattedKey)
                                  }
                                >
                                  <div className="grid grid-cols-6 items-center">
                                    <div className="col-span-1 flex items-center">
                                      <svg
                                        className={`w-4 h-4 font-bold text-[#E27A13] transform transition-transform duration-300 ${
                                          accordionState[formattedKey]
                                            ? "rotate-90"
                                            : ""
                                        }`}
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path d="M9 5l7 7-7 7"></path>
                                      </svg>
                                      <span className="ml-2 text-[#E27A13] font-bold">
                                        {employeesInfo[key].numOutstanding}
                                        {key}
                                      </span>
                                    </div>
                                    {!accordionState[formattedKey] && (
                                      <>
                                        <div className="col-span-1 text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {(employeesInfo[key]
                                                .numValidDriveOffs || 0) +
                                                (employeesInfo[key]
                                                  .numValidIOUs || 0) +
                                                (employeesInfo[key]
                                                  .numCancelled || 0) +
                                                (employeesInfo[key]
                                                  .numIncomplete || 0)}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Reported
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-span-1 text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {employeesInfo[key]
                                                .numValidDriveOffs || 0}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Drive Off
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-span-1 text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {employeesInfo[key]
                                                .numValidIOUs || 0}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              IOUs
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-span-1 text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {employeesInfo[key]
                                                .numIncomplete || 0}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Incomplete
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-span-1 text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {employeesInfo[key]
                                                .numCancelled || 0}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Cancelled
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </button>
                                <div
                                  ref={contentRefs.current[formattedKey]}
                                  className="overflow-hidden transition-all duration-300 ease-in-out"
                                  style={{
                                    maxHeight: accordionState[formattedKey]
                                      ? `${contentHeights[formattedKey]}px`
                                      : "0",
                                    opacity: accordionState[formattedKey]
                                      ? 1
                                      : 0,
                                  }}
                                >
                                  <CustomizedTable
                                    reportName="debtRecovered"
                                    heading={[
                                      "OCCURRED AT",
                                      "LICENSE PLATE",
                                      "TYPE",
                                      "STATUS",
                                      "SITE",
                                      "PUMP",
                                      "REFERENCE",
                                      "VALUE",
                                    ]}
                                    data={newdata[key]}
                                    selectedLabel={key}
                                  />
                                  <div className="grid grid-cols-6 items-center py-3">
                                    <div className="col-span-1 flex items-center"></div>
                                    <div className="col-span-1 text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {(employeesInfo[key]
                                            .numValidDriveOffs || 0) +
                                            (employeesInfo[key].numValidIOUs ||
                                              0) +
                                            (employeesInfo[key].numCancelled ||
                                              0) +
                                            (employeesInfo[key].numIncomplete ||
                                              0)}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Reported
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-span-1 text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {employeesInfo[key]
                                            .numValidDriveOffs || 0}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Drive Off
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-span-1 text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {employeesInfo[key].numValidIOUs || 0}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          IOUs
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-span-1 text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {employeesInfo[key].numIncomplete ||
                                            0}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Incomplete
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-span-1 text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {employeesInfo[key].numCancelled || 0}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Cancelled
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {hasEmployeesInfo && (
                      <div className="accordion accordion-flush">
                        <div className="accordion-item pe-0">
                          <h2
                            className="accordion-header pe-0"
                            id="flush-headingStats"
                          >
                            <div
                              className="accordion-button collapsed row d-flex pe-0"
                              type="button"
                              data-bs-toggle="collapse"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              <div
                                className="col-2  text-sm font-extrabold"
                                style={{ color: "#b4b4b4", padding: "0px" }}
                              >
                                Totals
                              </div>
                              <div className="col-2 text-end">
                                <div>
                                  <div
                                    className="totals-value"
                                    style={{ color: textAccentColor }}
                                  >
                                    {(data.numValidDriveOffs || 0) +
                                      (data.numValidIOUs || 0) +
                                      (data.numCancelled || 0) +
                                      (data.numIncomplete || 0)}
                                  </div>
                                  <div
                                    className="totals-label"
                                    style={{ color: "#b4b4b4" }}
                                  >
                                    Reported
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 text-end ">
                                <div>
                                  <div
                                    className="totals-value"
                                    style={{ color: textAccentColor }}
                                  >
                                    {data.numValidDriveOffs || 0}
                                  </div>
                                  <div
                                    className="totals-label"
                                    style={{ color: "#b4b4b4" }}
                                  >
                                    Drive Off
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 text-end ">
                                <div>
                                  <div
                                    className="totals-value"
                                    style={{ color: textAccentColor }}
                                  >
                                    {data.numValidIOUs || 0}
                                  </div>
                                  <div
                                    className="totals-label"
                                    style={{ color: "#b4b4b4" }}
                                  >
                                    IOUs
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 text-end pe-1">
                                <div>
                                  <div
                                    className="totals-value"
                                    style={{ color: textAccentColor }}
                                  >
                                    {data.numIncomplete || 0}
                                  </div>
                                  <div
                                    className="totals-label"
                                    style={{ color: "#b4b4b4" }}
                                  >
                                    Incomplete
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 text-end pe-1">
                                <div>
                                  <div
                                    className="totals-value"
                                    style={{ color: textAccentColor }}
                                  >
                                    {data.numCancelled || 0}
                                  </div>
                                  <div
                                    className="totals-label"
                                    style={{ color: "#b4b4b4" }}
                                  >
                                    Cancelled
                                  </div>
                                </div>
                              </div>
                            </div>
                          </h2>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="row">
                    <div
                      className="col-sm-12 text-center mt-4"
                      style={{
                        fontSize: "20px",
                        color: "#6f7172",
                        paddingTop: "50px",
                      }}
                    >
                      <p>
                        There are no incidents which match your search criteria.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmployeePerformance;
