import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './EmailModal.css'; // Import the CSS file
import userSession from '../Constants/auth';
const ReportTypeModal = ({ show, onClose, onSelectReportType }) => {
  const [reportType, setReportType] = useState('detailed'); // 'detailed' is the default
  const ctaBackgroudColor = userSession.themeData?.ctaBackgroudColor || '#e27a13';
  const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
  const handleReportTypeChange = (event) => {
    setReportType(event.target.value);
  };

  const handleGenerateReport = () => {
    onSelectReportType(reportType);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose} >
      <div className='m-2' closeButton>
        <div style={{ fontSize: "18px", fontWeight: '700', color: textAccentColor }}>Print-friendly PDF</div>
      </div>

      <Modal.Body className="email-modal-body" style={{ cursor: 'pointer' }}>

        <div>
          <div className="report-type-container">What type of report would you like to create?</div>
          <div className="text-center" id='printPDF-body' style={{ cursor: 'pointer' }}>
            <Form >
              <div className="email-label" style={{cursor:'pointer'}}>
                <input
                  type="radio"
                  id="radio-detailed"
                  name="reportType"
                  value="detailed"
                  checked={reportType === 'detailed'}
                  style={{cursor:'pointer'}}
                  onChange={handleReportTypeChange}
                />
                <label htmlFor="radio-detailed" style={{cursor:'pointer'}}>
                  Detail (show all incidents)
                </label>
              </div>

              <div className=" email-label" style={{cursor:'pointer'}}>
                <input
                  type="radio"
                  id="radio-summary"
                  name="reportType"
                  value="summary"
                  checked={reportType === 'summary'}
                  onChange={handleReportTypeChange}
                  style={{cursor:'pointer'}}
                />
                <label htmlFor="radio-summary" style={{cursor:'pointer'}}>
                  Summary (show sub-totals only)
                </label>
              </div>
            </Form>
          </div>
        </div>

      </Modal.Body>

      <div style={{ cursor: 'pointer' }}>
        <div className="col-12 d-flex justify-content-center align-items-center" style={{ fontSize: '14px', fontWeight: '500', textAlign: 'center' }}>
          <div className='col-6' onClick={onClose} style={{ backgroundColor: '#ffffff', padding: '12px' }}>
            Cancel
          </div>
          <div className='col-6' onClick={handleGenerateReport} style={{ backgroundColor: ctaBackgroudColor, padding: '12px', color: '#ffffff' }}>
            Create
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportTypeModal;