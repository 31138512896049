import { Modal } from 'react-bootstrap';
import userSession from '../Constants/auth';
import { APP_SERVICES_URL } from "../Constants/constants";
import { useState } from 'react';

const DeleteVideoModal = ({ show, onClose, pkAlert, eventID, setIncidentDetails, setActiveLinkBox2, checkMediaUrs, videoFrameId, setVideos }) => {
    const { CustID, authHeader } = userSession;
    const [buttonActiveState, SetButtonActiveState] = useState(true);
    const fetchDataAndUpdateIncidentDetails = async () => {
        try {
            // Fetch data from API
            const apiUrl = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident?alertID=${pkAlert}`;
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Authorization": authHeader,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            setIncidentDetails(responseData);
            checkMediaUrs(responseData);
            setActiveLinkBox2('details');
            closeModal();
        } catch (error) {
            console.error("Error fetching data from the API:", error);
        }
    };

    const handleDelete = async () => {
        // Set deleteVideoLoading to true for the matching frameID
        setVideos(prevVideos =>
            prevVideos.map(video =>
                video.frameID === videoFrameId ? { ...video, deleteVideoLoading: true } : video
            )
        );
        try {
            SetButtonActiveState(false);
            const response = await fetch(`/sn-events-service/${CustID}/file?eventID=${eventID}&frameID=${videoFrameId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: authHeader // Assuming you have the accessToken available
                }
            });
            if (!response.ok) {
                // If the delete operation fails, set deleteVideoLoading to false
                setVideos(prevVideos =>
                    prevVideos.map(video =>
                        video.frameID === videoFrameId ? { ...video, deleteVideoLoading: false } : video
                    )
                );
                SetButtonActiveState(true);
                onClose();
                throw new Error('Failed to delete file');
            }
            // If the delete operation is successful, set deleteVideoLoading to false and update the url to null
            setVideos(prevVideos => prevVideos.filter(video => video.frameID !== videoFrameId)); // Remove the video with matching frameID
            fetchDataAndUpdateIncidentDetails();
            SetButtonActiveState(true);
            onClose();
            // Handle success (e.g., display a message or update state)
            console.log('File deleted successfully');
        } catch (error) {
            // If the delete operation fails, set deleteVideoLoading to false
            setVideos(prevVideos =>
                prevVideos.map(video =>
                    video.frameID === videoFrameId ? { ...video, deleteVideoLoading: false } : video
                )
            );
            console.error('Error deleting file:', error.message);
            SetButtonActiveState(true);
            onClose();

            // Handle error (e.g., display an error message)
        }
    };
    const closeModal = () => {
        onClose();
    };
    return (
        <Modal show={show} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '18px', color: '#e27a13', fontWeight: 'bold' }}>Delete Video</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#f4f4f4", color: "#000000", fontSize: '14px' }}>
                <div className='mb-3'>Are you sure you want to delete the video?</div>
            </Modal.Body >
            <div>
                <div className='col-sm-12 d-flex' style={{ fontWeight: 'bold', fontSize: '14px', border: 'none' }}>
                    <button className="col-sm-6 text-center" style={{ padding: '20px', border: 'none',cursor:'pointer' }} onClick={closeModal}>Cancel</button>
                    <button className="col-sm-6 text-center" style={{ backgroundColor: buttonActiveState ? '#e27a13' : "grey", padding: '20px', color: 'white', border: 'none',cursor:'pointer' }} onClick={buttonActiveState ? handleDelete : null}>Confirm</button>
                </div>
            </div>
        </Modal >
    );
};
export default DeleteVideoModal;