import React, { useEffect, useRef, useState } from "react";
import "../../CSS/ReportsPage.css";
import UseReportsApi from "../../Custom Hooks/useReportsApi";
import styles from "../../CSS/Base/Basic.module.css";
import userSession from "../../Constants/auth";
import { APP_SERVICES_URL } from "../../Constants/constants";
import StackedBarChart from "../../Charts/StackedBarChart";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import MaterialUIMultiSelectCheckmarks from "../../Components/ViewReportPage/MaterialUIMultiSelectCheckmarks";
import CustomizedTable from "../../Components/ViewReportPage/CustomizedTable";
import CSVDownloadButton from "../../Components/ViewReportPage/CSVDownloadButton";
import ErrorModal from "../../Modals/ErrorModal";
import ReportTypeModal from "../../Modals/ReportTypeModal";
import EmailModal from "../../Modals/EmailModal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PrintIcon from "@mui/icons-material/Print";
import LaunchIcon from "@mui/icons-material/Launch";
import EmailIcon from "@mui/icons-material/Email";
import { useApiDataContext } from "../../ApiDataContext";
import moment from "moment";
import RotatingIcon from "../../Components/Base/RotatingIcon";
import TitileReport from "../../Components/common/TitileReport";

const Reimbursement = () => {
  const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-01");
  const endTime = moment().format("YYYY-MM-DD");
  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const { authHeader, sites, CustID, userRole } = userSession;
  const getSitesNames = (sites) => {
    if (!sites || !Array.isArray(sites)) {
      return [];
    }
    return sites.map((site) => site.name);
  };
  const siteNames = getSitesNames(sites);
  const { customersList, productsList, statesList } = useApiDataContext();
  const [showResult, setShowResult] = useState(false);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(endTime);
  const [selectedSite, setSelectedSite] = useState([]);
  const [selectedStates, setSelectedStates] = useState(statesList);
  const [selectedBrands, setSelectedBrands] = useState(customersList);
  const [selectedProducts, setSelectedProducts] = useState(productsList);
  const [newdata, setNewData] = useState({});
  const [apiResponse, setApiResponse] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [emailReport, setEmailReport] = useState(false);
  const [isReportTypeModalOpen, setIsReportTypeModalOpen] = useState(false);

  const openReportTypeModal = () => {
    setButtonClicked("printPDF");
    setIsReportTypeModalOpen(true);
  };

  const closeReportTypeModal = () => {
    setIsReportTypeModalOpen(false);
  };

  const openEmailModal = () => {
    setButtonClicked("emailReport");
    setEmailReport(true);
  };

  const closeEmailModal = () => {
    setEmailReport(false);
    setApiResponse(null);
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  // Generic function to handle selection for different dropdowns
  const handleSelection = (selectedValues, allValues, setSelectedFunction) => {
    if (selectedValues.includes("Select All")) {
      if (selectedValues.length === allValues.length) {
        // If all items are already selected, unselect all
        setSelectedFunction([]);
      } else {
        // Select all items
        setSelectedFunction(allValues);
      }
    } else {
      setSelectedFunction(selectedValues);
    }
  };

  const formatDate = (dateString, isStart) => {
    const utcTime = moment.utc(dateString); // Assuming the UTC time is '2024-04-30T05:10:00'
    let localTime = utcTime.local(); // Convert to local time zone

    if (isStart) {
      // Set the time to 00:00:00 for the start date
      localTime.startOf("day");
    } else {
      localTime = localTime.endOf("day");
    }

    const localTimeWithoutOffset = localTime.format("YYYY-MM-DDTHH:mm:ss.SSS"); // Omitting the timezone offset

    return localTimeWithoutOffset;
  };
  // Define state variables for data, loading, and error
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fetchData = UseReportsApi();
  const fetchReportData = async (payloadData) => {
    const { CustID, authHeader } = userSession;

    try {
      // Serialize payloadData into a query string
      setError(false);
      const queryParams = new URLSearchParams({
        limit: 10000,
        offset: 0,
      });
      const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incidentsList?${queryParams.toString()}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader, // Convert to base64 (Basic Auth)
        },
        body: JSON.stringify(payloadData), // Convert payloadData to JSON string
      });

      if (!response.ok) {
        setError(true);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      setError(true);
      setErrorMessage(
        "Failed to fetch report data. Please try again. If the issue persists, contact support."
      );
      return [];
    }
  };

  const updateDataStateWithSpecificValues = (result) => {
    // Create a dictionary to store the specific values.
    const specificValues = {};

    // Check for the specific values in the result variable.
    for (const key in result) {
      if (result.hasOwnProperty(key)) {
        specificValues[key] = result[key];
      }
    }

    // Update the data state with the specific values.
    setNewData({
      ...specificValues,
    });
  };

  const handleSubmit = async (e) => {
    const formattedStartDate = formatDate(startDate, true);
    const formattedEndDate = formatDate(endDate, false);

    const payloadData = {
      sites: selectedSite,
      states: selectedStates,
      brands: selectedBrands,
      products: selectedProducts,
      to: formattedEndDate,
      from: formattedStartDate,
    };
    if (payloadData.sites.length < 1) {
      return;
    }
    try {
      setIsLoading(true);
      setError(false);
      const result = await fetchData(
        authHeader,
        CustID,
        "reimbursementReport",
        payloadData
      );
      setData(result);
      updateDataStateWithSpecificValues(result.sitesInfo);
      setShowResult(true);
    } catch (error) {
      setError(true);
      setErrorMessage(
        "Failed to fetch report data. Please try again. If the issue persists, contact support."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const ReimbursementPayloadGenerator = (
    label,
    from,
    to,
    sites,
    brands,
    products,
    states
  ) => {
    return {
      recoveredStatuses: true,
      isReimbursed: false,
      sites,
      from,
      to,
      brands,
      products,
      states,
    };
  };

  // Define a function for fetching and updating report data
  const fetchAndUpdateReportData = async () => {
    const formattedStartDate = formatDate(startDate, true);
    const formattedEndDate = formatDate(endDate, false);
    const allKeys = Object.keys(newdata);

    // Iterate over the keys in the newData state and call the fetchReportData API for each key.
    for (const key of allKeys) {
      // Generate the payload for the API call.
      const payload = ReimbursementPayloadGenerator(
        key, // Use the current key as-is
        formattedStartDate,
        formattedEndDate,
        selectedSite,
        selectedBrands,
        selectedProducts,
        selectedStates
      );

      try {
        payload.isFuelLitresRequired = true;
        payload.isSiteAddressRequired = true;
        payload.isProcessingFeeRequired = true;
        payload.isEmployeeNameRequired = true;
        payload.isSiteTypeRequired = true;
        payload.isFuelTypeRequired = true;
        payload.isStoreIDRequired = true;
        setError(false);
        const result = await fetchReportData(payload, key); // Use the current key as the second argument
        // Update the newData state with the result.
        newdata[key] = result;
      } catch (error) {
        console.error(error);
        setError(true);
        setErrorMessage(
          "Failed to fetch report data. Please try again. If the issue persists, contact support."
        );
      }
    }

    // Set the new newData state.
    setNewData(newdata);
  };

  useEffect(() => {
    fetchAndUpdateReportData();
    // eslint-disable-next-line
  }, [newdata]);

  const onSiteRecoveredAmount =
    (data &&
      data.recoveredIncidents &&
      data.recoveredIncidents.paymentModesInfo["On Site"] &&
      data.recoveredIncidents.paymentModesInfo["On Site"].recoveredAmount) ||
    0;
  const onSiteLatePaymentRecoveredAmount =
    (data &&
      data.recoveredIncidents &&
      data.recoveredIncidents.paymentModesInfo["On Site Late Payment"] &&
      data.recoveredIncidents.paymentModesInfo["On Site Late Payment"]
        .recoveredAmount) ||
    0;
  const onlineRecoveredAmount =
    (data &&
      data.recoveredIncidents &&
      data.recoveredIncidents.paymentModesInfo["Online"] &&
      data.recoveredIncidents.paymentModesInfo["Online"].recoveredAmount) ||
    0;
  const onlineFRSRecoveredAmount =
    (data &&
      data.recoveredIncidents &&
      data.recoveredIncidents.paymentModesInfo["Online FRS"] &&
      data.recoveredIncidents.paymentModesInfo["Online FRS"].recoveredAmount) ||
    0;

  const chartData = [
    {
      data: [onSiteRecoveredAmount],
      color: "#e27a13",
    },
    {
      data: [onSiteLatePaymentRecoveredAmount],
      color: "#f7a123",
    },
    {
      data: [onlineRecoveredAmount],
      color: "#ffcb07",
    },
    {
      data: [onlineFRSRecoveredAmount],
      color: "#e4e4e4",
    },
  ];

  // Watch for changes in the selectedSite array
  useEffect(() => {
    if (selectedSite.length === 0) {
      // Clear your state here, replace with your actual state clearing logic
      setData(null);
      setShowResult(false);
    }
  }, [selectedSite]); // This effect will run whenever selectedSite changes

  const paymentModesInfo = data?.sitesInfo || {};
  // Check if paymentModesInfo has at least one key
  const hasPaymentModes = Object.keys(paymentModesInfo).length > 0;

  const handleCloseErrorModal = () => {
    setError(false);
  };
  // Initialize accordion state
  const [accordionState, setAccordionState] = useState(() => {
    const initialState = {};
    Object.keys(paymentModesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      initialState[formattedKey] = false;
    });
    return initialState;
  });

  // Initialize contentRefs and contentHeights
  const contentRefs = useRef({});
  const [contentHeights, setContentHeights] = useState(() => {
    const heights = {};
    Object.keys(paymentModesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      heights[formattedKey] = 0;
    });
    return heights;
  });

  // Function to open all accordions
  const openAllAccordions = () => {
    const updatedAccordionState = {};
    Object.keys(paymentModesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      updatedAccordionState[formattedKey] = true;
    });
    setAccordionState(updatedAccordionState);
  };

  // Function to close all accordions
  const closeAllAccordions = () => {
    const updatedAccordionState = {};
    Object.keys(paymentModesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      updatedAccordionState[formattedKey] = false;
    });
    setAccordionState(updatedAccordionState);
  };

  // Toggle individual accordion
  const toggleAccordionNew = (accordionName) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [accordionName]: !prevState[accordionName],
    }));
  };

  // Effect to initialize contentRefs
  useEffect(() => {
    const refs = {};
    Object.keys(paymentModesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      refs[formattedKey] = React.createRef();
    });
    contentRefs.current = refs;
  }, [paymentModesInfo]);

  // Effect to update content heights
  useEffect(() => {
    const updatedHeights = {};
    Object.keys(contentRefs.current).forEach((key) => {
      if (contentRefs.current[key].current) {
        updatedHeights[key] = contentRefs.current[key].current.scrollHeight;
      }
    });
    setContentHeights(updatedHeights);
  }, [accordionState]);


  const handleReportTypeSelection = (reportType, emailAddress) => {
    // Handle the selected report type (detailed or summary) here.
    if (reportType === "summary") {
      // Call generatePDF for a summary report
      closeAllAccordions();
      setTimeout(() => {
        generatePDF("Reimbursement_Summary_Report", emailAddress);
      }, 2000); // 2 seconds delay
    } else if (reportType === "detailed") {
      // Open all accordions, and then call generatePDF after 2 seconds
      openAllAccordions();
      setTimeout(() => {
        generatePDF("Reimbursement_Detailed_Report", emailAddress);
      }, 2000); // 2 seconds delay
    }
  };

  function generatePDF(filename, emailAddress) {
    let input = document.getElementById("pdf-content");
    const hidden_content = document.getElementById("hidden-content");
    const report_created_time = document.getElementById("report-created-time");
    const pdf = new jsPDF("portrait", "mm", "a4");

    html2canvas(input, {
      scale: 6,
      useCORS: true,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("hidden-content").classList.remove("d-none");
        clonedDoc
          .getElementById("report-created-time")
          .classList.remove("d-none");
      },
    }).then((canvas) => {
      hidden_content.classList.add("d-none");
      report_created_time.classList.add("d-none");
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width;
      let heightLeft = height;
      let pageHeight = pdf.internal.pageSize.getHeight();
      let position = 10;
      pdf.addImage(imgData, "JPEG", 0, position, width, height);

      heightLeft -= pageHeight - position;
      while (heightLeft >= 0) {
        position = heightLeft - height + 10;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, width, height);
        heightLeft -= pageHeight;
      }
      // Save the PDF or send it to the API based on the buttonClicked state
      if (buttonClicked === "printPDF") {
        pdf.save(`${filename}.pdf`);
      } else if (buttonClicked === "emailReport" && emailAddress) {
        sendPDFToAPI(pdf.output("blob"), emailAddress, filename);
      }
    });
  }

  function sendPDFToAPI(pdfBlob, emailAddressToSend, filename) {
    // Create a FormData object to send the PDF as a file attachment
    const mailHeading = "Scancam Report PDF";
    const mailBody = "Please find attached the report you requested.";
    const formData = new FormData();
    formData.append("attachment", pdfBlob, `${filename}.pdf`);

    // Set up the API endpoint and headers
    const endpoint = `${APP_SERVICES_URL}/senapi/${CustID}/mailWithAttachment?subject=${mailHeading}&body=${mailBody}&mailTo=${emailAddressToSend}`;

    // Reset the state before making the API call
    setApiResponse(null);
    setEmailReport(false);

    fetch(endpoint, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: authHeader,
      },
    })
      .then((response) => {
        if (response.ok) {
          setApiResponse(true);
          setEmailReport(true);
        } else {
          setApiResponse(false);
          setEmailReport(true);
        }
      })
      .catch((error) => {
        setApiResponse(false);
        setEmailReport(true);
      });
  }

  const simulateFormSubmit = () => {
    // Replace this with your actual form submission logic
    // For example, if handleSubmit is an async function, you can await it here
    handleSubmit();
  };

  useEffect(() => {
    simulateFormSubmit();
    // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    selectedSite,
    selectedBrands,
    selectedStates,
    selectedProducts,
  ]);

  //call the function to close accordions when data changes
  useEffect(() => {
    closeAllAccordions();
    // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    selectedSite,
    selectedBrands,
    selectedStates,
    selectedProducts,
  ]);
  const ErrorReportHeading = "Report Data Fetch Error";

  const formattedDateTime = new Date().toLocaleString("en-US", {
    dateStyle: "medium", // or 'short', 'long', 'full' as needed
    timeStyle: "short", // or 'medium', 'long', 'full' as needed
  });
  return (
    <div className="w-dvw min-h-[65vh]">
      {emailReport && (
        <EmailModal
          show={emailReport}
          onClose={closeEmailModal}
          onSelectReportType={handleReportTypeSelection}
          apiResponse={apiResponse}
        />
      )}
      {isReportTypeModalOpen && (
        <ReportTypeModal
          show={isReportTypeModalOpen}
          onClose={closeReportTypeModal}
          onSelectReportType={handleReportTypeSelection}
        />
      )}
      {error && (
        <ErrorModal
          show={true}
          onClose={handleCloseErrorModal}
          message={errorMessage}
          heading={ErrorReportHeading}
        />
      )}
      <div className="row">
        <div className="row p-0 bg-[#F4F4F4]">
          <div className="col-sm-12 flex w-dvw">
            <div className="min-w-[1170px] max-w-[1170px] mx-auto">
              <TitileReport label={"Reimbursement"} />
              {/* reports options */}
              <div className="min-w-[1170px] max-w-[1170px] lg:flex md:flex mb-4">
                <div className="flex-grow-[9] flex-shrink-0 basis-[85%]">
                  <div className="text-[18px] text-[#B4B4B4] font-bold pt-1 pb-2">
                    Filters
                  </div>
                  <div className="grid grid-cols-6 grid-rows-1 text-[#6f7172]">
                    <div className="text-sm  h-[64px] pe-[15px]">
                      <div className="h-[20px]">Start date</div>
                      <div className="h-[44px]">
                        <input
                          className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                          type="date"
                          id="startDate"
                          value={startDate}
                          onChange={handleStartDateChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">End date</div>
                      <div className="h-[44px]">
                        <input
                          className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                          type="date"
                          id="endDate"
                          value={endDate}
                          onChange={handleEndDateChange}
                          required
                        />
                      </div>
                    </div>
                    {(userRole === "sensenadmin" ||
                      userRole === "frs-reviewer") && (
                      <>
                        {" "}
                        <div className="text-sm h-[64px] pe-[15px]">
                          <div className="h-[20px]">States</div>
                          <div className="h-[44px]">
                            <MaterialUIMultiSelectCheckmarks
                              options={statesList}
                              setSelectedStateFunction={setSelectedStates}
                              initialStateAllSelected={true}
                            />
                          </div>
                        </div>
                        <div className="text-sm h-[64px] pe-[15px]">
                          <div className="h-[20px]">Brand</div>
                          <div className="h-[44px]">
                            <MaterialUIMultiSelectCheckmarks
                              options={customersList}
                              setSelectedStateFunction={setSelectedBrands}
                              initialStateAllSelected={true}
                            />
                          </div>
                        </div>{" "}
                      </>
                    )}
                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">Sites</div>
                      <div className="h-[44px]">
                        <MaterialUIMultiSelectCheckmarks
                          options={siteNames}
                          setSelectedStateFunction={setSelectedSite}
                          initialStateAllSelected={false}
                        />
                      </div>
                    </div>
                    {(userRole === "sensenadmin" ||
                      userRole === "frs-reviewer") && (
                      <div className="text-sm h-[64px] pe-[15px]">
                        <div className="h-[20px]">Product</div>
                        <div className="h-[44px]">
                          <MaterialUIMultiSelectCheckmarks
                            options={productsList}
                            setSelectedStateFunction={setSelectedProducts}
                            initialStateAllSelected={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-grow-[1] flex-shrink-0 basis-[15%]">
                  <div className="text-[18px] text-[#B4B4B4] font-bold pt-1 pb-2">
                    Actions
                  </div>
                  <div className="grid grid-rows-3 grid-cols-1">
                    <div
                      className={`${
                        hasPaymentModes ? "action-btns" : "disable-btns"
                      }`}
                      onClick={hasPaymentModes ? openReportTypeModal : null} // Conditionally call the function
                      style={{
                        color:
                          hasPaymentModes && textAccentColor
                            ? textAccentColor
                            : "inherit",
                      }}
                    >
                      <span className="flex fw-bolder">
                        <PrintIcon style={{ fontSize: "18px" }} />
                        <span className="ps-1">Print-friendly PDF</span>
                      </span>
                    </div>
                    {hasPaymentModes ? (
                      <div
                        className="action-btns "
                        style={{ color: textAccentColor || "inherit" }}
                      >
                        <CSVDownloadButton
                          data={newdata}
                          reportName={"scancam-reimbursement-report"}                        />
                      </div>
                    ) : (
                      <div className="d-flex  disable-btns">
                        <LaunchIcon style={{ fontSize: "18px" }} />
                        <span className="ps-1">Export as CSV</span>
                      </div>
                    )}
                    <div
                      className={`${
                        hasPaymentModes ? "action-btns" : "disable-btns"
                      } `}
                      style={{
                        color:
                          hasPaymentModes && textAccentColor
                            ? textAccentColor
                            : "inherit",
                      }}
                      onClick={hasPaymentModes ? openEmailModal : null}
                    >
                      <div className="d-flex ps-0">
                        <EmailIcon style={{ fontSize: "18px" }} />
                        <span className="ps-1">Email report</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <RotatingIcon colorValue={"grey"} />
        </div>
      ) : (
        <div id="pdf-content">
          <div
            id="report-created-time"
            className="text-end mt-2 report-created-time d-none"
          >
            Report created {formattedDateTime} by
            <span className="ps-1 pe-2">{`${userSession.username}`}</span>
          </div>
          {showResult === true && data && hasPaymentModes && (
            <div style={{ backgroundColor: "#F4F4F4" }}>
              <div className="accordion accordion-flush container-max-width-reports-page">
                <div id="hidden-content" className="d-none">
                  <h1 className="report-heading m-1 p-1">
                    Reimbursement Report
                  </h1>
                  <h4 className="m-1 p-1">Detail</h4>
                  <div className="mt-3">
                    <div className="row ms-1">
                      <div className="col-6"> Date range :</div>
                      <div className="col-6"> Sites :</div>
                    </div>
                    <div className="row ms-1 report-range">
                      <div className="col-6">
                        {`${startDate}`} - {`${endDate}`}
                      </div>
                      <div className="col-6">
                        {`${selectedSite.toString()}`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    style={{ backgroundColor: "#F4F4F4" }}
                    id="flush-headingStats"
                  >
                    <div
                      className="accordion-button ps-0 pe-0 collapsed row d-flex"
                      type="button"
                      style={{ backgroundColor: "#F4F4F4" }}
                      data-bs-toggle="collapse"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <div
                        className="col-8 ps-0 counts-heading text-start"
                        style={{ color: "#6f7172" }}
                      >
                        ${(data && data.recoveredIncidents) ? ((parseFloat(data.recoveredIncidents.recoveredDriveOffAmount) || 0) + (parseFloat(data.recoveredIncidents.recoveredIOUAmount) || 0)).toFixed(2) : '0.00'} recovered from {(data && data.recoveredIncidents
                          ? ((data.recoveredIncidents.numRecoveredDriveOffs || 0) + (data.recoveredIncidents.numRecoveredIOUs || 0))
                          : 0
                        )} incident{(data.recoveredIncidents.numRecoveredDriveOffs || 0) + (data.recoveredIncidents.numRecoveredIOUs || 0) > 1 ?'s':''}
                      </div>
                      <div className="col-2 text-end">
                        <div>
                          <div
                            className="totals-value text-end"
                            style={{ color: "#6f7172" }}
                          >
                            ${(data && data.recoveredIncidents && data.recoveredIncidents.recoveredDriveOffAmount
                              ? data.recoveredIncidents.recoveredDriveOffAmount.toFixed(2)
                              : '0.00'
                            )} from {(data && data.recoveredIncidents && data.recoveredIncidents.numRecoveredDriveOffs
                              ? data.recoveredIncidents.numRecoveredDriveOffs
                              : 0
                            )}
                          </div>
                          <div
                            className="totals-label text-end"
                            style={{ color: "#b4b4b4" }}
                          >
                            Drive Offs
                          </div>
                        </div>
                      </div>
                      <div className="col-2 text-end">
                        <div>
                          <div
                            className="totals-value text-end"
                            style={{ color: "#6f7172" }}
                          >
                            ${(data && data.recoveredIncidents && data.recoveredIncidents.recoveredIOUAmount
                              ? data.recoveredIncidents.recoveredIOUAmount.toFixed(2)
                              : '0.00'
                            )} from {(data && data.recoveredIncidents && data.recoveredIncidents.numRecoveredIOUs
                              ? data.recoveredIncidents.numRecoveredIOUs
                              : 0
                            )}
                          </div>
                          <div
                            className="totals-label text-end"
                            style={{ color: "#b4b4b4" }}
                          >
                            IOUs
                          </div>
                        </div>
                      </div>
                    </div>
                  </h2>
                </div>
              </div>
              {/* chart data */}
              <div className="container-max-width-reports-page">
                <StackedBarChart
                  chartData={chartData}
                  className="report-bar-chart"
                />
              </div>
              <div className=" accordion accordion-flush container-max-width-reports-page">
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    style={{ backgroundColor: "#F4F4F4" }}
                    id="flush-headingStats"
                  >
                    <div
                      className="accordion-button ps-0 pe-0 collapsed row d-flex"
                      type="button"
                      style={{ backgroundColor: "#F4F4F4" }}
                      data-bs-toggle="collapse"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <div className="col-3 ps-0">
                        <div
                          className="counts-heading text-start"
                          style={{ color: "#b4b4b4", padding: "0px" }}
                        >
                          Total
                        </div>
                      </div>
                      <div className="col-2 text-end">
                        <div>
                          <div
                            className="totals-value text-end"
                            style={{ color: "#6f7172" }}
                          >
                           ${onSiteRecoveredAmount?.toFixed(2) ?? '0.00'}
                          </div>
                          <div
                            className="totals-label text-end"
                            style={{ color: "#b4b4b4" }}
                          >
                            <div className="summary-chart-legend-spot background-colour-chart-3"></div>
                            Paid On Site
                          </div>
                        </div>
                      </div>
                      <div className="col-3 text-end">
                        <div>
                          <div
                            className="totals-value text-end"
                            style={{ color: "#6f7172" }}
                          >
                            ${onSiteLatePaymentRecoveredAmount?.toFixed(2) ?? '0.00'}
                          </div>
                          <div
                            className="totals-label text-end"
                            style={{ color: "#b4b4b4" }}
                          >
                            <div className="summary-chart-legend-spot background-colour-chart-5"></div>
                            Paid On-Site Late Payment
                          </div>
                        </div>
                      </div>
                      <div className="col-2 text-end">
                        <div>
                          <div
                            className="totals-value text-end"
                            style={{ color: "#6f7172" }}
                          >
                             ${onlineRecoveredAmount?.toFixed(2) ?? '0.00'}
                          </div>
                          <div
                            className="totals-label text-end"
                            style={{ color: "#b4b4b4" }}
                          >
                            <div className="summary-chart-legend-spot background-colour-chart-4"></div>
                            Paid Online
                          </div>
                        </div>
                      </div>
                      <div className="col-2 text-end">
                        <div>
                          <div
                            className="totals-value text-end"
                            style={{ color: "#6f7172" }}
                          >
                            ${onlineFRSRecoveredAmount?.toFixed(2) ?? '0.00'}
                          </div>
                          <div
                            className="totals-label text-end"
                            style={{ color: "#b4b4b4" }}
                          >
                            <div className="summary-chart-legend-spot background-colour-chart-2"></div>
                            FRS Recovered
                          </div>
                        </div>
                      </div>
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          )}
          <div className="row body-content">
            <div className="row ps-0 pe-0 ">
              <div className="col-sm-12 ps-0 pe-0 d-flex">
                <div className="col-12 container-max-width-reports-page">
                  {showResult === false ? (
                    <div className="row">
                      <div
                        className="col-sm-12 text-center mt-4"
                        style={{
                          fontSize: "20px",
                          color: "#6f7172",
                          paddingTop: "50px",
                        }}
                      >
                        <p>At least one Site must be selected.</p>
                      </div>
                    </div>
                  ) : (
                    <>
                      {data && hasPaymentModes ? (
                        <>
                          {hasPaymentModes && (
                            <div
                              className="accordion"
                              id="accordionPanelsStayOpenExample"
                            >
                              {/* {Object.keys(paymentModesInfo).map((key) => {
                                const formattedKey = key.replace(/ /g, "_"); // Format the key for state access
                                return (
                                  <div
                                    key={formattedKey}
                                    className={`accordion-item ${
                                      accordionState[formattedKey] ? "show" : ""
                                    }`}
                                  >
                                    <h2
                                      className="accordion-header"
                                      id={`panelsStayOpen-heading-${formattedKey}`}
                                    >
                                      <div
                                        className="accordion-button  ps-0 pe-0 row d-flex"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#panelsStayOpen-collapse-${formattedKey}`}
                                        aria-expanded={
                                          accordionState[formattedKey]
                                        }
                                        aria-controls={`panelsStayOpen-collapse-${formattedKey}`}
                                        onClick={() =>
                                          toggleAccordion(formattedKey)
                                        }
                                      >
                                        <div className="col-10 ps-0">
                                          <div
                                            style={{
                                              color: "#e27a13",
                                              fontWeight: "bolder",
                                              fontSize: "14px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              {accordionState[formattedKey] ? (
                                                <ExpandMoreRoundedIcon />
                                              ) : (
                                                <ChevronRightRoundedIcon />
                                              )}
                                              <span
                                                style={{ marginLeft: "10px" }}
                                              >
                                                {paymentModesInfo[key].count}
                                                {key}
                                              </span>
                                            </div>
                                            <span
                                              className="pt-1"
                                              style={{
                                                fontSize: "12px",
                                                color: "#e27a13",
                                                marginLeft: "34px",
                                              }}
                                            >
                                              {accordionState[formattedKey]
                                                ? "Hide All"
                                                : "Show All"}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-2 text-end">
                                          <div>
                                            <div
                                              className="totals-value"
                                              style={{ color: "#6f7172" }}
                                            >
                                              $
                                              {(
                                                paymentModesInfo[key]
                                                  .totalAmount || 0
                                              ).toFixed(2)}
                                            </div>
                                            <div
                                              className="totals-label"
                                              style={{ color: "#b4b4b4" }}
                                            >
                                              Recovered
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </h2>
                                    <div
                                      className={`accordion-collapse collapse ${
                                        accordionState[formattedKey]
                                          ? "show"
                                          : ""
                                      }`}
                                    >
                                      <CustomizedTable
                                        reportName={"debtOutstanding"}
                                        heading={[
                                          "OCCURRED AT",
                                          "LICENSE PLATE",
                                          "TYPE",
                                          "STATUS",
                                          "SITE",
                                          "PUMP",
                                          "REFERENCE",
                                          "VALUE",
                                        ]}
                                        data={newdata[key]}
                                      />
                                      <div className="row  d-flex me-4  mt-2 mb-2">
                                        <div className="col-9"></div>
                                        <div className="col-3 text-end">
                                          <div>
                                            <div
                                              className="totals-value"
                                              style={{ color: "#6f7172" }}
                                            >
                                              $
                                              {(
                                                paymentModesInfo[key]
                                                  .totalAmount || 0
                                              ).toFixed(2)}
                                            </div>
                                            <div
                                              className="totals-label"
                                              style={{ color: "#b4b4b4" }}
                                            >
                                              Recovered
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })} */}
                                                  <div
                      className="accordion container-max-width-reports-page"
                      id="accordionPanelsStayOpenExample"
                    >
                      {/* /// */}
                      {Object.entries(paymentModesInfo).map(([key, value]) => {
                        const formattedKey = key.replace(/ /g, "_");
                        return (
                          <div
                            key={formattedKey}
                            className="container mx-auto px-0 py-0"
                          >
                            <div className="bg-white border-t border-b border-gray-200">
                              <button
                                type="button"
                                className="w-full px-0 py-3 text-left"
                                onClick={() => toggleAccordionNew(formattedKey)}
                              >
                                <div className="grid grid-cols-6 items-center">
                                  <div className="col-span-5 flex items-center">
                                    <svg
                                      className={`w-4 h-4 font-bold text-[#E27A13] transform transition-transform duration-300 ${
                                        accordionState[formattedKey]
                                          ? "rotate-90"
                                          : ""
                                      }`}
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path d="M9 5l7 7-7 7"></path>
                                    </svg>
                                    <span className="ml-2 text-[#E27A13] font-bold">
                                    {paymentModesInfo[key].count}
                                    <span className="ml-1">{key}</span>
                                      <div className="text-sm font-extralight">
                                        {accordionState[formattedKey]
                                          ? "Hide all"
                                          : "Show all"}
                                      </div>
                                    </span>
                                  </div>
                                  {!accordionState[formattedKey] && (
                                    <>
                                      <div className="col-span-1 text-end pe-0">
                                        <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                          <span>
                                          $
                                              {(
                                                paymentModesInfo[key]
                                                  .totalAmount || 0
                                              )?.toFixed(2)}
                                          </span>
                                          <span className="text-[#b4b4b4]">
                                            Recovered
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </button>
                              <div
                                ref={contentRefs.current[formattedKey]}
                                className="overflow-hidden transition-all duration-300 ease-in-out"
                                style={{
                                  maxHeight: accordionState[formattedKey]
                                    ? `${contentHeights[formattedKey]}px`
                                    : "0",
                                  opacity: accordionState[formattedKey] ? 1 : 0,
                                }}
                              >
                                <CustomizedTable
                                        reportName={"debtOutstanding"}
                                        heading={[
                                          "OCCURRED AT",
                                          "LICENSE PLATE",
                                          "TYPE",
                                          "STATUS",
                                          "SITE",
                                          "PUMP",
                                          "REFERENCE",
                                          "VALUE",
                                        ]}
                                  data={newdata[key]}
                                  selectedLabel={key}
                                />
                                <div className="grid grid-cols-6 items-center py-3">
                                  <div className="col-span-5 flex items-center"></div>
                                  <div className="col-span-1 text-end pe-0">
                                    <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                      <span>
                                      $
                                              {(
                                                paymentModesInfo[key]
                                                  .totalAmount || 0
                                              )?.toFixed(2)}
                                      </span>
                                      <span className="text-[#b4b4b4]">
                                        Recovered
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                            </div>
                          )}
                          <div className="accordion accordion-flush">
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingStats"
                              >
                                <div
                                  className="accordion-button ps-0 pe-0  collapsed row d-flex"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne"
                                >
                                  <div
                                    className="col-8 ps-3 counts-heading"
                                    style={{
                                      color: "#b4b4b4",
                                      padding: "0px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Total
                                  </div>
                                  <div className="col-2 text-end">
                                    <div>
                                      <div
                                        className="totals-value"
                                        style={{ color: textAccentColor }}
                                      >
                                        $
                                        {(
                                          onSiteRecoveredAmount +
                                            onSiteLatePaymentRecoveredAmount +
                                            onlineRecoveredAmount +
                                            onlineFRSRecoveredAmount || 0
                                        ).toFixed(2)}
                                      </div>
                                      <div
                                        className="totals-label"
                                        style={{ color: "#b4b4b4" }}
                                      >
                                        Recovered
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-2 pe-0 text-end">
                                    <div>
                                      <div
                                        className="totals-value"
                                        style={{ color: textAccentColor }}
                                      >
                                        $
                                        {(
                                          onSiteRecoveredAmount +
                                            onSiteLatePaymentRecoveredAmount +
                                            onlineRecoveredAmount +
                                            onlineFRSRecoveredAmount || 0
                                        ).toFixed(2)}
                                      </div>
                                      <div
                                        className="totals-label"
                                        style={{ color: "#b4b4b4" }}
                                      >
                                        For Reimbursement
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </h2>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="row">
                          <div
                            className="col-sm-12 text-center mt-4"
                            style={{
                              fontSize: "20px",
                              color: "#6f7172",
                              paddingTop: "50px",
                            }}
                          >
                            <p>
                              There are no incidents which match your search
                              criteria.
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Reimbursement;