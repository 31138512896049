import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Pages from "./Pages/index";
import Component from "./Components/index";
import ForgotPassword from "./Pages/LoginPage/ForgotPassword";
import ResetPasswordSuccess from "./Pages/LoginPage/ResetPasswordSuccess";
import ResetPasswordPage from "./Pages/LoginPage/ResetPasswordPage";
import { ApiDataProvider } from "./ApiDataContext"; // Adjust the path accordingly
import "./App.css";
import "./CSS/Base/fonts.css";
import PrivateRoute from "./utils/functions/PrivateRoute";
const App = () => {
  // Custom hook to get the current location
  const location = useLocation();

  // Check if the current path should exclude the footer and navbar
  const excludeNavBarFooter = [
    "/scancam/regosearch",
    "/scancam/searchrego",
  ].includes(location.pathname);

  const navigate = useNavigate();

  // Check if the user is logged in
  const isLogged = Boolean(localStorage.getItem("scancamPortalisLogged"));

  useEffect(() => {
    if (
      location.pathname.startsWith("/scancam/resetpassword") ||
      location.pathname === "/scancam/ampol" ||
      location.pathname === "/scancam/home"
    ) {
      return;
    }

    if (
      !isLogged &&
      location.pathname !== "/scancam/" &&
      location.pathname !== "/scancam/forgotpassword" &&
      location.pathname !== "/scancam/resetpasswordsuccess"
    ) {
      navigate("/scancam/");
    } else if (isLogged && location.pathname === "/scancam/") {
      navigate("/scancam/home");
    }
  }, [isLogged, location, navigate]);

  return (
    <ApiDataProvider>
      <div className="App">
        <Routes>
          <Route path="/scancam/" element={<Pages.LoginForm />} />
          <Route path="/scancam/ampol/" element={<Pages.LoginPage />} />
          <Route path="/scancam/forgotpassword" element={<ForgotPassword />} />
          <Route
            path="/scancam/resetpasswordsuccess"
            element={<ResetPasswordSuccess />}
          />
          <Route
            path="/scancam/resetpassword/:custId/:name/:resetToken"
            element={<ResetPasswordPage />}
          />
          <Route
            path="/scancam/*"
            element={
              <>
                <Component.NavBar />
                <Routes>
                <Route path="/home" element={<PrivateRoute element={Pages.HomePage} />} />
                  <Route
                    path="/reviewincidents"
                    element={<Component.ReviewIncidents />}
                  />
                  <Route
                    path="/add"
                    element={<Component.AddIncidentFirstPage />}
                  />
                  <Route
                    path="/AddIncident"
                    element={<Component.AddIncident />}
                  />
                  <Route
                    path="/incident/:pkAlert/"
                    element={<Component.IncidentDetails />}
                  >
                    <Route path="details" element={<Component.Details />} />
                    <Route path="notes" element={<Component.Notes />} />
                    <Route path="history" element={<Component.Records />} />
                    <Route
                      path="latepayment"
                      element={<Component.LatePayment />}
                    />
                    <Route
                      path="sendpaymentlink"
                      element={<Component.SendPaymentLink />}
                    />
                  </Route>

                  <Route path="/report" element={<Pages.Report />} />
                  <Route
                    path="/report/siteOperations"
                    element={<Pages.SiteOperations />}
                  />
                  <Route
                    path="/report/debtOutstanding"
                    element={<Pages.DebtOutstanding />}
                  />
                  <Route
                    path="/report/debtRecovered"
                    element={<Pages.DebtRecovered />}
                  />

                  <Route
                    path="/report/employeePerformance"
                    element={<Pages.EmployeePerformance />}
                  />
                  <Route
                    path="/report/siteTraffic"
                    element={<Pages.SiteTraffic />}
                  />
                  <Route
                    path="/report/offenderDetection"
                    element={<Pages.OffenderDetection />}
                  />
                  <Route
                    path="/report/advanceReport"
                    element={<Pages.AdvanceReport />}
                  />

                  <Route
                    path="/report/reimbursement"
                    element={<Pages.Reimbursement />}
                  />
                  <Route path="/report/precourt" element={<Pages.PreCourt />} />
                  <Route
                    path="/report/agentexport"
                    element={<Pages.AgentExport />}
                  />
                  <Route
                    path="/report/iouletterrun"
                    element={<Pages.IOULetterRun />}
                  />
                  <Route
                    path="/report/dotletterrun"
                    element={<Pages.DoTLetterRun />}
                  />
                  <Route
                    path="/report/letterrun/wa"
                    element={<Pages.WALetterRunProcessing />}
                  />
                  <Route
                    path="/report/letterrun/nsw"
                    element={<Pages.NSWLetterRunProcessing />}
                  />
                  <Route
                    path="/report/letterrun/vic"
                    element={<Pages.VICLetterRunProcessing />}
                  />
                  <Route
                    path="/report/letterrun/qld"
                    element={<Pages.QLDLetterRunProcessing />}
                  />
                  <Route
                    path="/report/letterrun/nt"
                    element={<Pages.NTLetterRunProcessing />}
                  />
                  <Route
                    path="/report/letterrun/sa"
                    element={<Pages.SALetterRunProcessing />}
                  />
                  <Route
                    path="/report/letterrun/act"
                    element={<Pages.ACTLetterRunProcessing />}
                  />
                  <Route
                    path="/regosearch"
                    element={<Component.SearchRego />}
                  />
                  <Route
                    path="/searchrego"
                    element={<Component.IconSearchPage />}
                  />
                  <Route
                    path="/ManageEmployees"
                    element={<Pages.ManageEmployees />}
                  />
                  <Route
                    path="/OrderFrsCards"
                    element={<Pages.OrderFrsCards />}
                  />
                  <Route
                    path="/Training/Videos"
                    element={<Component.TrainingVideos />}
                  />
                  <Route path="/Training/Faqs" element={<Pages.FaqPage />} />
                  <Route
                    path="/Training/Troubleshooting"
                    element={<Pages.TroubleshootingPage />}
                  />
                  <Route
                    exact
                    path="/training/viewvideo/:videoTitle"
                    element={<Component.ViewVideo />}
                  />
                  <Route
                    path="*"
                    element={
                      <Navigate to={isLogged ? "/scancam/home" : "/scancam/"} />
                    }
                  />
                </Routes>

                {!excludeNavBarFooter && <Component.Footer />}
              </>
            }
          />
        </Routes>
      </div>
    </ApiDataProvider>
  );
};

export default App;
