import React, { useState } from "react";
import useFetchIncidents from "../../Custom Hooks/useFetchIncidents";
import CustomizedTable from "../ViewReportPage/CustomizedTable";

const IconSearchPage = () => {
  const [isInputActive, setIsInputActive] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const statuses = {
    "Open": [], "Verified": [], "Escalated": [], "Pre-Court": [], "In-Court": [], "Details Received": [], "Recovery In-Progress": [], "Debt Recovered": [], "Debt Paid": [], "Cancelled": [], "Expired": [], "Incomplete Transaction": [], "Returned To Site": []
  };

  const payload = {
    statuses: statuses
  }
  const { incidents } = useFetchIncidents(payload);
  // console.log({ incidents })

  const handleInputFocus = () => {
    setIsInputActive(true);
  };

  const handleInputBlur = () => {
    setIsInputActive(false);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value.toUpperCase());
    setErrorMessage("");
    setSearchResult([]);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (searchQuery.trim() === "") {
      setErrorMessage("Please enter a registration number to search.");
    } else {
      setErrorMessage("");
      const filteredResult = incidents.filter((item) =>
        item.ocr.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (filteredResult.length > 0) {
        setSearchResult(filteredResult);
      } else {
        setSearchResult([]);
        setErrorMessage("No results found.");
      }
    }
  };

  return (
    <div className="search-page-box">
      <div className="row">
        <div className="col-sm-12">
          <div className="col-sm-4 search-input-box">
            <input
              type="text"
              className={isInputActive ? "input-active" : "search-input-bar"}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              placeholder="Search..."
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleFormSubmit(event);
                }
              }}
            />
          </div>
          {errorMessage && (
            <div className="row">
              <div className="col-sm-12">
                <div className="col-sm-4 search-no-output-text error-text">
                  {errorMessage}
                </div>
              </div>
            </div>
          )}
          <div className="col-sm-8"></div>
        </div>
      </div>
      <div className="row">
        {searchResult?.length === 0 ? (
          <div className="col-sm-12">
            <div className="col-sm-4 search-no-input-text">
              Search for license plate detection.
            </div>
            <div className="col-sm-8"></div>
          </div>
        ) : (
          <div className="col-sm-12 ps-2 pe-2 ">
            <div className="col-sm-12">
              <div className="mt-3 ms-4 me-4">
                <CustomizedTable
                  reportName={"debtOustannding"}
                  heading={['OCCURRED AT', 'LICENSE PLATE', 'TYPE', 'STATUS', 'SITE', 'PUMP', 'REFERENCE', 'VALUE']}
                  data={searchResult} // Replace 'data' with your actual data array
                  selectedLabel={"advanceReport"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IconSearchPage;