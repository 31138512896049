// api.js

import { APP_SERVICES_URL } from "../Constants/constants";
import userSession from "../Constants/auth";

export const sendPaymentLink = async (formData) => {
    const { email, phoneNumber, alertID } = formData;
    const CustID = userSession.CustID;
    const authHeader = userSession.authHeader;
    const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/paymentLink`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: authHeader,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                alertID: alertID,
                phoneNumber: phoneNumber,
                email: email
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to send payment link');
        }

        return response;
    } catch (error) {
        throw error;
    }
};
