import { useEffect, useState } from 'react';
import { APP_SERVICES_URL } from '../Constants/constants';
import userSession from "../Constants/auth"
const useFetchIncidents = (payloadData) => {
  const [incidents, setIncidents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { CustID, authHeader } = userSession;
  useEffect(() => {
    const fetchData = async () => {
      try {

        // Serialize payloadData into a query string
        const queryParams = new URLSearchParams({
          limit: 10000,
          offset: 0,
        });

        const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incidentsList?${queryParams.toString()}`;

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader, // Convert to base64 (Basic Auth)
          },
          body: JSON.stringify(payloadData), // Convert request body to JSON string
        });

        if (!response.ok) {
          throw new Error('Failed to fetch incidents data.');
        }

        const data = await response.json();
        setIncidents(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [CustID]);

  return { incidents, loading, error };
};

export default useFetchIncidents;
