import React, { useEffect, useState,useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import scancamLogo from "../../Assets/scancam_220x64.jpg";
import { Link, useNavigate } from "react-router-dom";
import userSession from "../../Constants/auth";
import { APP_SERVICES_URL } from "../../Constants/constants";
import bpLogo from "../../Assets/BrandLogo/bp_logo.png";

const Navbar = () => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { CustID, authHeader, sites, userRole, username } = userSession;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("#home");
  const [incidents, setIncidents] = useState([]);
  const customerName = userSession.themeData?.customerName;
  const logo = customerName === "bp" ? bpLogo : scancamLogo;
  const fetchData = async () => {
    try {
      // Construct query parameters
      const queryParams = new URLSearchParams({
        limit: 101,
        offset: 0,
      });
      const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incidentsList?${queryParams.toString()}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader, // Convert to base64 (Basic Auth)
        },
        body: JSON.stringify(payloadData), // Convert request body to JSON string
      });

      if (!response.ok) {
        throw new Error("Failed to fetch incidents data.");
      }

      const data = await response.json();
      setIncidents(data.length);
    } catch (error) {
      console.error(error);
    }
  };

  const payloadData = {
    toReviewStatuses: true,
    sites: sites.name,
  };

  useEffect(() => {
    fetchData(payloadData);
    // eslint-disable-next-line
  }, [location.pathname, sites, payloadData]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const logoutUser = (custID) => {
    const url = `/sn-auth-service/${custID}/logout`;
    const options = {
      method: "POST",
      headers: {
        Authorization: `${authHeader}`, // Include the bearer token in the Authorization header
      },
    };

    fetch(url, options)
      .then((response) => {
        if (response.ok) {
        } else {
          console.error("Logout failed:", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  };

  // Function to handle logout
  const handleLogout = () => {
    // Clear session storage data
    sessionStorage.clear();
    localStorage.clear();
    navigate("/scancam/"); // Use useNavigate to navigate to /scancam page
    logoutUser(CustID);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-white shadow-md w-lvw">
      <div className="flex items-center justify-between h-[64px] ps-2">
        {/* Left Side: Logo and Navigation Links */}
        <div className="flex items-center space-x-2">
          {/* Logo */}
          <div className="flex items-center">
            <Link to={"/scancam/home"}>
              <img src={logo} alt="scancam" className="w-auto" />
            </Link>
          </div>

          {/* Navigation Links */}
          <div className="hidden md:flex  text-[#6f7172] text-sm">
            <Link
              to={"/scancam/home"}
              className="flex items-center hover:text-[#6f7172]  justify-center hover:bg-[#f4f4f4] h-[64px] px-4"
              onClick={() => handleLinkClick("#today")}
            >
              <span
                className="w-full text-center "
                style={{
                  borderBottom:
                    activeLink === "#today" ? "1px solid #6f7172" : "none",
                }}
              >
                Today
              </span>
            </Link>

            <Link
              className="relative flex items-center justify-center hover:text-[#6f7172]  hover:bg-[#f4f4f4] h-[64px]  px-4"
              onClick={() => handleLinkClick("#ReviewIncidents")}
              to={"/scancam/reviewincidents"}
            >
              <span
                className="w-full text-center "
                style={{
                  borderBottom:
                    activeLink === "#ReviewIncidents"
                      ? "1px solid #6f7172"
                      : "none",
                }}
              >
                Review Incidents
              </span>
              {incidents > 0 && (
                <div className="absolute top-[4px] right-[2px] bg-[#e27a13] text-white font-bold text-[10px] rounded-full h-5 w-5 flex items-center justify-center">
                  {incidents > 100 ? "99+" : incidents}
                </div>
              )}
            </Link>

            <Link
              className="flex items-center justify-center hover:text-[#6f7172]  hover:bg-[#f4f4f4] h-[64px] px-4 "
              onClick={() => handleLinkClick("#ViewaReport")}
              to={"/scancam/report"}
            >
              <span
                className="w-full text-center "
                style={{
                  borderBottom:
                    activeLink === "#ViewaReport"
                      ? "1px solid #6f7172"
                      : "none",
                }}
              >
                View a Report
              </span>
            </Link>

            {(userRole === "sensenadmin" || userRole === "frs-reviewer") && (
              <Link
                className="flex items-center justify-center hover:text-[#6f7172]  hover:bg-[#f4f4f4] h-[64px] px-4"
                onClick={() => handleLinkClick("#SearchRego")}
                to={"/scancam/regosearch"}
              >
                <span
                  className="w-full text-center"
                  style={{
                    borderBottom:
                      activeLink === "#SearchRego"
                        ? "1px solid #6f7172"
                        : "none",
                  }}
                >
                  Search Rego
                </span>
              </Link>
            )}
          </div>
        </div>

        {/* Right Side: Dropdown and Search */}
        <div className="flex items-center">
          {/* Dropdown */}
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={toggleDropdown}
              className="text-gray-800 bg-[#f4f4f4] h-[64px] flex justify-center items-center px-4"
            >
              <span className="mr-2 text-[#6f7172] text-sm">
                Hello
                <span className="text-[#e27a13] capitalize"> {username}</span>
              </span>
              <FiChevronDown size={20} />
            </button>
            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="absolute right-0 text-[14px] mt-2 bg-white border border-gray-200 rounded-md shadow-lg w-[172px]">
                <div
                  onClick={handleLogout}
                  className="block px-4 py-1 text-gray-800 hover:bg-gray-100"
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </div>
                {userRole !== "frs-reviewer" && userRole !== "sensenadmin" && (
                  <>
                    <Link
                      to={"/scancam/ManageEmployees"}
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-100 hover:text-[#6f7172]"
                    >
                      Manage Employees
                    </Link>
                    <Link
                      to={"/scancam/OrderFrsCards"}
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-100 hover:text-[#6f7172]"
                    >
                      Order FRS Card
                    </Link>
                  </>
                )}
              </div>
            )}
          </div>

          {/* Search Icon */}
          <button
            className="flex items-center justify-center text-gray-800 bg-[#3a3a3a] h-[64px] w-[64px]"
            onClick={() => navigate("/scancam/searchrego")}
          >
            <FaSearch size={20} className="text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
