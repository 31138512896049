import { orange } from '@mui/material/colors';
import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import "../CSS/LandingPage.css"


const HomePageBarcharts = ({ chartData, label }) => {
  const [tooltipData, setTooltipData] = useState(null);

  const isHomepage = label === 'homepage';


  // Function to format date as 'dd-Mon-yyyy'
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }


  // Sample object with existing dates and counts
  const existingDates = chartData == {} ? [] : chartData;


  // Get current date and calculate start date (31 days ago)
  const today = new Date();
  const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
  const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()); // End date (yesterday)


  const newObjects = [];

  // Loop through 31 days
  while (startDate <= endDate && newObjects.length < 31) {
    const formattedDate = formatDate(startDate);
    const existingDate = existingDates.find(item => item.date === formattedDate);
    if (!existingDate) {
      newObjects.push({ date: formattedDate, count: 0.5 });
    } else {
      newObjects.push({ date: formattedDate, count: existingDate.count });
    }
    // Move to the next day
    startDate.setDate(startDate.getDate() + 1);
  }


  const barColor = isHomepage ? '#ffffff' : '#c56b13cf';


  const chartOptions = {
    chart: {
      id: 'hourly-bar',
      stacked: false,
      width: '80%',
      height: 200,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '20%',
        columnHeight: '20%',
        borderRadius: 3
      },
    },
    xaxis: {
      categories: newObjects.map((item) => item.date),
      title: {
        text: isHomepage ? '' : 'Date',
        style: {
          color: '#000000', // Black text color
          fontSize: '10px',
        },
        offsetY: 0,
      },
      labels: {
        show: false,
        trim: false,
        style: {
          colors: isHomepage ? '#ffffff' : orange,
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: isHomepage ? '#ffffff' : orange,
        },
      },
      formatter: (value) => {
        // Log the Y-axis data
        // console.log(value);
        // Return the formatted value (or modify as needed)
        return value;
      },
    },
    grid: {
      show: false,
    },
    markers: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: [barColor],
    },
    tooltip: {
      enabled: true,
      theme: 'dark', // Set theme to dark for white text color
      style: {
        color: 'red', // Set text color to white
      },
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const xData = w.config.xaxis.categories[dataPointIndex];
        const yData = newObjects[dataPointIndex]?.count;
        setTooltipData(yData);
        return `<div className="apexcharts-tooltip-custom">${xData} - ${parseInt(yData)} incidents</div>`;
      },
    },




  };


  const chartSeries = [
    {
      name: 'Incidents Data',
      data: newObjects.map((item) => item.count),
    },
  ];


  return (
    <div style={{ position: 'relative' }}>
      <Chart options={chartOptions} series={chartSeries} type="bar" height={120} />
    </div>
  );
};


export default HomePageBarcharts;
