import React, { useEffect, useState } from "react";
import "../../CSS/ReportsPage.css";
import UseReportsApi from "../../Custom Hooks/useReportsApi";
import styles from "../../CSS/Base/Basic.module.css";
import userSession from "../../Constants/auth";
import { APP_SERVICES_URL } from "../../Constants/constants";
import StackedBarChart from "../../Charts/StackedBarChart";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import MaterialUIMultiSelectCheckmarks from "../../Components/ViewReportPage/MaterialUIMultiSelectCheckmarks";
import CustomizedTable from "../../Components/ViewReportPage/CustomizedTable";
import CSVDownloadButton from '../../Components/ViewReportPage/CSVDownloadButton';
import ErrorModal from "../../Modals/ErrorModal";
import ReportTypeModal from "../../Modals/ReportTypeModal";
import EmailModal from "../../Modals/EmailModal";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PrintIcon from '@mui/icons-material/Print';
import LaunchIcon from '@mui/icons-material/Launch';
import EmailIcon from '@mui/icons-material/Email';

const AgentExport = () => {
    const { authHeader, sites, CustID, userRole } = userSession;
    // const getSitesNames = (sites) => {
    //     return sites.map(site => site.name);
    //   };
    //   const siteNames = getSitesNames(sites);
    const states_data = ['state1', 'state2', 'state3']
    const brands_data = ['brand1', 'brand2', 'brand3']
    const products_data = ['product1', 'product2', 'product3']
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const formattedFirstDayOfMonth = `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}-01`;
    const [showResult, setShowResult] = useState(false);
    const [startDate, setStartDate] = useState(formattedFirstDayOfMonth);
    const [endDate, setEndDate] = useState(today.toISOString().split('T')[0]);
    const [selectedSite, setSelectedSite] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [newdata, setNewData] = useState({});
    const [apiResponse, setApiResponse] = useState(null);
    const [buttonClicked, setButtonClicked] = useState(null);
    const [emailReport, setEmailReport] = useState(false);
    const [isReportTypeModalOpen, setIsReportTypeModalOpen] = useState(false);

    const openReportTypeModal = () => {
        setButtonClicked('printPDF');
        setIsReportTypeModalOpen(true);
    };

    const closeReportTypeModal = () => {
        setIsReportTypeModalOpen(false);
    };


    const openEmailModal = () => {
        setButtonClicked('emailReport');
        setEmailReport(true);
    };

    const closeEmailModal = () => {
        setEmailReport(false);
        setApiResponse(null);
    };
    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };


    // Generic function to handle selection for different dropdowns
    const handleSelection = (selectedValues, allValues, setSelectedFunction) => {
        if (selectedValues.includes('Select All')) {
            if (selectedValues.length === allValues.length) {
                // If all items are already selected, unselect all
                setSelectedFunction([]);
            } else {
                // Select all items
                setSelectedFunction(allValues);
            }
        } else {
            setSelectedFunction(selectedValues);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
        const timezoneOffset = -date.getTimezoneOffset();
        const timezoneOffsetHours = Math.floor(timezoneOffset / 60);
        const timezoneOffsetMinutes = timezoneOffset % 60;
        const timezoneOffsetString = `${timezoneOffsetHours >= 0 ? "+" : "-"
            }${String(Math.abs(timezoneOffsetHours)).padStart(2, "0")}${String(
                Math.abs(timezoneOffsetMinutes)
            ).padStart(2, "0")}`;

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneOffsetString}`;
    };
    // Define state variables for data, loading, and error
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const fetchData = UseReportsApi();
    const fetchReportData = async (payloadData) => {
        const { CustID, authHeader } = userSession;

        try {
            // Serialize payloadData into a query string
            setError(false);
            const queryParams = new URLSearchParams({
                limit: 10000,
                offset: 0,
              });
              const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incidentsList?${queryParams.toString()}`;
              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: authHeader, // Convert to base64 (Basic Auth)
                },
                body: JSON.stringify(payloadData), // Convert payloadData to JSON string
              });

            if (!response.ok) {
                setError(true);
            }

            const data = await response.json();

            return data;
        } catch (error) {
            setError(true);
            setErrorMessage("Failed to fetch report data. Please try again. If the issue persists, contact support.");
            return [];
        }
    };

    const updateDataStateWithSpecificValues = (result) => {
        // Create a dictionary to store the specific values.
        const specificValues = {};

        // Check for the specific values in the result variable.
        for (const key in result) {
            if (result.hasOwnProperty(key)) {
                specificValues[key] = result[key];
            }
        }

        // Update the data state with the specific values.
        setNewData({
            ...specificValues,
        });
    };
    const handleSubmit = async (e) => {
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        const payloadData = {
            sites: selectedSite,
            states: selectedStates,
            brands: selectedBrands,
            products: selectedProducts,
            to: formattedEndDate,
            from: formattedStartDate,
        };
        if (payloadData.sites.length < 1) {

            return;
        }
        try {
            setIsLoading(true);
            setError(false);
            const result = await fetchData(authHeader, CustID, 'debtRecovered', payloadData);
            setData(result);
            updateDataStateWithSpecificValues(result.recoveredIncidents.paymentModesInfo);
            setShowResult(true);
        } catch (error) {
            setError(true);
            setErrorMessage("Failed to fetch report data. Please try again. If the issue persists, contact support.");
        } finally {
            setIsLoading(false);
        }
    };

    const DebtOutRecoveredPayloadGenerator = (label, from, to, sites, brands, products, states) => {
        return {
            "recoveredStatuses": true,
            "paymentModes": [label],
            sites,
            from,
            to,
            brands, products, states
        };
    };

    // Define a function for fetching and updating report data
    const fetchAndUpdateReportData = async () => {
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        const allKeys = Object.keys(newdata);

        // Iterate over the keys in the newData state and call the fetchReportData API for each key.
        for (const key of allKeys) {
            // Generate the payload for the API call.
            const payload = DebtOutRecoveredPayloadGenerator(
                key, // Use the current key as-is
                formattedStartDate,
                formattedEndDate,
                selectedSite,
                selectedBrands,
                selectedProducts,
                selectedStates
            );

            try {
                setError(false);
                const result = await fetchReportData(payload, key); // Use the current key as the second argument
                // Update the newData state with the result.
                newdata[key] = result;
            } catch (error) {
                console.error(error);
                setError(true);
                setErrorMessage("Failed to fetch report data. Please try again. If the issue persists, contact support.");
            }
        }

        // Set the new newData state.
        setNewData(newdata);
    };

    useEffect(() => {
        fetchAndUpdateReportData();
        // eslint-disable-next-line
    }, [newdata]);

    // Use the fetchAndUpdateReportData function in handleReportClick

    // Prepare the chart data based on the values you want to pass
    const properties = ['expiredAmount', 'cancelledAmount', 'recoveredIncidents.recoveredAmount', 'outstandingIncidents.outstandingAmount'];
    const colors = ['#6f7172', '#e4e4e4', '#e27a13', '#ffcb07'];
    const chartData = [];

    properties.forEach((property, index) => {
        // Use a utility function to safely access nested properties
        const value = getValueSafely(data, property);
        if (value !== undefined && value !== 0) {
            chartData.push({
                data: [value],
                color: colors[index],
            });
        }
    });

    // Utility function to safely access nested properties
    function getValueSafely(obj, property) {
        const properties = property.split('.');
        let value = obj;

        for (let prop of properties) {
            if (value && value.hasOwnProperty(prop)) {
                value = value[prop];
            } else {
                return undefined;
            }
        }

        return value;
    }

    // Watch for changes in the selectedSite array
    useEffect(() => {
        if (selectedSite.length === 0) {
            // Clear your state here, replace with your actual state clearing logic
            setData(null);
            setShowResult(false);
        }
    }, [selectedSite]); // This effect will run whenever selectedSite changes

    const paymentModesInfo = data?.recoveredIncidents?.paymentModesInfo || {};
    // Check if paymentModesInfo has at least one key
    const hasPaymentModes = Object.keys(paymentModesInfo).length > 0;

    const onSiteRecoveredAmount = ((data && data.recoveredIncidents && data.recoveredIncidents.paymentModesInfo["On Site"] && data.recoveredIncidents.paymentModesInfo["On Site"].recoveredAmount) || 0).toFixed(2);
    const onSiteLatePaymentRecoveredAmount = ((data && data.recoveredIncidents && data.recoveredIncidents.paymentModesInfo["On Site Late Payment"] && data.recoveredIncidents.paymentModesInfo["On Site Late Payment"].recoveredAmount) || 0).toFixed(2);
    const onlineRecoveredAmount = ((data && data.recoveredIncidents && data.recoveredIncidents.paymentModesInfo["Online"] && data.recoveredIncidents.paymentModesInfo["Online"].recoveredAmount) || 0).toFixed(2);
    const onlineFRSRecoveredAmount = ((data && data.recoveredIncidents && data.recoveredIncidents.paymentModesInfo["Online FRS"] && data.recoveredIncidents.paymentModesInfo["Online FRS"].recoveredAmount) || 0).toFixed(2);


    const handleCloseErrorModal = () => {
        setError(false);
    };
    const [accordionState, setAccordionState] = useState(() => {
        const initialState = {};
        Object.keys(paymentModesInfo).forEach((key) => {
            const formattedKey = key.replace(/ /g, '_'); // Replace spaces with underscores
            initialState[formattedKey] = false; // Initialize each formatted key with a false state
        });
        return initialState;
    });
    const openAllAccordions = () => {
        const updatedAccordionState = {};

        Object.keys(paymentModesInfo).forEach((key) => {
            const formattedKey = key.replace(/ /g, '_');
            updatedAccordionState[formattedKey] = true;
        });

        setAccordionState(updatedAccordionState);
    };
    const closeAllAccordions = () => {
        const updatedAccordionState = {};

        Object.keys(paymentModesInfo).forEach((key) => {
            const formattedKey = key.replace(/ /g, '_');
            updatedAccordionState[formattedKey] = false;
        });

        setAccordionState(updatedAccordionState);
    };


    const handleReportTypeSelection = (reportType, emailAddress) => {
        // Handle the selected report type (detailed or summary) here.
        if (reportType === 'summary') {
            // Call generatePDF for a summary report
            closeAllAccordions();
            setTimeout(() => {
                generatePDF('DebtRecovered_Summary_Report', emailAddress);
            }, 2000); // 2 seconds delay
        } else if (reportType === 'detailed') {
            // Open all accordions, and then call generatePDF after 2 seconds
            openAllAccordions();
            setTimeout(() => {
                generatePDF('DebtRecovered_Detailed_Report', emailAddress);
            }, 2000); // 2 seconds delay
        }
    };

    function generatePDF(filename, emailAddress) {
        const input = document.getElementById('pdf-content');
        const pdf = new jsPDF('portrait', 'mm', 'a4');

        html2canvas(input, {
            scale: 2,
            useCORS: true,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const width = pdf.internal.pageSize.getWidth();
            const height = (canvas.height * width) / canvas.width;
            pdf.addImage(imgData, 'JPEG', 0, 0, width, height);

            // Save the PDF or send it to the API based on the buttonClicked state
            if (buttonClicked === 'printPDF') {
                pdf.save(`${filename}.pdf`);
            } else if (buttonClicked === 'emailReport' && emailAddress) {
                sendPDFToAPI(pdf.output('blob'), emailAddress, filename);
            }

        });
    }


    function sendPDFToAPI(pdfBlob, emailAddressToSend, filename) {
        // Create a FormData object to send the PDF as a file attachment
        const mailHeading = 'Scancam Report PDF';
        const mailBody = 'Please find attached the report you requested.';
        const formData = new FormData();
        formData.append('attachment', pdfBlob, `${filename}.pdf`);

        // Set up the API endpoint and headers
        const endpoint = `${APP_SERVICES_URL}/senapi/${CustID}/mailWithAttachment?subject=${mailHeading}&body=${mailBody}&mailTo=${emailAddressToSend}`;

        // Reset the state before making the API call
        setApiResponse(null);
        setEmailReport(false);

        fetch(endpoint, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': authHeader,
            },
        })
            .then((response) => {
                if (response.ok) {
                    setApiResponse(true);
                    setEmailReport(true);
                } else {
                    setApiResponse(false);
                    setEmailReport(true);
                }
            })
            .catch((error) => {
                setApiResponse(false);
                setEmailReport(true);
            });
    }


    const toggleAccordion = (accordionName) => {
        // Format the accordion name to remove spaces
        const formattedAccordionName = accordionName.replace(/ /g, '_');

        setAccordionState({
            ...accordionState,
            [formattedAccordionName]: !accordionState[formattedAccordionName],
        });
    };

    const simulateFormSubmit = () => {
        // Replace this with your actual form submission logic
        // For example, if handleSubmit is an async function, you can await it here
        handleSubmit();
    };

    useEffect(() => {
        simulateFormSubmit();
        // eslint-disable-next-line
    }, [startDate, endDate, selectedSite, selectedBrands, selectedStates, selectedProducts]);

    //call the function to close accordions when data changes
    useEffect(() => {
        closeAllAccordions();
        // eslint-disable-next-line
    }, [startDate, endDate, selectedSite, selectedBrands, selectedStates, selectedProducts]);

    return (
        <div className="Reports-Page">
            {emailReport && (
                <EmailModal show={emailReport} onClose={closeEmailModal} onSelectReportType={handleReportTypeSelection} apiResponse={apiResponse} />
            )}
            {isReportTypeModalOpen && (
                <ReportTypeModal show={isReportTypeModalOpen} onClose={closeReportTypeModal} onSelectReportType={handleReportTypeSelection} />
            )}
            {error && <ErrorModal show={true} onClose={handleCloseErrorModal} message={errorMessage} />}
            <div className="main-box">
                <div className="col-sm-12 container-max-width-reports-page">
                    <div className={`row ${styles.TitleSection}`}>
                        <div className="col-sm-12 d-flex">

                            <div className="col-sm-10">
                                <h2 className={styles.Title}>Agent Export Reports</h2>
                            </div>

                        </div>
                    </div>
                    <div className="row search-bar pb-4">
                        <div className="col-sm-12 d-flex">

                            <div className="col-sm-12">
                                <div className="col-sm-12 d-flex">
                                    <div className="col-sm-10 report-filters-actions-heading">
                                        Filters
                                    </div>
                                    <div className="col-sm-2 ps-3 report-filters-actions-heading">
                                        Actions
                                    </div>
                                </div>
                                <div className="col-sm-12 d-flex">
                                    <div className="col-sm-10 ">
                                        <div className="row p-0">
                                            <form onSubmit={handleSubmit}>
                                                <div className="col-sm-12 d-flex">
                                                    <div className="col-sm-2 div-item ">
                                                        <div className="col-sm-12">Start Date</div>
                                                        <div className="col-sm-12">
                                                            <input
                                                                style={{ marginTop: '10px', height: '2.8rem', fontSize: '16px', paddingLeft: '10px' }}
                                                                className=" report-date-field date-hover"
                                                                type="date"
                                                                id="startDate"
                                                                value={startDate}
                                                                onChange={handleStartDateChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2 div-item">
                                                        <div className="col-sm-12">End Date</div>
                                                        <div className="col-sm-12">
                                                            <input
                                                                style={{ marginTop: '10px', height: '2.8rem', fontSize: '16px', paddingLeft: '10px' }}
                                                                className=" report-date-field date-hover"
                                                                type="date"
                                                                id="endDate"
                                                                value={endDate}
                                                                onChange={handleEndDateChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* states */}
                                                    {(userRole === 'sensenadmin' || userRole === 'frs-reviewer') && (
                                                        <>
                                                            <div className="col-sm-2 div-item">
                                                                <div className="col-sm-12 mb-2">States</div>
                                                                <div className="col-sm-12 dropdown-container">
                                                                    <MaterialUIMultiSelectCheckmarks
                                                                        placeholder="States"
                                                                        width={180}
                                                                        height={35}
                                                                        dropdownWidth={180}
                                                                        label="Select a State"
                                                                        dropdownMaxWidth={180}
                                                                        id="States"
                                                                        value={selectedStates}
                                                                        onChange={(selectedValues) => handleSelection(selectedValues, states_data, setSelectedStates)}
                                                                        options={states_data}
                                                                    />

                                                                </div>
                                                            </div>
                                                            {/* brands */}
                                                            <div className="col-sm-2 div-item">
                                                                <div className="col-sm-12 mb-2">Brands</div>
                                                                <div className="col-sm-12 dropdown-container">
                                                                    <MaterialUIMultiSelectCheckmarks
                                                                        placeholder="Brands"
                                                                        width={180}
                                                                        height={35}
                                                                        dropdownWidth={180}
                                                                        label="Select a Brand"
                                                                        dropdownMaxWidth={180}
                                                                        id="Brands"
                                                                        value={selectedBrands}
                                                                        onChange={(selectedValues) => handleSelection(selectedValues, brands_data, setSelectedBrands)}
                                                                        options={brands_data}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="col-sm-2 div-item">
                                                        <div className="col-sm-12 mb-2">Sites</div>
                                                        <div className="col-sm-12 dropdown-container">
                                                            <MaterialUIMultiSelectCheckmarks
                                                                placeholder="Sites"
                                                                width={180}
                                                                height={35}
                                                                dropdownWidth={180}
                                                                label="Select a Site"
                                                                dropdownMaxWidth={180}
                                                                id="sites"
                                                                value={selectedSite}
                                                                onChange={(selectedValues) => handleSelection(selectedValues, sites, setSelectedSite)}
                                                                options={sites}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* product */}
                                                    {(userRole === 'sensenadmin' || userRole === 'frs-reviewer') && (
                                                        <div className="col-sm-2 div-item">
                                                            <div className="col-sm-12 mb-2">Product</div>
                                                            <div className="col-sm-12 dropdown-container">
                                                                <MaterialUIMultiSelectCheckmarks
                                                                    placeholder="Products"
                                                                    width={180}
                                                                    height={35}
                                                                    dropdownWidth={180}
                                                                    label="Select a Product"
                                                                    dropdownMaxWidth={180}
                                                                    id="Products"
                                                                    value={selectedProducts}
                                                                    onChange={(selectedValues) => handleSelection(selectedValues, products_data, setSelectedProducts)}
                                                                    options={products_data}
                                                                />

                                                            </div>
                                                        </div>)}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 ps-4">
                                        <span
                                            className={`${hasPaymentModes ? "action-btns" : "disable-btns"
                                                } row p-0`}
                                            onClick={hasPaymentModes ? openReportTypeModal : null} // Conditionally call the function
                                        >
                                            <div className="d-flex ps-0">
                                                <span className="pe-1"> <PrintIcon style={{ fontSize: "18px" }} /> </span> <span>Print-friendly PDF</span>
                                            </div>
                                        </span>

                                        <span
                                            className={`${hasPaymentModes ? "action-btns" : "disable-btns"
                                                } row p-0`}
                                        >
                                            {hasPaymentModes ? <CSVDownloadButton data={newdata} reportName={'DebtRecovered'} /> : <div className="d-flex ps-0 disable-btns">
                                                <span className="pe-1"> <LaunchIcon style={{ fontSize: "18px" }} /> </span> <span>Export as CSV</span>
                                            </div>}
                                        </span>
                                        <span
                                            className={`${hasPaymentModes ? "action-btns" : "disable-btns"
                                                } row p-0`} onClick={hasPaymentModes ? openEmailModal : null}
                                        >
                                            <div className="d-flex ps-0">
                                                <span className="pe-1"> <EmailIcon style={{ fontSize: "18px" }} /> </span> <span>Email report</span>
                                            </div>
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                : <div id="pdf-content">
                    {showResult === true && data && hasPaymentModes && (
                        <div style={{ backgroundColor: '#F4F4F4' }}>
                            <div className="accordion accordion-flush container-max-width-reports-page" >
                                <div className="accordion-item">
                                    <h2 className="accordion-header" style={{ backgroundColor: '#F4F4F4' }} id="flush-headingStats">
                                        <div className="accordion-button ps-0 pe-0 collapsed row d-flex" type="button"
                                            style={{ backgroundColor: '#F4F4F4' }}
                                            data-bs-toggle="collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                            <div className="col-8 ps-0 counts-heading text-start" style={{ color: '#6f7172' }}>${(data && data.outstandingIncidents && data.recoveredIncidents.recoveredAmount !== 0) ? data.recoveredIncidents.recoveredAmount.toFixed(2) : '0.00'} outstanding from {(data && data.recoveredIncidents
                                                ? (data.recoveredIncidents.numRecoveredDriveOffs || 0) + (data.recoveredIncidents.numRecoveredIOUs || 0)
                                                : 0
                                            )} incident
                                            </div>
                                            <div className="col-2 text-end">
                                                <div>
                                                    <div className="totals-value text-end" style={{ color: '#6f7172' }}>
                                                        ${(data && data.recoveredIncidents && data.recoveredIncidents.recoveredDriveOffAmount
                                                            ? data.recoveredIncidents.recoveredDriveOffAmount.toFixed(2)
                                                            : '0.00'
                                                        )} from {(data && data.recoveredIncidents && data.recoveredIncidents.numRecoveredDriveOffs
                                                            ? data.recoveredIncidents.numRecoveredDriveOffs
                                                            : 0
                                                        )}
                                                    </div>
                                                    <div className="totals-label text-end" style={{ color: '#b4b4b4' }}>Drive Offs</div>
                                                </div>
                                            </div>
                                            <div className="col-2 text-end">
                                                <div>
                                                    <div className="totals-value text-end" style={{ color: '#6f7172' }}>
                                                        ${(data && data.recoveredIncidents && data.recoveredIncidents.recoveredIOUAmount
                                                            ? data.recoveredIncidents.recoveredIOUAmount.toFixed(2)
                                                            : '0.00'
                                                        )} from {(data && data.recoveredIncidents && data.recoveredIncidents.numRecoveredIOUs
                                                            ? data.recoveredIncidents.numRecoveredIOUs
                                                            : 0
                                                        )}
                                                    </div>
                                                    <div className="totals-label text-end" style={{ color: '#b4b4b4' }}>IOUs</div>
                                                </div>
                                            </div>
                                        </div>
                                    </h2>
                                </div>

                            </div>
                            {/* chart data */}
                            <div className="container-max-width-reports-page">
                                <StackedBarChart chartData={chartData} className='report-bar-chart' />
                            </div>
                            <div className=" accordion accordion-flush container-max-width-reports-page" >
                                <div className="accordion-item">
                                    <h2 className="accordion-header" style={{ backgroundColor: '#F4F4F4' }} id="flush-headingStats">
                                        <div className="accordion-button ps-0 pe-0 collapsed row d-flex" type="button"
                                            style={{ backgroundColor: '#F4F4F4' }}
                                            data-bs-toggle="collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                            <div className="col-3 ps-0">
                                                <div className="counts-heading text-start" style={{ color: '#b4b4b4', padding: '0px' }}>Total</div>
                                            </div>
                                            <div className="col-2 text-end">
                                                <div>
                                                    <div className="totals-value text-end" style={{ color: '#6f7172' }}>
                                                        ${onSiteRecoveredAmount}
                                                    </div>
                                                    <div className="totals-label text-end" style={{ color: '#b4b4b4' }}><div className="summary-chart-legend-spot background-colour-chart-3"></div>Paid On Site</div>
                                                </div>
                                            </div>
                                            <div className="col-3 text-end">
                                                <div>
                                                    <div className="totals-value text-end" style={{ color: '#6f7172' }}>
                                                        ${onSiteLatePaymentRecoveredAmount}
                                                    </div>
                                                    <div className="totals-label text-end" style={{ color: '#b4b4b4' }}><div className="summary-chart-legend-spot background-colour-chart-5"></div>Paid On-Site Late Payment</div>
                                                </div>
                                            </div>
                                            <div className="col-2 text-end">
                                                <div>
                                                    <div className="totals-value text-end" style={{ color: '#6f7172' }}>
                                                        ${onlineRecoveredAmount}
                                                    </div>
                                                    <div className="totals-label text-end" style={{ color: '#b4b4b4' }}><div className="summary-chart-legend-spot background-colour-chart-4"></div>Paid Online
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 text-end">
                                                <div>
                                                    <div className="totals-value text-end" style={{ color: '#6f7172' }}>
                                                        ${onlineFRSRecoveredAmount}
                                                    </div>
                                                    <div className="totals-label text-end" style={{ color: '#b4b4b4' }}><div className="summary-chart-legend-spot background-colour-chart-2"></div>FRS Recovered
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </h2>
                                </div>

                            </div>
                        </div>
                    )}
                    <div className="row body-content">
                        <div className="row ps-0 pe-0 ">
                            <div className="col-sm-12 ps-0 pe-0 d-flex">

                                <div className="col-12 container-max-width-reports-page">
                                    {showResult === false ? (
                                        <div className="row">
                                            <div className="col-sm-12 text-center mt-4" style={{ fontSize: '20px', color: '#6f7172', paddingTop: "50px" }}>
                                                <p>At least one Site must be selected.</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {data && hasPaymentModes ?
                                                (<>
                                                    {hasPaymentModes && (
                                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                                            {Object.keys(paymentModesInfo).map((key) => {
                                                                const formattedKey = key.replace(/ /g, '_'); // Format the key for state access
                                                                return (
                                                                    <div key={formattedKey} className={`accordion-item ${accordionState[formattedKey] ? 'show' : ''}`}>
                                                                        <h2 className="accordion-header" id={`panelsStayOpen-heading-${formattedKey}`}>
                                                                            <div
                                                                                className="accordion-button  ps-0 pe-0 row d-flex"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={`#panelsStayOpen-collapse-${formattedKey}`}
                                                                                aria-expanded={accordionState[formattedKey]}
                                                                                aria-controls={`panelsStayOpen-collapse-${formattedKey}`}
                                                                                onClick={() => toggleAccordion(formattedKey)}
                                                                            >
                                                                                <div className="col-8 ps-0">
                                                                                    <div style={{ color: "#e27a13", fontWeight: "bolder", fontSize: "14px" }}>
                                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                                            {accordionState[formattedKey] ? <ExpandMoreRoundedIcon /> : <ChevronRightRoundedIcon />}
                                                                                            <span style={{ marginLeft: "10px" }}>{paymentModesInfo[key].count} {key}</span>
                                                                                        </div>
                                                                                        <span className="pt-1" style={{ fontSize: '12px', color: "#e27a13", marginLeft: "34px" }}>
                                                                                            {accordionState[formattedKey] ? 'Hide All' : 'Show All'}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-2 text-end">
                                                                                    <div>
                                                                                        <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                            ${(paymentModesInfo[key].recoveredAmount || 0).toFixed(2)}
                                                                                        </div>
                                                                                        <div className="totals-label" style={{ color: '#b4b4b4' }}>Recovered</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-2 pe-0 text-end">
                                                                                    <div>
                                                                                        <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                            ${(paymentModesInfo[key].reimbursedAmount || 0).toFixed(2)}
                                                                                        </div>
                                                                                        <div className="totals-label" style={{ color: '#b4b4b4' }}>Reimbursed</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </h2>
                                                                        <div className={`accordion-collapse collapse ${accordionState[formattedKey] ? 'show' : ''}`}>
                                                                            <CustomizedTable
                                                                                reportName={"debtOutstanding"}
                                                                                heading={['OCCURRED AT', 'LICENSE PLATE', 'TYPE', 'STATUS', 'SITE', 'PUMP', 'REFERENCE', 'VALUE']}
                                                                                data={newdata[key]}
                                                                            />
                                                                            <div className="row  d-flex me-4  mt-2 mb-2">
                                                                                <div className="col-7"></div>
                                                                                <div className="col-3 text-end">
                                                                                    <div>
                                                                                        <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                            ${(paymentModesInfo[key].recoveredAmount || 0).toFixed(2)}
                                                                                        </div>
                                                                                        <div className="totals-label" style={{ color: '#b4b4b4' }}>Recovered</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-2 ps-1 pe-0 text-end">
                                                                                    <div>
                                                                                        <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                            ${(paymentModesInfo[key].reimbursedAmount || 0).toFixed(2)}
                                                                                        </div>
                                                                                        <div className="totals-label" style={{ color: '#b4b4b4' }}>Reimbursed</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>

                                                    )}
                                                    <div className="accordion accordion-flush" >
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="flush-headingStats">
                                                                <div className="accordion-button ps-0 pe-0  collapsed row d-flex" type="button"

                                                                    data-bs-toggle="collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                    <div className="col-8 counts-heading" style={{ color: '#b4b4b4', padding: '0px' }}>Total</div>
                                                                    <div className="col-2 text-end">
                                                                        <div>
                                                                            <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                ${(data.recoveredIncidents.recoveredAmount || 0).toFixed(2)}
                                                                            </div>
                                                                            <div className="totals-label" style={{ color: '#b4b4b4' }}>Recovered</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2 pe-0 text-end">
                                                                        <div>
                                                                            <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                ${(data.outstandingIncidents.recoveredAmount || 0).toFixed(2)}
                                                                            </div>
                                                                            <div className="totals-label" style={{ color: '#b4b4b4' }}>Reimbursed</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </h2>
                                                        </div>

                                                    </div>
                                                    <div className="accordion accordion-flush" >
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="flush-headingStats">
                                                                <div className="accordion-button ps-0 pe-0  collapsed row d-flex" type="button"

                                                                    data-bs-toggle="collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                    <div className="col-5 counts-heading" style={{ color: '#b4b4b4', padding: '0px' }}>Reimbursements
                                                                    </div>
                                                                    <div className="col-3 text-end">
                                                                        <div>
                                                                            <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                ${(data.recoveredIncidents.recoveredAmount || 0).toFixed(2)}
                                                                            </div>
                                                                            <div className="totals-label" style={{ color: '#b4b4b4' }}>Total for Reimbursement
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2 text-end">
                                                                        <div>
                                                                            <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                ${(data ? data.recoveredIncidents.recoveredAmount : 0).toFixed(2)}
                                                                            </div>
                                                                            <div className="totals-label" style={{ color: '#b4b4b4' }}>Reimbursed</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2 pe-0 text-end">
                                                                        <div>
                                                                            <div className="totals-value" style={{ color: '#6f7172' }}>
                                                                                ${(data.outstandingIncidents.outstandingAmount || 0).toFixed(2)}
                                                                            </div>
                                                                            <div className="totals-label" style={{ color: '#b4b4b4' }}>Balance for Reimbursement
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </h2>
                                                        </div>

                                                    </div>
                                                </>)
                                                : (
                                                    <div className="row">
                                                        <div className="col-sm-12 text-center mt-4" style={{ fontSize: '20px', color: '#6f7172', paddingTop: "50px" }}>
                                                            <p>There are no incidents which match your search criteria.</p>
                                                        </div>
                                                    </div>
                                                )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    );
};
export default AgentExport;