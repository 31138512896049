import React from "react";
import userSession from "../../Constants/auth";
import vehicleBg from "../../Assets/plate_outline_light_290x80.png";

const InfiniteScrollWidget = ({ sortedMostVisitedVehicles }) => {
  console.log({ sortedMostVisitedVehicles });

  return (
    <div className="bg-[#aa5c0e] p-3 w-[300px] h-[320px] rounded-lg flex flex-col">
      <div className="flex justify-between text-white font-semibold pb-2 border-b-2 border-[#bf854a]">
        <span className="text-lg">Top vehicles</span>
        <span className="text-sm">Visits</span>
      </div>
      <div className="overflow-y-auto flex-grow pr-1 scrollbar-thin scrollbar-thumb-[#bf854a] scrollbar-track-transparent">
        {sortedMostVisitedVehicles?.map((vehicle, index) => (
          <div
            key={index}
            className="flex items-center h-14  rounded overflow-hidden"
          >
            <div className="flex-grow h-full relative ">
              <div
                className="absolute inset-0 bg-no-repeat bg-left bg-contain text-[#bf854a] opacity-50"
                style={{ backgroundImage: `url(${vehicleBg})` }}
              ></div>
              <div className="h-full flex items-center justify-center z-10 relative pl-2">
                <span className="text-white text-base font-bold">
                  {vehicle.vehicle}
                </span>
              </div>
            </div>
            <div className="w-24 h-full flex items-center justify-end pr-2">
              <span className="text-white text-base font-bold border-2  border-[#bf854a] rounded-full w-12 h-12 flex items-center justify-center">
                {vehicle.count}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfiniteScrollWidget;
