import React from "react";
const inputTagStyle = {
  height: "34px",
  fontSize: "14px",
  padding: "6px 12px",
  lineHeight: "1.42857143",
  color: "#555",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  boxShadow: "inset 0 1px 1px rgba(0, 0, 0, .075)",
};
const InputField = ({ label, name, value, onChange, type = "text", required = false }) => (
  <div className="col-12 d-flex justify-content-between ps-0">
    <div className="col-3 col-3-right" style={label === 'Name' || label === 'Phone Number' || label === 'Address' ? { textAlign: 'end' } : {}}>{label}:</div>
    <div className="col-8 text-left">
      <div>
        <input
          style={inputTagStyle}
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className="col-6 w-100"
          required={required}
        />
      </div>
    </div>
  </div>
);

export default InputField;