import React from 'react';

const ProgressChart = ({ percentage }) => {
  // Calculate the angle based on the percentage
  const angle = (percentage / 100) * 360;

  // Calculate the (x, y) coordinates for the path
  const x = Math.cos((angle - 90) * (Math.PI / 180)) * 32.5 + 35;
  const y = Math.sin((angle - 90) * (Math.PI / 180)) * 32.5 + 35;

  return (
    <svg width="70" height="70">
      <circle
        cx="35"
        cy="35"
        r="32.5"
        stroke="#aa5c0e"
        strokeWidth="3"
        fill="none"
      />
      <path
        d={`M 35 2.5 A 32.5 32.5 0 ${angle > 180 ? 1 : 0} 1 ${x} ${y}`}
        stroke="#fff" // Set your desired color here
        strokeWidth="3"
        fill="none"
      />
      <text x="35" y="35" textAnchor="middle" dominantBaseline="central" fill="#fff">
        {percentage}%
      </text>
    </svg>
  );
};

export default ProgressChart;
