import { useState } from "react";
import { APP_SERVICES_URL } from "../Constants/constants";

const useEmployeeApi = ({ CustID, site, authHeader }) => {
  const [error, setError] = useState(null);
  const getEmployees = async (siteNamesList) => {
    try {
      const url = `${APP_SERVICES_URL}/senapi/${CustID}/employees?siteNames=${siteNamesList}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: authHeader,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch employee data.");
      }

      const employees = await response.json();
      const employeesWithId = employees.map((employee, index) => ({
        ...employee,
        id: index + 1,
      }));
      return employeesWithId;

    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
    return { getEmployees, error };
  };
  const addEmployee = async (siteName,employeeName) => {
    try {
      const url = `${APP_SERVICES_URL}/senapi/${CustID}/employee?siteName=${siteName}&employeeName=${employeeName}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: authHeader,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to add employee.");
      }
      return true;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const updateEmployee = async (updatedData) => {
    try {
      const url = `${APP_SERVICES_URL}/senapi/${CustID}/employee?siteName=${site.name}`;

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: authHeader,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData), // Pass the updated data in the request body
      });

      if (!response.ok) {
        throw new Error("Failed to update employee.");
      }

      // Optionally, you can return the updated employee data if needed
      return true;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const deleteEmployee = async (employeeId) => {
    try {
      const url = `${APP_SERVICES_URL}/senapi/${CustID}/employee?siteName=${site.name}&employeeName=${employeeId}`;

      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: authHeader,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete employee.");
      }

      // Optionally, you can return a success message or status if needed
      return true;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return { addEmployee, updateEmployee, deleteEmployee, getEmployees };
};

export default useEmployeeApi;