export const DRIVE_OFF = "Drive Off";
export const IOU = "IOU";
export const BASE_URL = process.env.REACT_APP_BASE_URL  || "";
export const APP_SERVICES_URL = process.env.REACT_APP_SERVICES_URL || "";
export const cancelReasonsAdmin = [
  "User Error",
  "Jerry Can",
  "Motorbike",
  "Stolen Plate",
  "Tampered Plate",
  "Stolen Vehicle",
  "Licence Unclear",
  "No Licence Plate",
  "Driver Nomination",
  "Pay at Pump Error",
  "Ownership Changed",
  "Stolen Plate Recovered",
  "Stolen Vehicle Recovered",
  "Test Incident for Training",
  "Unable to Recover - (Unregistered Plate)",
  "Other",
];

export const cancelReasonsManager = [
  "Jerry Can",
  "User Error",
  "Motorbike",
  "Tampered Plate",
  "Licence Unclear",
  "No Licence Plate",
  "Test Incident for Training",
  "Pay at Pump Error",
  "Other",
];
