import { Link, useLocation } from "react-router-dom";
import style2 from "../../CSS/ManageEmployees.module.css";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import styles from "../../CSS/Base/Basic.module.css";
import useEmployeeApi from '../../Custom Hooks/useEmployeeApi';
import EmployeeModals from "../../Modals/EmployeeModals"
import ErrorModal from "../../Modals/ErrorModal";
import userSession from "../../Constants/auth"
import SimpleBackdrop from "../../Components/Base/SimpleBackdrop";

const ManageEmployees = () => {
  const ErrorModalHeading = 'Employee Details Fetch Error';
  const location = useLocation();
  const { authHeader, CustID, site, sites } = userSession;
  const getSitesNames = (sites) => {
    if (!sites || !Array.isArray(sites)) {
      return [];
    }
    return sites.map((site) => site.name);
  };
  const siteNames = getSitesNames(sites);
  const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
  const ctaBackgroudColor = userSession.themeData?.ctaBackgroudColor || '#e27a13';
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [isNameValid, setIsNameValid] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [loading, setLoading] = useState(false);
  const [editedStatus, setEditedStatus] = useState(false);
  const [newEmployeeName, setNewEmployeeName] = useState("");
  const [showErrorText, setShowErrorText] = useState(false);
  const [isSiteValid, setIsSiteValid] = useState(true);
  const [activeHeading, setActiveHeading] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [originalName, setOriginalName] = useState("");
  // eslint-disable-next-line
  const { addEmployee, updateEmployee, deleteEmployee, getEmployees } = useEmployeeApi({
    CustID,
    site,
    authHeader,
  });
  // Define the styles
  const activeHeadingStyle = {
    color: textAccentColor, // Red color
    borderBottom: `2px solid ${textAccentColor}` // Red border bottom
  };

  const inactiveHeadingStyle = {
    color: '#6F7172', // Black color
    borderBottom: '2px solid #000000' // Black border bottom
  };
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        setLoading(true);
        setError(false);
        const employees = await getEmployees(siteNames);
        setEmployeeDetails(Array.isArray(employees) ? employees : []);
      } catch (error) {
        console.error('Error fetching employee data:', error); // Add this line
        setError(true);
        setErrorMessage('Error fetching employee details. Please try again. If the issue persists, contact support.');
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeData();
  }, []);






  useEffect(() => {
    const pathname = location.pathname;
    setActiveHeading(pathname);
  }, [location]);


  const handleEdit = (id, originalName) => {
    const selectedData = employeeDetails.find((item) => item.id === id);
    setSelectedId(id);
    setEditedName(selectedData.name);
    setEditedStatus(selectedData.active);
    setShowModal(true);
    setOriginalName(originalName);
  };

  const handleSave = async () => {
    if (!editedName.trim()) {
      setIsNameValid(false);
      return;
    } else {
      setIsNameValid(true);
    }

    // Create the requestBody with the edited details
    const requestBody = {
      name: originalName,
      active: editedStatus,
      newName: editedName, // Assuming you want to update the name with the same value
    };

    // Call the updateEmployee API function to update the employee
    const isUpdated = await updateEmployee(requestBody);

    if (isUpdated) {
      // Update the state with the updated employee
      const updatedData = employeeDetails.map((item) => {
        if (item.id === selectedId) {
          return { ...item, name: editedName, active: editedStatus };
        }
        return item;
      });
      setEmployeeDetails(updatedData);
      setShowModal(false);
      setSelectedId(null);
      setEditedName("");
      setEditedStatus(false);
    } else {
      // Handle error if needed
    }
  };


  const handleAddEmployee = () => {
    setNewEmployeeName("");
    setShowAddEmployeeModal(true);
  };

  const handleSaveNewEmployee = async () => {
    if (!newEmployeeName.trim()) {
      setShowErrorText(true);
      console.log(showErrorText);
      return;
    } else {
      setShowErrorText(false);
      console.log(showErrorText);
    }
    if (!selectedSite.trim()) {
      setIsSiteValid(false);
      return;
    } else {
      setIsSiteValid(true);
    }

    // Call the addEmployee function to add the new employee
    const success = await addEmployee(selectedSite, newEmployeeName);

    if (success) {
      const newEmployee = {
        id: employeeDetails.length + 1,
        name: newEmployeeName,
        active: true,
        siteName: selectedSite
      };

      setEmployeeDetails([...employeeDetails, newEmployee]);
      setShowAddEmployeeModal(false);
      setNewEmployeeName("");
    } else {
      // Handle error if needed
    }
  };

  const getUniqueSiteNames = () => {
    if (Array.isArray(employeeDetails)) {
      return [...new Set(employeeDetails.map((item) => item.siteName))];
    } else {
      return [];
    }
  };



  const handleRowMouseEnter = () => {
    setIsHovered(true);
  };

  const handleRowMouseLeave = () => {
    setIsHovered(false);
  };

  const totalActiveUsers = Array.isArray(employeeDetails)
    ? employeeDetails.filter((data) => data.active === true).length
    : 0; // Default to 0 if employeeDetails is not an array



  const handleCloseErrorModal = () => {
    setError(false);
  };
  return (
    <div className={styles.PageMainBoxOther}>
      <SimpleBackdrop open={loading} onClose={() => setLoading(false)} />
      {error && <ErrorModal show={true} onClose={handleCloseErrorModal} message={errorMessage} heading={ErrorModalHeading} />}
      <div className={style2.ManageEmployeesTitleSection}>
        <div
          className={`container-fluid`}
        >
          <div className={`row ${style2.managePageRow} ${style2.titleRow}`}>
            <div className={style2.managePagecolSm12}>
              <ul className="nav">
                <li className="Navlinks">
                  <Link
                    className={`${style2.manageEmployeesHeading} ${activeHeading === "/scancam/ManageEmployees"
                      ? ""
                      : ""
                      }`}
                    to="/scancam/ManageEmployees"
                    style={activeHeading === "/scancam/ManageEmployees" ? activeHeadingStyle : inactiveHeadingStyle}
                  >
                    Manage Employees
                  </Link>
                </li>
                <li className="Navlinks">
                  <Link
                    className={`${style2.manageEmployeesHeading} ${activeHeading === "/scancam/OrderFrsCards"
                      ? style2.activeHeading
                      : ""
                      }`}
                    to="/scancam/OrderFrsCards"
                  >
                    Order FRS Cards
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container-fluid">
          <div className={`row ${style2.managePageRow}`} >
            <div className="col-md-9 col-sm-8 ps-0 pe-0">
              <div id={style2.employeeCount} className={styles.brandColorText} style={{ color: textAccentColor }}>
                {totalActiveUsers} active employees
              </div>
            </div>
            <div className="col-md-3 col-sm-4 pe-0">
              <div>
                <button
                  className={styles.Button}
                  id={style2.employeesAddButton}
                  onClick={handleAddEmployee}
                  style={{ backgroundColor: ctaBackgroudColor }}
                >
                  Add an employee <span className={style2.positiveSymbol}>+</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style2.employeesListTableContainer}>
        <Table>
          <thead>
            <tr className={style2.employeesListTableContainerHeading} >
              <th>Name</th>
              {getUniqueSiteNames().length > 0 && <th>Site</th>}
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className={style2.employeesListTableContainerBody}>
            {Array.isArray(employeeDetails) ? (
              employeeDetails.length > 0 ? (
                employeeDetails.map((item) => (
                  <tr
                    key={item.id}
                    className={style2.employeesListRow}
                    onClick={() => handleEdit(item.id, item.name)}
                    onMouseEnter={handleRowMouseEnter}
                    onMouseLeave={handleRowMouseLeave}
                  >
                    <td className={style2.employeesListRowData}>{item.name}</td>
                    {getUniqueSiteNames().length > 0 && (
                      <td className={style2.employeesListRowData}>
                        {item.siteName}
                      </td>
                    )}
                    <td
                      className={style2.employeesListRowData}
                      style={{ color: item.active ? textAccentColor : "#b4b4b4" }}
                    >
                      {item.active ? "Active" : "Inactive"}
                    </td>
                    <td
                      className={`${style2.employeesListRowData} ${style2.smallColumn}`}
                    >
                      <span className={style2.manageEmployeeEditIcon}>
                        <MdEdit
                          className={isHovered ? style2.activeEditIcon : ""}
                        />
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No employee data available</td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={4}>Loading...</td>
              </tr>
            )}
          </tbody>

        </Table>
        <EmployeeModals
          showModal={showModal}
          setShowModal={setShowModal}

          editedName={editedName}
          setEditedName={setEditedName}
          editedStatus={editedStatus}
          setEditedStatus={setEditedStatus}
          handleSave={handleSave}
          isNameValid={isNameValid}
          setShowErrorText={setShowErrorText}
          showErrorText={showErrorText}
          newEmployeeName={newEmployeeName}
          setNewEmployeeName={setNewEmployeeName}
          handleSaveNewEmployee={handleSaveNewEmployee}
          setShowAddEmployeeModal={setShowAddEmployeeModal}
          showAddEmployeeModal={showAddEmployeeModal}
          setSelectedSite={setSelectedSite}
          selectedSite={selectedSite}
          setIsSiteValid={setIsSiteValid}
          isSiteValid={isSiteValid}
        />
      </div>
    </div>
  );
};
export default ManageEmployees;