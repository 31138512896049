import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import "../../CSS/incidetDetails.css";
import Details from "./Details";
import Notes from "./Notes";
import Records from "./Records";
import LatePayment from "./LatePayment";
import SendPaymentLink from "./SendPaymentLink";
import ReturnToSite from "./ReturnToSite.js";
import styles from "../../CSS/Base/Basic.module.css";
import useFetchIncidents from "../../Custom Hooks/useFetchIncidents";
import ImageComponent from "./ImageComponent";
import MediaUrlFetcher from "./MediaUrlFetcher";
import userSession from "../../Constants/auth";
import { APP_SERVICES_URL, IOU } from "../../Constants/constants";
import FeedbackModal from "../../Modals/FeedbackModal";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import WaiveFees from "./WaiveFees.js";
import SimpleBackdrop from "../Base/SimpleBackdrop.js";
import DeleteVideoModal from "../../Modals/DeleteVideoModal.js";
import ErrorModal from "../../Modals/ErrorModal";
import VideoSlider from "./VideoSlider.js";
import VideoUploadModal from "../../Modals/VideoUploadModal.js";
import IncidentDetailsTopSection from "../incidentDetails/IncidentDetailsTopSection.js";

function IncidentDetails() {
  const [videos, setVideos] = useState([]);
  const [videoFrameId, setVideoFrameId] = useState(null);
  const ErrorModalHeading = "Task Failed";
  const [errorMessage, setErrorMessage] = useState(
    "An error occurred while performing the task. Please try again. If the issue persists, contact support"
  );
  const [error, setError] = useState(false);
  const [incidentLoading, setIncidentLoading] = useState(false);
  const [helpSent, setHelpSent] = useState(false);
  const [incidentDetails, setIncidentDetails] = useState([]);
  const [openDeleteVideModal, setOpenDeleteVideModal] = useState(false);
  const incidentDetailHeadingClasses =
    "col-sm-2 incident-detail-overview-heading";
  const incidentDetailValueClasses = "col-sm-2 incident-detail-overview-value";
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const navigate = useNavigate();
  const [activeLinkBox1, setActiveLinkBox1] = useState("Video");
  const [activeLinkBox2, setActiveLinkBox2] = useState("details");
  const [videoUploading, setVideoUploading] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const [imageApiResponse, setImageApiResponse] = useState(null);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [isErrorVideo, setIsErrorVideo] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isErrorImage, setIsErrorImage] = useState(false);
  const { pkAlert } = useParams();
  const [showModal, setShowModal] = useState(false);

  const handleCloseErrorModal = () => {
    setError(false);
  };

  //function to open close incident modal
  const handleOpenDeleteVideoModal = (id) => {
    // Toggle the value of openCloseIncidentModal
    setOpenDeleteVideModal(true);
    setVideoFrameId(id);
  };

  const handleCloseDeleteVideoModal = () => {
    // Toggle the value of openCloseIncidentModal
    setOpenDeleteVideModal(false);
  };

  const { CustID, authHeader, sites, userRole } = userSession;

  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const payloadData = {
    toReviewStatuses: true,
    sites: sites.name,
  };
  const activeStyle = {
    borderBottom:
      "2px solid " + (textAccentColor ? textAccentColor : "#e27a13"),
  };
  const inActiveStyle = {
    borderBottom: "none",
  };
  const activeTextColor = {
    color: textAccentColor ? textAccentColor : "#e27a13",
  };
  const inActiveTextColor = {
    color: "#6f7172",
  };
  const checkMediaUrls = (fetchFrames) => {
    if (fetchFrames && fetchFrames.frames && fetchFrames.frames.length > 0) {
      const videosArray = []; // Initialize an empty array
      let nvrEvidenceVideoFound = false; // Flag to track if nvr-evidence-video is found
      let currentId = 2; // Initialize the currentId counter with 2

      // Loop through each frame
      fetchFrames.frames.forEach((frame) => {
        if (
          frame.type === "evidence-video" ||
          frame.type === "nvr-evidence-video"
        ) {
          // Create a new video object based on the frame data
          let video;
          if (frame.type === "nvr-evidence-video" && !nvrEvidenceVideoFound) {
            // If nvr-evidence-video is encountered and not already found, update the existing record with id: 1
            video = {
              id: 1,
              url: frame.url,
              key: frame.type,
              frameID: frame.id,
              created: frame.created,
              deleteVideoLoading: false,
            };
            nvrEvidenceVideoFound = true; // Update flag to indicate nvr-evidence-video is found
          } else if (frame.type === "evidence-video") {
            // For evidence-video frames, assign incremental IDs
            video = {
              id: currentId++,
              url: frame.url,
              key: frame.type,
              frameID: frame.id,
              created: frame.created,
              deleteVideoLoading: false,
            };
          }

          // Push the video object to the videosArray if it's not undefined
          if (video) {
            videosArray.push(video);
          }
        }
      });

      setVideos(videosArray);
      setUrlsFromFrames(videosArray); // Pass videosArray to setUrlsFromFrames
    } else {
      setVideoUrl(false);
      setImageSrc(false);
      setIsLoadingVideo(false);
      setIsErrorVideo(false);
    }
  };

  const { incidents } = useFetchIncidents(payloadData);
  const index = incidents.findIndex(({ alertID }) => alertID === pkAlert);

  // fetching data to show incident details
  useEffect(() => {
    const fetchData = async () => {
      setError(false);
      try {
        setIncidentLoading(true);
        const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident?alertID=${pkAlert}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: authHeader,
          },
        });

        if (!response.ok) {
          setIncidentLoading(false);
          // setError(true);
          // setErrorMessage(
          //   "An error occurred while fetching incident details. Please try again. If the issue persists, contact support"
          // );
          throw new Error("Failed to fetch incident details.");
        }

        const jsonData = await response.json();

        setVideos([]);
        setIncidentDetails(jsonData);
        setIncidentLoading(false);
        checkMediaUrls(jsonData);
        // Find and fetch image data for idFrame
        const idFrame = jsonData.frames.find(
          (frame) => frame.type === "id" || frame.type === "customer"
        );
        if (idFrame && idFrame.url) {
          fetchImageData(idFrame.url);
          setImageSrc(true);
        }
        setError(false);
      } catch (error) {
        // setError(true);
        // setErrorMessage(
        //   "An error occurred while fetching incident details. Please try again. If the issue persists, contact support"
        // );
        setIncidentLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [pkAlert, isUploaded]);

  const setUrlsFromFrames = (framesData) => {
    let hasEvidenceVideo = false;
    let hasNvrEvidenceVideo = false;
    // Loop through all videos in the state
    videos.forEach((video) => {
      // Log the current video
      // console.log("Processing video:", video);

      if (
        !video.url &&
        (video.key === "evidence-video" || video.key === "nvr-evidence-video")
      ) {
        // Log that URL is not present for the current video
        // console.log("URL is not present for video:", video);

        if (video.key === "evidence-video") {
          hasEvidenceVideo = true;
        } else if (video.key === "nvr-evidence-video") {
          hasNvrEvidenceVideo = true;
        }
        fetchVideoData(video.url, video.id);
      }
    });

    // Check if at least one evidence video or nvr evidence video is found
    if (hasEvidenceVideo || hasNvrEvidenceVideo) {
      setVideoUrl(true);
    } else {
      setVideoUrl(false);
    }
  };

  const { fetchVideoUrl, fetchImageUrl } = MediaUrlFetcher();

  // Call the MediaUrlFetcher function to get the fetch functions
  // const { incidents } = useFetchIncidents(payloadData);
  // const index = incidents.findIndex(({ alertID }) => alertID === pkAlert);
  // const [disablePrevious, setDisablePrevious] = useState(false);

  const fetchVideoData = async (videoUrl, id) => {
    setIsLoadingVideo(true);
    setError(false);

    try {
      const videoUrlResponse = await fetchVideoUrl(videoUrl);
      if (videoUrlResponse) {
        // Update the video URL response for the video with the given frameID
        setVideos((prevVideos) => {
          const updatedVideos = prevVideos.map((video) => {
            if (video.id === id) {
              return { ...video, url: videoUrlResponse };
            }
            return video;
          });
          return updatedVideos;
        });
        setIsErrorVideo(false);
        setError(false);
      } else {
        setIsErrorVideo(true);
        setError(true);
        setErrorMessage(
          "An error occurred while fetching incident video. Please try again. If the issue persists, contact support"
        );
      }
    } catch (error) {
      setIsErrorVideo(true);
      setError(true);
      setErrorMessage(
        "An error occurred while fetching incident video. Please try again. If the issue persists, contact support"
      );
    }

    setIsLoadingVideo(false);
  };

  const fetchImageData = async (imageSrc) => {
    setIsLoadingImage(true);
    setError(false);
    if (authHeader && imageSrc) {
      const imageApiResponse = await fetchImageUrl(imageSrc);
      if (imageApiResponse) {
        setImageApiResponse(imageApiResponse);
        setError(false);
      } else {
        setError(true);
        setErrorMessage(
          "An error occurred while fetching incident image. Please try again. If the issue persists, contact support"
        );
        setIsErrorImage(true);
      }
    }
    setIsLoadingImage(false);
  };

  const handleLinkClickBox1 = (link) => {
    setActiveLinkBox1(link);
  };

  const handleLinkClickBox2 = (link) => {
    setActiveLinkBox2(link);
  };
  const handleNavigateNext = () => {
    handleNavigate("next");
  };

  const handleNavigatePrevious = () => {
    handleNavigate("previous");
  };

  const handleNavigate = (direction) => {
    if (incidents.length > 0) {
      const index = incidents.findIndex(
        (incident) => incident.alertID === pkAlert
      );
      let nextIndex;
      if (direction === "next") {
        nextIndex = (index + 1) % incidents.length;
      } else if (direction === "previous") {
        nextIndex = (index - 1 + incidents.length) % incidents.length;
      }
      const nextPkAlert = incidents[nextIndex].alertID;
      navigate(`/scancam/incident/${nextPkAlert}`);
      setActiveLinkBox1("Video");
      setActiveLinkBox2("details");
      // window.location.reload();
    }
  };

  const handleVideoUpload = async () => {
    // Set videoLoading to true before initiating the video upload
    setVideoUploading(true);
    setError(false);
    if (selectedVideo) {
      const fileType = "evidence-video";
      const formData = new FormData();
      formData.append("file", selectedVideo);

      try {
        // eslint-disable-next-line
        const response = await fetch(
          `/sn-events-service/${CustID}/file?eventID=${incidentDetails.eventID}&type=${fileType}`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: authHeader,
            },
          }
        );
        setSelectedVideo(null);
        setIsUploaded(true);
        setError(false);
        setActiveLinkBox1("Video");
        setVideoUploading(false);
        // Handle success if needed
        setShowModal(false);
        setTimeout(() => {
          setSelectedVideo(null);
          setIsUploaded(false);
        }, 2000);
      } catch (error) {
        // Handle upload success if needed
        setError(true);
        setVideoUploading(false);
        setShowModal(false);
        setErrorMessage(
          "An error occurred while uploadng Video. Please try again. If the issue persists, contact support"
        );
        setActiveLinkBox1("Video");
        // Set videoUploaded to false after 2 seconds
        setTimeout(() => {
          setSelectedVideo(null);
          setIsUploaded(false);
        }, 2000);
      } finally {
        // Set videoLoading to false after the video upload completes (regardless of success or failure)
        setVideoUploading(false);
        setShowModal(false);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = [
      "video/mp4",
      "video/mpeg",
      "video/quicktime",
      "video/x-msvideo",
      "video/x-ms-wmv",
      "video/x-flv",
    ];
    if (!allowedTypes.includes(file.type)) {
      setError(true);
      setErrorMessage(
        "Please upload a video file only. Supported formats include: MP4, MPEG, QuickTime, AVI, WMV, FLV."
      );
      return;
    }

    if (videos.length >= 5) {
      setError(true);
      setErrorMessage(
        "You can upload a maximum of 5 videos. Please delete a video and try again."
      );
      return;
    }
    const maxFileSize = 500 * 1024 * 1024; // 500 MB
    if (file.size > maxFileSize) {
      setError(true);
      setErrorMessage("Please upload a video smaller than 500 MB.");
      return;
    }

    setSelectedVideo(file);
    setError(false);
    setErrorMessage("");
  };
  function sortAndFilterVideos(videos) {
    // 1. Sort videos by created date and time in ascending order (oldest first)
    const sortedVideos = videos.slice().sort((a, b) => {
      const dateA = new Date(a.created);
      const dateB = new Date(b.created);

      // Compare dates first
      if (dateA < dateB) return -1; // Oldest first
      if (dateA > dateB) return 1;

      // If dates are equal, compare times directly (already in milliseconds)
      return dateA.getTime() - dateB.getTime(); // Ascending order (oldest first)
    });

    // 2. Find the index of the first "nvr-evidence-video" (if it exists)
    const nvrIndex = sortedVideos.findIndex(
      (video) => video.key === "nvr-evidence-video"
    );

    // 3. If "nvr-evidence-video" is found, move it to the first index
    if (nvrIndex > 0) {
      const nvrVideo = sortedVideos.splice(nvrIndex, 1)[0];
      sortedVideos.unshift(nvrVideo);
    }

    return sortedVideos;
  }
  const sortedVideos = sortAndFilterVideos(videos);
  const activeLeftComponentMap = {
    Video: (
      <VideoSlider
        videos={sortedVideos}
        textAccentColor={textAccentColor}
        handleOpenDeleteVideoModal={handleOpenDeleteVideoModal}
      />
    ),
    EvidenceImage: (
      <ImageComponent
        imageApiResponse={imageApiResponse}
        imageSrc={imageSrc}
        isLoadingImage={isLoadingImage}
        isErrorImage={isErrorImage}
      />
    ),
    UploadVideo: <div className={styles.incidentUploadVideoBox}></div>,
  };

  const activeLeftComponent = activeLeftComponentMap[activeLinkBox1] || (
    <div className="container left-side-box d-flex justify-content-center align-items-center">
      <div className="video-show-box">
        {videoUrl ? (
          <video className="uploaded-video-box" src={videoUrl} controls />
        ) : (
          <p
            style={{
              color: textAccentColor,
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            No video available
          </p>
        )}
      </div>
    </div>
  );

  // useEffect to update sharedData whenever incidentDetails changes
  useEffect(() => {
    setSharedData(incidentDetails);
  }, [incidentDetails]); // Trigger useEffect when incidentDetails changes

  const [sharedData, setSharedData] = useState(incidentDetails); // Example text string

  const activeRightComponentMap = {
    details: (
      <Details
        sharedData={sharedData}
        setSharedData={setSharedData}
        setIncidentDetails={setIncidentDetails}
        setActiveLinkBox2={setActiveLinkBox2}
      />
    ),
    notes: (
      <Notes sharedData={sharedData} setIncidentDetails={setIncidentDetails} />
    ),
    history: <Records sharedData={sharedData} />,
    latepayment: (
      <LatePayment
        sharedData={sharedData}
        setIncidentDetails={setIncidentDetails}
        setActiveLinkBox2={setActiveLinkBox2}
      />
    ),
    sendpaymentlink: (
      <SendPaymentLink
        setIncidentDetails={setIncidentDetails}
        setActiveLinkBox2={setActiveLinkBox2}
      />
    ),
    returnToSite: (
      <ReturnToSite
        setIncidentDetails={setIncidentDetails}
        setActiveLinkBox2={setActiveLinkBox2}
      />
    ),
    WaiveFees: (
      <WaiveFees
        sharedData={sharedData}
        setIncidentDetails={setIncidentDetails}
        setActiveLinkBox2={setActiveLinkBox2}
      />
    ),
  };

  const activeRightComponent = activeRightComponentMap[activeLinkBox2] || (
    <Details />
  );

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  function supportMail(subject, message) {
    setError(false);
    setHelpSent(true);
    // Check if message is null
    if (message === null) {
      // If message is null, don't call the API
      return;
    }

    // Set up the API endpoint and headers
    const endpoint = `${APP_SERVICES_URL}/senapi/${CustID}/supportMail`;

    const requestBody = {
      subject: subject,
      message: message,
    };

    fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) {
          setApiResponse(true);
          setFeedbackModal(true);
          setError(false);
          setHelpSent(false);
        } else {
          setApiResponse(false);
          setFeedbackModal(true);
          setError(true);
          setHelpSent(false);
        }
      })
      .catch((error) => {
        setApiResponse(false);
        setFeedbackModal(true);
        setError(true);
        setHelpSent(false);
      });
  }

  const openFeedBackModal = () => {
    setFeedbackModal(true);
  };

  const closeFeedBackModal = () => {
    setFeedbackModal(false);
    setApiResponse(null);
  };
  const handleReportTypeSelection = (subject, message) => {
    supportMail(subject, message);
  };

  const statusesToHidePaymentElement = [
    "Open",
    "Verified",
    "Returned To Site",
    "Recovery In-Progress",
    "Escalated",
    "Pre-Court",
    "Incomplete Transaction",
  ];
  const statusesToHideSendPaymentElement = [
    "Open",
    "Returned To Site",
    "Escalated",
    "Pre-Court",
    "Verified",
  ];

  const statusesToHideReturnToSiteElement = [
    "Escalated",
    "Verified",
    "Pre-Court",
    "Incomplete Transaction",
    "Cancelled",
  ];

  const isReviewer =
    userRole === "frs-reviewer" ||
    userRole === "sensenadmin" ||
    userRole === "customeradmin";
  const isMoreThan4Days = (dateTimeString) => {
    const utcDateTime = moment.utc(dateTimeString); // Parse the UTC date-time string
    const localDateTime = utcDateTime.local(); // Convert to local time zone of browser
    const currentLocalDateTime = moment(); // Get current date-time in local time zone of browser

    // Check if the difference in days is greater than 4
    return localDateTime.isBefore(currentLocalDateTime.subtract(4, "days"));
  };

  const formatText = (status, resolutionNote, maxWordsPerLine = 5) => {
    let combinedText;

    if (status === "Debt Paid") {
      combinedText = `${status} - ${resolutionNote}`;
    } else if (status === "Cancelled") {
      combinedText = `${status} - ${resolutionNote}`;
    } else {
      combinedText = `${status}`;
    }

    const words = combinedText.split(" ");
    let formattedText = "";
    for (let i = 0; i < words.length; i += maxWordsPerLine) {
      const line = words.slice(i, i + maxWordsPerLine).join(" ");
      formattedText += line + "\n";
    }
    return formattedText.trim();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    setSelectedVideo(null);
  };

  // Define the boolean conditions
  const isEvidenceImageVisible = incidentDetails.type === "IOU";
  const isUploadVideoVisible =
    incidentDetails.status === "Open" ||
    incidentDetails.status === "Returned To Site";
  return (
    <div className={styles.PageMainBox}>
      {error && (
        <ErrorModal
          show={true}
          onClose={handleCloseErrorModal}
          message={errorMessage}
          heading={ErrorModalHeading}
        />
      )}
      <VideoUploadModal
        isOpen={showModal}
        onRequestClose={toggleModal}
        selectedVideo={selectedVideo}
        handleFileChange={handleFileChange}
        handleVideoUpload={handleVideoUpload}
      />
      <DeleteVideoModal
        show={openDeleteVideModal}
        onClose={handleCloseDeleteVideoModal}
        pkAlert={pkAlert}
        eventID={incidentDetails.eventID}
        setIncidentDetails={setIncidentDetails}
        setActiveLinkBox2={setActiveLinkBox2}
        checkMediaUrls={checkMediaUrls}
        videoFrameId={videoFrameId}
        setVideos={setVideos}
      />
      <SimpleBackdrop
        open={incidentLoading}
        onClose={() => setIncidentDetails(false)}
      />
      <SimpleBackdrop open={helpSent} onClose={() => setHelpSent(false)} />
      <SimpleBackdrop
        open={videoUploading}
        onClose={() => setVideoUploading(false)}
      />
      {feedbackModal && (
        <FeedbackModal
        isOpen={feedbackModal}
          onClose={closeFeedBackModal}
          onSelectReportType={handleReportTypeSelection}
          apiResponse={apiResponse}
          ocr={incidentDetails.ocr}
          site={incidentDetails.siteName}
          detected={incidentDetails.detected}
          pkAlert={pkAlert}
        />
      )}
      <div className="topBox">
        <div className="title-section">
          <div className="container-fluid ">
            <div className={`row ${styles.TitleSection}`}>
              <div
                className="col-sm-12 d-flex p-2"
                style={{ maxWidth: "1170px", margin: "auto" }}
              >
                <div className="col-sm-6">
                  <h2 className={styles.Title2}>Incident</h2>
                </div>
                <div className="col-sm-6 d-flex align-items-center justify-content-end p-0">
                  {incidents.length > 0 && (
                    <div
                      className="ps-2"
                      style={{ fontSize: "20px", color: textAccentColor }}
                    >
                      <span>{incidents.length} unverified incidents </span>

                      <div
                        id="chevron-arrow-left"
                        style={{
                          borderColor:
                            index === 0 || !index ? "grey" : "#e27a13",
                          // other styles can be added here
                        }}
                        onClick={handleNavigatePrevious}
                        disabled={index === 0 || !index}
                      ></div>

                      <div
                        id="chevron-arrow-right"
                        style={{
                          borderColor:
                            index === incidents?.length - 1
                              ? "grey"
                              : "#e27a13",
                          // other styles can be added here
                        }}
                        onClick={handleNavigateNext}
                        disabled={index === incidents?.length - 1}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid incident-detail-section">
        <div className="" style={{ maxWidth: "1170px", margin: "auto" }}>
          {/* <div className="row" style={{ maxWidth: "100%" }}>
            <div className="col-sm-12 d-flex p-0">
              <div className={incidentDetailHeadingClasses}>TYPE</div>
              <div
                className="col-sm-2 incident-detail-overview-heading"
                style={{ cursor: "pointer" }}
                onMouseEnter={() => handleMouseEnter("detected")}
                onMouseLeave={handleMouseLeave}
                onClick={toggleEditMode}
              >
                OCCURRED AT
                <span>
                  {hoveredField === "detected" &&
                    (incidentDetails.status === "Open" ||
                      incidentDetails.status === "Returned To Site") && (
                      <EditIcon style={{ fontSize: "18px" }} />
                    )}
                </span>
              </div>
              <div className={incidentDetailHeadingClasses}>STATUS</div>
              <div className={`${incidentDetailHeadingClasses} ms-3`}>SITE</div>
              <div className={incidentDetailHeadingClasses}>SITE TYPE</div>
              <div className="col-sm-2 text-end" id="incident-support-link">
                <span className="needhelp" onClick={openFeedBackModal}>
                  Need Help
                  <span style={{ fontSize: "18px", paddingLeft: "5px" }}>
                    <BsFillQuestionCircleFill />
                  </span>
                </span>
              </div>
            </div>
            <div className="col-sm-12 d-flex p-0">
              <div className={`${incidentDetailValueClasses} incident-type`}>
                {incidentDetails.type ? incidentDetails.type.toUpperCase() : ""}
              </div>
              <div
                className="col-sm-2 incident-detail-overview-value p-0"
                style={{ cursor: "pointer" }}
                onMouseEnter={() => handleMouseEnter("detected")}
                onMouseLeave={handleMouseLeave}
              >
                {editDateMode &&
                (incidentDetails.status === "Open" ||
                  incidentDetails.status === "Returned To Site") ? (
                  <div className="col-sm-12 d-flex">
                    <div className="col-sm-6">
                      <Datetime
                        value={selectedDate ? new Date(selectedDate) : null}
                        onChange={(value) => setSelectedDate(value)}
                        id="latepayment-date-input"
                        name="paymentDate"
                        className="calendar-input"
                        inputProps={{
                          style: {
                            height: "35px",
                            fontSize: "13px",
                            backgroundColor: "transparent",
                            color: "#fff",
                            border: "1px solid rgb(204, 204, 204)",
                          },
                        }}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={true} // Display time in 12-hour format
                      />
                    </div>
                    <div className="col-sm-4 d-flex me-1">
                      <div className="col-sm-12 iconsContainer">
                        <div
                          className="iconsAlignment"
                          onClick={(event) => handleSaveChanges(event)}
                        >
                          <DoneIcon
                            style={{ fontSize: "16px", fontWeight: "900" }}
                          />
                        </div>
                        <div
                          className="iconsAlignment"
                          onClick={handleCancelChanges}
                        >
                          <CloseIcon
                            style={{ fontSize: "16px", fontWeight: "900" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div onClick={toggleEditMode}>
                    {incidentDetails.detected
                      ? formatDateTime(incidentDetails.detected)
                      : ""}
                  </div>
                )}
              </div>
              <div
                className={`${incidentDetailValueClasses}`}
                style={{ color: textAccentColor }}
              >
                {incidentDetails.status && (
                  <div>
                    <pre style={{ whiteSpace: "pre-wrap", fontSize: "14px" }}>
                      {formatText(
                        incidentDetails.status,
                        incidentDetails.resolutionNote
                      )}
                    </pre>
                  </div>
                )}
              </div>
              <div className={`${incidentDetailValueClasses} ms-3`}>
                {incidentDetails.siteName
                  ? incidentDetails.siteName.toUpperCase()
                  : ""}
              </div>
              <div className={incidentDetailValueClpkAlertasses}>
                {incidentDetails.siteType
                  ? incidentDetails.siteType.toUpperCase()
                  : ""}
              </div>
            </div>
          </div> */}

          {/* section for incident top headers details */}
          <IncidentDetailsTopSection
            incidentDetails={incidentDetails}
            openFeedBackModal={openFeedBackModal}
            setIncidentDetails={setIncidentDetails}
            setError={setError}
            setErrorMessage={setErrorMessage}
          />
          {/* incident details main container */}

          <div id="incident-review-box-data-container">
            {/* incident details left section */}
            <div id="heading-left-box">
              <div id="heading-left-tab-box">
                <div
                  className="heading-left-box-item"
                  onClick={() => handleLinkClickBox1("Video")}
                  style={
                    activeLinkBox1 === "Video" ? activeStyle : inActiveStyle
                  }
                >
                  <Link
                    aria-current="page"
                    className={`custom-nav-link  ${
                      activeLinkBox1 === "Video" ? "active" : ""
                    }`}
                    style={
                      activeLinkBox1 === "Video"
                        ? activeTextColor
                        : inActiveTextColor
                    }
                  >
                    Evidence Video
                  </Link>
                </div>
                {isEvidenceImageVisible && (
                  <div
                    className="heading-left-box-item"
                    style={
                      activeLinkBox1 === "EvidenceImage"
                        ? activeStyle
                        : inActiveStyle
                    }
                  >
                    <Link
                      className={`custom-nav-link ${
                        activeLinkBox1 === "EvidenceImage" ? "active" : ""
                      }`}
                      onClick={() => handleLinkClickBox1("EvidenceImage")}
                      style={
                        activeLinkBox1 === "EvidenceImage"
                          ? activeTextColor
                          : inActiveTextColor
                      }
                    >
                      Evidence Image
                    </Link>
                  </div>
                )}
                {isUploadVideoVisible && (
                  <div
                    className="heading-left-box-item"
                    style={
                      activeLinkBox1 === "UploadVideo"
                        ? activeStyle
                        : inActiveStyle
                    }
                  >
                    <Link
                      className={`custom-nav-link ${
                        activeLinkBox1 === "UploadVideo" ? "active" : ""
                      }`}
                      onClick={toggleModal}
                      style={
                        activeLinkBox1 === "UploadVideo"
                          ? activeTextColor
                          : inActiveTextColor
                      }
                    >
                      Upload Video
                    </Link>
                  </div>
                )}
              </div>
              {activeLeftComponent}
            </div>

            <div id="heading-right-box">
              <div id="heading-right-tab-box">
                <div
                  className="heading-right-box-item"
                  style={
                    activeLinkBox2 === "details" ? activeStyle : inActiveStyle
                  }
                >
                  <Link
                    aria-current="page"
                    // to={`/scancam/incident/${pkAlert}/details`}

                    onClick={() => handleLinkClickBox2("details")}
                    className={`custom-nav-link ${
                      activeLinkBox2 === "details" ? "active" : ""
                    }`}
                    style={
                      activeLinkBox2 === "details"
                        ? activeTextColor
                        : inActiveTextColor
                    }
                  >
                    Details
                  </Link>
                </div>
                <div
                  className="heading-right-box-item"
                  style={
                    activeLinkBox2 === "notes" ? activeStyle : inActiveStyle
                  }
                >
                  <Link
                    className={`custom-nav-link ${
                      activeLinkBox2 === "notes" ? "active" : ""
                    }`}
                    // to={`/scancam/incident/${pkAlert}/notes`}
                    onClick={() => handleLinkClickBox2("notes")}
                    style={
                      activeLinkBox2 === "notes"
                        ? activeTextColor
                        : inActiveTextColor
                    }
                  >
                    Notes
                  </Link>
                </div>
                <div
                  className="heading-right-box-item"
                  style={
                    activeLinkBox2 === "history" ? activeStyle : inActiveStyle
                  }
                >
                  <Link
                    className={`custom-nav-link ${
                      activeLinkBox2 === "history" ? "active" : ""
                    }`}
                    // to={`/scancam/incident/${pkAlert}/history`}
                    onClick={() => handleLinkClickBox2("history")}
                    style={
                      activeLinkBox2 === "history"
                        ? activeTextColor
                        : inActiveTextColor
                    }
                  >
                    History
                  </Link>
                </div>

                {statusesToHidePaymentElement.includes(
                  incidentDetails.status
                ) && (
                  <div
                    className="heading-right-box-item"
                    style={
                      activeLinkBox2 === "latepayment"
                        ? activeStyle
                        : inActiveStyle
                    }
                  >
                    <Link
                      className={`custom-nav-link ${
                        activeLinkBox2 === "latepayment" ? "active" : ""
                      }`}
                      // to={`/scancam/incident/${pkAlert}/latepayment`}
                      onClick={() => handleLinkClickBox2("latepayment")}
                      style={
                        activeLinkBox2 === "latepayment"
                          ? activeTextColor
                          : inActiveTextColor
                      }
                    >
                      {isMoreThan4Days(incidentDetails.detected)
                        ? "Late Payment"
                        : "Take Payment"}
                    </Link>
                  </div>
                )}

                {(incidentDetails.status === "Escalated" ||
                  incidentDetails.status === "Pre-Court") &&
                  isReviewer && (
                    <div
                      className="heading-right-box-item"
                      style={
                        activeLinkBox2 === "WaiveFees"
                          ? activeStyle
                          : inActiveStyle
                      }
                    >
                      <Link
                        className={`custom-nav-link ${
                          activeLinkBox2 === "WaiveFees" ? "active" : ""
                        }`}
                        // to={`/scancam/incident/${pkAlert}/sendpaymentlink`}
                        onClick={() => handleLinkClickBox2("WaiveFees")}
                        style={
                          activeLinkBox2 === "WaiveFees"
                            ? activeTextColor
                            : inActiveTextColor
                        }
                      >
                        Waive Fees
                      </Link>
                    </div>
                  )}

                {statusesToHideSendPaymentElement.includes(
                  incidentDetails.status
                ) &&
                  isReviewer && (
                    <div
                      className="heading-right-box-item"
                      style={
                        activeLinkBox2 === "sendpaymentlink"
                          ? activeStyle
                          : inActiveStyle
                      }
                    >
                      <Link
                        className={`custom-nav-link ${
                          activeLinkBox2 === "sendpaymentlink" ? "active" : ""
                        }`}
                        // to={`/scancam/incident/${pkAlert}/sendpaymentlink`}
                        onClick={() => handleLinkClickBox2("sendpaymentlink")}
                        style={
                          activeLinkBox2 === "sendpaymentlink"
                            ? activeTextColor
                            : inActiveTextColor
                        }
                      >
                        Send Payment Link
                      </Link>
                    </div>
                  )}

                {statusesToHideReturnToSiteElement.includes(
                  incidentDetails.status
                ) &&
                  isReviewer && (
                    <div
                      className="heading-right-box-item"
                      style={
                        activeLinkBox2 === "returnToSite"
                          ? activeStyle
                          : inActiveStyle
                      }
                    >
                      <Link
                        className={`custom-nav-link ${
                          activeLinkBox2 === "returnToSite" ? "active" : ""
                        }`}
                        // to={`/scancam/incident/${pkAlert}/sendpaymentlink`}
                        onClick={() => handleLinkClickBox2("returnToSite")}
                        style={
                          activeLinkBox2 === "returnToSite"
                            ? activeTextColor
                            : inActiveTextColor
                        }
                      >
                        Return To Site
                      </Link>
                    </div>
                  )}
              </div>
              {activeRightComponent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncidentDetails;
