import React, { Component } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
// Function to map month numbers to month names
class CSVDownloadButton extends Component {
    convertToCSV = (data) => {
        if (!data) {
            return ''; // Return an empty string for invalid input
        }

        let headers = [];
        const rows = [];

        if (this.props.reportName === 'scancam-site-traffic-report') {
            headers = ["From", "To", "Morning Peak Time", "Morning Detections / 15 mins", "Afternoon Peak Time", "Afternoon Detections / 15 mins", "Evening Peak Time", "Evening Detections / 15 mins"];

            // Transform the data for 'scancam-site-traffic-report'

            // Ensure data is an array
            if (Array.isArray(data)) {
                data.forEach((groupedData) => {
                    if (Array.isArray(groupedData)) {
                        // Push the combined row for each period into the rows array
                        rows.push([
                            groupedData[0], // From
                            groupedData[1], // To
                            groupedData[2], // Morning Peak Time
                            groupedData[3], // Morning Detections / 15 mins
                            groupedData[4], // Afternoon Peak Time
                            groupedData[5], // Afternoon Detections / 15 mins
                            groupedData[6], // Evening Peak Time
                            groupedData[7]  // Evening Detections / 15 mins
                        ]);
                    }
                });
            }
        }
        else if (this.props.reportName === 'scancam-site-offender-detections-report') {
            // Custom headers for 'offenderDetections' report
            headers = ['Site','From', 'To', 'Total # of Detections', 'Total # of Offender Detections'];
        
            // Loop through each inner array in the data and push it into rows
            data.forEach((innerArray) => {
                rows.push(innerArray);
            });
        }
         else {
            // Handle other reports
            headers = ["Date / Time", "Licence Plate", "Type", "Status", "Site", "Site Type", "Store ID", "Pump", "Fuel Litres", "Fuel Type", "Fuel Value", "Reference", "Employee Name"];

            if (Array.isArray(data)) {
                // Handle array of objects
                data.forEach((record) => {
                    const detectedDate = new Date(record.detected);
                    const day = String(detectedDate.getDate()).padStart(2, '0');
                    const month = String(detectedDate.getMonth() + 1).padStart(2, '0');
                    const year = detectedDate.getFullYear();
                    const formattedDate = `${day}/${month}/${year}`;
                    const time = record.detected.split('T')[1].substring(0, 8);

                    const values = [
                        `"${formattedDate} / ${time}"`, // Combine date and time
                        `"${record.ocr || ''}"`,
                        `"${record.type || ''}"`,
                        `"${record.status || ''}"`,
                        `"${record.siteName || ''}"`,
                        `"${record.siteType || ''}"`,
                        `"${record.storeID || ''}"`,
                        `"${record.pumpNumber || ''}"`,
                        `"${record.fuelLitres || ''}"`,
                        `"${record.fuelType || ''}"`,
                        `"${record.fuelvalue || ''}"`,
                        `"${record.fuelReference || ''}"`,
                        `"${record.employeeName || ''}"`,
                    ];

                    // Push the values for this record into the rows array
                    rows.push(values.join(','));
                });
            } else if (typeof data === 'object') {
                headers = ["Date / Time", "Licence Plate", "Type", "Status", "Site", "Site Type", "Store ID", "Pump", "Fuel Litres", "Fuel Type", "Fuel Value", "Reference", "Employee Name"];
                // Handle dictionary (single object)
                Object.values(data).forEach((dataArray) => {
                    dataArray.forEach((record) => {
                        const detectedDate = new Date(record.detected);
                        const day = String(detectedDate.getDate()).padStart(2, '0');
                        const month = String(detectedDate.getMonth() + 1).padStart(2, '0');
                        const year = detectedDate.getFullYear();
                        const formattedDate = `${day}/${month}/${year}`;
                        const time = record.detected.split('T')[1].substring(0, 8);

                        const values = [
                            `"${formattedDate} / ${time}"`, // Combine date and time
                            `"${record.ocr || ''}"`,
                            `"${record.type || ''}"`,
                            `"${record.status || ''}"`,
                            `"${record.siteName || ''}"`,
                            `"${record.type || ''}"`,
                            `"${record.storeID || ''}"`,
                            `"${record.pumpNumber || ''}"`,
                            `"${record.fuelLitres || ''}"`,
                            `"${record.fuelType || ''}"`,
                            `"${record.fuelvalue || ''}"`,
                            `"${record.fuelReference || ''}"`,
                            `"${record.employeeName || ''}"`,
                        ];

                        // Push the values for this record into the rows array
                        rows.push(values.join(','));
                    });
                });
            }
        }

        // Add the headers to the beginning of the rows
        rows.unshift(headers.map((header) => `"${header}"`).join(','));

        return rows.join('\n');
    };

    downloadCSV = (data) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        const csv = this.convertToCSV(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;

        // Set the filename to the reportName.csv
        a.download = `${this.props.reportName}.csv`;

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    render() {
        return (
            <span style={{ padding: '0px', margin: '0px' }} onClick={() => this.downloadCSV(this.props.data)}>
                <span className=""> <LaunchIcon style={{ fontSize: "18px" }} /> </span> <span>Export as CSV</span>
            </span>
        );
    }
}

export default CSVDownloadButton;