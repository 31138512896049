import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import userSession from '../Constants/auth';
import { APP_SERVICES_URL, cancelReasonsAdmin, cancelReasonsManager } from "../Constants/constants";
import useDetailsPatchApi from "../Custom Hooks/useDetailsPatchApi";
// import { useNavigate } from "react-router-dom";

const CloseIncidentModal = ({ show, onClose, ocr, pkAlert, setIncidentDetails, setActiveLinkBox2, setError }) => {
    // const navigate = useNavigate();
    const [buttonActiveState, SetButtonActiveState] = useState(true);
    const { CustID, authHeader, userRole } = userSession;
    const { patchAPI } = useDetailsPatchApi();

    const reasons = userRole === 'site-manager' ? cancelReasonsManager : cancelReasonsAdmin;

    const [selectedOption, setSelectedOption] = useState(reasons[0]);
    const [otherReason, setOtherReason] = useState('');

    const handleOtherReasonChange = (e) => {
        setOtherReason(e.target.value);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };
    const fetchDataAndUpdateIncidentDetails = async () => {
        try {
            // Fetch data from API
            const apiUrl = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident?alertID=${pkAlert}`;
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Authorization": authHeader,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            setIncidentDetails(responseData);
            setActiveLinkBox2('details');
            closeModal();
        } catch (error) {
            console.error("Error fetching data from the API:", error);
        }
    };

    const handleSubmit = () => {
        // Check if a reason is selected
        setError(false);
        if (selectedOption) {
            let resolutionNote = selectedOption; // Use the selected option as the status value

            const payload = {
                status: "Cancelled",
                resolutionNote: resolutionNote
            };
            SetButtonActiveState(false);
            // Call the patchAPI function with the payload
            patchAPI(authHeader, CustID, pkAlert, payload)
                .then(() => {
                    // Update data in storage after successful patch operation
                    fetchDataAndUpdateIncidentDetails();
                    SetButtonActiveState(true);
                    setError(false);
                })
                .catch((error) => {
                    SetButtonActiveState(true);
                    setError(true);
                    console.error("Error:", error);
                });
        } else {
            SetButtonActiveState(true);
            console.error("No reason selected");
        }
    };
    const closeModal = () => {
        onClose();
        setSelectedOption(reasons[0]);
    };
    return (
        <Modal show={show} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '18px', color: '#e27a13', fontWeight: 'bold' }}>Close Incident for {ocr}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#f4f4f4", color: "#000000", fontSize: '14px' }}>
                <div className='mb-3'>What is the reason for closing the incident?</div>
                <Form>
                    {reasons.map((reason, index) => (
                        <div key={index}>
                            <Form.Check
                                type="radio"
                                label={
                                    <label htmlFor={`reason-${index}`} style={{ cursor: 'pointer' }}>
                                        {/* Add extra text as prefix or suffix */}
                                        {reason}
                                    </label>
                                }
                                id={`reason-${index}`}
                                value={reason}
                                checked={selectedOption === reason}
                                onChange={handleOptionChange}
                            />
                        </div>
                    ))}

                    {/* Render text area if "Other" option is selected */}
                    {(selectedOption === 'Other') && (
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter other reason..."
                            value={otherReason}
                            onChange={handleOtherReasonChange}
                        />
                    )}
                </Form>
            </Modal.Body >
            <div>
                <div className='col-sm-12 d-flex' style={{ fontWeight: 'bold', fontSize: '14px' }}>
                    <button className="col-sm-6 text-center" style={{ padding: '20px', border: 'none',cursor:'pointer' }} onClick={closeModal}>Back</button>
                    <button className="col-sm-6 text-center" style={{ backgroundColor: buttonActiveState ? '#e27a13' : "grey", padding: '20px', color: 'white', border: 'none',cursor:'pointer' }} onClick={buttonActiveState ? handleSubmit : null}>Close Incident</button>
                </div>
            </div>
        </Modal >
    );
};
export default CloseIncidentModal;