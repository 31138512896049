import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import styles2 from "../../CSS/Details.module.css";
import { APP_SERVICES_URL, DRIVE_OFF } from "../../Constants/constants";
import styles from "../../CSS/Base/Basic.module.css";
import useSenapiPumps from "../../Custom Hooks/useSenapiPumps";
import useSenapiVehiclesInfo from "../../Custom Hooks/useSenapiVehiclesInfo";
import useSenapiFuelTypes from "../../Custom Hooks/SenapiFuelTypes";
import { FaCheck } from "react-icons/fa";
import useDetailsPatchApi from "../../Custom Hooks/useDetailsPatchApi";
import userSession from "../../Constants/auth";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import CloseIncidentModal from "../../Modals/CloseIncidentModal";
import ErrorModal from "../../Modals/ErrorModal";
import { BsFillCameraVideoFill } from "react-icons/bs";
import "../../CSS/incidetDetails.css";

function Details({ sharedData, setIncidentDetails, setActiveLinkBox2 }) {
  const [error, setError] = useState(false);
  const [errorFootageGenerate, setErrorFootageGenerate] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [buttonActive, setButtonActive] = useState(true);
  const { CustID, authHeader, userRole } = userSession;
  const ctaBackgroudColor =
    userSession.themeData?.ctaBackgroudColor || "#e27a13";
  const [siteDetails, setSiteDetails] = useState("");
  const { colors, models, shapes } = useSenapiVehiclesInfo();
  const { pkAlert } = useParams();
  // const { data, details } = useFetchdata(pkAlert, CustID, authHeader);
  const { patchAPI } = useDetailsPatchApi();
  const [openCloseIncidentModal, setOpenCloseIncidentModal] = useState(false);
  const [showText, setShowText] = useState(false);
  const [editableFields, setEditableFields] = useState({});
  const [tempData, setTempData] = useState({});
  const [note, setNote] = useState("");
  const [text, setText] = useState("");

  const [showWarning, setShowWarning] = useState(false);
  //function to open close incident modal
  const handleClickCloseIncidentModal = useCallback(() => {
    setOpenCloseIncidentModal((prevState) => !prevState);
  }, []);

  const handleChange = useCallback((event) => {
    const value = event.target.value;
    setNote(value);
    setShowText(value !== "");
  }, []);

  // fetching data to show incident details
  useEffect(() => {
    const handleFetchingData = () => {
      setError(false);
      try {
        if (sharedData) {
          setSiteDetails(sharedData.siteName);
          setEditableFields({});
          setTempData(sharedData);
        }
      } catch (error) {
        setError(true);
        console.error("Error handling fetching data:", error);
      }
    };

    handleFetchingData();
  }, [sharedData]);

  const fuelTypes = useSenapiFuelTypes(siteDetails);
  const pumps = useSenapiPumps(siteDetails);

  // below function is used for handling Verify for debt to change the status from detected to Reviewed
  const handleClick = useCallback(
    (event, status) => {
      setButtonActive(false);
      setError(false);
      event.preventDefault();
      const payload = { status };

      setIncidentDetails((prevData) => ({
        ...prevData,
        status,
      }));

      if (note.trim()) {
        payload.note = note;
      }

      patchAPI(authHeader, CustID, pkAlert, payload)
        .then(() => {
          setNote("");
          setButtonActive(true);
          setError(false);
        })
        .catch((error) => {
          setButtonActive(true);
          setError(true);
          console.error("Error saving note:", error);
        });
    },
    [note, patchAPI, authHeader, CustID, pkAlert, setIncidentDetails]
  );

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = useCallback((event) => {
    setSelectedOption(event.target.value);
  }, []);

  const handleChangeOptions = useCallback(
    (event) => {
      const value = event.target.value;
      setText(value);
      let updatedNote = "";
      if (selectedOption === "option2") {
        updatedNote = `YES the person entered the store, but NO transaction was made - ${value}`;
      } else if (selectedOption === "option3") {
        updatedNote = `YES the person made a transaction, but did not pay for the fuel - ${value}`;
      }
      setNote(updatedNote);
    },
    [selectedOption]
  );

  const renderComponent = useCallback(() => {
    if (selectedOption === "option1") {
      return (
        <div className={`ps-2 ${styles2.incidentDetailBodyHeading}`}>
          <div>
            <div className={`${styles2.incidentTermsConditionsText} mt-2`}>
              By verifying the incident I confirm that I have reviewed the
              footage and all incident details are correct.
              <br />
              <span>
                I acknowledge that the motorist has 4 days to pay for the fuel,
                after which this incident will be escalated to debt recovery
                where fees may apply.
              </span>
            </div>
          </div>
          {buttonActive && (
            <button
              className={styles2.reviewSubmitButton}
              style={{ backgroundColor: ctaBackgroudColor }}
              onClick={(event) => handleClick(event, "Verified")}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                Verify for Debt Recovery
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginLeft: "8px" }} // Add margin for spacing
                >
                  <path
                    d="M10 3L4.5 8.5L2 6"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>
          )}
        </div>
      );
    } else if (selectedOption === "option2") {
      return (
        <div className={`ps-2 ${styles2.incidentDetailBodyHeading}`}>
          <span style={{ color: "#6F7172" }}>
            Describe what they did in the store?
          </span>
          <textarea
            value={text}
            // placeholder="eg The person entered the store, used the ATM and left without paying."
            rows={4}
            cols={25}
            onChange={handleChangeOptions}
            className={`mt-4 p-2 ${styles.customTextarea} text-[#000] px-2`}
          />
          {text && (
            <div>
              <div className={`${styles2.incidentTermsConditionsText}`}>
                By verifying the incident I confirm that I have reviewed the
                footage and all incident details are correct.
                <br />
                <span>
                  I acknowledge that the motorist has 4 days to pay for the
                  fuel, after which this incident will be escalated to debt
                  recovery where fees may apply.
                </span>
              </div>
              {buttonActive && (
                <button
                  className={styles2.reviewSubmitButton}
                  style={{ backgroundColor: ctaBackgroudColor }}
                  onClick={(event) => handleClick(event, "Verified")}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    Verify for Debt Recovery
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginLeft: "8px" }} // Add margin for spacing
                    >
                      <path
                        d="M10 3L4.5 8.5L2 6"
                        stroke="currentColor"
                        strokeWidth="2.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              )}
            </div>
          )}
        </div>
      );
    } else if (selectedOption === "option3") {
      return (
        <div className={`ps-2 ${styles2.incidentDetailBodyHeading}`}>
          <span style={{ color: "#6F7172" }}>
            Describe the transaction made?
          </span>
          <textarea
            value={text}
            // placeholder="eg The person bought a newspaper ,but did not pay for the fuel."
            rows={4}
            cols={25}
            onChange={handleChangeOptions}
            className={`mt-4 p-2 ${styles.customTextarea} text-[#000] px-2`}
          />
          {text && (
            <div>
              <div className={styles2.incidentTermsConditionsText}>
                By verifying the incident I confirm that I have reviewed the
                footage and all incident details are correct.
                <br />
                <span>
                  This incident will be recorded as an incomplete transaction.
                </span>
              </div>
              {buttonActive && (
                <button
                  className={`${styles2.reviewSubmitButton} flex`}
                  style={{ backgroundColor: ctaBackgroudColor }}
                  onClick={(event) =>
                    handleClick(event, "Incomplete Transaction")
                  }
                >
                  <span>Incomplete Transaction</span>
                  <span className="ps-1">
                    <FaCheck />
                  </span>
                </button>
              )}
            </div>
          )}
        </div>
      );
    }
    return null;
  }, [
    selectedOption,
    text,
    handleChangeOptions,
    buttonActive,
    ctaBackgroudColor,
    handleClick,
  ]);

  const handleFieldEdit = useCallback(
    (fields) => {
      if (!Array.isArray(fields)) {
        fields = [fields];
      }

      const updatedFields = {};
      fields.forEach((field) => {
        updatedFields[field] =
          sharedData[field] !== undefined ? sharedData[field] : "";
      });

      setEditableFields((prevFields) => ({
        ...prevFields,
        ...Object.fromEntries(fields.map((field) => [field, true])),
      }));

      setTempData((prevData) => ({
        ...prevData,
        ...updatedFields,
      }));
    },
    [sharedData]
  );

  const handleFieldChange = (event, field) => {
    const { value, type, selectionStart, selectionEnd } = event.target;
    let modifiedValue = value;

    switch (field) {
      case "customerName":
        modifiedValue = value.replace(/[^A-Za-z\s]/g, "").toUpperCase();
        break;
      case "ocr":
        modifiedValue = value
          .replace(/[^A-Za-z0-9]/g, "")
          .slice(0, 10)
          .toUpperCase();
        break;
      case "fuelLitres":
      case "fuelValue":
        modifiedValue = value.replace(/[^\d.]/g, "").slice(0, 6);
        break;
      case "customerPhoneNumber":
        modifiedValue = `+${value.replace(/[^\d]/g, "").slice(0, 15)}`;
        break;
      case "fuelReference":
        modifiedValue = value
          .replace(/[^A-Za-z0-9]/g, "")
          .slice(0, 10)
          .toUpperCase();
        break;
      default:
        modifiedValue = value;
        break;
    }

    setTempData((prevData) => ({
      ...prevData,
      [field]: modifiedValue,
    }));

    // Only set selection range for text inputs
    if (type === "text" || type === "textarea" || type === "tel") {
      // Use setTimeout to ensure the state update completes before setting the cursor position
      setTimeout(() => {
        const inputElement = event.target;
        if (inputElement) {
          inputElement.setSelectionRange(selectionStart, selectionEnd);
        }
      }, 0);
    }
  };

  const handleSave = (event, ...fieldTypes) => {
    event.stopPropagation();
    setIncidentDetails((prevData) => ({
      ...prevData,
      ...tempData,
    }));
    const dataToSend = fieldTypes.reduce((data, fieldType) => {
      data[fieldType] = tempData[fieldType];
      return data;
    }, {});

    // Call patchAPI with dataToSend
    patchAPI(authHeader, CustID, pkAlert, dataToSend)
      .then(() => {
        // Update data in storage after successful patch operation
        // Reset editable fields
        setEditableFields({});
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
    setEditableFields({});
  };

  const handleCancel = (event, fieldOrFields) => {
    event.stopPropagation();

    // Convert single string to an array if needed
    const fieldsArray = Array.isArray(fieldOrFields)
      ? fieldOrFields
      : [fieldOrFields];

    setEditableFields((prevFields) => {
      const updatedFields = { ...prevFields };
      fieldsArray.forEach((field) => {
        updatedFields[field] = false; // Set only the specific field to false to close it
      });
      return updatedFields;
    });

    setTempData((prevData) => {
      const updatedData = { ...prevData };
      fieldsArray.forEach((field) => {
        updatedData[field] = ""; // Clear the temporary data for the specific field
      });
      return updatedData;
    });
  };

  const [hoveredField, setHoveredField] = useState(null);

  const handleMouseEnter = useCallback((fieldName) => {
    setHoveredField(fieldName);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredField(null);
  }, []);

  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = useCallback((button) => {
    setSelectedButton(button);
    button === "no" ? setShowWarning(true) : setShowWarning(false);
  }, []);

  const isMotorist = sharedData.type === DRIVE_OFF;
  const isOpenOrReturned =
    sharedData.status === "Open" || sharedData.status === "Returned To Site";
  const isFRSReviewerorManager =
    userRole === "frs-reviewer" ||
    userRole === "site-manager" ||
    userRole === "sensenadmin" ||
    userRole === "customeradmin";

  const handleCloseErrorModal = useCallback(() => {
    setError(false);
  }, []);

  const handleSucessFootageGenerateModal = useCallback(() => {
    setSucess(false);
  }, []);

  const handleCloseErrorFootageGenerateModal = useCallback(() => {
    setErrorFootageGenerate(false);
  }, []);

  const fetchDataAndUpdateNotes = useCallback(async () => {
    try {
      const apiUrl = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident?alertID=${pkAlert}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: authHeader,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setIncidentDetails(responseData);
    } catch (error) {
      console.error("Error fetching data from the API:", error);
    }
  }, [CustID, authHeader, pkAlert, setIncidentDetails]);

  // function to regenerate the footage
  const regenerateFootage = useCallback(
    async (alertID, siteName) => {
      const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/footageRegeneration?alertID=${alertID}&siteName=${siteName}`;
      const headers = {
        Authorization: authHeader,
        "Content-Type": "application/json",
      };
      setErrorFootageGenerate(false);
      setSucess(false);
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
        });

        if (!response.ok) {
          setErrorFootageGenerate(true);
          fetchDataAndUpdateNotes();
          return;
        }
        setSucess(true);
        fetchDataAndUpdateNotes();
      } catch (error) {
        console.error("Error regenerating footage:", error);
        setErrorFootageGenerate(true);
        fetchDataAndUpdateNotes();
      }
    },
    [CustID, authHeader, fetchDataAndUpdateNotes]
  );

  const statusKeywords = ["Debt", "Paid", "Cancelled"]; // Keywords to check
  const status = sharedData?.status || ""; // Assuming status is part of sharedData

  const containsStatusKeyword = statusKeywords.some((keyword) =>
    status.includes(keyword)
  );

  return (
    <div className={styles.incidentBox1}>
      {error && (
        <ErrorModal
          show={true}
          onClose={handleCloseErrorModal}
          message="An unexpected error occurred. Please try again or contact support if the problem persists."
          heading="Operation Unsuccessful"
        />
      )}
      {errorFootageGenerate && (
        <ErrorModal
          show={true}
          onClose={handleCloseErrorFootageGenerateModal}
          message={"Regenerate footage request failed. Please try again."}
          heading={"Regenerate Footage"}
        />
      )}
      {sucess && (
        <ErrorModal
          show={true}
          onClose={handleSucessFootageGenerateModal}
          message={
            "Regenerate footage request submitted successfully. Check back later for video."
          }
          heading={"Regenerate Footage"}
        />
      )}

      <CloseIncidentModal
        show={openCloseIncidentModal}
        onClose={handleClickCloseIncidentModal}
        ocr={sharedData.ocr}
        pkAlert={pkAlert}
        setIncidentDetails={setIncidentDetails}
        setActiveLinkBox2={setActiveLinkBox2}
        setError={setError}
      />

      <div className={`col-sm-12 ${styles2.detailsSubRow} d-flex`}>
        {/* License Plate */}
        <div
          className={`col-sm-6 ${styles2.detailsColumnRight}`}
          onClick={() => handleFieldEdit(["ocr"])}
          onMouseEnter={() => handleMouseEnter("ocr")}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`col-sm-12 ${styles2.incidentDetailBodyHeading}`}
            style={{ color: "#6f7172" }}
          >
            LICENCE PLATE
            <span>
              {hoveredField === "ocr" &&
                isOpenOrReturned &&
                isFRSReviewerorManager && (
                  <EditIcon style={{ fontSize: "18px" }} />
                )}
            </span>
          </div>
          <div className="col-sm-12 d-flex">
            {editableFields.ocr &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-8">
                <input
                  style={{ height: "35px" }}
                  type="text"
                  id="licencePlateNumber"
                  name="licencePlateNumber"
                  autoComplete="off"
                  value={tempData.ocr || ""}
                  onChange={(e) => {
                    if (e.target.value.trim() !== "") {
                      handleFieldChange(e, "ocr");
                    }
                  }}
                  className={`col-6 w-100 text-[#000] px-2 ${styles2.inputTagStyle}`}
                />
              </div>
            ) : (
              <div id={styles2.incidentDetailBodyLicenceNumber}>
                {sharedData && sharedData.ocr ? sharedData.ocr : ""}
              </div>
            )}
            {editableFields.ocr &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-4 d-flex">
                <div className={`col-sm-12 ${styles2.iconsContainer}`}>
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) => handleSave(event, "ocr")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 3L4.5 8.5L2 6"
                        stroke="currentColor"
                        stroke-width="3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) => handleCancel(event, "ocr")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 3L9 9M9 3L3 9"
                        stroke="currentColor"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* Pump */}
        <div
          className={`col-sm-6 ${styles2.detailsColumnLeft}`}
          onClick={() => handleFieldEdit("pump")}
          onMouseEnter={() => handleMouseEnter("pump")}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`col-sm-12 ${styles2.incidentDetailBodyHeading}`}
            style={{ color: "#6f7172" }}
          >
            PUMP
            <span>
              {hoveredField === "pump" &&
                isOpenOrReturned &&
                isFRSReviewerorManager && (
                  <EditIcon style={{ fontSize: "18px" }} />
                )}
            </span>
          </div>
          <div className="col-sm-12 d-flex">
            {editableFields.pump &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-8">
                <select
                  style={{ height: "35px" }}
                  name="pump"
                  id="pump"
                  value={tempData.pumpNumber || ""}
                  onChange={(e) => handleFieldChange(e, "pumpNumber")}
                  className={`col-6 w-100 text-[#000] px-2 ${styles2.inputTagStyle}`}
                >
                  {pumps.map((pump) => (
                    <option
                      key={pump.number}
                      value={pump.number}
                      className="text-[#000] px-2"
                    >
                      {pump.number}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div id={styles2.incidentDetailPumpNumber}>
                {sharedData && sharedData.pumpNumber
                  ? sharedData.pumpNumber
                  : " "}
              </div>
            )}
            {editableFields.pump &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-4 d-flex">
                <div className={`col-sm-12 ${styles2.iconsContainer}`}>
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) => handleSave(event, "pumpNumber")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 3L4.5 8.5L2 6"
                        stroke="currentColor"
                        stroke-width="3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) => handleCancel(event, "pump")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 3L9 9M9 3L3 9"
                        stroke="currentColor"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className={`col-sm-12 ${styles2.detailsSubRow} d-flex`}>
        {/* Fuel */}
        <div
          className={`col-sm-6 ${styles2.detailsColumnRight}`}
          onClick={() => handleFieldEdit("Fuel")}
          onMouseEnter={() => handleMouseEnter("fuel")}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`col-sm-12 ${styles2.incidentDetailBodyHeading}`}
            style={{ color: "#6f7172" }}
          >
            FUEL
            <span>
              {hoveredField === "fuel" &&
                isOpenOrReturned &&
                isFRSReviewerorManager && (
                  <EditIcon style={{ fontSize: "18px" }} />
                )}
            </span>
          </div>
          <div className="col-sm-12">
            {editableFields.Fuel &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-12">
                <div className="col-sm-12 d-flex">
                  <div className="col-sm-3" style={{ fontSize: "14px" }}>
                    Litres:
                  </div>
                  <div className="col-sm-9">
                    <input
                      style={{ height: "35px", marginBottom: "5px" }}
                      type="text"
                      id="fuelLitres"
                      name="fuelLitres"
                      maxLength={4}
                      // placeholder={tempData.fuelLitres || 'Enter fuel litres...'}
                      value={tempData.fuelLitres || ""}
                      onChange={(e) => handleFieldChange(e, "fuelLitres")}
                      className={`col-6 w-100 text-[#000] px-2 ${styles2.inputTagStyle}`}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-sm-12 d-flex">
                  <div className="col-sm-3" style={{ fontSize: "14px" }}>
                    TYPE:
                  </div>
                  <div className="col-sm-9">
                    <select
                      style={{ height: "35px", marginBottom: "5px" }}
                      id="fuelType"
                      name="fuelType"
                      value={tempData.fuelType || ""}
                      onChange={(e) => handleFieldChange(e, "fuelType")}
                      className={`col-6 w-100 text-[#000] px-2 ${styles2.inputTagStyle}`}
                    >
                      <option className="text-[#000] px-2" value="">
                        Select a fuel type
                      </option>
                      {fuelTypes.map((fuelType) => (
                        <option
                          className="text-[#000] px-2"
                          key={fuelType}
                          value={fuelType}
                        >
                          {fuelType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles2.incidentDetailValues}>
                {sharedData && sharedData.fuelLitres
                  ? `${parseFloat(sharedData.fuelLitres).toFixed(2)}L `
                  : "0.00L "}
                {sharedData && sharedData.fuelType ? sharedData.fuelType : ""}
              </div>
            )}
            {editableFields.Fuel &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-12 d-flex">
                <div className="col-sm-6"></div>
                <div className="col-sm-6 d-flex justify-content-center">
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) =>
                      handleSave(event, "fuelLitres", "fuelType")
                    }
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 3L4.5 8.5L2 6"
                        stroke="currentColor"
                        stroke-width="3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) => handleCancel(event, "Fuel")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 3L9 9M9 3L3 9"
                        stroke="currentColor"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* Value */}
        <div
          className={`col-sm-6 ${styles2.detailsColumnLeft}`}
          onClick={() => handleFieldEdit(["fuelValue"])}
          onMouseEnter={() => handleMouseEnter("fuelValue")}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`col-sm-12 ${styles2.incidentDetailBodyHeading}`}
            style={{ color: "#6f7172" }}
          >
            VALUE
            <span>
              {hoveredField === "fuelValue" &&
                isOpenOrReturned &&
                isFRSReviewerorManager && (
                  <EditIcon style={{ fontSize: "18px" }} />
                )}
            </span>
          </div>
          <div className="col-sm-12 d-flex">
            {editableFields.fuelValue &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-8">
                <input
                  style={{ height: "35px" }}
                  type="text"
                  autoComplete="off"
                  id="fuelValue"
                  name="fuelValue"
                  value={tempData.fuelValue || ""}
                  onChange={(e) => handleFieldChange(e, "fuelValue")}
                  className={`col-6 w-100 text-[#000] px-2 ${styles2.inputTagStyle}`}
                />
              </div>
            ) : (
              <div className={styles2.incidentDetailValues}>
                {sharedData &&
                sharedData.fuelValue !== null &&
                sharedData.fuelValue !== undefined
                  ? `$${parseFloat(sharedData.fuelValue).toFixed(2)}`
                  : "$0.00"}
              </div>
            )}
            {editableFields.fuelValue &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-4 d-flex">
                <div className={`col-sm-12 ${styles2.iconsContainer}`}>
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) => handleSave(event, "fuelValue")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 3L4.5 8.5L2 6"
                        stroke="currentColor"
                        stroke-width="3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) => handleCancel(event, "fuelValue")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 3L9 9M9 3L3 9"
                        stroke="currentColor"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className={`col-sm-12 ${styles2.detailsSubRow} d-flex`}>
        {/* Vehicle */}
        <div
          className={`col-sm-6 ${styles2.detailsColumnRight}`}
          onClick={() => handleFieldEdit("vehicle")}
          onMouseEnter={() => handleMouseEnter("vehicle")}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`col-sm-12 ${styles2.incidentDetailBodyHeading}`}
            style={{ color: "#6f7172" }}
          >
            VEHICLE
            <span>
              {hoveredField === "vehicle" &&
                isOpenOrReturned &&
                isFRSReviewerorManager && (
                  <EditIcon style={{ fontSize: "18px" }} />
                )}
            </span>
          </div>
          <div className="col-sm-12">
            {editableFields.vehicle &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-12">
                <div className="col-sm-12 d-flex">
                  <div className="col-sm-3" style={{ fontSize: "14px" }}>
                    Colour:
                  </div>
                  <div className="col-sm-9">
                    <select
                      style={{ height: "35px" }}
                      id="colour"
                      name="colour"
                      value={tempData.vehicleColor || ""}
                      onChange={(e) => handleFieldChange(e, "vehicleColor")}
                      className={`col-6 w-100 text-[#000] px-2 border-2 border-[#000] ${styles2.inputTagStyle}`}
                    >
                      <option className="text-[#000] px-2" value="">
                        Select colour
                      </option>
                      {colors.map((shape) => (
                        <option
                          key={shape}
                          value={shape}
                          className="text-[#000] px-2"
                        >
                          {shape}
                        </option>
                      ))}
                    </select>
                    <div className={styles2.incidentDetailValues}></div>
                  </div>
                </div>

                <div className="col-sm-12 d-flex">
                  <div className="col-sm-3" style={{ fontSize: "14px" }}>
                    Make:
                  </div>
                  <div className="col-sm-9">
                    <select
                      style={{ height: "35px" }}
                      id="make"
                      name="make"
                      value={tempData.vehicleMake || ""}
                      onChange={(e) => handleFieldChange(e, "vehicleMake")}
                      className={`col-6 w-100 text-[#000] px-2 border-2 border-[#000] ${styles2.inputTagStyle}`}
                    >
                      <option className="text-[#000] px-2" value="">
                        Select make
                      </option>
                      {models.map((shape) => (
                        <option
                          key={shape}
                          value={shape}
                          className="text-[#000] px-2"
                        >
                          {shape}
                        </option>
                      ))}
                    </select>
                    <div className={styles2.incidentDetailValues}></div>
                  </div>
                </div>

                <div className="col-sm-12 d-flex">
                  <div className="col-sm-3" style={{ fontSize: "14px" }}>
                    Shape:
                  </div>
                  <div className="col-sm-9">
                    <select
                      style={{ height: "35px" }}
                      id="shape"
                      name="shape"
                      value={tempData.vehicleShape || ""}
                      onChange={(e) => handleFieldChange(e, "vehicleShape")}
                      className={`col-6 w-100 text-[#000] px-2 border-2 border-[#000] ${styles2.inputTagStyle}`}
                    >
                      <option className="text-[#000] px-2" value="">
                        Select shape
                      </option>
                      {shapes.map((shape) => (
                        <option
                          className="text-[#000] px-2"
                          key={shape}
                          value={shape}
                        >
                          {shape}
                        </option>
                      ))}
                    </select>
                    <div className={styles2.incidentDetailValues}></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`d-flex ${styles2.incidentDetailValues}`}>
                <div className={`${styles2.mutlipleDataShow}`}>{`${
                  sharedData && sharedData.vehicleColor
                    ? sharedData.vehicleColor
                    : ""
                } `}</div>
                <div className={styles2.mutlipleDataShow}>{` ${
                  sharedData && sharedData.vehicleMake
                    ? sharedData.vehicleMake
                    : ""
                }`}</div>
                <div className={styles2.mutlipleDataShow}>{`${
                  sharedData && sharedData.vehicleShape
                    ? sharedData.vehicleShape
                    : ""
                }`}</div>
              </div>
            )}
            {editableFields.vehicle &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-12 d-flex">
                <div className="col-sm-6"></div>
                <div className="col-sm-6 d-flex justify-content-center mt-1">
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) =>
                      handleSave(
                        event,
                        "vehicleColor",
                        "vehicleShape",
                        "vehicleMake"
                      )
                    }
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 3L4.5 8.5L2 6"
                        stroke="currentColor"
                        stroke-width="3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) => handleCancel(event, "vehicle")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 3L9 9M9 3L3 9"
                        stroke="currentColor"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* Reference */}
        <div
          className={`col-sm-6 ${styles2.detailsColumnLeft}`}
          onClick={() => handleFieldEdit(["fuelReference"])}
          onMouseEnter={() => handleMouseEnter("fuelReference")}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`col-sm-12 ${styles2.incidentDetailBodyHeading}`}
            style={{ color: "#6f7172" }}
          >
            REFERENCE
            <span>
              {hoveredField === "fuelReference" &&
                isOpenOrReturned &&
                isFRSReviewerorManager && (
                  <EditIcon style={{ fontSize: "18px" }} />
                )}
            </span>
          </div>
          <div className="col-sm-12 d-flex">
            {editableFields.fuelReference &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-8">
                <input
                  style={{ height: "35px" }}
                  type="text"
                  autoComplete="off"
                  id="fuelReference"
                  name="fuelReference"
                  // placeholder="e.g 235.." // Ensure tempData.fuelReference has a valid value
                  value={tempData.fuelReference || ""}
                  onChange={(e) => handleFieldChange(e, "fuelReference")}
                  className={`col-6 w-100 text-[#000] px-2 ${styles2.inputTagStyle}`}
                />
              </div>
            ) : (
              <div className={styles2.incidentDetailValues}>
                {sharedData && sharedData.fuelReference
                  ? sharedData.fuelReference
                  : ""}
              </div>
            )}
            {editableFields.fuelReference &&
            isOpenOrReturned &&
            isFRSReviewerorManager ? (
              <div className="col-sm-4 d-flex">
                <div className={`col-sm-12 ${styles2.iconsContainer}`}>
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) => handleSave(event, "fuelReference")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 3L4.5 8.5L2 6"
                        stroke="currentColor"
                        stroke-width="3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                    onClick={(event) => handleCancel(event, "fuelReference")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 3L9 9M9 3L3 9"
                        stroke="currentColor"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div
        className={`col-sm-12 ${styles2.detailsSubRow} d-flex`}
        onClick={() =>
          handleFieldEdit(
            isMotorist
              ? ["customerGender"]
              : ["customerName", "customerPhoneNumber", "customerAddress"]
          )
        }
        onMouseEnter={() => handleMouseEnter("customerGender")}
        onMouseLeave={handleMouseLeave}
      >
        {isMotorist ? (
          <div className={`col-sm-12 ${styles2.detailsMiddleColumn}`}>
            <div
              className={`col-sm-12 ${styles2.incidentDetailBodyHeading}`}
              style={{ color: "#6f7172" }}
            >
              MOTORIST
              <span>
                {hoveredField === "customerGender" &&
                  isOpenOrReturned &&
                  isFRSReviewerorManager && (
                    <EditIcon style={{ fontSize: "18px" }} />
                  )}
              </span>
            </div>
            <div className="col-sm-12">
              {editableFields.customerGender &&
              (sharedData.status === "Open" ||
                sharedData.status === "Returned To Site") &&
              isFRSReviewerorManager ? (
                <div className="row">
                  <div className="col-sm-12 d-flex">
                    <div className="col-sm-4 text-start d-flex align-items-center">
                      <span className="text-sm">Gender:</span>
                    </div>
                    <div className="col-sm-8 mydict">
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          value="female"
                          checked={tempData.customerGender === "female"}
                          onChange={(e) =>
                            handleFieldChange(e, "customerGender")
                          }
                        />
                        <span className="text-sm">Female</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          value="male"
                          checked={tempData.customerGender === "male"}
                          onChange={(e) =>
                            handleFieldChange(e, "customerGender")
                          }
                        />
                        <span className="text-sm">Male</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          value="Unknown"
                          checked={tempData.customerGender === "Unknown"}
                          onChange={(e) =>
                            handleFieldChange(e, "customerGender")
                          }
                        />
                        <span className="text-sm">Unknown</span>
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles2.incidentDetailValues}>
                  {sharedData && sharedData.customerGender
                    ? sharedData.customerGender.toUpperCase()
                    : ""}
                </div>
              )}
              {editableFields.customerGender &&
              (sharedData.status === "Open" ||
                sharedData.status === "Returned To Site") &&
              isFRSReviewerorManager ? (
                <div className="row">
                  <div className="col-sm-12 d-flex">
                    <div className="col-sm-4 text-start d-flex align-items-center"></div>
                    <div className="col-sm-8">
                      <div className="row">
                        <div className="col-sm-12 d-flex justify-content-start ms-1 mt-2">
                          <button
                            className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                            onClick={(event) =>
                              handleSave(event, "customerGender")
                            }
                          >
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 3L4.5 8.5L2 6"
                                stroke="currentColor"
                                stroke-width="3"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <button
                            className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                            onClick={(event) =>
                              handleCancel(event, "customerGender")
                            }
                          >
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 3L9 9M9 3L3 9"
                                stroke="currentColor"
                                stroke-width="3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div
            className={`col-sm-12 ${styles2.detailsMiddleColumn}`}
            onClick={() => handleFieldEdit("customer")}
            onMouseEnter={() => handleMouseEnter("customer")}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className={`col-sm-12 ${styles2.incidentDetailBodyHeading}`}
              style={{ color: "#6f7172" }}
            >
              CUSTOMER
              <span>
                {hoveredField === "customer" &&
                  isOpenOrReturned &&
                  isFRSReviewerorManager && (
                    <EditIcon style={{ fontSize: "18px" }} />
                  )}
              </span>
            </div>
            <div className="col-sm-12">
              {editableFields.customerName &&
              editableFields.customerPhoneNumber &&
              editableFields.customerAddress &&
              (sharedData.status === "Open" ||
                sharedData.status === "Returned To Site") &&
              isFRSReviewerorManager ? (
                <div className="col-sm-12">
                  <div className="col-sm-12 d-flex">
                    <div className="col-sm-2">Name:</div>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        id="customerName"
                        name="customerName"
                        autoComplete="off"
                        maxLength={40}
                        placeholder="e.g. james"
                        style={{ fontSize: "14px", marginBottom: "5px" }}
                        value={tempData.customerName || ""}
                        onChange={(e) => handleFieldChange(e, "customerName")}
                        className={`col-6 w-100 ${styles2.inputTagStyle}`}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 d-flex">
                    <div className="col-sm-2">Phone:</div>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        id="customerPhoneNumber"
                        autoComplete="off"
                        placeholder="e.g. 9753772XXXXX"
                        style={{ fontSize: "14px", marginBottom: "5px" }}
                        name="customerPhoneNumber"
                        value={tempData.customerPhoneNumber || ""}
                        onChange={(e) =>
                          handleFieldChange(e, "customerPhoneNumber")
                        }
                        className={`col-6 w-100 ${styles2.inputTagStyle}`}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 d-flex">
                    <div className="col-sm-2">Address:</div>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        id="customerAddress"
                        autoComplete="off"
                        name="customerAddress"
                        maxLength={80}
                        placeholder="e.g. st street 1..."
                        style={{ fontSize: "14px", marginBottom: "5px" }}
                        value={tempData.customerAddress || ""}
                        onChange={(e) =>
                          handleFieldChange(e, "customerAddress")
                        }
                        className={`col-6 w-100 ${styles2.inputTagStyle}`}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="  ">
                  <p className={`m-0 ${styles2.mutlipleDataShow}`}>{`${
                    sharedData && sharedData.customerName
                      ? sharedData.customerName
                      : " "
                  } `}</p>

                  <p className={`m-0 ${styles2.mutlipleDataShow}`}>
                    {containsStatusKeyword
                      ? "(Phone Number Removed)"
                      : sharedData && sharedData.customerPhoneNumber
                      ? sharedData.customerPhoneNumber
                      : " "}
                  </p>

                  <p className={`m-0 ${styles2.mutlipleDataShow}`}>
                    {containsStatusKeyword
                      ? "(Address Removed)"
                      : sharedData && sharedData.customerAddress
                      ? sharedData.customerAddress
                      : " "}
                  </p>
                </div>
              )}
              {editableFields.customerName &&
              editableFields.customerPhoneNumber &&
              editableFields.customerAddress &&
              (sharedData.status === "Open" ||
                sharedData.status === "Returned To Site") &&
              isFRSReviewerorManager ? (
                <div className="col-sm-12 d-flex">
                  <div className="col-sm-10"></div>
                  <div className="col-sm-1 ">
                    <button
                      className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                      onClick={(event) =>
                        handleSave(
                          event,
                          "customerName",
                          "customerPhoneNumber",
                          "customerAddress"
                        )
                      }
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 3L4.5 8.5L2 6"
                          stroke="currentColor"
                          stroke-width="3"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="col-sm-1">
                    <button
                      className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center me-2"
                      onClick={(event) =>
                        handleCancel(event, [
                          "customerName",
                          "customerPhoneNumber",
                          "customerAddress",
                        ])
                      }
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 3L9 9M9 3L3 9"
                          stroke="currentColor"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
      {/* showing verify buttons and text */}
      {sharedData.status === "Open" && (
        <div className={`col-sm-12 ${styles2.detailsLastRow}`}>
          {sharedData.type === DRIVE_OFF ? (
            <div className="col-sm-12 d-flex h-100">
              <div className="col-sm-6 d-flex flex-column justify-content-start">
                <div>
                  <div
                    className={`col-sm-12 mb-2 ${styles2.incidentDetailBodyHeading}`}
                    style={{ color: "#6f7172" }}
                  >
                    <span className="">
                      Did the person enter the store and make a transaction?
                    </span>
                  </div>
                  <div className="col-sm-12 d-flex ">
                    <div className="col-sm-1 ">
                      <input
                        type="radio"
                        id="option1"
                        value="option1"
                        checked={selectedOption === "option1"}
                        onChange={handleOptionChange}
                        className={`${styles2.radio}`}
                      />
                    </div>
                    <div className="col-sm-10 ">
                      <label
                        htmlFor="option1"
                        className={`${styles2.radioBtnLabelsDetailsPage} p-0`}
                      >
                        NO the person did not enter
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 d-flex">
                    <div className="col-sm-1 ">
                      <input
                        type="radio"
                        id="option2"
                        value="option2"
                        checked={selectedOption === "option2"}
                        onChange={handleOptionChange}
                        className={`${styles2.radio}`}
                      />
                    </div>
                    <div className="col-sm-10">
                      <label
                        htmlFor="option2"
                        className={`${styles2.radioBtnLabelsDetailsPage} p-0`}
                      >
                        YES the person entered the store, but NO transaction was
                        made
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 d-flex">
                    <div className="col-sm-1 ">
                      <input
                        type="radio"
                        id="option3"
                        value="option3"
                        checked={selectedOption === "option3"}
                        onChange={handleOptionChange}
                        className={`${styles2.radio}`}
                      />
                    </div>
                    <div className="col-sm-10">
                      <label
                        htmlFor="option3"
                        className={`${styles2.radioBtnLabelsDetailsPage} p-0`}
                      >
                        YES the person made a transaction, but did not pay for
                        the fuel
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 align-self-center mt-4">
                  <div className="col-sm-12 d-flex justify-content-between">
                    {sharedData &&
                      sharedData.siteType.toUpperCase() !== "CONNECT" && (
                        <div className="col-sm-6">
                          <button
                            className={styles.CloseButton}
                            onClick={() =>
                              regenerateFootage(pkAlert, sharedData.siteName)
                            }
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            Regenerate
                            <BsFillCameraVideoFill className="ms-1" />
                          </button>
                        </div>
                      )}
                    <div className="col-sm-6">
                      <button
                        className={styles.CloseButton}
                        onClick={handleClickCloseIncidentModal}
                      >
                        Close Incident
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">{renderComponent()}</div>
            </div>
          ) : (
            <div className="col-sm-12 d-flex h-100">
              <div className="col-sm-6 d-flex flex-column justify-content-between">
                <div
                  className={`col-sm-12 ${styles2.incidentDetailBodyHeading}`}
                  style={{ color: "#6f7172" }}
                >
                  <div>
                    <span>Have you called the customer?</span>
                    <div
                      className={`text-danger text-small mt-2 ${
                        showWarning ? "" : "d-none"
                      }`}
                    >
                      *You must try to call customer before verifying an IOU
                    </div>
                  </div>
                  <div className="d-flex p-1 mb-4">
                    <p
                      className={`mutliple-data-show ${styles.customerBtn} ${
                        selectedButton === "yes" ? styles.selectedButton : ""
                      }`}
                      onClick={() => handleButtonClick("yes")}
                    >
                      Yes
                    </p>
                    <p
                      className={`mutliple-data-show ${styles.customerBtn} ${
                        selectedButton === "no" ? styles.selectedButton : ""
                      }`}
                      onClick={() => handleButtonClick("no")}
                    >
                      No
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 align-self-center mt-4">
                  <div className="col-sm-12 d-flex justify-content-between">
                    {sharedData &&
                      sharedData.siteType.toUpperCase() !== "CONNECT" && (
                        <div className="col-sm-6">
                          <button
                            className={styles.CloseButton}
                            onClick={() =>
                              regenerateFootage(pkAlert, sharedData.siteName)
                            }
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            Regenerate
                            <BsFillCameraVideoFill className="ms-1" />
                          </button>
                        </div>
                      )}
                    <div className="col-sm-6">
                      <button
                        className={styles.CloseButton}
                        onClick={handleClickCloseIncidentModal}
                      >
                        Close Incident
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div>
                  <p
                    className={styles2.incidentDetailBodyHeading}
                    style={{ color: "#6f7172" }}
                  >
                    Enter the details of the call, or note if there was no
                    answer:
                  </p>
                  <textarea
                    id="myTextarea"
                    value={note}
                    onChange={handleChange}
                    // // placeholder="Enter text here..."
                    className={`${styles.customTextarea} text-[#000] px-2`}
                  />
                </div>
                {showText && (
                  <div>
                    <div className={styles2.incidentTermsConditionsText}>
                      By verifying the incident I confirm that I have reviewed
                      the footage and all incident details are correct.
                      <br />
                      <span>
                        I acknowledge that the motorist has 4 days to pay for
                        the fuel, after which this incident will be escalated to
                        debt recovery where fees may apply.
                      </span>
                    </div>
                    {buttonActive && (
                      <button
                        className={styles2.reviewSubmitButton}
                        style={{ backgroundColor: ctaBackgroudColor }}
                        onClick={(event) => handleClick(event, "Verified")}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          Verify for Debt Recovery
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginLeft: "8px" }} // Add margin for spacing
                          >
                            <path
                              d="M10 3L4.5 8.5L2 6"
                              stroke="currentColor"
                              strokeWidth="2.5"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {sharedData.status === "Escalated" &&
        (userRole === "frs-reviewer" ||
          userRole === "sensenadmin" ||
          userRole === "customeradmin") && (
          <div div className={`col-sm-12 ${styles2.detailsLastRow}`}>
            <div className="col-sm-12 d-flex">
              <div className="col-sm-6 d-flex flex-column justify-content-evenly">
                <div className="text-center">
                  <button
                    className={styles.CloseButton}
                    onClick={handleClickCloseIncidentModal}
                  >
                    Close Incident
                  </button>
                </div>
              </div>
              <div className="col-sm-6 flex-column">
                <div className="text-start">
                  {buttonActive && (
                    <button
                      className={styles2.reviewSubmitButton}
                      onClick={(event) => handleClick(event, "Pre-Court")}
                      style={{ backgroundColor: ctaBackgroudColor }}
                    >
                      <span>Set To Pre-Court</span>
                      <span>
                        <DoneIcon
                          style={{ backgroundColor: ctaBackgroudColor }}
                        />
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      {/* showing verify button when status is Return to site */}
      {sharedData.status === "Returned To Site" &&
      (userRole === "frs-reviewer" ||
        userRole === "site-manager" ||
        userRole === "sensenadmin" ||
        userRole === "customeradmin") ? (
        // Render content for DRIVE_OFF type with all conditions
        <div div className={`col-sm-12 ${styles2.detailsLastRow}`}>
          <div className="col-sm-12 d-flex">
            <div className="col-sm-6 d-flex flex-column justify-content-end">
              <div className="col-sm-12 d-flex justify-content-between">
                {sharedData &&
                  sharedData.siteType.toUpperCase() !== "CONNECT" && (
                    <div className="col-sm-6">
                      <button
                        className={styles.CloseButton}
                        onClick={() =>
                          regenerateFootage(pkAlert, sharedData.siteName)
                        }
                        style={{ display: "inline-flex", alignItems: "center" }}
                      >
                        Regenerate <BsFillCameraVideoFill className="ms-1" />
                      </button>
                    </div>
                  )}
                <div className="col-sm-6">
                  <button
                    className={styles.CloseButton}
                    onClick={handleClickCloseIncidentModal}
                  >
                    Close Incident
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-6 flex-column justify-content-end">
              <div className={`${styles2.incidentTermsConditionsText}`}>
                <br />
                <span>
                  By verifying the incident I confirm that I have reviewed the
                  footage and all incident details are correct. This incident
                  will be escalated to debt recovery where fees may apply.
                </span>
              </div>
              <div className="text-start">
                {buttonActive && (
                  <button
                    className={styles2.reviewSubmitButton}
                    style={{ backgroundColor: ctaBackgroudColor }}
                    onClick={(event) => handleClick(event, "Verified")}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      Verify for Debt Recovery
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginLeft: "8px" }} // Add margin for spacing
                      >
                        <path
                          d="M10 3L4.5 8.5L2 6"
                          stroke="currentColor"
                          strokeWidth="2.5"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {sharedData.status === "Pre-Court" &&
        (userRole === "frs-reviewer" ||
          userRole === "sensenadmin" ||
          userRole === "customeradmin") && (
          <div div className={`col-sm-12 ${styles2.detailsLastRow}`}>
            <div className="col-sm-6 d-flex flex-column justify-content-evenly mt-3 mb-3">
              <div className="text-center">
                <button
                  className={styles.CloseButton}
                  onClick={handleClickCloseIncidentModal}
                >
                  Close Incident
                </button>
              </div>
            </div>
          </div>
        )}
      {sharedData.status === "Verified" && (
        <div div className={`col-sm-12 ${styles2.detailsLastRow}`}>
          <div className="col-sm-6 d-flex flex-column justify-content-evenly mt-3 mb-3">
            <div className="text-center">
              <button
                className={styles.CloseButton}
                onClick={handleClickCloseIncidentModal}
              >
                Close Incident
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default React.memo(Details);
