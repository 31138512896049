import styles from "../../CSS/Base/Basic.module.css";
import styles2 from "../../CSS/troubleshooting.module.css";
import { SlBookOpen } from "react-icons/sl";
import { BsArrowRightCircle } from "react-icons/bs";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import userSession from "../../Constants/auth";
import trouble_pepwave_front from "../../Assets/troubleshootingImages/trouble_pepwave_front.webp";
import { useState } from "react";
import Accordion from "../../Components/common/Accordion";
const TroubleshootingPage = () => {
  const { themeData } = userSession;
  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const [showAdditionalContent, setShowAdditionalContent] = useState(false);
  const [showSecondAdditionalContent, setShowSecondAdditionalContent] =
    useState(false);
  const [showThirdAdditionalContent, setShowThirdAdditionalContent] =
    useState(false);
  const [showFourthAdditionalContent, setShowFourthAdditionalContent] =
    useState(false);
  const [showFifthAdditionalContent, setFifthAdditionalContent] =
    useState(false);
  const [
    showThirdLastOptionAdditionalContent,
    setShowThirdLastOptionAdditionalContent,
  ] = useState(false);

  const handleContinueRadioChange = () => {
    setShowAdditionalContent(!showAdditionalContent);
  };

  const handleSecondContinueRadioChange = () => {
    setShowSecondAdditionalContent(!showSecondAdditionalContent);
  };
  const handleThirdContinueRadioChange = () => {
    setShowThirdAdditionalContent(!showThirdAdditionalContent);
  };
  const handleFourthContinueRadioChange = () => {
    setFifthAdditionalContent(!showFifthAdditionalContent);
  };
  const handleThirdLastOptionContinueRadioChange = () => {
    setShowThirdLastOptionAdditionalContent(
      !showThirdLastOptionAdditionalContent
    );
  };
  const troubleShootData1 = (
    <div>
      <p className={styles2.TroubleshootingPageSubHeadings}>
        Restart Scancam App
      </p>
      <div className={styles2.TroubleshootingPageDataBox}>
        Double click the Home button on the iPad
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Swipe up on the Scancam app to close it
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Re-open the Scancam app
      </div>
      <p className={styles2.TroubleshootingPageSubData}>
        If the app is still not working after the restart then try restarting
        the iPad
      </p>

      <p className={styles2.TroubleshootingPageSubHeadings}>Restart iPad</p>
      <div className={styles2.TroubleshootingPageDataBox}>
        Hold the power button down on the iPad until the 'slide to power off'
        slider appears
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Slide to power off the iPad and wait until the iPad has turned off
        completely
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Restart the iPad by pressing the power button
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Once rebooted, open the Scancam App
      </div>
      <p className={styles2.TroubleshootingPageSubData}>
        If the iPad and app are still not working after the restarts then email
        support@scancam.com.au
      </p>
    </div>
  );
  const troubleShootData2 = (
    <div>
      <p className={styles2.TroubleshootingPageSubHeadings}>
        Restart Scancam App
      </p>
      <div className={styles2.TroubleshootingPageDataBox}>
        Double click the Home button on the iPad
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Swipe up on the Scancam app to close it
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Re-open the Scancam app
      </div>
      <p className={styles2.TroubleshootingPageSubData}>
        If the app is still not working after the restart then try restarting
        the iPad
      </p>
      <p className={styles2.TroubleshootingPageSubHeadings}>Restart iPad</p>
      <div className={styles2.TroubleshootingPageDataBox}>
        Hold the power button down on the iPad until the 'slide to power off'
        slider appears
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Slide to power off the iPad and wait until the iPad has turned off
        completely
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Restart the iPad by pressing the power button
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Once rebooted, open the Scancam App
      </div>
      <p className={styles2.TroubleshootingPageSubData}>
        If the issue remains then restart the Pepwave modem.
      </p>
      <p className={styles2.TroubleshootingPageSubHeadings}>
        Restart Pepwave Modem
      </p>
      <div className={styles2.TroubleshootingPageDataBox}>
        Remove the cable from the back of the device or unplug the power plug
        labelled Pepwave from the PDU
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Wait five seconds then plug it back in. The modem usually takes around
        two minutes to reboot
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Once the two LED lights on the front of the modem turn green then it is
        ready
      </div>
      <div className={styles2.TroubleshootingPageImage}>
        <img src={trouble_pepwave_front} alt="troublePepwaveFront" />
      </div>
    </div>
  );
  const troubleShootData3 = (
    <div>
      <p className={styles2.TroubleshootingPageSubHeadings}>
        Restart Scancam App
      </p>
      <p className={styles2.TroubleshootingPageSubData}>
        Follow the steps below to restart the Scancam iPad app to see if it
        resolves the issue:
      </p>
      <div className={styles2.TroubleshootingPageDataBox}>
        Double click the Home button on the iPad
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Swipe up on the Scancam app to close it
      </div>
      <div className={styles2.TroubleshootingPageDataBox}>
        Re-open the Scancam app
      </div>
      <p className={styles2.TroubleshootingPageSubData}>
        <span>
          <AiOutlineQuestionCircle style={{ fontSize: "20px" }} />
        </span>
        If restarting the app didn't fix the problem select continue.
      </p>
      <p>
        <label className={`${styles2.TroubleshootingPageCheckButton} `}>
          <input
            type="radio"
            name="continueOption"
            checked={showAdditionalContent}
            onChange={handleContinueRadioChange}
            className="ms-2 me-1"
          />
          Continue
        </label>
      </p>
      {showAdditionalContent && (
        <div id="additionalContent">
          <p className={styles2.TroubleshootingPageSubHeadings}>Restart iPad</p>
          <div className={styles2.TroubleshootingPageDataBox}>
            Hold the power button down on the iPad until the 'slide to power
            off' slider appears
          </div>
          <div className={styles2.TroubleshootingPageDataBox}>
            Slide to power off the iPad and wait until the iPad has turned off
            completely
          </div>
          <div className={styles2.TroubleshootingPageDataBox}>
            Restart the iPad by pressing the power button
          </div>
          <div className={styles2.TroubleshootingPageDataBox}>
            Once rebooted, open the Scancam App
          </div>
          <p className={styles2.TroubleshootingPageSubData}>
            <span>
              <AiOutlineQuestionCircle style={{ fontSize: "20px" }} />
            </span>
            If restarting the iPad didn't fix the problem select continue.
          </p>

          {/* /////////////// */}
          <p>
            <label className={styles2.TroubleshootingPageCheckButton}>
              <input
                type="radio"
                name="continueSecondOption"
                checked={showSecondAdditionalContent}
                onChange={handleSecondContinueRadioChange}
                className="ms-2 me-1"
              />
              Continue
            </label>
          </p>
          {showSecondAdditionalContent && (
            <div id="continueSecondOption">
              <p className={styles2.TroubleshootingPageSubHeadings}>
                Restart Pepwave Modem
              </p>
              <p className={styles2.TroubleshootingPageSubData}>
                Follow the steps below to restart the Pepwave modem to see if it
                resolves the issue:
              </p>
              <div className={styles2.TroubleshootingPageDataBox}>
                Remove the cable from the back of the device or unplug the power
                plug labelled Pepwave from the PDU
              </div>
              <div className={styles2.TroubleshootingPageDataBox}>
                Wait five seconds then plug it back in. The modem usually takes
                around two minutes to reboot
              </div>
              <div className={styles2.TroubleshootingPageDataBox}>
                Once the two LED lights on the front of the modem turn green
                then it is ready
              </div>
              <div className={styles2.TroubleshootingPageImage}>
                <img src={trouble_pepwave_front} alt="troublePepwaveFront" />
              </div>
              <p className={styles2.TroubleshootingPageSubData}>
                <span>
                  <AiOutlineQuestionCircle style={{ fontSize: "20px" }} />
                </span>
                If restarting the modem didn't fix the problem select continue.
              </p>

              {/* ///////////////// */}
              <p>
                <label className={styles2.TroubleshootingPageCheckButton}>
                  <input
                    type="radio"
                    name="continueThirdOption"
                    checked={showThirdLastOptionAdditionalContent}
                    onChange={handleThirdLastOptionContinueRadioChange}
                    className="ms-2 me-1"
                  />
                  Continue
                </label>
              </p>
              {showThirdLastOptionAdditionalContent && (
                <div id="continueThirdOption">
                  <p className={styles2.TroubleshootingPageSubHeadings}>
                    Restart Switch
                  </p>
                  <p className={styles2.TroubleshootingPageSubData}>
                    Follow the steps below to restart the switch to see if it
                    resolves the issue:
                  </p>
                  <div className={styles2.TroubleshootingPageDataBox}>
                    Unplug the power cable marked Netgear switch from the PDU
                  </div>
                  <div className={styles2.TroubleshootingPageDataBox}>
                    Wait five seconds then plug it back in. The switch usually
                    takes around two minutes to reboot.
                  </div>

                  <p className={styles2.TroubleshootingPageSubData}>
                    <span>
                      <AiOutlineQuestionCircle style={{ fontSize: "20px" }} />
                    </span>
                    If restarting the switch didn't fix the problem then email
                    support@scancam.com.au
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
  const troubleShootData4 = (
    <div>
      <p className={styles2.TroubleshootingPageSubData}>
        For one of the pumps not showing plates, press the empty bay and select
        'View Image'
      </p>
      <p className={styles2.TroubleshootingPageSubData}>
        <span>
          <AiOutlineQuestionCircle style={{ fontSize: "20px" }} />
        </span>
        Did a photo of the vehicle bay appear?
      </p>
      <p className={styles2.TroubleshootingPageRadioOptionsButton}>
        <label className={styles2.TroubleshootingPageCheckButton}>
          <input
            type="radio"
            name="troubleshootingOption"
            checked={showThirdAdditionalContent}
            onChange={handleThirdContinueRadioChange}
            className="ms-2 me-1"
          />
          Yes
        </label>
        <label className={styles2.TroubleshootingPageCheckButton}>
          <input
            type="radio"
            name="troubleshootingOption"
            checked={!showThirdAdditionalContent}
            onChange={handleThirdContinueRadioChange}
            className="ms-2 me-1"
          />
          No
        </label>
      </p>
      {showThirdAdditionalContent && (
        <div id="troubleshootingOption">
          <p className={styles2.TroubleshootingPageSubData}>
            For one of the pumps not showing plates, press the empty bay and
            select 'Detect'
          </p>
          <p className={styles2.TroubleshootingPageSubData}>
            <span>
              <AiOutlineQuestionCircle style={{ fontSize: "20px" }} />
            </span>
            If the licence plate appears then the system is working for that
            particular pump. Repeat for the other pumps. If the plate doesn't
            appear for a pump select continue.
          </p>
          <p>
            <label className={styles2.TroubleshootingPageCheckButton}>
              <input
                type="radio"
                name="continueOption"
                checked={showFifthAdditionalContent}
                onChange={handleFourthContinueRadioChange}
                className="ms-2 me-1"
              />
              Continue
            </label>
          </p>
          {showFifthAdditionalContent && (
            <div id="additionalContent">
              <p className={styles2.TroubleshootingPageSubData}>
                Email support@scancam.com.au with details of the issue including
                the pump number(s) and date/time the issue occurred.
              </p>
              <p className={styles2.TroubleshootingPageSubData}>
                In the meantime, use Manual Input to enter the licence plates on
                the iPad until the issue has been resolved.
              </p>
              {showFourthAdditionalContent && (
                <div id="additionalContent"></div>
              )}
            </div>
          )}
        </div>
      )}

      {!showThirdAdditionalContent && (
        <div id="additionalContent">
          <p className={styles2.TroubleshootingPageSubHeadings}>
            Restart Switch
          </p>
          <p className={styles2.TroubleshootingPageSubData}>
            Follow the steps below to restart the switch to see if it resolves
            the issue:
          </p>
          <div className={styles2.TroubleshootingPageDataBox}>
            Unplug the power cable marked Netgear switch from the PDU
          </div>
          <div className={styles2.TroubleshootingPageDataBox}>
            Wait five seconds then plug it back in. The switch usually takes
            around two minutes to reboot.
          </div>
          <p className={styles2.TroubleshootingPageSubData}>
            If restarting the switch didn't fix the problem then email
            support@scancam.com.au
          </p>
        </div>
      )}
    </div>
  );

  const troubleShootData = [
    {
      title: "iPad App Crashes/Frozen",
      content: troubleShootData1,
    },
    {
      title: "iPad Offline",
      content: troubleShootData2,
    },
    {
      title: "iPad Not Showing Licence Plates on All Pumps",
      content: troubleShootData3,
    },
    {
      title: "iPad Not Showing Licence Plates on Specific Pumps",
      content: troubleShootData4,
    },
  ];
  return (
    <div className={styles.PageMainBoxOther}>
      <div className={styles2.TroubleshootingPageTitleBox}>
        <div className={styles2.TroubleshootingPageTitle}>
          <h2 className={styles.Title}>Troubleshooting</h2>
        </div>
      </div>
      <div className={styles2.TroubleshootingPageBox}>
        <div className={styles2.TroubleshootingPageContainer}>
          <div
            className={`d-flex justify-content-center align-items-center ${styles2.TroubleshootingPageHeading}`}
          >
            <div
              className={styles2.TroubleshootingPageCircle}
              style={{ color: textAccentColor }}
            >
              <SlBookOpen style={{ color: textAccentColor }} />
            </div>
            <h3
              className={styles2.TroubleshootingPageRowHeadings}
              style={{ color: textAccentColor }}
            >
              Guides
            </h3>
          </div>
        </div>
      </div>

      <div className={styles2.TroubleshootingPageDataContainer}>
        {troubleShootData.map((data, index) => (
          <div className="container mx-auto p-0" key={index}>
            <Accordion title={data.title} content={data.content} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default TroubleshootingPage;
