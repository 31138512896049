import React, { useEffect, useRef, useState } from "react";
import "../../CSS/ReportsPage.css";
import UseReportsApi from "../../Custom Hooks/useReportsApi";
import userSession from "../../Constants/auth";
import generatePayload from "../../Custom Hooks/generatePayload";
import { APP_SERVICES_URL } from "../../Constants/constants";
import StackedBarChart from "../../Charts/StackedBarChart";
import MaterialUIMultiSelectCheckmarks from "../../Components/ViewReportPage/MaterialUIMultiSelectCheckmarks";
import CustomizedTable from "../../Components/ViewReportPage/CustomizedTable";
import CSVDownloadButton from "../../Components/ViewReportPage/CSVDownloadButton";
import ErrorModal from "../../Modals/ErrorModal";
import ReportTypeModal from "../../Modals/ReportTypeModal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import EmailModal from "../../Modals/EmailModal";
import PrintIcon from "@mui/icons-material/Print";
import LaunchIcon from "@mui/icons-material/Launch";
import EmailIcon from "@mui/icons-material/Email";
import { useApiDataContext } from "../../ApiDataContext";
import moment from "moment";
import RotatingIcon from "../../Components/Base/RotatingIcon";
import TitileReport from "../../Components/common/TitileReport";

const SiteOperations = () => {
  const { authHeader, sites, CustID, userRole } = userSession;
  const getSitesNames = (sites) => {
    if (!sites || !Array.isArray(sites)) {
      return [];
    }
    return sites.map((site) => site.name);
  };
  const siteNames = getSitesNames(sites);

  //getting all the themes colour
  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const chart1Color = userSession.themeData?.chart1Color || "#6f7172";
  const chart2Color = userSession.themeData?.chart2Color || "#e4e4e4";
  const chart3Color = userSession.themeData?.chart3Color || "#e27a13";
  const chart4Color = userSession.themeData?.chart4Color || "#ffcb07";
  const { customersList, statesList, productsList } = useApiDataContext();

  const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-01");
  const endTime = moment().format("YYYY-MM-DD");

  const [showResult, setShowResult] = useState(false);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(endTime);
  const [selectedSite, setSelectedSite] = useState([]);
  const [selectedStates, setSelectedStates] = useState(statesList);
  const [selectedBrands, setSelectedBrands] = useState(customersList);
  const [selectedProducts, setSelectedProducts] = useState(productsList);
  const [apiResponse, setApiResponse] = useState(null);
  const [emailReport, setEmailReport] = useState(false);

  const openEmailModal = () => {
    setButtonClicked("emailReport");
    setEmailReport(true);
  };

  const closeEmailModal = () => {
    setEmailReport(false);
    setApiResponse(null);
  };
  const [accordionState, setAccordionState] = useState({
    DRIVE_OFF: false,
    IOU: false,
    CANCELLED: false,
    EXPIRED: false,
  });

  const openAllAccordions = () => {
    setAccordionState({
      DRIVE_OFF: true,
      IOU: true,
      CANCELLED: true,
      EXPIRED: true,
    });
  };
  const closeAllAccordions = () => {
    setAccordionState({
      DRIVE_OFF: false,
      IOU: false,
      CANCELLED: false,
      EXPIRED: false,
    });
  };

  // accordion

  const contentRefs = {
    DRIVE_OFF: useRef(null),
    IOU: useRef(null),
    CANCELLED: useRef(null),
    EXPIRED: useRef(null),
  };

  const [contentHeights, setContentHeights] = useState({
    DRIVE_OFF: 0,
    IOU: 0,
    CANCELLED: 0,
    EXPIRED: 0,
  });

  useEffect(() => {
    Object.keys(contentRefs).forEach((key) => {
      if (contentRefs[key].current) {
        setContentHeights((prev) => ({
          ...prev,
          [key]: contentRefs[key].current.scrollHeight,
        }));
      }
    });
  }, [accordionState]);

  const toggleAccordionNew = (accordionName) => {
    setAccordionState({
      ...accordionState,
      [accordionName]: !accordionState[accordionName],
    });
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const formatDate = (dateString, isStart) => {
    const utcTime = moment.utc(dateString); // Assuming the UTC time is '2024-04-30T05:10:00'
    let localTime = utcTime.local(); // Convert to local time zone

    if (isStart) {
      // Set the time to 00:00:00 for the start date
      localTime.startOf("day");
    } else {
      localTime = localTime.endOf("day");
    }

    const localTimeWithoutOffset = localTime.format("YYYY-MM-DDTHH:mm:ss.SSS"); // Omitting the timezone offset

    return localTimeWithoutOffset;
  };

  // Define state variables for data, loading, and error
  const [data, setData] = useState(null);
  const [newdata, setNewData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonClicked, setButtonClicked] = useState(null);
  const fetchData = UseReportsApi();
  const fetchReportData = async (payloadData) => {
    const { CustID, authHeader } = userSession;

    try {
      setError(false);
      const queryParams = new URLSearchParams({
        limit: 10000,
        offset: 0,
      });
      const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incidentsList?${queryParams.toString()}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader, // Convert to base64 (Basic Auth)
        },
        body: JSON.stringify(payloadData), // Convert payloadData to JSON string
      });

      if (!response.ok) {
        setError(true);

        setErrorMessage(
          "Failed to fetch report data. Please try again. If the issue persists, contact support."
        );
      }

      const data = await response.json();
      setIsLoading(false);
      return data;
    } catch (error) {
      setError(true);

      setErrorMessage(
        "Failed to fetch report data. Please try again. If the issue persists, contact support."
      );
      return [];
    }
  };

  const updateDataStateWithSpecificValues = (result) => {
    // Create a dictionary to store the specific values.
    const specificValues = {};
    // Check for the specific values in the result variable.
    for (const keyword of [
      "numDriveOffs",
      "numIOUs",
      "numCancelled",
      "numExpired",
    ]) {
      if (result[keyword]) {
        specificValues[keyword] = [];
      }
    }

    // Update the data state with the specific values.
    setNewData({
      ...specificValues,
    });
  };

  const handleSubmit = async (e) => {
    const formattedStartDate = formatDate(startDate, true);
    const formattedEndDate = formatDate(endDate, false);

    const payloadData = {
      sites: selectedSite,
      states: selectedStates,
      brands: selectedBrands,
      products: selectedProducts,
      to: formattedEndDate,
      from: formattedStartDate,
    };
    if (payloadData.sites.length < 1) {
      return;
    }
    try {
      setIsLoading(true);
      setError(false);
      const result = await fetchData(
        authHeader,
        CustID,
        "siteOperations",
        payloadData
      );
      updateDataStateWithSpecificValues(result);
      setData(result);
      setShowResult(true);
    } catch (error) {
      setError(true);
      setErrorMessage(
        "Failed to fetch report data. Please try again. If the issue persists, contact support."
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Define a function for fetching and updating report data
  const fetchAndUpdateReportData = async () => {
    const formattedStartDate = formatDate(startDate, true);
    const formattedEndDate = formatDate(endDate, false);
    const allKeys = Object.keys(newdata);

    // Iterate over the keys in the newData state and call the fetchReportData API for each key.
    for (const key of allKeys) {
      // Check if the key is one of the specific keys.
      if (
        ["numDriveOffs", "numIOUs", "numCancelled", "numExpired"].includes(key)
      ) {
        // Change the name of the key to the appropriate value.
        const newKey = {
          numDriveOffs: "DRIVE OFF",
          numIOUs: "IOU",
          numCancelled: "CANCELLED",
          numExpired: "EXPIRED",
        }[key];

        // Generate the payload for the API call.
        const payload = generatePayload(
          newKey,
          formattedStartDate,
          formattedEndDate,
          selectedSite,
          selectedBrands,
          selectedProducts,
          selectedStates
        );

        try {
          payload.isFuelLitresRequired = true;
          payload.isSiteAddressRequired = true;
          payload.isProcessingFeeRequired = true;
          payload.isEmployeeNameRequired = true;
          payload.isSiteTypeRequired = true;
          payload.isFuelTypeRequired = true;
          payload.isStoreIDRequired = true;
          setError(false);
          const result = await fetchReportData(payload, newKey);
          // Update the newData state with the result.
          newdata[key] = result;
        } catch (error) {
          setError(true);
          setErrorMessage(
            "Failed to fetch report data. Please try again. If the issue persists, contact support."
          );
        }
      }
    }

    // Set the new newData state.
    setNewData(newdata);
  };

  useEffect(() => {
    fetchAndUpdateReportData();
    // eslint-disable-next-line
  }, [newdata]);

  // Prepare the chart data based on the values you want to pass
  const properties = [
    "expiredAmount",
    "cancelledAmount",
    "recoveredIncidents.recoveredAmount",
    "outstandingIncidents.outstandingAmount",
  ];
  const colors = [chart1Color, chart2Color, chart3Color, chart4Color];
  const chartData = [];

  properties.forEach((property, index) => {
    const value = getProperty(data, property);
    chartData.push({
      data: [value],
      color: colors[index],
    });
  });

  // Helper function to safely access nested properties
  function getProperty(obj, path) {
    return path
      .split(".")
      .reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : 0),
        obj
      );
  }

  // Watch for changes in the selectedSite array
  useEffect(() => {
    if (selectedSite.length === 0) {
      // Clear your state here, replace with your actual state clearing logic
      setData(null);
      setShowResult(false);
    }
  }, [selectedSite]); // This effect will run whenever selectedSite changes
  const numDriveOffs = data?.numDriveOffs ?? 0;
  const numIOUs = data?.numIOUs ?? 0;
  const sum = numDriveOffs + numIOUs;
  const handleCloseErrorModal = () => {
    setError(false);
  };

  const [isReportTypeModalOpen, setIsReportTypeModalOpen] = useState(false);

  const openReportTypeModal = () => {
    setButtonClicked("printPDF");
    setIsReportTypeModalOpen(true);
  };

  const closeReportTypeModal = () => {
    setIsReportTypeModalOpen(false);
  };

  const handleReportTypeSelection = (reportType, emailAddress) => {
    // Handle the selected report type (detailed or summary) here.

    if (reportType === "summary") {
      // Call generatePDF for a summary report
      closeAllAccordions();
      setTimeout(() => {
        generatePDF("SiteOperation_Summary_Report", emailAddress);
      }, 2000); // 2 seconds delay
    } else if (reportType === "detailed") {
      // Open all accordions, and then call generatePDF after 2 seconds
      openAllAccordions();
      setTimeout(() => {
        generatePDF("SiteOperation_Detailed_Report", emailAddress);
        closeAllAccordions();
      }, 2000); // 2 seconds delay
    }
  };

  function generatePDF(filename, emailAddress) {
    let input = document.getElementById("pdf-content");
    const hidden_content = document.getElementById("hidden-content");
    const report_created_time = document.getElementById("report-created-time");
    const pdf = new jsPDF("portrait", "mm", "a4");

    html2canvas(input, {
      scale: 6,
      useCORS: true,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("hidden-content").classList.remove("d-none");
        clonedDoc
          .getElementById("report-created-time")
          .classList.remove("d-none");
      },
    }).then((canvas) => {
      hidden_content.classList.add("d-none");
      report_created_time.classList.add("d-none");
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width;
      let heightLeft = height;
      let pageHeight = pdf.internal.pageSize.getHeight();
      let position = 10;
      pdf.addImage(imgData, "JPEG", 0, position, width, height);

      heightLeft -= pageHeight - position;
      while (heightLeft >= 0) {
        position = heightLeft - height + 10;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, width, height);
        heightLeft -= pageHeight;
      }
      // Save the PDF or send it to the API based on the buttonClicked state
      if (buttonClicked === "printPDF") {
        pdf.save(`${filename}.pdf`);
      } else if (buttonClicked === "emailReport" && emailAddress) {
        sendPDFToAPI(pdf.output("blob"), emailAddress, filename);
      }
    });
  }

  function sendPDFToAPI(pdfBlob, emailAddressToSend, filename) {
    // Create a FormData object to send the PDF as a file attachment
    const mailHeading = "Scancam Report PDF";
    const mailBody = "Please find attached the report you requested.";
    const formData = new FormData();
    formData.append("attachment", pdfBlob, `${filename}.pdf`);

    // Set up the API endpoint and headers
    const endpoint = `${APP_SERVICES_URL}/senapi/${CustID}/mailWithAttachment?subject=${mailHeading}&body=${mailBody}&mailTo=${emailAddressToSend}`;
    fetch(endpoint, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: authHeader,
      },
    })
      .then((response) => {
        if (response.ok) {
          setApiResponse(true);
          setEmailReport(true);
        } else {
          setApiResponse(false);
          setEmailReport(true);
        }
      })
      .catch((error) => {
        setApiResponse(false);
        setEmailReport(true);
      });
  }

  const simulateFormSubmit = () => {
    // Replace this with your actual form submission logic
    // For example, if handleSubmit is an async function, you can await it here
    handleSubmit();
  };

  useEffect(() => {
    simulateFormSubmit();
    // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    selectedSite,
    selectedBrands,
    selectedStates,
    selectedProducts,
  ]);

  useEffect(() => {
    closeAllAccordions();
  }, [
    startDate,
    endDate,
    selectedSite,
    selectedBrands,
    selectedStates,
    selectedProducts,
  ]);

  //setting a state to minimize code
  const isAvailable =
    showResult &&
    data &&
    data.reportedAmount !== 0 &&
    ([
      "numIOUs",
      "numValidIOUs",
      "reportedAmount",
      "expiredAmount",
      "cancelledAmount",
    ].some((key) => data[key] !== undefined && data[key] !== 0) ||
      (data.outstandingIncidents &&
        ["outstandingAmount", "outstandingIOUAmount"].some(
          (key) =>
            data.outstandingIncidents[key] !== undefined &&
            data.outstandingIncidents[key] !== 0
        )));
  const ErrorReportHeading = "Report Data Fetch Error";
  // Check if "*" is present in the array
  const hasSelectAll = selectedSite.some((site) => site.value === "*");

  // Determine the output
  const values = hasSelectAll
    ? "ALL"
    : selectedSite.map((site) => site.value).join(", ");

  const formattedDateTime = new Date().toLocaleString("en-US", {
    dateStyle: "medium", // or 'short', 'long', 'full' as needed
    timeStyle: "short", // or 'medium', 'long', 'full' as needed
  });

  return (
    <div className="w-dvw min-h-[65vh]">
      {emailReport && (
        <EmailModal
          show={emailReport}
          onClose={closeEmailModal}
          onSelectReportType={handleReportTypeSelection}
          apiResponse={apiResponse}
        />
      )}
      {isReportTypeModalOpen && (
        <ReportTypeModal
          show={isReportTypeModalOpen}
          onClose={closeReportTypeModal}
          onSelectReportType={handleReportTypeSelection}
        />
      )}
      {error && (
        <ErrorModal
          show={true}
          onClose={handleCloseErrorModal}
          message={errorMessage}
          heading={ErrorReportHeading}
        />
      )}

      <div className="row">
        <div className="row p-0 bg-[#F4F4F4]">
          <div className="col-sm-12 flex w-dvw">
            <div className="min-w-[1170px] max-w-[1170px] mx-auto">
              <TitileReport label={"Site Operations"} />
              {/* reports options */}
              <div className="min-w-[1170px] max-w-[1170px] lg:flex md:flex mb-4">
                <div className="flex-grow-[9] flex-shrink-0 basis-[85%]">
                  <div className="text-[18px] text-[#B4B4B4] font-bold pt-1 pb-2">
                    Filters
                  </div>
                  <div className="grid grid-cols-6 grid-rows-1 text-[#6f7172]">
                    <div className="text-sm  h-[64px] pe-[15px]">
                      <div className="h-[20px]">Start date</div>
                      <div className="h-[44px]">
                        <input
                          className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                          type="date"
                          id="startDate"
                          value={startDate}
                          onChange={handleStartDateChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">End date</div>
                      <div className="h-[44px]">
                        <input
                          className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                          type="date"
                          id="endDate"
                          value={endDate}
                          onChange={handleEndDateChange}
                          required
                        />
                      </div>
                    </div>
                    {(userRole === "sensenadmin" ||
                      userRole === "frs-reviewer") && (
                      <>
                        <div className="text-sm h-[64px] pe-[15px]">
                          <div className="h-[20px]">States</div>
                          <div className="h-[44px]">
                            <MaterialUIMultiSelectCheckmarks
                              options={statesList}
                              setSelectedStateFunction={setSelectedStates}
                              initialStateAllSelected={true}
                            />
                          </div>
                        </div>
                        <div className="text-sm h-[64px] pe-[15px]">
                          <div className="h-[20px]">Brand</div>
                          <div className="h-[44px]">
                            <MaterialUIMultiSelectCheckmarks
                              options={customersList}
                              setSelectedStateFunction={setSelectedBrands}
                              initialStateAllSelected={true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">Sites</div>
                      <div className="h-[44px]">
                        <MaterialUIMultiSelectCheckmarks
                          options={siteNames}
                          setSelectedStateFunction={setSelectedSite}
                          initialStateAllSelected={false}
                        />
                      </div>
                    </div>
                    {(userRole === "sensenadmin" ||
                      userRole === "frs-reviewer") && (
                      <div className="text-sm h-[64px] pe-[15px]">
                        <div className="h-[20px]">Product</div>
                        <div className="h-[44px]">
                          <MaterialUIMultiSelectCheckmarks
                            options={productsList}
                            setSelectedStateFunction={setSelectedProducts}
                            initialStateAllSelected={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-grow-[1] flex-shrink-0 basis-[15%]">
                  <div className="text-[18px] text-[#B4B4B4] font-bold pt-1 pb-2">
                    Actions
                  </div>
                  <div className="grid grid-rows-3 grid-cols-1">
                    <div
                      className={`${
                        isAvailable ? "action-btns" : "disable-btns"
                      }`}
                      onClick={isAvailable ? openReportTypeModal : null}
                      style={{
                        color:
                          isAvailable && textAccentColor
                            ? textAccentColor
                            : "inherit",
                      }}
                    >
                      <span className="flex fw-bolder">
                        <PrintIcon style={{ fontSize: "18px" }} />
                        <span className="ps-1">Print-friendly PDF</span>
                      </span>
                    </div>
                    {isAvailable ? (
                      <div
                        className="action-btns "
                        style={{ color: textAccentColor || "inherit" }}
                      >
                        <CSVDownloadButton
                          data={newdata}
                          reportName="scancam-site-operations-report"
                        />
                      </div>
                    ) : (
                      <div className="d-flex  disable-btns">
                        <LaunchIcon style={{ fontSize: "18px" }} />
                        <span className="ps-1">Export as CSV</span>
                      </div>
                    )}
                    <div
                      className={`${
                        isAvailable ? "action-btns" : "disable-btns"
                      } `}
                      style={{
                        color:
                          isAvailable && textAccentColor
                            ? textAccentColor
                            : "inherit",
                      }}
                      onClick={isAvailable ? openEmailModal : null}
                    >
                      <div className="d-flex ps-0">
                        <EmailIcon style={{ fontSize: "18px" }} />
                        <span className="ps-1">Email report</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <RotatingIcon colorValue={"grey"} />
        </div>
      ) : (
        <div id="pdf-content">
          <div
            id="report-created-time"
            className="text-end mt-2 report-created-time d-none"
          >
            Report created {formattedDateTime} by
            <span className="ps-1 pe-2">{`${userSession.username}`}</span>
          </div>
          {isAvailable && (
            <div className="row">
              <div className="row p-0 bg-[#F4F4F4]">
                <div className="col-sm-12 flex w-dvw">
                  <div className="min-w-[1170px] max-w-[1170px] mx-auto">
                    <div className="max-w-[1170px] mx-auto">
                      <div
                        className="min-w-[1170px] max-w-[1170px] mx-auto"
                        style={{ marginBottom: "8px" }}
                      >
                        <div id="hidden-content" className="d-none">
                          <h1 className="report-heading m-1 p-1">
                            Site Operations Report
                          </h1>
                          <h4 className="m-1 p-1">Detail</h4>
                          <div className="mt-3">
                            <div className="row ms-1">
                              <div className="col-6"> Date range :</div>
                              <div className="col-6"> Sites :</div>
                            </div>
                            <div className="row ms-1 report-range">
                              <div className="col-6">
                                {`${startDate}`} - {`${endDate}`}
                              </div>
                              <div className="col-6">
                                {selectedSite.toString()}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="max-w-[1170px] mx-auto ">
                          <div className="grid grid-cols-6 items-center py-0">
                            <div className="flex items-center text-[#6f7172]  text-[18px] font-bold">
                              {sum} incident{sum > 1 ? "s" : ""}
                            </div>
                            <div className="text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]"></div>
                            </div>
                            <div className="text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                <span>
                                  {data &&
                                  data.numDriveOffs !== undefined &&
                                  data.numDriveOffs !== 0
                                    ? data.numDriveOffs
                                    : "0"}
                                </span>
                                <span className="text-[#b4b4b4]">
                                  Drive Offs
                                </span>
                              </div>
                            </div>
                            <div className="text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                <span>
                                  {data &&
                                  data.numIOUs !== undefined &&
                                  data.numIOUs !== 0
                                    ? data.numIOUs
                                    : "0"}
                                </span>
                                <span className="text-[#b4b4b4]">IOUs</span>
                              </div>
                            </div>
                            <div className="text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                <span>
                                  {data &&
                                  data.numCancelled !== undefined &&
                                  data.numCancelled !== 0
                                    ? data.numCancelled
                                    : "0"}
                                </span>
                                <span className="text-[#b4b4b4]">
                                  Cancelled
                                </span>
                              </div>
                            </div>
                            <div className="text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                <span>
                                  {data &&
                                  data.numExpired !== undefined &&
                                  data.numExpired !== 0
                                    ? data.numExpired
                                    : "0"}
                                </span>
                                <span className="text-[#b4b4b4]">Expired</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* chart data */}
                      <StackedBarChart
                        chartData={chartData}
                        className="report-bar-chart "
                      />
                      <div
                        className="max-w-[1170px] mx-auto"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="grid grid-cols-6 items-center pb-3">
                          <div className="flex items-center text-[#B4B4B4] font-bold text-base">
                            Totals
                          </div>
                          <div className="text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>
                                {data
                                  ? `$${(data.reportedAmount || 0).toFixed(2)}`
                                  : "$0.00"}
                              </span>
                              <span className="text-[#b4b4b4]">Reported</span>
                            </div>
                          </div>
                          <div className="text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>
                                {data
                                  ? `$${(data.expiredAmount || 0).toFixed(2)}`
                                  : "$0.00"}
                              </span>

                              <span className="text-[#b4b4b4]">
                                <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#6F7172]"></span>
                                Expired
                              </span>
                            </div>
                          </div>
                          <div className="text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>
                                {data
                                  ? `$${(data.cancelledAmount || 0).toFixed(2)}`
                                  : "$0.00"}
                              </span>
                              <span className="text-[#b4b4b4]">
                                <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#e4e4e4]"></span>
                                Cancelled
                              </span>
                            </div>
                          </div>
                          <div className="text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>
                                {data
                                  ? `$${(
                                      data.recoveredIncidents.recoveredAmount ||
                                      0
                                    ).toFixed(2)}`
                                  : "$0.00"}
                              </span>
                              <span className="text-[#b4b4b4]">
                                <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#e27a13]"></span>
                                Recovered
                              </span>
                            </div>
                          </div>
                          <div className="text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>
                                {data
                                  ? `$${(
                                      data.outstandingIncidents
                                        .outstandingAmount || 0
                                    ).toFixed(2)}`
                                  : "$0.00"}
                              </span>
                              <span className="text-[#b4b4b4]">
                                <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#ffcb07]"></span>
                                Outstanding
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="row p-0">
              <div className="col-sm-12 flex w-dvw">
                <div className="col-12 min-w-[1170px] max-w-[1170px] mx-auto">
                  {selectedSite.length < 1 ? (
                    <div className="row ">
                      <div
                        className="col-sm-12 text-center mt-4"
                        style={{
                          fontSize: "20px",
                          color: "#6f7172",
                          paddingTop: "50px",
                        }}
                      >
                        <p>At least one Site must be selected.</p>
                      </div>
                    </div>
                  ) : isAvailable ? (
                    <>
                      {/* {data && data.reportedAmount > 0 && ()} */}
                      {data && data.reportedAmount > 0 && (
                        <div
                          className="accordion container-max-width-reports-page"
                          id="accordionPanelsStayOpenExample"
                        >
                          {data && data.numValidDriveOffs > 0 && (
                            <div className="container mx-auto px-0 py-0                                                                                                                                                                                                                                         ">
                              <div className="bg-white border-t border-b border-gray-200">
                                <button
                                  type="button"
                                  className="w-full px-0 py-3 text-left"
                                  onClick={() =>
                                    toggleAccordionNew("DRIVE_OFF")
                                  }
                                >
                                  <div className="grid grid-cols-6 items-center">
                                    <div className="flex items-center">
                                      <svg
                                        className={`w-4 h-4 font-bold text-[#E27A13] transform transition-transform duration-300 ${
                                          accordionState.DRIVE_OFF
                                            ? "rotate-90"
                                            : ""
                                        }`}
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path d="M9 5l7 7-7 7"></path>
                                      </svg>
                                      <span className="ml-2 text-[#E27A13] font-bold">
                                        {data.numValidDriveOffs &&
                                        data.numValidDriveOffs > 1
                                          ? `${data.numValidDriveOffs} Drive Offs`
                                          : `${data.numValidDriveOffs} Drive Off`}
                                        <div className="text-sm font-extralight">
                                          {accordionState.DRIVE_OFF
                                            ? "Hide all"
                                            : "Show all"}
                                        </div>
                                      </span>
                                    </div>
                                    {!accordionState.DRIVE_OFF && (
                                      <>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              $
                                              {data &&
                                              data.outstandingIncidents &&
                                              data.outstandingIncidents
                                                .outstandingDriveOffAmount !==
                                                undefined
                                                ? (
                                                    parseFloat(
                                                      data.outstandingIncidents
                                                        .outstandingDriveOffAmount
                                                    ) +
                                                    parseFloat(
                                                      data.recoveredIncidents
                                                        ?.recoveredDriveOffAmount ||
                                                        0
                                                    )
                                                  ).toFixed(2)
                                                : "0.00"}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Reported
                                            </span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>NA</span>
                                            <span>Expired</span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>NA</span>
                                            <span>Cancelled</span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              $
                                              {data && data.recoveredIncidents
                                                ? data.recoveredIncidents
                                                    .recoveredDriveOffAmount !==
                                                  undefined
                                                  ? data.recoveredIncidents.recoveredDriveOffAmount.toFixed(
                                                      2
                                                    )
                                                  : "0.00"
                                                : "0.00"}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Recovered
                                            </span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              $
                                              {data &&
                                              data.outstandingIncidents &&
                                              data.outstandingIncidents
                                                .outstandingDriveOffAmount !==
                                                undefined
                                                ? data.outstandingIncidents.outstandingDriveOffAmount.toFixed(
                                                    2
                                                  )
                                                : "0.00"}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Outstanding
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </button>
                                <div
                                  ref={contentRefs.DRIVE_OFF}
                                  className="overflow-hidden transition-all duration-300 ease-in-out"
                                  style={{
                                    maxHeight: accordionState.DRIVE_OFF
                                      ? `${contentHeights.DRIVE_OFF}px`
                                      : "0",
                                    opacity: accordionState.DRIVE_OFF ? 1 : 0,
                                  }}
                                >
                                  <CustomizedTable
                                    reportName="siteOperation"
                                    heading={[
                                      "OCCURRED AT",
                                      "LICENSE PLATE",
                                      "TYPE",
                                      "STATUS",
                                      "SITE",
                                      "PUMP",
                                      "REFERENCE",
                                      "VALUE",
                                    ]}
                                    data={newdata.numDriveOffs} // Replace 'data' with your actual data array
                                    selectedLabel={"selectedLabel"}
                                  />
                                  <div className="grid grid-cols-6 items-center py-3">
                                    <div className="flex items-center"></div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          $
                                          {data &&
                                          data.outstandingIncidents &&
                                          data.outstandingIncidents
                                            .outstandingDriveOffAmount !==
                                            undefined
                                            ? (
                                                parseFloat(
                                                  data.outstandingIncidents
                                                    .outstandingDriveOffAmount
                                                ) +
                                                parseFloat(
                                                  data.recoveredIncidents
                                                    ?.recoveredDriveOffAmount ||
                                                    0
                                                )
                                              ).toFixed(2)
                                            : "0.00"}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Reported
                                        </span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>NA</span>
                                        <span>Expired</span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>NA</span>
                                        <span>Cancelled</span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          $
                                          {data && data.recoveredIncidents
                                            ? data.recoveredIncidents
                                                .recoveredDriveOffAmount !==
                                              undefined
                                              ? data.recoveredIncidents.recoveredDriveOffAmount.toFixed(
                                                  2
                                                )
                                              : "0.00"
                                            : "0.00"}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Recovered
                                        </span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          $
                                          {data &&
                                          data.outstandingIncidents &&
                                          data.outstandingIncidents
                                            .outstandingDriveOffAmount !==
                                            undefined
                                            ? data.outstandingIncidents.outstandingDriveOffAmount.toFixed(
                                                2
                                              )
                                            : "0.00"}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Outstanding
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Add more Accordion components as needed */}
                            </div>
                          )}

                          {/* IOU accordion */}
                          {data && data.numValidIOUs > 0 && (
                            <div className="container mx-auto px-0 py-0">
                              <div className="bg-white border-t border-b border-gray-200">
                                <button
                                  type="button"
                                  className="w-full px-0 py-3 text-left"
                                  onClick={() => toggleAccordionNew("IOU")}
                                >
                                  <div className="grid grid-cols-6 items-center">
                                    <div className="flex items-center">
                                      <svg
                                        className={`w-4 h-4 font-bold text-[#E27A13] transform transition-transform duration-300 ${
                                          accordionState.IOU ? "rotate-90" : ""
                                        }`}
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path d="M9 5l7 7-7 7"></path>
                                      </svg>
                                      <span className="ml-2 text-[#E27A13] font-bold">
                                        {data.numValidIOUs &&
                                        data.numValidIOUs > 1
                                          ? `${data.numValidIOUs} IOUs`
                                          : `${data.numValidIOUs} IOU`}
                                        <div className="text-sm font-extralight">
                                          {accordionState.IOU
                                            ? "Hide all"
                                            : "Show all"}
                                        </div>
                                      </span>
                                    </div>
                                    {!accordionState.IOU && (
                                      <>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {data
                                                ? `$${(
                                                    parseFloat(
                                                      data.recoveredIncidents
                                                        ?.recoveredIOUAmount ||
                                                        0
                                                    ) +
                                                    parseFloat(
                                                      data.outstandingIncidents
                                                        ?.outstandingIOUAmount ||
                                                        0
                                                    )
                                                  ).toFixed(2)}`
                                                : "$0.00"}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Reported
                                            </span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>NA</span>
                                            <span>Expired</span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>NA</span>
                                            <span>Cancelled</span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {data
                                                ? `$${(
                                                    data.recoveredIncidents
                                                      .recoveredIOUAmount || 0
                                                  ).toFixed(2)}`
                                                : "$0.00"}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Recovered
                                            </span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {data
                                                ? `$${(
                                                    data.outstandingIncidents
                                                      .outstandingIOUAmount || 0
                                                  ).toFixed(2)}`
                                                : "$0.00"}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Outstanding
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </button>
                                <div
                                  ref={contentRefs.IOU}
                                  className="overflow-hidden transition-all duration-300 ease-in-out"
                                  style={{
                                    maxHeight: accordionState.IOU
                                      ? `${contentHeights.IOU}px`
                                      : "0",
                                    opacity: accordionState.IOU ? 1 : 0,
                                  }}
                                >
                                  <CustomizedTable
                                    reportName="siteOperation"
                                    heading={[
                                      "OCCURRED AT",
                                      "LICENSE PLATE",
                                      "TYPE",
                                      "STATUS",
                                      "SITE",
                                      "PUMP",
                                      "REFERENCE",
                                      "VALUE",
                                    ]}
                                    data={newdata.numIOUs} // Replace 'data' with your actual data array
                                    selectedLabel={"selectedLabel"}
                                  />
                                  <div className="grid grid-cols-6 items-center py-3">
                                    <div className="flex items-center"></div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {data
                                            ? `$${(
                                                parseFloat(
                                                  data.recoveredIncidents
                                                    ?.recoveredIOUAmount || 0
                                                ) +
                                                parseFloat(
                                                  data.outstandingIncidents
                                                    ?.outstandingIOUAmount || 0
                                                )
                                              ).toFixed(2)}`
                                            : "$0.00"}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Reported
                                        </span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>NA</span>
                                        <span>Expired</span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>NA</span>
                                        <span>Cancelled</span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {data
                                            ? `$${(
                                                data.recoveredIncidents
                                                  .recoveredIOUAmount || 0
                                              ).toFixed(2)}`
                                            : "$0.00"}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Recovered
                                        </span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {data
                                            ? `$${(
                                                data.outstandingIncidents
                                                  .outstandingIOUAmount || 0
                                              ).toFixed(2)}`
                                            : "$0.00"}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Outstanding
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Add more Accordion components as needed */}
                            </div>
                          )}

                          {/* Cancelled accordion */}
                          {data && data.numCancelled > 0 && (
                            <div className="container mx-auto px-0 py-0">
                              <div className="bg-white border-t border-b border-gray-200">
                                <button
                                  type="button"
                                  className="w-full px-0 py-3 text-left"
                                  onClick={() =>
                                    toggleAccordionNew("CANCELLED")
                                  }
                                >
                                  <div className="grid grid-cols-6 items-center">
                                    <div className="flex items-center">
                                      <svg
                                        className={`w-4 h-4 font-bold text-[#E27A13] transform transition-transform duration-300 ${
                                          accordionState.CANCELLED
                                            ? "rotate-90"
                                            : ""
                                        }`}
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path d="M9 5l7 7-7 7"></path>
                                      </svg>
                                      <span className="ml-2 text-[#E27A13] font-bold ">
                                        {data &&
                                        data.numCancelled !== undefined &&
                                        data.numCancelled !== 0
                                          ? data.numCancelled
                                          : "0"}
                                        <span className="ps-1">Cancelled</span>
                                        <div className="text-sm font-extralight">
                                          {accordionState.CANCELLED
                                            ? "Hide all"
                                            : "Show all"}
                                        </div>
                                      </span>
                                    </div>
                                    {!accordionState.CANCELLED && (
                                      <>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {data
                                                ? `$${(
                                                    data.cancelledAmount || 0
                                                  ).toFixed(2)}`
                                                : "$0.00"}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Reported
                                            </span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>NA</span>
                                            <span>Expired</span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {data
                                                ? `$${(
                                                    data.cancelledAmount || 0
                                                  ).toFixed(2)}`
                                                : "$0.00"}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Cancelled
                                            </span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>NA</span>
                                            <span className="text-[#b4b4b4]">
                                              Recovered
                                            </span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>NA</span>
                                            <span className="text-[#b4b4b4]">
                                              Outstanding
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </button>
                                <div
                                  ref={contentRefs.CANCELLED}
                                  className="overflow-hidden transition-all duration-300 ease-in-out"
                                  style={{
                                    maxHeight: accordionState.CANCELLED
                                      ? `${contentHeights.CANCELLED}px`
                                      : "0",
                                    opacity: accordionState.CANCELLED ? 1 : 0,
                                  }}
                                >
                                  <CustomizedTable
                                    reportName="siteOperation"
                                    heading={[
                                      "OCCURRED AT",
                                      "LICENSE PLATE",
                                      "TYPE",
                                      "STATUS",
                                      "SITE",
                                      "PUMP",
                                      "REFERENCE",
                                      "VALUE",
                                    ]}
                                    data={newdata.numCancelled} // Replace 'data' with your actual data array
                                    selectedLabel={"selectedLabel"}
                                  />
                                  <div className="grid grid-cols-6 items-center py-3">
                                    <div className="flex items-center"></div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {data
                                            ? `$${(
                                                data.cancelledAmount || 0
                                              ).toFixed(2)}`
                                            : "$0.00"}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Reported
                                        </span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>NA</span>
                                        <span>Expired</span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {data
                                            ? `$${(
                                                data.cancelledAmount || 0
                                              ).toFixed(2)}`
                                            : "$0.00"}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Cancelled
                                        </span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#b4b4b4] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>NA</span>
                                        <span className="text-[#b4b4b4]">
                                          Recovered
                                        </span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#b4b4b4] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>NA</span>
                                        <span className="text-[#b4b4b4]">
                                          Outstanding
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Add more Accordion components as needed */}
                            </div>
                          )}

                          {/* Expired accordion */}
                          {data && data.numExpired > 0 && (
                            <div className="container mx-auto px-0 py-0">
                              <div className="bg-white border-t border-b border-gray-200">
                                <button
                                  type="button"
                                  className="w-full px-0 py-3 text-left"
                                  onClick={() => toggleAccordionNew("EXPIRED")}
                                >
                                  <div className="grid grid-cols-6 items-center">
                                    <div className="flex items-center">
                                      <svg
                                        className={`w-4 h-4 font-bold text-[#E27A13] transform transition-transform duration-300 ${
                                          accordionState.EXPIRED
                                            ? "rotate-90"
                                            : ""
                                        }`}
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path d="M9 5l7 7-7 7"></path>
                                      </svg>
                                      <span className="ml-2 text-[#E27A13] font-bold ">
                                        {data.numExpired} Expired
                                        <div className="text-sm font-extralight">
                                          {accordionState.EXPIRED
                                            ? "Hide all"
                                            : "Show all"}
                                        </div>
                                      </span>
                                    </div>
                                    {!accordionState.EXPIRED && (
                                      <>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {data
                                                ? `$${(
                                                    data.expiredAmount || 0
                                                  ).toFixed(2)}`
                                                : "$0.00"}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Reported
                                            </span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>
                                              {data
                                                ? `$${(
                                                    data.expiredAmount || 0
                                                  ).toFixed(2)}`
                                                : "$0.00"}
                                            </span>
                                            <span className="text-[#b4b4b4]">
                                              Expired
                                            </span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#D9D9D9]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>NA</span>
                                            <span>Cancelled</span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>NA</span>
                                            <span>Recovered</span>
                                          </div>
                                        </div>
                                        <div className="text-end pe-0">
                                          <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                            <span>NA</span>
                                            <span>Outstanding</span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </button>
                                <div
                                  ref={contentRefs.EXPIRED}
                                  className="overflow-hidden transition-all duration-300 ease-in-out"
                                  style={{
                                    maxHeight: accordionState.EXPIRED
                                      ? `${contentHeights.EXPIRED}px`
                                      : "0",
                                    opacity: accordionState.EXPIRED ? 1 : 0,
                                  }}
                                >
                                  <CustomizedTable
                                    reportName="siteOperation"
                                    heading={[
                                      "OCCURRED AT",
                                      "LICENSE PLATE",
                                      "TYPE",
                                      "STATUS",
                                      "SITE",
                                      "PUMP",
                                      "REFERENCE",
                                      "VALUE",
                                    ]}
                                    data={newdata.numExpired} // Replace 'data' with your actual data array
                                    selectedLabel={"selectedLabel"}
                                  />
                                  <div className="grid grid-cols-6 items-center py-3">
                                    <div className="flex items-center"></div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {data
                                            ? `$${(
                                                data.expiredAmount || 0
                                              ).toFixed(2)}`
                                            : "$0.00"}
                                        </span>
                                        <span className="text-[#b4b4b4]">
                                          Reported
                                        </span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>
                                          {data
                                            ? `$${(
                                                data.expiredAmount || 0
                                              ).toFixed(2)}`
                                            : "$0.00"}
                                        </span>
                                        <span>Expired</span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#D9D9D9]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>NA</span>
                                        <span>Cancelled</span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>NA</span>
                                        <span>Recovered</span>
                                      </div>
                                    </div>
                                    <div className="text-end pe-0">
                                      <div className="flex flex-col justify-between text-[#D9D9D9] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                        <span>NA</span>
                                        <span>Outstanding</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Add more Accordion components as needed */}
                            </div>
                          )}

                          <div className="grid grid-cols-6 items-center py-3">
                            <div className="flex items-center text-[#B4B4B4] font-bold text-base">
                              Totals
                            </div>
                            <div className="text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                <span className="text-[#e27a13]">
                                  {data
                                    ? `$${(data.reportedAmount || 0).toFixed(
                                        2
                                      )}`
                                    : "$0.00"}
                                </span>
                                <span className="text-[#b4b4b4]">Reported</span>
                              </div>
                            </div>
                            <div className="text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                <span className="text-[#e27a13]">
                                  {data
                                    ? `$${(data.expiredAmount || 0).toFixed(2)}`
                                    : "$0.00"}
                                </span>
                                <span className="text-[#b4b4b4]">Expired</span>
                              </div>
                            </div>
                            <div className="text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                <span className="text-[#e27a13]">
                                  {data
                                    ? `$${(data.cancelledAmount || 0).toFixed(
                                        2
                                      )}`
                                    : "$0.00"}
                                </span>
                                <span className="text-[#b4b4b4]">
                                  Cancelled
                                </span>
                              </div>
                            </div>
                            <div className="text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                <span className="text-[#e27a13]">
                                  {data
                                    ? `$${(
                                        data.recoveredIncidents
                                          .recoveredAmount || 0
                                      ).toFixed(2)}`
                                    : "$0.00"}
                                </span>
                                <span className="text-[#b4b4b4]">
                                  Recovered
                                </span>
                              </div>
                            </div>
                            <div className="text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                <span className="text-[#e27a13]">
                                  {data
                                    ? `$${(
                                        data.outstandingIncidents
                                          .outstandingAmount || 0
                                      ).toFixed(2)}`
                                    : "$0.00"}
                                </span>
                                <span className="text-[#b4b4b4]">
                                  Outstanding
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="row">
                      <div
                        className="col-sm-12 text-center mt-4"
                        style={{
                          fontSize: "20px",
                          color: "#6f7172",
                          paddingTop: "50px",
                        }}
                      >
                        <p>
                          There are no incidents which match your search
                          criteria.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SiteOperations;
