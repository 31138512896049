import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../CSS/AddIncident.css";
import styles from "../../CSS/Base/Basic.module.css";
import userSession from "../../Constants/auth";
import CustomSelect from "./CustomSelect";

const AddIncidentFirstPage = () => {
  const navigate = useNavigate();
  const [selectedSite, setSelectedSite] = useState("");
  const [error, setError] = useState(false);
  const { sites } = userSession;
  const ctaBackgroudColor =
    userSession.themeData?.ctaBackgroudColor || "#e27a13";
  const handleSiteChange = (e) => {
    const siteValue = e.target.value;
    setSelectedSite(e.target.value);
    setError(!siteValue.trim()); // Update error state based on site value
  };
  const handleSubmit = () => {
    if (!selectedSite || !selectedSite.trim()) {
      setError(true); // Set error to true if selected site is blank
      console.log("Missing selected site value");
      return;
    }
    setError(false); // Reset error state
    navigate("/scancam/AddIncident", { state: { siteName: selectedSite } });
  };

  const options = sites.map((site) => ({
    value: site.name,
    label: site.name,
  }));
  // Add automatic ids to sites

  return (
    <div
      className="container-fluid add-incident-page-main-box"
      style={{ minHeight: "60vh" }}
    >
      <div className="title-section">
        <div className="container-fluid manage-Page-container-max-width manage-Page-body-content title-box">
          <div
            className="col-sm-12 d-flex justify-content-between"
            style={{ maxWidth: "1170px", margin: "auto" }}
          >
            <div className="col-sm-12 ">
              <h2 className={styles.Title}>Add Incident</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container ps-0"
        style={{ maxWidth: "1170px", margin: "auto" }}
      >
        <div className="col-6">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="col-sm-6 pt-3 pb-3 "
                style={{ fontSize: "13px", color: "#6f7172" }}
              >
                Select the site where the incident occurred:
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="col-6 mb-2">
                <div className="col-sm-12 mb-3">
                  <div className="box-dropdown">
                    <CustomSelect
                      label=""
                      options={options}
                      selectedValue={selectedSite}
                      onChange={handleSiteChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 pt-2">
                {error && (
                  <div className="error-message">
                    Please select a site to continue
                  </div>
                )}
                <button
                  onClick={handleSubmit}
                  className="add-incident-firs-page-submit-btn"
                  style={{ backgroundColor: ctaBackgroudColor, width: "60%" }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  );
};
export default AddIncidentFirstPage;
