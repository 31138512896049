import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style1 from "../../CSS/reviewIncidents.module.css";
import styles from "../../CSS/Base/Basic.module.css";
import userSession from "../../Constants/auth";
import { APP_SERVICES_URL } from "../../Constants/constants";
import ErrorModal from "../../Modals/ErrorModal";
// import SimpleBackdrop from '../Base/SimpleBackdrop'
import moment from "moment";
import RotatingIcon from "../Base/RotatingIcon";
import '../../CSS/ReviewIncident.css'

const formatDateTime = (dateTimeString) => {
  // Create a moment object from the input date-time string
  const momentDateTime = moment(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSS");

  // Format the moment object to 12-hour format with AM/PM
  const formattedTime = momentDateTime.format("h:mm A"); // 12-hour format with AM/PM

  // Format the date in the desired format (DD/MM/YYYY)
  const localDateString = momentDateTime.format("DD/MM/YYYY");

  return (
    <div>
      <span>{formattedTime.toUpperCase()}</span>
      <br />
      <span>{localDateString}</span>
    </div>
  );
};

const IncidentTable = ({ data, onRowClick, textAccentColor }) => (
  // <table className={style1.reviewPageIncidentList}>
  //   <thead className={style1.reviewPageIncidentHeader}>
  //     <tr>
  //       <th>OCCURRED AT</th>
  //       <th>LICENCE PLATE</th>
  //       <th>TYPE</th>
  //       <th>SITE</th>
  //       <th>PUMP</th>
  //       <th>NEXT ACTION</th>
  //       <th>VALUE</th>
  //     </tr>
  //   </thead>
  //   <tbody className={style1.reviewPageIncidentBody}>
  //     {data.length > 0 &&
  //       data.map((item) => (
  //         <tr
  //           key={item.alertID}
  //           onClick={() => onRowClick(item.alertID)}
  //           className={style1.reviewPagedataRows}
  //         >
  //           <td className={style1.reviewPagedataRows}>
  //             {formatDateTime(item.detected)}
  //           </td>
  //           <td
  //             className={`${style1.reviewPagedataRows} ${style1.reviewPageitemOcrText}`}
  //           >
  //             <div>
  //               <span className={style1.reviewPageitemOcrText}>{item.ocr}</span>
  //               <div className={style1.itemBackgroundImage}></div>
  //             </div>
  //           </td>
  //           <td className={style1.reviewPagedataRows}>
  //             {item.type ? item.type : ""}
  //           </td>
  //           <td className={style1.reviewPagedataRows}>
  //             {item.siteName ? item.siteName.toUpperCase() : ""}
  //           </td>
  //           <td
  //             className={`${style1.reviewPagedataRows} ${style1.reviewPagedPumpDataRow}`}
  //           >
  //             {item.pumpNumber ? item.pumpNumber.toUpperCase() : ""}
  //           </td>
  //           <td
  //             className={`${style1.reviewPagedataRows} `}
  //             style={{ color: textAccentColor }}
  //           >
  //             {item.nextAction ? item.nextAction : ""}
  //           </td>
  //           <td className={style1.reviewPagedataRows}>${item.fuelvalue}</td>
  //         </tr>
  //       ))}
  //   </tbody>
  // </table>
  <div id="review-incident-container">
    <div className="review-incident-heading-container">
      <div className="review-incident-heading" style={{maxWidth:'138px'}}>OCCURRED AT</div>
      <div className="review-incident-heading" style={{maxWidth:'138px'}}>LICENCE PLATE	</div>
      <div className="review-incident-heading" style={{maxWidth:'138px'}}>TYPE</div>
      <div className="review-incident-heading" style={{maxWidth:'240px'}}>SITE</div>
      <div className="review-incident-heading" style={{maxWidth:'138px'}}>PUMP</div>
      <div className="review-incident-heading" style={{maxWidth:'240px'}}>NEXT ACTION</div>
      <div className="review-incident-heading" style={{maxWidth:'138px'}}>VALUE</div>
    </div>
    
    {data.length > 0 &&
  data.map((item) => (
    <div className="review-incident-values-container" key={item.id} onClick={() => onRowClick(item.alertID)}>
      <div className="review-incident-values" style={{maxWidth:'138px'}}>{formatDateTime(item?.detected)}</div>
      <div className="review-incident-values ocr-background" style={{maxWidth:'138px'}}>      
        {item?.ocr || ''}      
  </div>
      <div className="review-incident-values" style={{maxWidth:'138px'}}> 
        {item?.type || ""}
      </div>
      <div className="review-incident-values wrapped-text" style={{maxWidth:'240px'}}>
      {item?.siteName ? item.siteName.toUpperCase() : ""}
      </div>
      <div className="review-incident-values" style={{maxWidth:'138px'}}>
      {item?.pumpNumber ? item.pumpNumber.toUpperCase() : ""}
        </div>
      <div className="review-incident-values" style={{ color: textAccentColor,maxWidth:'240px' }}>
      {item?.nextAction || ""}
      </div>
      <div className="review-incident-values" style={{maxWidth:'138px'}}>
      ${item?.fuelvalue || 0}
      </div>
    </div>
  ))
}

  </div>
);

const ReviewIncidents = () => {
  const ErrorModalHeading = "Incidents Fetch Error";
  const { sites } = userSession;
  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const [incidents, setIncidents] = useState([]); // Store incidents data in state
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const [errorMessage, setErrorMessage] = useState("");

  const fetchIncidentList = async (payloadData) => {
    const { CustID, authHeader } = userSession;
    try {
      setIsLoading(true);
      setError(false);
      // Serialize payloadData into a query string
      const queryParams = new URLSearchParams({
        limit: 10000,
        offset: 0,
      });

      const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incidentsList?${queryParams.toString()}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader, // Convert to base64 (Basic Auth)
        },
        body: JSON.stringify(payloadData), // Convert request body to JSON string
      });

      if (!response.ok) {
        setError(true);
        setErrorMessage(
          "Failed to fetch incidents list. Please try again. If the issue persists, contact ScanCam support."
        );
        setIsLoading(false);
      }

      const data = await response.json();

      // Update the incidents state with the fetched data
      setIncidents(data);
      setIsLoading(false); // Set loading to false
    } catch (error) {
      console.error(error);
      setError(true); // Set the error state
      setErrorMessage(
        "Failed to fetch incidents list. Please try again. If the issue persists, contact ScanCam support."
      );
      setIsLoading(false); // Set loading to false
    }
  };
  const payloadData = {
    toReviewStatuses: true,
    sites: sites.name,
  };

  useEffect(() => {
    fetchIncidentList(payloadData);
  }, []);

  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`/scancam/incident/${id}`);
  };

  const handleAddIncidentBtn = () => {
    navigate("/scancam/add");
  };
  const handleCloseErrorModal = () => {
    setError(false);
  };
  return (
    <div>
      <div className={`container-fluid`} id={style1.reviewPageMainBox}>
        {/* <SimpleBackdrop open={isLoading} onClose={() => setIsLoading(false)} /> */}

        <div className={style1.reviewincidentTitleSection}>
          <div
            className={`container-fluid manage-Page-container-max-width manage-Page-body-content`}
          >
            <div className={`row ${style1.reviewPagetitleRow}`}>
              <div
                className="col-sm-12 d-flex justify-content-between"
                style={{ maxWidth: "1170px", margin: "auto" }}
              >
                <h2 className={styles.Title}>Incidents</h2>
                <button
                  type="button"
                  className={style1.reviewPagecreateIncident}
                  onClick={handleAddIncidentBtn}
                  style={{ color: textAccentColor }}
                >
                  Create new incident
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`container`}
          style={{ maxWidth: "1170px", margin: "auto" }}
        >
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <RotatingIcon colorValue={'grey'} />
            </div>
          ) : error ? (
            <ErrorModal
              show={true}
              onClose={handleCloseErrorModal}
              message={errorMessage}
              heading={ErrorModalHeading}
            />
          ) : incidents.length === 0 ? (
            <div className={style1.centeredContainer}>
              <h5 className={style1.reviewPageNoDataText}>
                No incidents found. Please refresh to check for new data.
              </h5>
            </div>
          ) : (
            <IncidentTable
              data={incidents}
              onRowClick={handleRowClick}
              textAccentColor={textAccentColor}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewIncidents;
