import FaqPage from "./FAQsPage/FaqPage";
import HomePage from "./LandingPage/HomePage";
import LoginForm from "./LoginPage/LoginForm";
import ManageEmployees from "./ManageEmployeesPage/ManageEmployees";
import OrderFrsCards from "./OrderFRSCards/OrderFrsCards"
import TroubleshootingPage from "./Troubleshooting Page/TroubleshootingPage";
import Report from "./Reports Page/Reports";
import Reimbursement from "./Reports Page/Reimbursement";
import ACTLetterRunProcessing from "./Reports Page/ACTLetterRunProcessing";
import AgentExport from "./Reports Page/AgentExport";
import DoTLetterRun from "./Reports Page/DoTLetterRun";
import IOULetterRun from "./Reports Page/IOULetterRun";
import NSWLetterRunProcessing from "./Reports Page/NSWLetterRunProcessing";
import NTLetterRunProcessing from "./Reports Page/NTLetterRunProcessing";
import PreCourt from "./Reports Page/PreCourt";
import QLDLetterRunProcessing from "./Reports Page/QLDLetterRunProcessing";
import SALetterRunProcessing from "./Reports Page/SALetterRunProcessing";
import VICLetterRunProcessing from "./Reports Page/VICLetterRunProcessing";
import WALetterRunProcessing from "./Reports Page/WALetterRunProcessing";
import SiteOperations from "./Reports Page/SiteOperations";
import AdvanceReport from "./Reports Page/AdvanceReport";
import DebtOutstanding from "./Reports Page/DebtOutstanding";
import DebtRecovered from "./Reports Page/DebtRecovered";
import EmployeePerformance from "./Reports Page/EmployeePerformance";
import OffenderDetection from "./Reports Page/OffenderDetection";
import SiteTraffic from "./Reports Page/SiteTraffic";
import LoginPage from "./LoginPage/LoginPage.js"
const Pages = {
    HomePage,
    ManageEmployees,
    OrderFrsCards,
    LoginForm,
    FaqPage,
    TroubleshootingPage,
    Report,
    Reimbursement,
    ACTLetterRunProcessing,
    AgentExport,
    DoTLetterRun,
    IOULetterRun,
    NSWLetterRunProcessing,
    NTLetterRunProcessing,
    PreCourt,
    QLDLetterRunProcessing,
    SALetterRunProcessing,
    VICLetterRunProcessing,
    WALetterRunProcessing,
    SiteOperations,
    AdvanceReport,
    DebtOutstanding,
    DebtRecovered,
    EmployeePerformance,
    OffenderDetection,
    SiteTraffic,
    LoginPage
};

export default Pages;
