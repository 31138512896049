import React from "react";

const StackedBarChart = ({ chartData }) => {
  console.log({ chartData });
  const total = chartData.reduce((sum, item) => sum + item.data[0], 0);
  return (
    <div className={`w-[1170px] h-[15px] pr-[15px] flex`}>
      {chartData.map((item, index) => {
        const percentage = (item.data[0] / total) * 100;
        return (
          <div
            key={index}
            style={{
              width: `${percentage}%`,
              backgroundColor: item.color,
            }}
            className="h-full"
            title={`${item.data[0]} (${percentage.toFixed(2)}%)`}
          ></div>
        );
      })}
    </div>
  );
};

export default StackedBarChart;
