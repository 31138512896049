import React, { useEffect, useState } from "react";
import "../../CSS/ReportsPage.css";
import userSession from "../../Constants/auth";
import { APP_SERVICES_URL } from "../../Constants/constants";
import UseReportsApi from "../../Custom Hooks/useReportsApi";
import StackedBarChart from "../../Charts/StackedBarChart";
import CustomizedTable from "../../Components/ViewReportPage/CustomizedTable";
import CSVDownloadButton from "../../Components/ViewReportPage/CSVDownloadButton";
import ErrorModal from "../../Modals/ErrorModal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import EmailModal from "../../Modals/EmailModal";
import MaterialUIMultiSelectCheckmarks from "../../Components/ViewReportPage/MaterialUIMultiSelectCheckmarks";
import PrintIcon from "@mui/icons-material/Print";
import LaunchIcon from "@mui/icons-material/Launch";
import EmailIcon from "@mui/icons-material/Email";
import { useApiDataContext } from "../../ApiDataContext";
import moment from "moment";
import RotatingIcon from "../../Components/Base/RotatingIcon";
import TitileReport from "../../Components/common/TitileReport";

const AdvanceReport = () => {
  const { userRole, sites, CustID, authHeader } = userSession;
  const ErrorReportHeading = "Report Data Fetch Error";
  const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-01");
  const endTime = moment().format("YYYY-MM-DD"); // Static data for select options
  const { customersList, statesList, productsList } = useApiDataContext();

  const statusesForAdmins = [
    "Open",
    "Verified",
    "Pre-Court",
    "Escalated",
    "Incomplete Transaction",
    "Returned To Site",
    "Debt Recovered",
    "Debt Paid - Online",
    "Debt Paid - On Site",
    "Debt Paid - Online FRS",
    "Debt Paid - On Site Late Payment",
    "Cancelled - Jerry Can",
    "Cancelled - Motorbike",
    "Cancelled - Expired",
    "Cancelled - Stolen Plate",
    "Cancelled - Other",
    "Cancelled - Stolen Vehicle",
    "Cancelled - Tampered Plate",
    "Cancelled - Licence Unclear",
    "Cancelled - No Licence Plate",
    "Cancelled - Driver Nomination",
    "Cancelled - Pay at Pump Error",
    "Cancelled - Ownership Changed",
    "Cancelled - Test Incident for Training",
    "Cancelled - Unable to Recover - (Unregistered Plate)",
    "Cancelled - User Error",
  ];

  const statusesForManager = [
    "Open",
    "Verified",
    "Debt Recovered",
    "Returned To Site",
    "Recovery In-Progress",
    "Incomplete Transaction",
    "Debt Paid - Online",
    "Debt Paid - On Site",
    "Debt Paid - Online FRS",
    "Debt Paid - On Site Late Payment",
    "Cancelled - User Error",
    "Cancelled - Expired",
    "Cancelled - Motorbike",
    "Cancelled - Jerry Can",
    "Cancelled - Stolen Plate",
    "Cancelled - Tampered Plate",
    "Cancelled - Stolen Vehicle",
    "Cancelled - Licence Unclear",
    "Cancelled - No Licence Plate",
    "Cancelled - Driver Nomination",
    "Cancelled - Ownership Changed",
    "Cancelled - Pay at Pump Error",
    "Cancelled - Test Incident for Training",
    "Cancelled - Unable to Recover - (Unregistered Plate)",
    "Cancelled - Other",
  ];

  const statuses =
    userRole === "site-manager" ? statusesForManager : statusesForAdmins;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState(null);
  const [incident, setIncidents] = useState();
  const [showResult, setShowResult] = useState(false);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(endTime);
  const [selectedState, setSelectedState] = useState(statesList);
  const [selectedBrand, setSelectedBrand] = useState(customersList);
  const [selectedSite, setSelectedSite] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(productsList);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(statuses);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [emailReport, setEmailReport] = useState(false);

  const openEmailModal = () => {
    setButtonClicked("emailReport");
    setEmailReport(true);
  };

  const closeEmailModal = () => {
    setEmailReport(false);
    setApiResponse(null);
  };
  const fetchData = UseReportsApi();
  const getSitesNames = (sites) => {
    if (!sites || !Array.isArray(sites)) {
      return [];
    }
    return sites.map((site) => site.name);
  };
  const siteNames = getSitesNames(sites);
  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const chart2Color = userSession.themeData?.chart2Color || "#e4e4e4";
  const chart3Color = userSession.themeData?.chart3Color || "#e27a13";
  const chart4Color = userSession.themeData?.chart4Color || "#ffcb07";

  const formatDate = (dateString, isStart) => {
    const utcTime = moment.utc(dateString); // Assuming the UTC time is '2024-04-30T05:10:00'
    let localTime = utcTime.local(); // Convert to local time zone

    if (isStart) {
      // Set the time to 00:00:00 for the start date
      localTime.startOf("day");
    } else {
      // Set the time to 23:59:59.999 for the end date
      localTime = localTime.endOf("day");
    } // Set localTime to the current local time

    const localTimeWithoutOffset = localTime.format("YYYY-MM-DDTHH:mm:ss.SSS"); // Omitting the timezone offset

    return localTimeWithoutOffset;
  };

  const getEmployees = async () => {
    try {
      // setError(false);
      const url = `${APP_SERVICES_URL}/senapi/${CustID}/employeesForFilter`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: authHeader,
        },
      });

      if (!response.ok) {
        // setError(true);
        console.log("Emp api is not working");
      }

      const employees = await response.json();
      // console.log({ employees });

      // Update the state with the fetched employees data
      if (employees.length > 0) {
        setEmployeeDetails(employees);
      } else {
        setEmployeeDetails(["NA"]);
      }
    } catch (error) {
      // setError(true);
      // console.log({ error })
      // setErrorMessage("Failed to fetch report data. Please try again. If the issue persists, contact support.");
    }
  };

  useEffect(() => {
    getEmployees();
    // Set default values for DriveOff and IOU options
    setSelectedOptions(["Drive Off", "IOU"]);
  }, []);

  useEffect(() => {
    setSelectedEmployee(employeeDetails);
  }, [employeeDetails]);
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleCheckboxChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };
  const hasAtLeastOneValue = selectedOptions && selectedOptions.length > 0;
  const hasAtLeastoneStatus = selectedStatus && selectedStatus.length > 0;

  const fetchReportData = async (payloadData) => {
    const { CustID, authHeader } = userSession;

    try {
      // Serialize payloadData into a query string
      setError(false);
      const queryParams = new URLSearchParams({
        limit: 10000,
        offset: 0,
      });
      const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incidentsList?${queryParams.toString()}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader, // Convert to base64 (Basic Auth)
        },
        body: JSON.stringify(payloadData), // Convert payloadData to JSON string
      });

      if (!response.ok) {
        setError(true);
      }

      const data = await response.json();

      return { incidents: data, loading: false, error: null };
    } catch (error) {
      setError(true);
      setErrorMessage(
        "Failed to fetch report data. Please try again. If the issue persists, contact support."
      );
    }
  };
  const createStatusesHashMap = (statuses) => {
    const statusesHashMap = {};
    statuses.forEach((status) => {
      if (typeof status === "string" && status.includes(" - ")) {
        const [key, value] = status.split(" - ");
        if (statusesHashMap.hasOwnProperty(key)) {
          statusesHashMap[key].push(value);
        } else {
          statusesHashMap[key] = [value];
        }
      } else if (typeof status === "string") {
        statusesHashMap[status] = [];
      }
    });
    return statusesHashMap;
  };

  const handleSubmit = async (e) => {
    const formattedStartDate = formatDate(startDate, true);
    const formattedEndDate = formatDate(endDate, false);
    const selectedTypes = selectedOptions.filter(
      (option) => option === "Drive Off" || option === "IOU"
    );
    const statusesHashMap = createStatusesHashMap(selectedStatus);
    // Check and modify the statuses if necessary
    if (statusesHashMap.Cancelled && Array.isArray(statusesHashMap.Cancelled)) {
      const cancelledIndex =
        statusesHashMap.Cancelled.indexOf("Unable to Recover");
      if (cancelledIndex !== -1) {
        statusesHashMap.Cancelled[cancelledIndex] =
          "Unable to Recover - (Unregistered Plate)";
      }
    }
    let updateEmp = selectedEmployee;
    const payloadData = {
      isFuelLitresRequired: true,
      isSiteAddressRequired: true,
      isProcessingFeeRequired: true,
      isEmployeeNameRequired: true,
      isSiteTypeRequired: true,
      isFuelTypeRequired: true,
      isStoreIDRequired: true,
      sites: selectedSite,
      to: formattedEndDate,
      from: formattedStartDate,
      states: selectedState,
      statuses: statusesHashMap,
      brands: selectedBrand,
      products: selectedProduct,
      employeeNames:
        employeeDetails.length === updateEmp.length ? [] : updateEmp,
      ...(selectedTypes.length > 0 && { types: selectedTypes }),
    };
    if (payloadData.sites.length < 1) {
      return;
    }

    try {
      setIncidents([]);
      setData(null);
      setIsLoading(true);
      setError(false);
      const result = await fetchData(
        authHeader,
        CustID,
        "advancedIncidents",
        payloadData
      );
      setData(result);
      setShowResult(true);
      const reportData = await fetchReportData(payloadData);
      setIncidents(reportData.incidents);
    } catch (error) {
      setError(true);
      setErrorMessage(
        "Failed to fetch report data. Please try again. If the issue persists, contact support."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedSite.length === 0) {
      // Clear your state here, replace with your actual state clearing logic
      setData(null);
      setShowResult(false);
    }
  }, [selectedSite]); // This effect will run whenever selectedSite changes

  const chartData = [
    {
      data: [data ? data.cancelledAmount : 0],
      color: chart2Color,
    },
    {
      data: [data ? data.recoveredIncidents.recoveredAmount : 0],
      color: chart3Color,
    },
    {
      data: [data ? data.outstandingIncidents.outstandingAmount : 0],
      color: chart4Color,
    },
  ];
  const handleCloseErrorModal = () => {
    setError(false);
  };

  function generatePDF(filename, emailAddress) {
    let input = document.getElementById("pdf-content");
    const hidden_content = document.getElementById("hidden-content");
    const report_created_time = document.getElementById("report-created-time");
    const pdf = new jsPDF("landscape", "mm", "a4");

    // Define padding values
    const paddingLeft = 10; // in mm
    const paddingRight = 10; // in mm

    html2canvas(input, {
      scale: 3,
      useCORS: true,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("hidden-content").classList.remove("d-none");
        clonedDoc
          .getElementById("report-created-time")
          .classList.remove("d-none");
      },
    }).then((canvas) => {
      hidden_content.classList.add("d-none");
      report_created_time.classList.add("d-none");
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Adjust width and position for padding
      const imageWidth = pdfWidth - paddingLeft - paddingRight;
      const imageHeight = (canvas.height * imageWidth) / canvas.width;
      let heightLeft = imageHeight;
      let position = 10;

      pdf.addImage(
        imgData,
        "JPEG",
        paddingLeft,
        position,
        imageWidth,
        imageHeight
      );

      heightLeft -= pdfHeight - position;
      while (heightLeft >= 0) {
        position = heightLeft - imageHeight + 10;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "JPEG",
          paddingLeft,
          position,
          imageWidth,
          imageHeight
        );
        heightLeft -= pdfHeight;
      }

      // Save the PDF or send it to the API based on the buttonClicked state
      if (buttonClicked === "printPDF") {
        pdf.save(`${filename}.pdf`);
      } else if (buttonClicked === "emailReport" && emailAddress) {
        sendPDFToAPI(pdf.output("blob"), emailAddress, filename);
      }
    });
  }

  function sendPDFToAPI(pdfBlob, emailAddressToSend, filename) {
    // Create a FormData object to send the PDF as a file attachment
    const mailHeading = "Scancam Report PDF";
    const mailBody = "Please find attached the report you requested.";
    const formData = new FormData();
    formData.append("attachment", pdfBlob, `${filename}.pdf`);

    // Set up the API endpoint and headers
    const endpoint = `${APP_SERVICES_URL}/senapi/${CustID}/mailWithAttachment?subject=${mailHeading}&body=${mailBody}&mailTo=${emailAddressToSend}`;

    // Reset the state before making the API call
    setApiResponse(null);
    setEmailReport(false);

    fetch(endpoint, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: authHeader,
      },
    })
      .then((response) => {
        if (response.ok) {
          setApiResponse(true);
          setEmailReport(true);
        } else {
          setApiResponse(false);
          setEmailReport(true);
        }
      })
      .catch((error) => {
        setApiResponse(false);
        setEmailReport(true);
      });
  }

  const simulateFormSubmit = () => {
    // Replace this with your actual form submission logic
    // For example, if handleSubmit is an async function, you can await it here
    handleSubmit();
  };

  useEffect(() => {
    simulateFormSubmit();
    // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    selectedSite,
    selectedStatus,
    selectedEmployee,
    selectedOptions,
    selectedBrand,
    selectedProduct,
    selectedState,
  ]);

  const handleReportTypeSelection = (reportType, emailAddress) => {
    // Handle the selected report type (detailed or summary) here.
    generatePDF("AdvanceIncident_Report", emailAddress);
  };
  //creating variable to minize code
  const isAvailable =
    showResult &&
    incident &&
    incident?.length > 0 &&
    hasAtLeastOneValue &&
    hasAtLeastoneStatus &&
    data &&
    data.reportedAmount !== 0;
  const numIOUs =
    data && data.numIOUs !== undefined && data.numIOUs !== 0
      ? parseInt(data.numIOUs)
      : 0;
  const numDriveOffs =
    data && data.numDriveOffs !== undefined && data.numDriveOffs !== 0
      ? parseInt(data.numDriveOffs)
      : 0;
  const sum = numIOUs + numDriveOffs;
  const formattedDateTime = new Date().toLocaleString("en-US", {
    dateStyle: "medium", // or 'short', 'long', 'full' as needed
    timeStyle: "short", // or 'medium', 'long', 'full' as needed
  });
  return (
    <div className="w-dvw min-h-[65vh]">
      {emailReport && (
        <EmailModal
          show={emailReport}
          onClose={closeEmailModal}
          onSelectReportType={handleReportTypeSelection}
          apiResponse={apiResponse}
          reportName={"AdvanceReport"}
        />
      )}
      {error && (
        <ErrorModal
          show={true}
          onClose={handleCloseErrorModal}
          message={errorMessage}
          heading={ErrorReportHeading}
        />
      )}
      <div className="top-section bg-[#F4F4F4] pb-4">
        <div className="w-[1170px] mx-auto">
          <div className="h-[54px]">
            <TitileReport label={"Advance Incident"} />
          </div>
          <div className="">
            <div className="lg:flex md:flex text-[18px] h-[26px] text-[#B4B4B4] font-extrabold">
              <div className="w-[85%]">Filters</div>
              <div className="w-[15%]">Actions</div>
            </div>
            <div className="">
              <div className="lg:flex md:flex text-[18px] text-[#B4B4B4] font-extrabold">
                <div className="w-[85%]">
                  <div className="grid grid-cols-6 grid-rows-1 text-[#6f7172]">
                    <div className="text-sm  h-[64px] pe-[15px]">
                      <div className="h-[20px]">Start date</div>
                      <div className="h-[44px]">
                        <input
                          className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                          type="date"
                          id="startDate"
                          value={startDate}
                          onChange={handleStartDateChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">End date</div>
                      <div className="h-[44px]">
                        <input
                          className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                          type="date"
                          id="endDate"
                          value={endDate}
                          onChange={handleEndDateChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">Type</div>
                      <div className="h-[44px] flex">
                        <div className="flex items-center text-sm">
                          <label className="checkbox-container text-sm">
                            Drive Off
                            <input
                              className="custom-checkbox"
                              value="Drive Off"
                              checked={selectedOptions.includes("Drive Off")}
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e.target.checked
                                    ? [...selectedOptions, "Drive Off"]
                                    : selectedOptions.filter(
                                        (option) => option !== "Drive Off"
                                      )
                                )
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>

                        <div className="ml-4 flex items-center">
                          <label className="checkbox-container">
                            IOU
                            <input
                              className="custom-checkbox"
                              value="IOU"
                              checked={selectedOptions.includes("IOU")}
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e.target.checked
                                    ? [...selectedOptions, "IOU"]
                                    : selectedOptions.filter(
                                        (option) => option !== "IOU"
                                      )
                                )
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    {userRole !== "site-manager" && (
                      <>
                        <div className="text-sm h-[64px] pe-[15px]">
                          <div className="h-[20px]">State</div>
                          <div className="h-[44px]">
                            <MaterialUIMultiSelectCheckmarks
                              options={statesList}
                              setSelectedStateFunction={setSelectedState}
                              initialStateAllSelected={true}
                            />
                          </div>
                        </div>
                        <div className="text-sm h-[64px] pe-[15px]">
                          <div className="h-[20px]">Brand</div>
                          <div className="h-[44px]">
                            <MaterialUIMultiSelectCheckmarks
                              options={customersList}
                              setSelectedStateFunction={setSelectedBrand}
                              initialStateAllSelected={true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">Sites</div>
                      <div className="h-[44px]">
                        <MaterialUIMultiSelectCheckmarks
                          options={siteNames}
                          setSelectedStateFunction={setSelectedSite}
                          initialStateAllSelected={false}
                        />
                      </div>
                    </div>

                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">Status</div>
                      <div className="h-[44px]">
                        <MaterialUIMultiSelectCheckmarks
                          options={statuses}
                          setSelectedStateFunction={setSelectedStatus}
                          initialStateAllSelected={true}
                        />
                      </div>
                    </div>

                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">Employees</div>
                      <div className="h-[44px]">
                        <MaterialUIMultiSelectCheckmarks
                          options={employeeDetails}
                          setSelectedStateFunction={setSelectedEmployee}
                          initialStateAllSelected={true}
                        />
                      </div>
                    </div>

                    {userRole !== "site-manager" && (
                      <div className="text-sm h-[64px] pe-[15px]">
                        <div className="h-[20px]">Product</div>
                        <div className="h-[44px]">
                          <MaterialUIMultiSelectCheckmarks
                            options={productsList}
                            setSelectedStateFunction={setSelectedProduct}
                            initialStateAllSelected={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-[15%]">
                  <div className="grid grid-rows-3">
                    <div
                      className={`flex items-center space-x-1 text-base ${
                        isAvailable ? "rule" : "disable-btns"
                      }`}
                      onClick={
                        isAvailable
                          ? () => {
                              setButtonClicked("printPDF");
                              generatePDF("AdvanceIncident_Report");
                            }
                          : null
                      }
                      style={{
                        color:
                          isAvailable && textAccentColor
                            ? textAccentColor
                            : "inherit",
                        cursor: isAvailable ? "pointer" : "not-allowed",
                      }}
                    >
                      <span>
                        <PrintIcon style={{ fontSize: "16px" }} />
                      </span>
                      <span>Print-friendly PDF</span>
                    </div>
                    <div
                      className={`flex items-center space-x-1 text-base ${
                        isAvailable ? "rule" : "disable-btns"
                      }`}
                      style={{
                        color:
                          isAvailable && textAccentColor
                            ? textAccentColor
                            : "inherit",
                        cursor: isAvailable ? "pointer" : "not-allowed",
                      }}
                    >
                      {isAvailable ? (
                        <CSVDownloadButton
                          data={incident}
                          reportName="scancam-advanced-incident-report"
                        />
                      ) : (
                        <>
                          <span>
                            <LaunchIcon style={{ fontSize: "16px" }} />
                          </span>
                          <span>Export as CSV</span>
                        </>
                      )}
                    </div>
                    <div
                      className={`flex items-center space-x-1 text-base ${
                        isAvailable ? "rule" : "disable-btns"
                      }`}
                      onClick={isAvailable ? openEmailModal : null}
                      style={{
                        color:
                          isAvailable && textAccentColor
                            ? textAccentColor
                            : "inherit",
                        cursor: isAvailable ? "pointer" : "not-allowed",
                      }}
                    >
                      <span>
                        <EmailIcon style={{ fontSize: "16px" }} />
                      </span>
                      <span>Email report</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <RotatingIcon colorValue={"grey"} />
        </div>
      ) : (
        <div className="report-body">
          <div id="pdf-content">
            {incident &&
              hasAtLeastOneValue &&
              hasAtLeastoneStatus &&
              incident?.length > 0 && (
                <div className="bg-[#F4F4F4]">
                  <div className="max-w-[1170px] mx-auto">
                    <div
                      className="min-w-[1170px] max-w-[1170px] mx-auto"
                      style={{ marginBottom: "8px" }}
                    >
                      <div
                        id="report-created-time"
                        className="text-end mt-2 report-created-time d-none"
                      >
                        Report created {formattedDateTime} by
                        <span className="ps-1 pe-2">{`${userSession.username}`}</span>
                      </div>
                      <div id="hidden-content" className="d-none">
                        <h1 className="report-heading m-1 p-1">
                          Advanced Incident Report
                        </h1>
                        <h4 className="ps-2">Detail</h4>
                        <div className="mt-3">
                          <div className="row ps-2">
                            <div className="col-6"> Date range :</div>
                            <div className="col-6"> Sites :</div>
                          </div>
                          <div className="row ps-2 mb-3 report-range">
                            <div className="col-6">
                              {`${startDate}`} - {`${endDate}`}
                            </div>
                            <div className="col-6">
                              {selectedSite.toString()}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="max-w-[1170px] mx-auto ">
                        <div className="grid grid-cols-6 items-center py-0">
                          <div className="col-span-3 flex items-center text-[#6f7172] text-[18px] font-extrabold">
                            {sum} incidents
                          </div>
                          <div className="col-span-1 text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172] text-[14px] font-extrabold pe-[15px] border-r border-[#ececec]">
                              <span className="font-extrabold">
                                {data &&
                                data.numDriveOffs !== undefined &&
                                data.numDriveOffs !== 0
                                  ? data.numDriveOffs
                                  : "0"}
                              </span>

                              <span className="text-[#b4b4b4]">Drive Offs</span>
                            </div>
                          </div>
                          <div className="col-span-1 text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172] text-[14px] font-extrabold pe-[15px] border-r border-[#ececec]">
                              <span className="font-extrabold">
                                {data &&
                                data.numIOUs !== undefined &&
                                data.numIOUs !== 0
                                  ? data.numIOUs
                                  : "0"}
                              </span>

                              <span className="text-[#b4b4b4]">IOUs</span>
                            </div>
                          </div>
                          <div className="col-span-1 text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172] text-[14px] font-extrabold pe-[15px] border-r border-[#ececec]">
                              <span className="font-extrabold">
                                {data &&
                                data.numCancelled !== undefined &&
                                data.numCancelled !== 0
                                  ? data.numCancelled
                                  : "0"}
                              </span>

                              <span className="text-[#b4b4b4]">Cancelled</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* chart data */}
                    <StackedBarChart
                      chartData={chartData}
                      className="report-bar-chart "
                    />
                    <div
                      className="max-w-[1170px] mx-auto"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="grid grid-cols-6 items-center pb-3">
                        <div className="col-span-2 flex items-center text-[#B4B4B4] font-bold text-base">
                          Totals
                        </div>
                        <div className="col-span-1 text-end pe-0">
                          <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                            <span>
                              $
                              {data &&
                              data.reportedAmount !== undefined &&
                              data.reportedAmount !== 0
                                ? data.reportedAmount.toFixed(2)
                                : "0.00"}
                            </span>

                            <span className="col-span-1 text-[#b4b4b4]">
                              Reported
                            </span>
                          </div>
                        </div>
                        <div className="col-span-1 text-end pe-0">
                          <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                            <span>
                              $
                              {data &&
                              data.cancelledAmount !== undefined &&
                              data.cancelledAmount !== 0
                                ? data.cancelledAmount.toFixed(2)
                                : "0.00"}
                            </span>
                            <span className="text-[#b4b4b4]">
                              <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#e4e4e4]"></span>
                              Cancelled
                            </span>
                          </div>
                        </div>
                        <div className="col-span-1 text-end pe-0">
                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                            <span>
                              $
                              {data &&
                              data.recoveredIncidents.recoveredAmount !==
                                undefined &&
                              data.recoveredIncidents.recoveredAmount !== 0
                                ? data.recoveredIncidents.recoveredAmount.toFixed(
                                    2
                                  )
                                : "0.00"}
                            </span>
                            <span className="text-[#b4b4b4]">
                              <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#e27a13]"></span>
                              Recovered
                            </span>
                          </div>
                        </div>
                        <div className="col-span-1 text-end pe-0">
                          <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                            <span>
                              $
                              {data &&
                              data.outstandingIncidents.outstandingAmount !==
                                undefined &&
                              data.outstandingIncidents.outstandingAmount !== 0
                                ? data.outstandingIncidents.outstandingAmount.toFixed(
                                    2
                                  )
                                : "0.00"}
                            </span>
                            <span className="text-[#b4b4b4]">
                              <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#ffcb07]"></span>
                              Outstanding
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {/* display data */}
            <div className="body-section w-[1170px] mx-auto">
              <div className="report-data-section ">
                {showResult === false ? (
                  <div className="row">
                    <div
                      className="col-sm-12 text-center mt-4"
                      style={{
                        fontSize: "20px",
                        color: "#6f7172",
                        paddingTop: "50px",
                      }}
                    >
                      <p>At least one Site must be selected.</p>
                    </div>
                  </div>
                ) : !hasAtLeastoneStatus ? (
                  <div className="row">
                    <div
                      className="col-sm-12 text-center mt-4"
                      style={{
                        fontSize: "20px",
                        color: "#6f7172",
                        paddingTop: "50px",
                      }}
                    >
                      <p>At least one status must be selected.</p>
                    </div>
                  </div>
                ) : !hasAtLeastOneValue ? (
                  <div className="row">
                    <div
                      className="col-sm-12 text-center mt-4"
                      style={{
                        fontSize: "20px",
                        color: "#6f7172",
                        paddingTop: "50px",
                      }}
                    >
                      <p>Either drive off, IOU, or both must be selected.</p>
                    </div>
                  </div>
                ) : incident &&
                  hasAtLeastOneValue &&
                  hasAtLeastoneStatus &&
                  incident?.length > 0 ? (
                  <>
                    <CustomizedTable
                      reportName={"advanceReport"}
                      heading={[
                        "OCCURRED AT",
                        "LICENSE PLATE",
                        "TYPE",
                        "STATUS",
                        "SITE",
                        "PUMP",
                        "EMPLOYEE",
                        "REFERENCE",
                        "VALUE",
                      ]}
                      data={incident} // Replace 'data' with your actual data array
                      selectedLabel={"advanceReport"}
                    />
                    {showResult === true &&
                      data &&
                      data.reportedAmount !== 0 && (
                        // <div className="col-12 d-flex mt-4">
                        //   <div
                        //     className="counts-heading col-4"
                        //     style={{ color: "#b4b4b4", padding: "0px" }}
                        //   >
                        //     Total
                        //   </div>
                        //   <div className="col-2 text-end">
                        //     <div
                        //       className="totals-value"
                        //       style={{ color: textAccentColor }}
                        //     >
                        //       $
                        //       {data &&
                        //       data.reportedAmount !== undefined &&
                        //       data.reportedAmount !== 0
                        //         ? data.reportedAmount.toFixed(2)
                        //         : "0.00"}
                        //     </div>
                        //     <div
                        //       className="totals-label "
                        //       style={{ color: "#b4b4b4" }}
                        //     >
                        //       <div></div>Reported
                        //     </div>
                        //   </div>
                        //   <div className="col-2 text-end">
                        //     <div
                        //       className="totals-value"
                        //       style={{ color: textAccentColor }}
                        //     >
                        //       $
                        //       {data &&
                        //       data.cancelledAmount !== undefined &&
                        //       data.cancelledAmount !== 0
                        //         ? data.cancelledAmount.toFixed(2)
                        //         : "0.00"}
                        //     </div>
                        //     <div
                        //       className="totals-label "
                        //       style={{ color: "#b4b4b4" }}
                        //     >
                        //       <div></div>Cancelled
                        //     </div>
                        //   </div>
                        //   <div className="col-2 text-end">
                        //     <div
                        //       className="totals-value"
                        //       style={{ color: textAccentColor }}
                        //     >
                        //       $
                        //       {data &&
                        //       data.recoveredIncidents.recoveredAmount !==
                        //         undefined &&
                        //       data.recoveredIncidents.recoveredAmount !== 0
                        //         ? data.recoveredIncidents.recoveredAmount.toFixed(
                        //             2
                        //           )
                        //         : "0.00"}
                        //     </div>
                        //     <div
                        //       className="totals-label "
                        //       style={{ color: "#b4b4b4" }}
                        //     >
                        //       <div></div>Recovered
                        //     </div>
                        //   </div>
                        //   <div className="col-2 text-end">
                        //     <div
                        //       className="totals-value "
                        //       style={{ color: textAccentColor }}
                        //     >
                        //       $
                        //       {data &&
                        //       data.outstandingIncidents.outstandingAmount !==
                        //         undefined &&
                        //       data.outstandingIncidents.outstandingAmount !== 0
                        //         ? data.outstandingIncidents.outstandingAmount.toFixed(
                        //             2
                        //           )
                        //         : "0.00"}
                        //     </div>
                        //     <div
                        //       className="totals-label "
                        //       style={{ color: "#b4b4b4" }}
                        //     >
                        //       <div></div>Outstanding
                        //     </div>
                        //   </div>
                        // </div>
                        <div className="grid grid-cols-6 items-center pb-3 mt-3">
                          <div className="col-span-2 flex items-center text-[#B4B4B4] font-bold text-base">
                            Totals
                          </div>
                          <div className="col-span-1 text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>
                                $
                                {data &&
                                data.reportedAmount !== undefined &&
                                data.reportedAmount !== 0
                                  ? data.reportedAmount.toFixed(2)
                                  : "0.00"}
                              </span>

                              <span className="col-span-1 text-[#b4b4b4]">
                                Reported
                              </span>
                            </div>
                          </div>
                          <div className="col-span-1 text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>
                                $
                                {data &&
                                data.cancelledAmount !== undefined &&
                                data.cancelledAmount !== 0
                                  ? data.cancelledAmount.toFixed(2)
                                  : "0.00"}
                              </span>
                              <span className="text-[#b4b4b4]">
                                <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#e4e4e4]"></span>
                                Cancelled
                              </span>
                            </div>
                          </div>
                          <div className="col-span-1 text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>
                                $
                                {data &&
                                data.recoveredIncidents.recoveredAmount !==
                                  undefined &&
                                data.recoveredIncidents.recoveredAmount !== 0
                                  ? data.recoveredIncidents.recoveredAmount.toFixed(
                                      2
                                    )
                                  : "0.00"}
                              </span>
                              <span className="text-[#b4b4b4]">
                                <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#e27a13]"></span>
                                Recovered
                              </span>
                            </div>
                          </div>
                          <div className="col-span-1 text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>
                                $
                                {data &&
                                data.outstandingIncidents.outstandingAmount !==
                                  undefined &&
                                data.outstandingIncidents.outstandingAmount !==
                                  0
                                  ? data.outstandingIncidents.outstandingAmount.toFixed(
                                      2
                                    )
                                  : "0.00"}
                              </span>
                              <span className="text-[#b4b4b4]">
                                <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#ffcb07]"></span>
                                Outstanding
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                ) : (
                  <div className="row">
                    <div
                      className="col-sm-12 text-center mt-4"
                      style={{
                        fontSize: "20px",
                        color: "#6f7172",
                        paddingTop: "50px",
                      }}
                    >
                      <p>
                        There are no incidents that match your search criteria.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AdvanceReport;
