// useDetailsPatchApi.js
import { useEffect } from "react";
import { APP_SERVICES_URL } from "../Constants/constants";

const useDetailsPatchApi = () => {
  const patchAPI = async (authHeader,CustID,pkAlert, payloadData) => {
 
    const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident?alertID=${pkAlert}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: authHeader,
    };

    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(payloadData),
      });

      if (!response.ok) {
        throw new Error("Failed to update incident details.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Any additional cleanup or effects can be added here if needed.
  }, []);

  return { patchAPI };
};

export default useDetailsPatchApi;
