import React, { useState } from "react";
import "../../CSS/SendPaymentLink.css";
import userSession from "../../Constants/auth";
import { sendPaymentLink } from "../../Custom Hooks/sendPaymentLink"; // Import the sendPaymentLink function
import SimpleBackdrop from "../Base/SimpleBackdrop";
import { APP_SERVICES_URL } from "../../Constants/constants";
import ErrorModal from "../../Modals/ErrorModal";

const SendPaymentLink = ({ setIncidentDetails, setActiveLinkBox2 }) => {
  const ErrorModalHeading = 'Payment Link Sending Failed';
  const errorMessage = 'An error occurred while sending the payment link. Please try again. If the issue persists, contact support.';
  const [error, setError] = useState(false);
  const alertID = window.location.pathname.split("/").slice(-1)[0];
  const [buttonActive, setButtonActive] = useState(true);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const { CustID, authHeader } = userSession;
  const ctaBackgroudColor = userSession.themeData?.ctaBackgroudColor || '#e27a13';

  const fetchDataAndUpdateIncidentDetails = async () => {
    try {
      // Fetch data from API
      setLoading(true); // Set loading to true before the fetch operation
      const apiUrl = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident?alertID=${alertID}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Authorization": authHeader,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setIncidentDetails(responseData);
      setLoading(false);
      setActiveLinkBox2('details');
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data from the API:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setButtonActive(false);
    try {
      // Check if either email or phoneNumber is present
      if (!email && !phoneNumber) {
        console.error("Please provide either an email or a phone number.");
        setLoading(false);
        return; // Exit early without making the API call
      }
      const response = await sendPaymentLink({ email, phoneNumber, alertID }); // Call the sendPaymentLink function with form data

      if (response.ok) {
        // Clear email and phoneNumber states after successful submission
        setEmail("");
        setPhoneNumber("");
        setLoading(false);
        fetchDataAndUpdateIncidentDetails();
        setButtonActive(true);
        setError(false);
      } else {
        console.error("Failed to send payment link:", response.statusText);
        setButtonActive(true);
        setError(true);
      }
    } catch (error) {
      setButtonActive(true);
      setError(true);
      console.error("Error sending payment link:", error.message);
    } finally {
      setLoading(false);
      setButtonActive(true);
    }
  };
  const handleCloseErrorModal = () => {
    setError(false);
  };
  return (
    <div className="send-payment-link-container">
      {error && <ErrorModal show={true} onClose={handleCloseErrorModal} message={errorMessage} heading={ErrorModalHeading} />}
      <SimpleBackdrop open={loading} onClose={() => setLoading(false)} />
      <form onSubmit={handleSubmit}>
        <div className="col-sm-12 d-flex flex-column justify-content-between latepayment-input-box">
          <div className="col-sm-12">
            <div className="col-sm-6">
              <label
                className="send-payment-field-heading"
                htmlFor="phoneNumber"
              >
                Email
              </label>
            </div>
            <div className="col-sm-6">
              <input
                style={{ height: '35px' }}
                type="email"
                id="send-payment-email-input"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-sm-6 mt-3">
              <label
                className="send-payment-field-heading"
                htmlFor="phoneNumber"
              >
                Phone Number
              </label>
            </div>
            <div className="col-sm-6">
              <input
                style={{ height: '35px' }}
                type="text"
                id="send-payment-phoneNumber-input"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                onInput={(e) => {
                  // Remove non-numeric characters
                  let sanitizedValue = e.target.value.replace(/\D/g, '');
                  // Prepend "+" symbol
                  sanitizedValue = '+' + sanitizedValue;
                  // Update the input value
                  e.target.value = sanitizedValue;
                }} />
            </div>
          </div>
          <div className="col-sm-2">
            {buttonActive && <button type="submit" style={{ backgroundColor: ctaBackgroudColor }} id="late-payment-confirm-btn">
              Send Payment Link
            </button>}
          </div>
        </div>
      </form>
    </div>
  );
};

export default SendPaymentLink;