import "../../CSS/ReturnToSite.css";
import styles from "../../CSS/Base/Basic.module.css";
import { useState } from "react";
import userSession from "../../Constants/auth";
import { useParams } from "react-router-dom";
import { APP_SERVICES_URL } from "../../Constants/constants";
import SimpleBackdrop from "../Base/SimpleBackdrop";

const WaiveFees = ({ sharedData, setIncidentDetails, setActiveLinkBox2 }) => {
    const [loading, setLoading] = useState(false);
    const [buttonActive, setButtonActive] = useState(true);
    const { CustID, authHeader } = userSession;
    const { pkAlert } = useParams();
    const ctaBackgroudColor = userSession.themeData?.ctaBackgroudColor || '#e27a13';
    const [note, setNote] = useState("");
    // fetching data to show incident details
    const fetchDataAndUpdateIncidentDetails = async () => {
        try {
            // Fetch data from API
            setLoading(true); // Set loading to true before the fetch operation
            const apiUrl = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident?alertID=${pkAlert}`;
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Authorization": authHeader,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                setLoading(false);
                throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            setIncidentDetails(responseData);
            setLoading(false);
            setActiveLinkBox2('details');
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data from the API:", error);
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setButtonActive(false);
            const url = `${APP_SERVICES_URL}/senapi/${CustID}/paymentWaiveOff?siteName=${encodeURIComponent(sharedData.siteName)}&alertID=${encodeURIComponent(pkAlert)}`;

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: authHeader,
                    "Content-Type": "application/json", // Adjust content type if needed
                },
            });

            if (response.ok) {
                // Handle successful response
                setLoading(false);
                fetchDataAndUpdateIncidentDetails();
                setButtonActive(true);
                // console.log("Payment waived off successfully.");
            } else {
                // Handle error response
                setButtonActive(true);
                console.error("Failed to waive off payment.");
                setLoading(false);
            }
        } catch (error) {
            setButtonActive(true);

            setLoading(false);

            console.error("Error waiving off payment:", error);
            // You can handle errors here, e.g., show an error message
        }
    };
    return (
        <div className={styles.waiveFeesBox}>
            <SimpleBackdrop open={loading} onClose={() => setLoading(false)} />
            <form onSubmit={handleSubmit}>
                <div className="TableEditMainBox">
                    <div className="top-heading-text">Reason for Waiving Fees:</div>
                    <textarea
                        className="TableEditBox"
                        value={note}
                        maxLength={500}
                        onChange={(e) => setNote(e.target.value)}
                        placeholder="Please enter reason for waiving fees..."
                    />
                    <div className="TableEditButtonBox">
                        {buttonActive && <button className="TableSaveNoteButton" style={{ backgroundColor: ctaBackgroudColor }}>Waive Fees</button>
                        }                    </div>
                </div>
            </form>
        </div>
    )
};
export default WaiveFees;