import React, { useEffect, useRef, useState } from "react";
import "../../CSS/ReportsPage.css";
import UseReportsApi from "../../Custom Hooks/useReportsApi";
import userSession from "../../Constants/auth";
import { APP_SERVICES_URL } from "../../Constants/constants";
import StackedBarChart from "../../Charts/StackedBarChart";
import MaterialUIMultiSelectCheckmarks from "../../Components/ViewReportPage/MaterialUIMultiSelectCheckmarks";
import CustomizedTable from "../../Components/ViewReportPage/CustomizedTable";
import CSVDownloadButton from "../../Components/ViewReportPage/CSVDownloadButton";
import ErrorModal from "../../Modals/ErrorModal";
import ReportTypeModal from "../../Modals/ReportTypeModal";
import EmailModal from "../../Modals/EmailModal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PrintIcon from "@mui/icons-material/Print";
import LaunchIcon from "@mui/icons-material/Launch";
import EmailIcon from "@mui/icons-material/Email";
import { useApiDataContext } from "../../ApiDataContext";
import moment from "moment";
import RotatingIcon from "../../Components/Base/RotatingIcon";
import TitileReport from "../../Components/common/TitileReport";

const DebtRecovered = () => {
  const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-01");
  const endTime = moment().format("YYYY-MM-DD");
  const ErrorReportHeading = "Report Data Fetch Error";
  const { authHeader, sites, CustID, userRole } = userSession;
  const getSitesNames = (sites) => {
    if (!sites || !Array.isArray(sites)) {
      return [];
    }
    return sites.map((site) => site.name);
  };
  const siteNames = getSitesNames(sites);
  const { customersList, statesList, productsList } = useApiDataContext();
  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const chart1Color = userSession.themeData?.chart1Color || "#e27a13";
  const chart2Color = userSession.themeData?.chart2Color || "#f7a123";
  const chart3Color = userSession.themeData?.chart3Color || "#ffcb07";
  const chart4Color = userSession.themeData?.chart4Color || "#6f7172";
  const [showResult, setShowResult] = useState(false);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(endTime);
  const [selectedSite, setSelectedSite] = useState([]);
  const [selectedStates, setSelectedStates] = useState(statesList);
  const [selectedBrands, setSelectedBrands] = useState(customersList);
  const [selectedProducts, setSelectedProducts] = useState(productsList);
  const [newdata, setNewData] = useState({});
  const [apiResponse, setApiResponse] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [emailReport, setEmailReport] = useState(false);
  const [isReportTypeModalOpen, setIsReportTypeModalOpen] = useState(false);

  const openReportTypeModal = () => {
    setButtonClicked("printPDF");
    setIsReportTypeModalOpen(true);
  };

  const closeReportTypeModal = () => {
    setIsReportTypeModalOpen(false);
  };

  const openEmailModal = () => {
    setButtonClicked("emailReport");
    setEmailReport(true);
  };

  const closeEmailModal = () => {
    setEmailReport(false);
    setApiResponse(null);
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const formatDate = (dateString, isStart) => {
    const utcTime = moment.utc(dateString); // Assuming the UTC time is '2024-04-30T05:10:00'
    let localTime = utcTime.local(); // Convert to local time zone

    if (isStart) {
      // Set the time to 00:00:00 for the start date
      localTime.startOf("day");
    } else {
      localTime = localTime.endOf("day");
    }

    const localTimeWithoutOffset = localTime.format("YYYY-MM-DDTHH:mm:ss.SSS"); // Omitting the timezone offset

    return localTimeWithoutOffset;
  };
  // Define state variables for data, loading, and error
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fetchData = UseReportsApi();
  const fetchReportData = async (payloadData) => {
    const { CustID, authHeader } = userSession;

    try {
      // Serialize payloadData into a query string
      setError(false);
      const queryParams = new URLSearchParams({
        limit: 10000,
        offset: 0,
      });
      const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incidentsList?${queryParams.toString()}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader, // Convert to base64 (Basic Auth)
        },
        body: JSON.stringify(payloadData), // Convert payloadData to JSON string
      });

      if (!response.ok) {
        setError(true);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      setError(true);
      setErrorMessage(
        "Failed to fetch report data. Please try again. If the issue persists, contact support."
      );
      return [];
    }
  };

  const updateDataStateWithSpecificValues = (result) => {
    // Create a dictionary to store the specific values.
    const specificValues = {};

    // Check for the specific values in the result variable.
    for (const key in result) {
      if (result.hasOwnProperty(key)) {
        specificValues[key] = result[key];
      }
    }

    // Update the data state with the specific values.
    setNewData({
      ...specificValues,
    });
  };

  const handleSubmit = async (e) => {
    const formattedStartDate = formatDate(startDate, true);
    const formattedEndDate = formatDate(endDate, false);

    const payloadData = {
      sites: selectedSite,
      states: selectedStates,
      brands: selectedBrands,
      products: selectedProducts,
      to: formattedEndDate,
      from: formattedStartDate,
    };
    if (payloadData.sites.length < 1) {
      return;
    }
    try {
      setIsLoading(true);
      setError(false);
      const result = await fetchData(
        authHeader,
        CustID,
        "debtRecovered",
        payloadData
      );
      setData(result);
      updateDataStateWithSpecificValues(
        result.recoveredIncidents.paymentModesInfo
      );
      setShowResult(true);
    } catch (error) {
      setError(true);
      setErrorMessage(
        "Failed to fetch report data. Please try again. If the issue persists, contact support."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const DebtOutRecoveredPayloadGenerator = (
    label,
    from,
    to,
    sites,
    brands,
    products,
    states
  ) => {
    return {
      statuses: { "Debt Paid": [label] },
      sites,
      from,
      to,
      brands,
      products,
      states,
    };
  };

  // Define a function for fetching and updating report data
  const fetchAndUpdateReportData = async () => {
    const formattedStartDate = formatDate(startDate, true);
    const formattedEndDate = formatDate(endDate, false);
    const allKeys = Object.keys(newdata);

    // console.log("Step 1: Formatting start and end dates:", formattedStartDate, formattedEndDate);

    // Iterate over the keys in the newData state and call the fetchReportData API for each key.
    for (const key of allKeys) {
      // console.log(`Step 2: Fetching data for key: ${key}`);
      // Generate the payload for the API call.
      const payload = DebtOutRecoveredPayloadGenerator(
        key, // Use the current key as-is
        formattedStartDate,
        formattedEndDate,
        selectedSite,
        selectedBrands,
        selectedProducts,
        selectedStates
      );

      try {
        payload.isFuelLitresRequired = true;
        payload.isSiteAddressRequired = true;
        payload.isProcessingFeeRequired = true;
        payload.isEmployeeNameRequired = true;
        payload.isSiteTypeRequired = true;
        payload.isFuelTypeRequired = true;
        payload.isStoreIDRequired = true;
        setError(false);
        // console.log("Step 3: Calling fetchReportData API with payload:", payload);
        const result = await fetchReportData(payload, key); // Use the current key as the second argument
        // console.log("Step 4: Received data for key:", key, "Data:", result);
        // Update the newData state with the result.
        newdata[key] = result;
      } catch (error) {
        console.error(
          "Step 5: Error occurred while fetching data for key:",
          key,
          "Error:",
          error
        );
        setError(true);
        setErrorMessage(
          "Failed to fetch report data. Please try again. If the issue persists, contact support."
        );
      }
    }
    // console.log("Step 6: Updated newData state:", newdata);
    // Set the new newData state.
    setNewData(newdata);
  };

  useEffect(() => {
    fetchAndUpdateReportData();
    // eslint-disable-next-line
  }, [newdata]);

  // Use the fetchAndUpdateReportData function in handleReportClick

  // Prepare the chart data based on the values you want to pass
  // Prepare the chart data based on the values you want to pass
  const paymentModes = [
    "On Site",
    "On Site Late Payment",
    "Online",
    "Online FRS",
  ];
  // const colors = [chart1Color, chart2Color, chart3Color, chart4Color];
  const colors = [chart1Color, chart2Color, chart3Color, chart4Color];

  const chartData = [];

  paymentModes.forEach((mode, index) => {
    const value = getValueSafely(
      data,
      `recoveredIncidents.paymentModesInfo.${mode}.recoveredAmount`
    );
    if (value !== undefined && value !== 0) {
      chartData.push({
        data: [value],
        color: colors[index],
      });
    }
  });

  // Utility function to safely access nested properties
  function getValueSafely(obj, property) {
    const properties = property.split(".");
    let value = obj;

    for (let prop of properties) {
      if (value && value.hasOwnProperty(prop)) {
        value = value[prop];
      } else {
        return undefined;
      }
    }

    return value;
  }

  // Watch for changes in the selectedSite array
  useEffect(() => {
    if (selectedSite.length === 0) {
      // Clear your state here, replace with your actual state clearing logic
      setData(null);
      setShowResult(false);
    }
  }, [selectedSite]); // This effect will run whenever selectedSite changes

  const paymentModesInfo = data?.recoveredIncidents?.paymentModesInfo || {};
  console.log({ paymentModesInfo });
  // Check if paymentModesInfo has at least one key
  const hasPaymentModes = Object.keys(paymentModesInfo).length > 0;

  const onSiteRecoveredAmount = (
    (data &&
      data.recoveredIncidents &&
      data.recoveredIncidents.paymentModesInfo["On Site"] &&
      data.recoveredIncidents.paymentModesInfo["On Site"].recoveredAmount) ||
    0
  ).toFixed(2);
  const onSiteLatePaymentRecoveredAmount = (
    (data &&
      data.recoveredIncidents &&
      data.recoveredIncidents.paymentModesInfo["On Site Late Payment"] &&
      data.recoveredIncidents.paymentModesInfo["On Site Late Payment"]
        .recoveredAmount) ||
    0
  ).toFixed(2);
  const onlineRecoveredAmount = (
    (data &&
      data.recoveredIncidents &&
      data.recoveredIncidents.paymentModesInfo["Online"] &&
      data.recoveredIncidents.paymentModesInfo["Online"].recoveredAmount) ||
    0
  ).toFixed(2);
  const onlineFRSRecoveredAmount = (
    (data &&
      data.recoveredIncidents &&
      data.recoveredIncidents.paymentModesInfo["Online FRS"] &&
      data.recoveredIncidents.paymentModesInfo["Online FRS"].recoveredAmount) ||
    0
  ).toFixed(2);

  const handleCloseErrorModal = () => {
    setError(false);
  };

  // Initialize accordion state
  const [accordionState, setAccordionState] = useState(() => {
    const initialState = {};
    Object.keys(paymentModesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      initialState[formattedKey] = false;
    });
    return initialState;
  });

  // Initialize contentRefs and contentHeights
  const contentRefs = useRef({});
  const [contentHeights, setContentHeights] = useState(() => {
    const heights = {};
    Object.keys(paymentModesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      heights[formattedKey] = 0;
    });
    return heights;
  });

  // Function to open all accordions
  const openAllAccordions = () => {
    const updatedAccordionState = {};
    Object.keys(paymentModesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      updatedAccordionState[formattedKey] = true;
    });
    setAccordionState(updatedAccordionState);
  };

  // Function to close all accordions
  const closeAllAccordions = () => {
    const updatedAccordionState = {};
    Object.keys(paymentModesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      updatedAccordionState[formattedKey] = false;
    });
    setAccordionState(updatedAccordionState);
  };

  // Toggle individual accordion
  const toggleAccordionNew = (accordionName) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [accordionName]: !prevState[accordionName],
    }));
  };

  // Effect to initialize contentRefs
  useEffect(() => {
    const refs = {};
    Object.keys(paymentModesInfo).forEach((key) => {
      const formattedKey = key.replace(/ /g, "_");
      refs[formattedKey] = React.createRef();
    });
    contentRefs.current = refs;
  }, [paymentModesInfo]);

  // Effect to update content heights
  useEffect(() => {
    const updatedHeights = {};
    Object.keys(contentRefs.current).forEach((key) => {
      if (contentRefs.current[key].current) {
        updatedHeights[key] = contentRefs.current[key].current.scrollHeight;
      }
    });
    setContentHeights(updatedHeights);
  }, [accordionState]);

  const handleReportTypeSelection = (reportType, emailAddress) => {
    // Handle the selected report type (detailed or summary) here.
    if (reportType === "summary") {
      // Call generatePDF for a summary report
      closeAllAccordions();
      setTimeout(() => {
        generatePDF("DebtRecovered_Summary_Report", emailAddress);
      }, 2000); // 2 seconds delay
    } else if (reportType === "detailed") {
      // Open all accordions, and then call generatePDF after 2 seconds
      openAllAccordions();
      setTimeout(() => {
        generatePDF("DebtRecovered_Detailed_Report", emailAddress);
      }, 2000); // 2 seconds delay
    }
  };

  function generatePDF(filename, emailAddress) {
    let input = document.getElementById("pdf-content");
    const hidden_content = document.getElementById("hidden-content");
    const report_created_time = document.getElementById("report-created-time");
    const pdf = new jsPDF("portrait", "mm", "a4");

    html2canvas(input, {
      scale: 6,
      useCORS: true,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("hidden-content").classList.remove("d-none");
        clonedDoc
          .getElementById("report-created-time")
          .classList.remove("d-none");
      },
    }).then((canvas) => {
      hidden_content.classList.add("d-none");
      report_created_time.classList.add("d-none");
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width;
      let heightLeft = height;
      let pageHeight = pdf.internal.pageSize.getHeight();
      let position = 10;
      pdf.addImage(imgData, "JPEG", 0, position, width, height);

      heightLeft -= pageHeight - position;
      while (heightLeft >= 0) {
        position = heightLeft - height + 10;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, width, height);
        heightLeft -= pageHeight;
      }
      // Save the PDF or send it to the API based on the buttonClicked state
      if (buttonClicked === "printPDF") {
        pdf.save(`${filename}.pdf`);
      } else if (buttonClicked === "emailReport" && emailAddress) {
        sendPDFToAPI(pdf.output("blob"), emailAddress, filename);
      }
    });
  }

  function sendPDFToAPI(pdfBlob, emailAddressToSend, filename) {
    // Create a FormData object to send the PDF as a file attachment
    const mailHeading = "Scancam Report PDF";
    const mailBody = "Please find attached the report you requested.";
    const formData = new FormData();
    formData.append("attachment", pdfBlob, `${filename}.pdf`);

    // Set up the API endpoint and headers
    const endpoint = `${APP_SERVICES_URL}/senapi/${CustID}/mailWithAttachment?subject=${mailHeading}&body=${mailBody}&mailTo=${emailAddressToSend}`;

    // Reset the state before making the API call
    setApiResponse(null);
    setEmailReport(false);

    fetch(endpoint, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: authHeader,
      },
    })
      .then((response) => {
        if (response.ok) {
          setApiResponse(true);
          setEmailReport(true);
        } else {
          setApiResponse(false);
          setEmailReport(true);
        }
      })
      .catch((error) => {
        setApiResponse(false);
        setEmailReport(true);
      });
  }

  const simulateFormSubmit = () => {
    // Replace this with your actual form submission logic
    // For example, if handleSubmit is an async function, you can await it here
    handleSubmit();
  };

  useEffect(() => {
    simulateFormSubmit();
    // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    selectedSite,
    selectedBrands,
    selectedStates,
    selectedProducts,
  ]);

  //call the function to close accordions when data changes
  useEffect(() => {
    closeAllAccordions();
    // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    selectedSite,
    selectedBrands,
    selectedStates,
    selectedProducts,
  ]);

  const formattedDateTime = new Date().toLocaleString("en-US", {
    dateStyle: "medium", // or 'short', 'long', 'full' as needed
    timeStyle: "short", // or 'medium', 'long', 'full' as needed
  });

  return (
    <div className="w-dvw min-h-[65vh]">
      {emailReport && (
        <EmailModal
          show={emailReport}
          onClose={closeEmailModal}
          onSelectReportType={handleReportTypeSelection}
          apiResponse={apiResponse}
        />
      )}
      {isReportTypeModalOpen && (
        <ReportTypeModal
          show={isReportTypeModalOpen}
          onClose={closeReportTypeModal}
          onSelectReportType={handleReportTypeSelection}
        />
      )}
      {error && (
        <ErrorModal
          show={true}
          onClose={handleCloseErrorModal}
          message={errorMessage}
          heading={ErrorReportHeading}
        />
      )}

      <div className="row">
        <div className="row p-0 bg-[#F4F4F4]">
          <div className="col-sm-12 flex w-dvw">
            <div className="min-w-[1170px] max-w-[1170px] mx-auto">
              <TitileReport label={"Debt Recovered"} />
              {/* reports options */}
              <div className="min-w-[1170px] max-w-[1170px] lg:flex md:flex mb-4">
                <div className="flex-grow-[9] flex-shrink-0 basis-[85%]">
                  <div className="text-[18px] text-[#B4B4B4] font-bold pt-1 pb-2">
                    Filters
                  </div>
                  <div className="grid grid-cols-6 grid-rows-1 text-[#6f7172]">
                    <div className="text-sm  h-[64px] pe-[15px]">
                      <div className="h-[20px]">Start date</div>
                      <div className="h-[44px]">
                        <input
                          className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                          type="date"
                          id="startDate"
                          value={startDate}
                          onChange={handleStartDateChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">End date</div>
                      <div className="h-[44px]">
                        <input
                          className="text-xl h-full text-[#515151] border border-[#fff] hover:border-[#fff] focus:border-white ps-1 outline-none"
                          type="date"
                          id="endDate"
                          value={endDate}
                          onChange={handleEndDateChange}
                          required
                        />
                      </div>
                    </div>
                    {(userRole === "sensenadmin" ||
                      userRole === "frs-reviewer") && (
                      <>
                        {" "}
                        <div className="text-sm h-[64px] pe-[15px]">
                          <div className="h-[20px]">States</div>
                          <div className="h-[44px]">
                            <MaterialUIMultiSelectCheckmarks
                              options={statesList}
                              setSelectedStateFunction={setSelectedStates}
                              initialStateAllSelected={true}
                            />
                          </div>
                        </div>
                        <div className="text-sm h-[64px] pe-[15px]">
                          <div className="h-[20px]">Brand</div>
                          <div className="h-[44px]">
                            <MaterialUIMultiSelectCheckmarks
                              options={customersList}
                              setSelectedStateFunction={setSelectedBrands}
                              initialStateAllSelected={true}
                            />
                          </div>
                        </div>{" "}
                      </>
                    )}
                    <div className="text-sm h-[64px] pe-[15px]">
                      <div className="h-[20px]">Sites</div>
                      <div className="h-[44px]">
                        <MaterialUIMultiSelectCheckmarks
                          options={siteNames}
                          setSelectedStateFunction={setSelectedSite}
                          initialStateAllSelected={false}
                        />
                      </div>
                    </div>
                    {(userRole === "sensenadmin" ||
                      userRole === "frs-reviewer") && (
                      <div className="text-sm h-[64px] pe-[15px]">
                        <div className="h-[20px]">Product</div>
                        <div className="h-[44px]">
                          <MaterialUIMultiSelectCheckmarks
                            options={productsList}
                            setSelectedStateFunction={setSelectedProducts}
                            initialStateAllSelected={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-grow-[1] flex-shrink-0 basis-[15%]">
                  <div className="text-[18px] text-[#B4B4B4] font-bold pt-1 pb-2">
                    Actions
                  </div>
                  <div className="grid grid-rows-3 grid-cols-1">
                    <div
                      className={`${
                        hasPaymentModes ? "action-btns" : "disable-btns"
                      }`}
                      onClick={hasPaymentModes ? openReportTypeModal : null}
                      style={{
                        color:
                          hasPaymentModes && textAccentColor
                            ? textAccentColor
                            : "inherit",
                      }}
                    >
                      <span className="flex fw-bolder">
                        <PrintIcon style={{ fontSize: "18px" }} />
                        <span className="ps-1">Print-friendly PDF</span>
                      </span>
                    </div>
                    {hasPaymentModes ? (
                      <div
                        className="action-btns "
                        style={{ color: textAccentColor || "inherit" }}
                      >
                        <CSVDownloadButton
                          data={newdata}
                          reportName={"scancam-debt-recovered-report"}
                        />
                      </div>
                    ) : (
                      <div className="d-flex  disable-btns">
                        <LaunchIcon style={{ fontSize: "18px" }} />
                        <span className="ps-1">Export as CSV</span>
                      </div>
                    )}
                    <div
                      className={`${
                        hasPaymentModes ? "action-btns" : "disable-btns"
                      } `}
                      style={{
                        color:
                          hasPaymentModes && textAccentColor
                            ? textAccentColor
                            : "inherit",
                      }}
                      onClick={hasPaymentModes ? openEmailModal : null}
                    >
                      <div className="d-flex ps-0">
                        <EmailIcon style={{ fontSize: "18px" }} />
                        <span className="ps-1">Email report</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <RotatingIcon colorValue={"grey"} />
        </div>
      ) : (
        <div id="pdf-content">
          <div
            id="report-created-time"
            className="text-end mt-2 report-created-time d-none"
          >
            Report created {formattedDateTime} by
            <span className="ps-1 pe-2">{`${userSession.username}`}</span>
          </div>
          {showResult === true && data && hasPaymentModes && (
            <div className="row">
              <div className="row p-0 bg-[#F4F4F4]">
                <div className="col-sm-12 flex w-dvw">
                  <div className="min-w-[1170px] max-w-[1170px] mx-auto">
                    <div className="max-w-[1170px] mx-auto">
                      <div
                        className="min-w-[1170px] max-w-[1170px] mx-auto"
                        style={{ marginBottom: "5px" }}
                      >
                        <div id="hidden-content" className="d-none">
                          <h1 className="report-heading m-1 p-1">
                            Debt Recovered Reports
                          </h1>
                          <h4 className="m-1 p-1">Detail</h4>
                          <div className="mt-3">
                            <div className="row ms-1">
                              <div className="col-6"> Date range :</div>
                              <div className="col-6"> Sites :</div>
                            </div>
                            <div className="row ms-1 report-range">
                              <div className="col-6">
                                {`${startDate}`} - {`${endDate}`}
                              </div>
                              <div className="col-6">
                                {" "}
                                {`${selectedSite.toString()}`}
                              </div>
                            </div>
                          </div>
                          <hr className="mb-3" />
                        </div>
                        <div className="max-w-[1170px] mx-auto ">
                          <div className="grid grid-cols-6 items-center py-0">
                            <div className="col-span-4 flex items-center text-[#6f7172] text-[18px] font-extrabold">
                              $
                              {data &&
                              data.outstandingIncidents &&
                              data.recoveredIncidents.recoveredAmount !== 0
                                ? data.recoveredIncidents.recoveredAmount.toFixed(
                                    2
                                  )
                                : "0.00"}{" "}
                              outstanding from{" "}
                              {data && data.recoveredIncidents
                                ? (data.recoveredIncidents
                                    .numRecoveredDriveOffs || 0) +
                                  (data.recoveredIncidents.numRecoveredIOUs ||
                                    0)
                                : 0}{" "}
                              incident
                              {(data.recoveredIncidents.numRecoveredDriveOffs ||
                                0) +
                                (data.recoveredIncidents.numRecoveredIOUs ||
                                  0) >
                              1
                                ? "s"
                                : ""}
                            </div>
                            <div className="col-span-1 text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172] text-[14px] font-extrabold pe-[15px] border-r border-[#ececec]">
                                <span className="font-extrabold">
                                  $
                                  {data &&
                                  data.recoveredIncidents &&
                                  data.recoveredIncidents
                                    .recoveredDriveOffAmount
                                    ? data.recoveredIncidents.recoveredDriveOffAmount.toFixed(
                                        2
                                      )
                                    : "0.00"}{" "}
                                  from{" "}
                                  {data &&
                                  data.recoveredIncidents &&
                                  data.recoveredIncidents.numRecoveredDriveOffs
                                    ? data.recoveredIncidents
                                        .numRecoveredDriveOffs
                                    : 0}
                                </span>

                                <span className="text-[#b4b4b4]">
                                  Drive Offs
                                </span>
                              </div>
                            </div>
                            <div className="col-span-1 text-end pe-0">
                              <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                <span className="font-extrabold">
                                  $
                                  {data &&
                                  data.recoveredIncidents &&
                                  data.recoveredIncidents.recoveredIOUAmount
                                    ? data.recoveredIncidents.recoveredIOUAmount.toFixed(
                                        2
                                      )
                                    : "0.00"}{" "}
                                  from{" "}
                                  {data &&
                                  data.recoveredIncidents &&
                                  data.recoveredIncidents.numRecoveredIOUs
                                    ? data.recoveredIncidents.numRecoveredIOUs
                                    : 0}
                                </span>
                                <span className="text-[#b4b4b4]">IOUs</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* chart data */}
                      <StackedBarChart
                        chartData={chartData}
                        className="report-bar-chart"
                      />
                      <div
                        className="max-w-[1170px] mx-auto"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="grid grid-cols-6 items-center pb-3">
                          <div className="col-span-2 flex items-center text-[#B4B4B4] font-bold text-base">
                            Totals
                          </div>
                          <div className="col-span-1 text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>${onSiteRecoveredAmount}</span>

                              <span className="col-span-1 text-[#b4b4b4]">
                                <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#e27a13]"></span>
                                On Site
                              </span>
                            </div>
                          </div>
                          <div className="col-span-1 text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172]  text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>${onSiteLatePaymentRecoveredAmount}</span>
                              <span className="text-[#b4b4b4]">
                                <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#f7a123]"></span>
                                On-Site Late Payment
                              </span>
                            </div>
                          </div>
                          <div className="col-span-1 text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>${onlineRecoveredAmount}</span>
                              <span className="text-[#b4b4b4]">
                                <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#ffcb07]"></span>
                                Online
                              </span>
                            </div>
                          </div>
                          <div className="col-span-1 text-end pe-0">
                            <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                              <span>${onlineFRSRecoveredAmount}</span>
                              <span className="text-[#b4b4b4]">
                                <span className="inline-block w-[10px] h-[10px] rounded me-[8px] bg-[#6f7172]"></span>
                                FRS Recovered
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="row p-0">
              <div className="col-sm-12 flex w-dvw">
                <div className="col-12 min-w-[1170px] max-w-[1170px] mx-auto">
                  {selectedSite.length < 1 ? (
                    <div className="row ">
                      <div
                        className="col-sm-12 text-center mt-4"
                        style={{
                          fontSize: "20px",
                          color: "#6f7172",
                          paddingTop: "50px",
                        }}
                      >
                        <p>At least one Site must be selected.</p>
                      </div>
                    </div>
                  ) : hasPaymentModes ? (
                    <div
                      className="accordion container-max-width-reports-page"
                      id="accordionPanelsStayOpenExample"
                    >
                      {/* /// */}
                      {Object.entries(paymentModesInfo).map(([key, value]) => {
                        const formattedKey = key.replace(/ /g, "_");
                        return (
                          <div
                            key={formattedKey}
                            className="container mx-auto px-0 py-0"
                          >
                            <div className="bg-white border-t border-b border-gray-200">
                              <button
                                type="button"
                                className="w-full px-0 py-3 text-left"
                                onClick={() => toggleAccordionNew(formattedKey)}
                              >
                                <div className="grid grid-cols-6 items-center">
                                  <div className="col-span-4 flex items-center">
                                    <svg
                                      className={`w-4 h-4 font-bold text-[#E27A13] transform transition-transform duration-300 ${
                                        accordionState[formattedKey]
                                          ? "rotate-90"
                                          : ""
                                      }`}
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path d="M9 5l7 7-7 7"></path>
                                    </svg>
                                    <span className="ml-2 text-[#E27A13] font-bold">
                                      {value.count} Debt Paid {key}
                                      <div className="text-sm font-extralight">
                                        {accordionState[formattedKey]
                                          ? "Hide all"
                                          : "Show all"}
                                      </div>
                                    </span>
                                  </div>
                                  {!accordionState[formattedKey] && (
                                    <>
                                      <div className="col-span-1 text-end pe-0">
                                        <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                          <span>
                                            ${value.recoveredAmount.toFixed(2)}
                                          </span>
                                          <span className="text-[#b4b4b4]">
                                            Recovered
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-span-1 text-end pe-0">
                                        <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                          <span>
                                            ${value.reimbursedAmount.toFixed(2)}
                                          </span>
                                          <span className="text-[#b4b4b4]">
                                            Reimbursed
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </button>
                              <div
                                ref={contentRefs.current[formattedKey]}
                                className="overflow-hidden transition-all duration-300 ease-in-out"
                                style={{
                                  maxHeight: accordionState[formattedKey]
                                    ? `${contentHeights[formattedKey]}px`
                                    : "0",
                                  opacity: accordionState[formattedKey] ? 1 : 0,
                                }}
                              >
                                <CustomizedTable
                                  reportName="debtRecovered"
                                  heading={[
                                    "OCCURRED AT",
                                    "LICENSE PLATE",
                                    "TYPE",
                                    "STATUS",
                                    "SITE",
                                    "PUMP",
                                    "REFERENCE",
                                    "VALUE",
                                  ]}
                                  data={newdata[key]}
                                  selectedLabel={key}
                                />
                                <div className="grid grid-cols-6 items-center py-3">
                                  <div className="col-span-4 flex items-center"></div>
                                  <div className="col-span-1 text-end pe-0">
                                    <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                      <span>
                                        ${value.recoveredAmount.toFixed(2)}
                                      </span>
                                      <span className="text-[#b4b4b4]">
                                        Recovered
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-span-1 text-end pe-0">
                                    <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                                      <span>
                                        ${value.reimbursedAmount.toFixed(2)}
                                      </span>
                                      <span className="text-[#b4b4b4]">
                                        Reimbursed
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="grid grid-cols-6 items-center py-3">
                        <div className="col-span-4 flex items-center text-[#B4B4B4] font-bold text-base">
                          Totals
                        </div>
                        <div className="col-span-1 text-end pe-0">
                        <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                          <span className="text-[#e27a13]">
                          ${(data.recoveredIncidents.recoveredAmount || 0).toFixed(2)}
                          </span>
                          <span className="text-[#b4b4b4]">Recovered</span>
                        </div>
                      </div>
                      <div className="col-span-1 text-end pe-0">
                        <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                          <span className="text-[#e27a13]">
                          ${(data.outstandingIncidents.recoveredAmount || 0).toFixed(2)}
                          </span>
                          <span className="text-[#b4b4b4]">Reimbursed</span>
                        </div>
                      </div>
                      </div>
                      <div className="grid grid-cols-6 items-center py-3">
                        <div className="col-span-3 flex items-center text-[#B4B4B4] font-bold text-base">
                        Reimbursements
                        </div>
                        <div className="col-span-1 text-end pe-0">
                        <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                          <span className="text-[#e27a13]">
                          ${(data.recoveredIncidents.recoveredAmount || 0).toFixed(2)}
                          </span>
                          <span className="text-[#b4b4b4]">Total for Reimbursement
                          </span>
                        </div>
                      </div>
                        <div className="col-span-1 text-end pe-0">
                        <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                          <span className="text-[#e27a13]">
                          ${(data ? data.recoveredIncidents.recoveredAmount : 0).toFixed(2)}
                          </span>
                          <span className="text-[#b4b4b4]">Reimbursed</span>
                        </div>
                      </div>
                      <div className="col-span-1 text-end pe-0">
                        <div className="flex flex-col justify-between text-[#6F7172] text-sm font-bold pe-[15px] border-r border-[#ececec]">
                          <span className="text-[#e27a13]">
                          ${(data.outstandingIncidents.outstandingAmount || 0).toFixed(2)}
                          </span>
                          <span className="text-[#b4b4b4]">Balance for Reimbursement</span>
                        </div>
                      </div>
                      </div>

                    </div>
                  ) : (
                    <div className="row">
                      <div
                        className="col-sm-12 text-center mt-4"
                        style={{
                          fontSize: "20px",
                          color: "#6f7172",
                          paddingTop: "50px",
                        }}
                      >
                        <p>
                          There are no incidents which match your search
                          criteria.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DebtRecovered;