import { useState, useEffect } from "react";
import { APP_SERVICES_URL } from "../Constants/constants";
import userSession from "../Constants/auth";

const useSenapiPumps = (siteName) => {
  const { authHeader, CustID } = userSession;
  const [pumps, setPumps] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if siteName is provided and not null or undefined
        if (!siteName) {
          return;
        }
        // If data doesn't exist in localStorage for the siteName, fetch from the API
        const url = `${APP_SERVICES_URL}/senapi/${CustID}/pumps?siteName=${encodeURIComponent(siteName)}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: authHeader,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setPumps(data);

        } else {
          console.error("Failed to fetch pumps data.");
        }
      } catch (error) {
        console.error("Error fetching pumps data:", error);
        // You can handle errors here, e.g., setPumps([]) or show an error message
      }
    };

    fetchData();
  }, [CustID, authHeader, siteName]);

  return pumps;
};

export default useSenapiPumps;