import React, { useState } from "react";
import "../../CSS/viewVideo.css";
import { useLocation } from "react-router-dom";
import SimpleBackdrop from "../Base/SimpleBackdrop";

const ViewVideo = () => {
  const location = useLocation();
  const { VideoURL, videoTitle } = location.state || {};
  const [iframeLoaded, setIframeLoaded] = useState(false); // State to track iframe loading

  const capitalizeWords = (str) => {
    return str.replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
  };
  const capitalizedVideoTitle = capitalizeWords(videoTitle); // Capitalize the first letter of each word

  const handleIframeLoad = () => {
    setIframeLoaded(true); // Set state to indicate iframe has loaded
  };

  return (
    <div className="view-video-box">
      <div className="row view-video-row">
        <div className="col-sm-12 view-video-col">
          <h1 className="view-video-title">{capitalizedVideoTitle} Video</h1>
        </div>
      </div>
      <div className="row">
        {!iframeLoaded && <SimpleBackdrop open={!iframeLoaded} />
        } {/* Show loading indicator until iframe is loaded */}
        <iframe
          className={`view-video-body ${iframeLoaded ? "show" : "hide"}`} // Conditionally apply classes to show/hide iframe
          src={VideoURL}
          title="dfsd"
          allowFullScreen
          onLoad={handleIframeLoad}
        ></iframe>
      </div>
    </div>
  );
};

export default ViewVideo;
