import { useState, useEffect } from "react";
import { APP_SERVICES_URL } from "../Constants/constants";
import userSession from "../Constants/auth";

const useSenapiFuelTypes = (siteName) => {
  const { CustID, authHeader } = userSession;
  const [fuelTypes, setFuelTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if siteName is provided and not null or undefined
        if (!siteName) {
          return;
        }

        // If data doesn't exist in localStorage for the siteName, fetch from the API
        let url = `${APP_SERVICES_URL}/senapi/${CustID}/fuelTypes`;
        if (siteName) {
          url += `?siteName=${encodeURIComponent(siteName)}`;
        }
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: authHeader,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setFuelTypes(data);
        } else {
          console.error("Failed to fetch fuel types.");
        }
      } catch (error) {
        console.error("Error fetching fuel types:", error);
      }
    };

    fetchData();
  }, [CustID, authHeader, siteName]);

  return fuelTypes;
};

export default useSenapiFuelTypes;