import { APP_SERVICES_URL } from "../Constants/constants";

const UseReportsApi = () => {
  const fetchData = async (authHeader, customerId, apiEndpoint, payload, isMonthly = undefined) => {
    try {
      const queryParams = new URLSearchParams();


      if (isMonthly !== undefined) {
        queryParams.append('isMonthly', isMonthly.toString());
      }

      const url = `${APP_SERVICES_URL}/sn-reports-service/${customerId}/${apiEndpoint}?${queryParams.toString()}`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader,
        },
        body: JSON.stringify(payload), // Convert payloadData to JSON string

      });

      if (!response.ok) {
        throw new Error('Failed to fetch data.');
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  return fetchData;
};

export default UseReportsApi;
