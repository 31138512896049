import { APP_SERVICES_URL } from "../Constants/constants";


const createIncidentApi = async (authHeader, CustID, incidentDetails) => {
  // Filter out empty, null, or undefined fields from the incidentDetails object
  const cleanedIncidentDetails = {};
  for (const [key, value] of Object.entries(incidentDetails)) {
    if (value !== "" && value !== null && value !== undefined) {
      cleanedIncidentDetails[key] = value;
    }
  }

  const url = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': authHeader,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(cleanedIncidentDetails) // Use the cleaned data here
  };

  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      throw new Error(data.message || 'Error creating incident');
    }
  } catch (error) {
    throw error;
  }
};

export default createIncidentApi;
