import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import userSession from "../../Constants/auth";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
  },
  ".hidden": {
    display: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomizedTable = ({ heading, data, reportName }) => {
  const formatDateTime = (dateTimeString) => {
    // Create a moment object from the input date-time string
    const momentDateTime = moment(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSS");

    // Format the moment object to 12-hour format with AM/PM
    const formattedTime = momentDateTime.format("h:mm A"); // 12-hour format with AM/PM

    // Format the date in the desired format (DD/MM/YYYY)
    const localDateString = momentDateTime.format("DD/MM/YYYY");

    return (
      <div>
        <span>
          {localDateString} {formattedTime.toUpperCase()}
        </span>
      </div>
    );
  };

  function formatPeriod(period) {
    if (!period || typeof period !== "string") {
      return period; // Return the same string back if it's not a string or is null/undefined
    }

    const parts = period.split(" ");
    if (parts.length !== 2) {
      return period; // Return the same string back if it does not have exactly two parts
    }

    const month = parseInt(parts[0], 10);
    const year = parts[1];

    if (isNaN(month) || month < 1 || month > 12 || !/^\d{4}$/.test(year)) {
      return period; // Return the same string back if the month or year is invalid
    }

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${monthNames[month - 1]} ${year}`;
  }

  const textAccentColor = userSession.themeData?.textAccentColor || "#e27a13";
  const getRowData = () => {
    if (data && reportName === "offenderDetection" && Array.isArray(data)) {
      return data.map((item, index) => (
        <StyledTableRow key={index}>
          <StyledTableCell align="center" style={{ color: "#6F7172" }}>
            {formatPeriod(item.period)}
          </StyledTableCell>
          <StyledTableCell align="center" style={{ color: "#6F7172" }}>
            {item.numDetections}
          </StyledTableCell>
          <StyledTableCell
            style={{ color: textAccentColor, fontWeight: "bolder" }}
            align="center"
          >
            {item.numOffenderDetections}
          </StyledTableCell>
        </StyledTableRow>
      ));
    } else if (
      data &&
      reportName === "siteTraffic" &&
      Array.isArray(data.periodicDetectionInfo)
    ) {
      const tableData = [];
      data.periodicDetectionInfo.forEach((periodInfo, index) => {
        // Display the period and add a row for each set under dayTimeInfo.
        const row = (
          <StyledTableRow key={`period-${index}`}>
            <StyledTableCell align="center" style={{ color: "#6F7172" }}>
              {formatPeriod(periodInfo.period)}
            </StyledTableCell>
            {periodInfo.dayTimeInfo.map((dayTime, dayTimeIndex) => (
              <React.Fragment key={`period-${index}-dayTime-${dayTimeIndex}`}>
                <StyledTableCell
                  style={{ color: textAccentColor, fontWeight: "bolder" }}
                  align="center"
                >
                  {dayTime.peakTime}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ color: "#6F7172" }}>
                  {dayTime.detectionRate
                    ? dayTime.detectionRate.toFixed(1)
                    : "0"}
                </StyledTableCell>
              </React.Fragment>
            ))}
          </StyledTableRow>
        );

        tableData.push(row);
      });

      return tableData;
    } else if (data && reportName === "advanceReport" && Array.isArray(data)) {
      return data.map((row, index) => (
        <StyledTableRow
          key={index}
          onClick={() => window.open(`/scancam/incident/${row.alertID}`)}
        >
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            {row.detected ? formatDateTime(row.detected) : ""}
            {/* {row.detected} */}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            {row.ocr}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            {row.type}
          </StyledTableCell>
          <StyledTableCell
            style={{ color: textAccentColor, fontWeight: "700" }}
            align="center"
          >
            {row.status && (
              <div>
                {row.status === "Debt Paid" ? (
                  <>
                    {row.status} {row.resolutionNote}
                  </>
                ) : row.status === "Cancelled" ? (
                  <>
                    {row.status} - {row.resolutionNote}
                  </>
                ) : (
                  <>
                    {row.status} {row.resolutionNote}
                  </>
                )}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
              maxWidth: "200px", // Set the maximum width you want
              wordWrap: "break-word", // Ensure long words are wrapped
              whiteSpace: "normal", // Allow text to wrap
            }}
            align="center"
          >
            {row.siteName}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            {row.pumpNumber}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
              maxWidth: "150px", // Set the maximum width you want
              wordWrap: "break-word", // Ensure long words are wrapped
              whiteSpace: "normal", // Allow text to wrap
            }}
            align="center"
          >
            {row.employeeName}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            {row.fuelReference}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            ${row.fuelvalue}
          </StyledTableCell>
        </StyledTableRow>
      ));
    } else if (Array.isArray(data)) {
      return data.map((row, index) => (
        <StyledTableRow
          key={index}
          onClick={() => window.open(`/scancam/incident/${row.alertID}`)}
        >
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            {row.detected ? formatDateTime(row.detected) : ""}
            {/* {row.detected} */}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            {row.ocr}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            {row.type}
          </StyledTableCell>
          <StyledTableCell
            style={{ color: textAccentColor, fontWeight: "700" }}
            align="center"
          >
            {row.status && (
              <div>
                {row.status === "Debt Paid" ? (
                  <>
                    {row.status} {row.resolutionNote}
                  </>
                ) : row.status === "Cancelled" ? (
                  <>
                    {row.status} - {row.resolutionNote}
                  </>
                ) : (
                  <>
                    {row.status} {row.resolutionNote}
                  </>
                )}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
              maxWidth: "200px", // Set the maximum width you want
              wordWrap: "break-word", // Ensure long words are wrapped
              whiteSpace: "normal", // Allow text to wrap
            }}
            align="center"
          >
            {row.siteName}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            {row.pumpNumber}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            {row.fuelReference}
          </StyledTableCell>
          <StyledTableCell
            style={{
              lineHeight: "1.42857143",
              fontSize: "14px",
              color: "#6f7172",
              fontWeight: "700",
            }}
            align="center"
          >
            ${row.fuelvalue}
          </StyledTableCell>
        </StyledTableRow>
      ));
    } else {
      return null; // Return null or handle the case where data is not an array
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {heading.map((head, index) => (
              <StyledTableCell
                style={{ color: "#6f7172", fontSize: "14px" }}
                key={index}
                align="center"
              >
                {head}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ cursor: "pointer" }}>
          {data === null || data?.length === 0 || data === undefined ? (
            <StyledTableCell
              style={{
                fontSize: "16px",
                color: "#6F7172",
                fontWeight: "bolder",
              }}
              colSpan={heading.length}
              align="center"
            >
              Loading incident data...
            </StyledTableCell>
          ) : (
            getRowData()
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CustomizedTable.propTypes = {
  heading: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLabel: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
};

export default CustomizedTable;
