import React, { useEffect, useState } from "react";
import "../../CSS/late-payment.css";
import { useParams } from "react-router-dom";
import { APP_SERVICES_URL } from "../../Constants/constants";
import userSession from "../../Constants/auth";
import SimpleBackdrop from "../Base/SimpleBackdrop";
// import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import ErrorModal from "../../Modals/ErrorModal";

function LatePayment({ sharedData, setIncidentDetails, setActiveLinkBox2 }) {
  const ErrorModalHeading = "Payment Taking Failed";
  const errorMessage =
    "An error occurred while processing the payment. Please try again. If the issue persists, contact support.";
  const [error, setError] = useState(false);
  const [activeButton, setActiveButton] = useState(true);
  const { CustID, authHeader } = userSession;
  const ctaBackgroudColor =
    userSession.themeData?.ctaBackgroudColor || "#e27a13";
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [penaltyAmount, setPenaltyAmount] = useState(sharedData.amount);
  const [loading, setLoading] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState("");
  const { pkAlert } = useParams();
  useEffect(() => {
    if (sharedData) {
      setPenaltyAmount(sharedData.amountDue); // Assuming incidentDetails.fuelvalue is a number
    }
  }, [sharedData]);

  const isMoreThan4Days = (dateTimeString) => {
    const utcDateTime = moment.utc(dateTimeString); // Parse the UTC date-time string
    const localDateTime = utcDateTime.local(); // Convert to local time zone of browser
    const currentLocalDateTime = moment(); // Get current date-time in local time zone of browser

    // Check if the difference in days is greater than 4
    return localDateTime.isBefore(currentLocalDateTime.subtract(4, "days"));
  };

  // const isMoreThan4Days = (dateTimeString) => {
  //   const utcDateTime = moment.utc(dateTimeString); // Parse the UTC date-time string
  //   const localDateTime = utcDateTime.local(); // Convert to local time zone of browser
  //   const currentLocalDateTime = moment(); // Get current date-time in local time zone of browser

  //   // Check if the difference in days is greater than 4
  //   return localDateTime.isBefore(currentLocalDateTime.subtract(4, 'days'));
  // };

  const fetchDataAndUpdateHistory = async () => {
    try {
      // Fetch data from API
      setLoading(true); // Set loading to true before the fetch operation
      const apiUrl = `${APP_SERVICES_URL}/sn-incidents-service/${CustID}/incident?alertID=${pkAlert}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: authHeader,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setIncidentDetails(responseData);
      setLoading(false);
      setActiveLinkBox2("details");
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data from the API:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Set loading to true before making the API request
    setLoading(true);
    setActiveButton(false);
    const utcTime = moment.utc(selectedDate); // Assuming the UTC time is '2024-04-30T05:10:00'
    const localTime = utcTime.local(); // Convert to local time zone
    const localTimeWithOffset = localTime.format("YYYY-MM-DDTHH:mm:ss"); // Use 'T' to separate the date and time, and omit the space

    const formData = {
      alertID: pkAlert,
      timestamp: localTimeWithOffset,
      amount: penaltyAmount,
      receiptNumber: referenceNumber,
      fees: 0,
      siteName: sharedData.siteName,
    };
    try {
      setActiveButton(false);
      setError(false);
      const response = await fetch(`${APP_SERVICES_URL}/senapi/${CustID}/payment`, {
        method: "POST",
        headers: {
          Authorization: authHeader,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        fetchDataAndUpdateHistory();
        setActiveButton(true);
        setError(false);
      }
    } catch (error) {
      setActiveButton(true);
      setError(true);
      console.error("Payment failed: " + error.message);
    } finally {
      setActiveButton(true);
      // Set loading to false after the API request completes (regardless of success or failure)
      setLoading(false);
    }
  };
  const handleCloseErrorModal = () => {
    setError(false);
  };
  return (
    <div className="latepayment-container">
      {error && (
        <ErrorModal
          show={true}
          onClose={handleCloseErrorModal}
          message={errorMessage}
          heading={ErrorModalHeading}
        />
      )}
      <SimpleBackdrop open={loading} onClose={() => setLoading(false)} />
      <form onSubmit={handleSubmit}>
        <div
          className="col-sm-12 d-flex flex-column justify-content-between"
          style={{ minHeight: "350px" }}
        >
          <div className="col-sm-10" style={{ width: "100%" }}>
            <div className="col-sm-12 d-flex">
              <div className="col-sm-6">
                <div className="col-sm-6">
                  <label
                    style={{ marginLeft: "13px" }}
                    className="late-payment-field-heading"
                    htmlFor="paymentDate"
                  >
                    DATE PAID
                  </label>
                </div>
                <div className="col-sm-6">
                  <div className="calender-input-box">
                    <input
                      type="date"
                      value={selectedDate.toISOString().slice(0, 10)}
                      onChange={(e) =>
                        setSelectedDate(new Date(e.target.value))
                      }
                      id="latepayment-date-input"
                      name="paymentDate"
                      className="calendar-input"
                      style={{
                        height: "35px",
                        backgroundColor: "transparent",
                        color: "white",
                        border: "1px solid #6f7172",
                        width: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="col-sm-6">
                  <label
                    className="late-payment-field-heading"
                    htmlFor="penaltyAmount"
                  >
                    AMOUNT PAID
                  </label>
                </div>
                <div className="col-sm-6">
                  <input
                    style={{ height: "35px" }}
                    type="text"
                    id="latepayment-penaltyAmount-input"
                    name="penaltyAmount"
                    value={penaltyAmount}
                    className="ps-2"
                    onChange={(e) => setPenaltyAmount(e.target.value)}
                    onInput={(e) => {
                      // Remove non-digit characters
                      e.target.value = e.target.value.replace(/\D/, "");
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 mt-3">
              <div className="col-sm-6">
                <label
                  className="late-payment-field-heading"
                  htmlFor="referenceNumber"
                >
                  RECEIPT / REFERENCE NUMBER:
                </label>
              </div>
              <div className="col-sm-6">
                <input
                  style={{ height: "35px" }}
                  type="text"
                  id="latepayment-referenceNumber-input"
                  name="referenceNumber"
                  onChange={(e) => setReferenceNumber(e.target.value)}
                  onInput={(e) => {
                    // Remove non-alphanumeric characters and spaces
                    let sanitizedValue = e.target.value
                      .replace(/[^a-zA-Z0-9]/g, "")
                      .toUpperCase();
                    // Limit to 15 characters
                    e.target.value = sanitizedValue.slice(0, 15);
                  }}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-2">
          {activeButton && (
            <button
              type="submit"
              style={{ backgroundColor: ctaBackgroudColor }}
              id="late-payment-confirm-btn"
            >
              {isMoreThan4Days(sharedData.detected)
                ? "Confirm late payment"
                : "Confirm payment"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default LatePayment;
