const MediaUrlFetcher = () => {
  const baseUrl = 'http://localhost:8080'; // Replace with your actual base URL

  const fetchVideoUrl = async (videoUrl) => {
    try {


      const trimmedVideoUrl = videoUrl.replace(baseUrl, '');
      const response = await fetch(trimmedVideoUrl, {
        method: 'GET',
      });

      if (!response.ok) {
        throw Error('Video network response was not ok');
      }

      const blob = await response.blob();
      const videoUrlResponse = URL.createObjectURL(blob);
      return videoUrlResponse;
    } catch (error) {
      console.error('Video Fetch error:', error);
      return null;
    }
  };

  const fetchImageUrl = async (imageSrc) => {
    try {
      const trimmedImageUrl = imageSrc.replace(baseUrl, '');
      const response = await fetch(trimmedImageUrl, {
        method: 'GET',
      });

      if (!response.ok) {
        throw Error('Image network response was not ok');
      }

      const blob = await response.blob();
      const imageUrlResponse = URL.createObjectURL(blob);
      return imageUrlResponse;
    } catch (error) {
      console.error('Image Fetch error:', error);
      return null;
    }
  };

  return { fetchVideoUrl, fetchImageUrl };
};

export default MediaUrlFetcher;
