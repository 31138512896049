import Footer from "./Base/Footer";
import NavBar from "./Base/NavBar";
import ReviewIncidents from "./ReviewIncidentPage/ReviewIncidents";
import AddIncidentFirstPage from "./ReviewIncidentPage/AddIncidentFirstPage";
import IncidentDetails from "./ReviewIncidentPage/IncidentDetails";
import AddIncident from "./ReviewIncidentPage/AddIncident";
import Details from "./ReviewIncidentPage/Details";
import Notes from "./ReviewIncidentPage/Notes";
import Records from "./ReviewIncidentPage/Records";
import LatePayment from "./ReviewIncidentPage/LatePayment";
import SendPaymentLink from "./ReviewIncidentPage/SendPaymentLink";
import TrainingVideos from "./TrainingVideosPage/TrainingVideos";
import ViewVideo from "./TrainingVideosPage/ViewVideo";
import SearchRego from "./SearchRegoPage/SearchRego";
import IconSearchPage from "./SearchRegoPage/IconSearchPage";
const Component = {
  Footer,
  NavBar,
  ReviewIncidents,
  AddIncidentFirstPage,
  IncidentDetails,
  AddIncident,
  Details,
  Notes,
  Records,
  LatePayment,
  SendPaymentLink,
  TrainingVideos,
  ViewVideo,
  SearchRego,
  IconSearchPage,
};
export default Component;


