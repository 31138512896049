import React, { useEffect, useState } from "react";
import styles from "../../CSS/Base/Basic.module.css";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import userSession from "../../Constants/auth";
const CircularWithValueLabel = () => {
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setLoading((prevLoading) => !prevLoading);
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="indeterminate" sx={{ color: '#e27a13' }} />
    </Box>
  );
};
function ImageComponent({ imageApiResponse, imageSrc, isLoadingImage, isErrorImage }) {
  const textAccentColor = userSession.themeData?.textAccentColor || '#e27a13';
  return (
    <div className={` ${styles.evidenceImageBox} d-flex justify-content-center align-items-center`}>
      {isLoadingImage ? (
        <><CircularWithValueLabel /></>
      ) : isErrorImage ? (
        <p style={{ color: textAccentColor, fontSize: '14px', fontWeight: 'bold' }}>Error loading ID image</p>
      ) : imageSrc ? (
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            width: "90%",
            height: "450px",
          }}
        >
          <img
            src={imageApiResponse}
            alt="ID_Image"
            width="100%"
            height="100%"
            style={{ transition: "transform 0.2s", objectFit: "contain" }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = "scale(1.5)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "scale(1)";
            }}
          />
        </div>
      ) : (
        <p style={{ color: textAccentColor, fontSize: '14px', fontWeight: 'bold' }}>No ID image available</p>
      )}
    </div>
  );
}

export default ImageComponent;
