// Utility function to generate payload based on label and status
function generatePayload(label, from, to, sites, brands, products, states) {
    const commonPayload = {
        statuses: {
            "Open": [],
            "Verified": [],
            "Escalated": [],
            "Pre-Court": [],
            "In-Court": [],
            "Recovery In-Progress": [],
            "Details Received": [],
            "Debt Recovered": [],
            "Debt Paid": [],
            "Returned To Site": [],
            // "Incomplete Transaction": [],
        },

    };
    switch (label) {
        case "DRIVE OFF":
            return {
                ...commonPayload,
                types: ["Drive Off"],
                sites,
                from,
                to,
                brands, products, states
            };
        case "On Site":
            return {
                ...commonPayload,
                types: ["Drive Off"],
                sites,
                from,
                to,
                brands, products, states
            };
        case "IOU":
            return {
                ...commonPayload,
                types: ["IOU"],
                sites,
                from,
                to,
                brands, products, states
            };
        case "CANCELLED":
            return {

                statuses: {
                    "Cancelled": ["Driver Nomination", "Jerry Can", "Licence Unclear", "No Licence Plate", "Motorbike", "Other", "Outdated Address Details", "Ownership Changed", "Recovered", "Stolen Plate", "Stolen Vehicle", "Stolen Plate Recovered", "Stolen Vehicle Recovered", "Tampered Plate", "Test Incident for Training", "Unable to verify details", "Unable to Recover - (Unregistered Plate)", "User Error","Pay at Pump Error"],
                    "Incomplete Transaction": []
                },
                sites,
                from,
                to,
                brands, products, states
            };
        case "EXPIRED":
            return {

                statuses:
                    { "Cancelled": ['Expired'] },
                sites,
                from,
                to,
                brands, products, states
            };
        default:
            return {}; // Default empty payload
    }
}

export default generatePayload;