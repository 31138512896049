import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../CSS/LoginForm.css";
import sensen_Login_Image from "../../Assets/sensen_Login_Image.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Validate email before submitting
    if (!email) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `/sn-auth-service/resetPasswordMail?email=${encodeURIComponent(email)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Success: Navigate to the success page
        setLoading(false);
        navigate(
          `/scancam/resetpasswordsuccess?email=${encodeURIComponent(email)}`
        );
      } else {
        // Error: Display an error message
        setLoading(false);
        console.error("Error:", response.statusText);
        // You can handle the error as needed, such as displaying an error message to the user.
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      // Handle network errors or other unexpected issues
    } finally {
      setLoading(false);
    }
  };

  return (
    // <div className="page-container">
    //     {
    //         showBackdrop && <Backdrop
    //             sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //             open={showBackdrop}
    //             onClick={() => { }}
    //         >
    //             <CircularProgress color="inherit" />
    //         </Backdrop>
    //     }
    //     <form className="content-container" onSubmit={handleSubmit}>
    //         <div className="login-header w-100 ">
    //             <img src={sensen_Login_Image} alt="Logo" className="forgotpassword-form-logo" />
    //             <div className="forgotPassword-form-title">
    //                 <h1>Forgot Your Password?</h1>
    //             </div>
    //             <h2 className="forgotPassword-form-subtitle text-center pt-0" >
    //                 Enter your email address and we will send you instructions to reset your password.
    //             </h2>
    //         </div>

    //         <div className="login-body w-100">
    //             <div className="inputContainer w-100">
    //                 <div className="form-floating w-100">
    //                     <FormControl fullWidth variant="outlined">
    //                         <p >Email address</p>
    //                         <TextField
    //                             id="outlined-basic"
    //                             type="email"
    //                             className={`inputField mb-4 ${emailError ? "inputError " : "passwordInput"}`}
    //                             value={email}
    //                             onChange={handleEmailChange}
    //                             fullWidth
    //                             required
    //                         />
    //                     </FormControl>
    //                 </div>
    //                 {emailError && (
    //                     <p className="form-errorMessage">
    //                         Above field is mandatory to be filled
    //                     </p>
    //                 )}
    //             </div>
    //             <button className="continueButton" type="submit">
    //                 Continue
    //             </button>
    //             <Link to="/scancam" className="mt-4 back-button">
    //                 Back to Scancam Portal
    //             </Link>
    //         </div>
    //     </form>
    // </div>
    <div className="h-screen flex items-center justify-center bg-[#363636] font-login">
      <div className="bg-white rounded-md  shadow-md min-h-[540px] max-h-fit w-[400px]">
        <form
          method="post"
          onSubmit={handleSubmit}
          className="flex flex-col h-full"
        >
          <div className="flex-grow borde">
            <div className="flex flex-col justify-center items-center ps-10 pe-10 pt-16 space-y-4 text-[#1E212A]">
              <img
                src={sensen_Login_Image}
                alt="logoSensen"
                height={52}
                width={168}
              />
              <h1 className="font-normal text-2xl">Forgot Your Password?</h1>
              <p className="text-sm text-center">
                Enter your email address and we will send you instructions to
                reset your password.
              </p>
            </div>
            {/* <div className="space-y-1 ps-10 pe-10 pt-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 h-[52px]"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    required
                    // minLength={8}
                    value={password}
                    onChange={handlePasswordChange}
                    onPaste={handleCopyPaste}
                    onCopy={handleCopyPaste}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 pr-10 h-[52px]"
                  />
                  <button
                    type="button"
                    onClick={handleTogglePassword}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 h-[52px] text-[24px]"
                  >
                    {showPassword ? <BsEyeSlash /> : <BsEye />}
                  </button>
                </div>
              </div>
              {error && (
                <p className="text-red-500">
                  Please check your email and password.
                </p>
              )}
            </div>
            <div className="space-y-4 mt-4 ps-10 pe-10">
              <p
                className="text-[#21358C] text-[14px] font-semibold"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </p>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-xl text-base font-medium text-white bg-[#21358C] hover:bg-[#21358C] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Continue"
                )}
              </button>
            </div> */}
            <div className="space-y-1 ps-10 pe-10 pt-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  required
                  onChange={handleEmailChange}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 h-[52px]"
                />
              </div>
            </div>
            <div className="space-y-4 mt-6 ps-10 pe-10">
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center items-center py-4 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#21358C] hover:bg-[#21358C] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Continue"
                )}
              </button>
              <Link
                className=" text-[#21358C] text-[14px] font-semibold text-center"
                to={"/scancam"}
              >
                <p className="mt-4">Back to Scancam Portal</p>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
