import React, { useEffect, useState } from "react";
import moment from "moment";
import "../../CSS/incidetDetails.css";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import useDetailsPatchApi from "../../Custom Hooks/useDetailsPatchApi";
import { useParams } from "react-router-dom";
import userSession from "../../Constants/auth";
import "react-datetime/css/react-datetime.css";
import { FaCalendarAlt } from "react-icons/fa";
const IncidentDetailsTopSection = ({
  incidentDetails,
  openFeedBackModal,
  setIncidentDetails,
  setError,
  setErrorMessage,
}) => {
  const { pkAlert } = useParams();
  const { CustID, authHeader } = userSession;

  const { patchAPI } = useDetailsPatchApi();

  const formatDateTime = (dateTimeString) => {
    // Create a moment object from the input date-time string
    const momentDateTime = moment(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSS");

    // Format the moment object to 12-hour format with AM/PM
    const formattedTime = momentDateTime.format("hh:mm A"); // 12-hour format with AM/PM

    // Format the date in the desired format (DD/MM/YYYY)
    const localDateString = momentDateTime.format("DD/MM/YYYY");

    return (
      <div>
        <span>{formattedTime.toUpperCase()}</span>
        <br />
        <span>{localDateString}</span>
      </div>
    );
  };

  const formatText = (status, resolutionNote, maxWordsPerLine = 5) => {
    let combinedText;

    if (status === "Debt Paid") {
      combinedText = `${status} - ${resolutionNote}`;
    } else if (status === "Cancelled") {
      combinedText = `${status} - ${resolutionNote}`;
    } else {
      combinedText = `${status}`;
    }

    const words = combinedText.split(" ");
    let formattedText = "";
    for (let i = 0; i < words.length; i += maxWordsPerLine) {
      const line = words.slice(i, i + maxWordsPerLine).join(" ");
      formattedText += line + "\n";
    }
    return formattedText.trim();
  };

  // Function to convert 12-hour UTC time to 24-hour UTC time
  const convertTo24HourUTC = (time12Hour) => {
    // Assuming time12Hour is in format "YYYY-MM-DDTHH:mm:ss.SSS"
    const formattedTime = moment.utc(time12Hour, "YYYY-MM-DDTHH:mm:ss.SSS");

    // Format the time to 24-hour UTC format
    const time24Hour = formattedTime.format("YYYY-MM-DDTHH:mm:ss.SSS");

    return time24Hour;
  };

  const [selectedDate, setSelectedDate] = useState();
  const [hoveredField, setHoveredField] = useState(null);
  const [editDateMode, setEditDateMode] = useState(false);

  useEffect(() => {
    if (incidentDetails.detected) {
      // Convert UTC time to 12-hour format using Moment.js
      const formattedTime = moment
        .utc(incidentDetails.detected)
        .format("YYYY-MM-DDTHH:mm:ss");
      setSelectedDate(formattedTime);
    }
  }, [incidentDetails.detected]);

  const incidentType = incidentDetails?.type ?? "";
  // const incidentStatus = incidentDetails?.status ?? "";
  const siteName = (incidentDetails?.siteName ?? "").toUpperCase();
  const siteType = (incidentDetails?.siteType ?? "").toUpperCase();

  const handleMouseEnter = (fieldName) => {
    setHoveredField(fieldName);
  };

  const handleMouseLeave = () => {
    setHoveredField(null);
  };

  const toggleEditMode = () => {
    setEditDateMode(true);
  };

  const handleCancelChanges = (event) => {
    event.stopPropagation();
    setEditDateMode(false);
  };

  const handleSaveChanges = (event) => {
    event.stopPropagation();
    setError(false);
    const utcTime = moment.utc(selectedDate); // Assuming the UTC time is '2024-04-30T05:10:00'
    const localTime = utcTime.local(); // Convert to local time zone
    const localTimeWithOffset = localTime.format("YYYY-MM-DDTHH:mm:ss.SSS");
    const time24HourUTC = convertTo24HourUTC(selectedDate);

    // Create a new object with updated detected field
    const updatedDetails = {
      ...incidentDetails, // Copy existing incidentDetails
      detected: time24HourUTC, // Update detected field with converted local time
    };
    setIncidentDetails(updatedDetails);

    const dataToSend = {
      detected: localTimeWithOffset, // Key is "detected" and value is converted local time
    };

    // Call patchAPI with dataToSend
    patchAPI(authHeader, CustID, pkAlert, dataToSend)
      .then(() => {
        // Update data in storage after successful patch operation
        // Reset editable fields
        setEditDateMode(false);
        setError(false);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setError(true);
        setErrorMessage(
          "An error occurred while updating incident details. Please try again. If the issue persists, contact support"
        );
      });
  };
  return (
    <div className="incident-details-container">
      <div className="incident-details-item incident-details-type">
        <div className="incident-details-heading">TYPE</div>
        <div
          className="incident-details-value incidet-type-value"
          style={{ fontSize: "24px" }}
        >
          {incidentType}
        </div>
      </div>
      <div
        className="incident-details-item incident-details-occurred-at"
        onMouseEnter={() => handleMouseEnter("detected")}
        onMouseLeave={handleMouseLeave}
        onClick={toggleEditMode}
      >
        <div className="incident-details-heading">
          OCCURRED AT
          <span>
            {hoveredField === "detected" &&
              (incidentDetails.status === "Open" ||
                incidentDetails.status === "Returned To Site") && (
                <EditIcon style={{ fontSize: "18px", marginLeft: "5px" }} />
              )}
          </span>
        </div>
        <div className="incident-details-value">
          {editDateMode &&
          (incidentDetails.status === "Open" ||
            incidentDetails.status === "Returned To Site") ? (
            <div className="col-sm-12 d-flex">
              <div className="col-sm-6 custom-datetime-local">
                <input
                  type="datetime-local"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  id="date-input"
                  name="detected"
                  className="calendar-input"
                  style={{
                    height: "35px",
                    backgroundColor: "transparent",
                    color: "#6f7172",
                    border: "1px solid rgb(204, 204, 204)",
                    width: "100%",
                    paddingLeft: "8px",
                  }}
                />
                <div className="calendar-icon-wrapper">
                  <FaCalendarAlt className="calendar-icon" />
                </div>
              </div>
              {/* <div className="col-sm-4 d-flex me-1">
                <div className="col-sm-12 iconsContainer">
                  <div
                    className="iconsAlignment"
                    onClick={(event) => handleSaveChanges(event)}
                  >
                    <DoneIcon style={{ fontSize: "16px", fontWeight: "900" }} />
                  </div>
                  <div className="iconsAlignment" onClick={handleCancelChanges(event)}>
                    <CloseIcon
                      style={{ fontSize: "16px", fontWeight: "900" }}
                    />
                  </div>
                </div>
              </div> */}
              <div
                className="col-sm-4 d-flex justify-content-around align-items-center"
                style={{
                  backgroundColor: "#EFEFEF",
                  border: "1px solid #ccc",
                  height: "35px",
                  fontWeight: "bolder",
                }}
              >
                <button
                  className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center"
                  onClick={(event) => handleSaveChanges(event)}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 3L4.5 8.5L2 6"
                      stroke="currentColor"
                      stroke-width="3"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button
                  className="icon-button bg-[#EFEFEF] h-[34px] w-[38px] flex items-center justify-center"
                  onClick={(event) => handleCancelChanges(event)}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 3L9 9M9 3L3 9"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ) : (
            <>{formatDateTime(incidentDetails?.detected) || ""}</>
          )}
        </div>
      </div>
      <div className="incident-details-item incident-details-status">
        <div className="incident-details-heading">STATUS</div>
        <div className="incident-details-value incident-details-status">
          <pre style={{ whiteSpace: "nowrap", fontSize: "15px" }}>
            {formatText(
              incidentDetails?.status,
              incidentDetails?.resolutionNote
            )}
          </pre>
        </div>
      </div>
      <div
        className="incident-details-item incident-details-site-name"
        style={{ paddingLeft: "2rem" }}
      >
        <div className="incident-details-heading">SITE</div>
        <div
          className="incident-details-value"
          style={{ whiteSpace: "nowrap" }}
        >
          {siteName}
        </div>
      </div>
      <div
        className="incident-details-item incident-details-site-type"
        style={{ paddingLeft: "3rem" }}
      >
        <div className="incident-details-heading">SITE TYPE</div>
        <div className="incident-details-value">{siteType}</div>
      </div>
      <div className="incident-details-item incident-details-need-help">
        <div className="incident-details-needhelp flex">
          <span
            className="incident-details-needhelp"
            onClick={openFeedBackModal}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <span style={{ marginRight: "5px" }}>Need help</span>
            <BsFillQuestionCircleFill />
          </span>
        </div>
      </div>
    </div>
  );
};

export default IncidentDetailsTopSection;
