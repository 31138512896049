import React, { useEffect, useState } from "react";
import userSession from "../Constants/auth";
import { APP_SERVICES_URL } from "../Constants/constants";
import "./FeedbackModal.css";
import { Form } from "react-bootstrap";

const FeedbackModal = ({
  isOpen,
  onClose,
  onSelectReportType,
  apiResponse,
  ocr,
  site,
  detected,
  pkAlert,
}) => {
  const { username, sites } = userSession;
  const [error, setError] = useState(false);
  
  const getSitesNames = (sites) => {
    if (!sites || !Array.isArray(sites)) {
      return [];
    }
    return sites.map((site) => site.name);
  };

  const siteNames = getSitesNames(sites);
  const ctaBackgroudColor =
    userSession.themeData?.ctaBackgroudColor || "#e27a13";

  const [feedbackText, setFeedbackText] = useState(""); // New state for feedback text

    // Clear feedback text when modal closes
    useEffect(() => {
      if (!isOpen) setFeedbackText("");
    }, [isOpen]);

  const mailFeedbackHeading = `Feedback from ${username}`;
  const mailFeedbackBodyFirstLine = `𝐔𝐬𝐞𝐫: ${username}`;
  const mailFeedbackBodySecondLine = `𝐒𝐢𝐭𝐞𝐬: ${siteNames}`;
  const mailFeedbackBodyThirdLine = `𝐌𝐞𝐬𝐬𝐚𝐠𝐞: ${feedbackText}`;

  const licensePlateLine = `𝐋𝐢𝐜𝐞𝐧𝐜𝐞 𝐩𝐥𝐚𝐭𝐞: ${ocr}`;
  const siteLine = `𝐒𝐢𝐭𝐞: ${site}`;
  const timeLine = `𝐓𝐢𝐦𝐞: ${detected}`;
  const linkLine = `𝐋𝐢𝐧𝐤: ${APP_SERVICES_URL}/scancam/incident/${pkAlert}`;
  const feedbackTextLine = `𝐌𝐞𝐬𝐬𝐚𝐠𝐞/𝐑𝐞𝐪𝐮𝐞𝐬𝐭: ${feedbackText}`;

  const mailHelpHeading = `Issue from ${site} for plate ${ocr}`;
  const mailHelpBody = `
      ${licensePlateLine}
      ${siteLine}
      ${timeLine}
      ${linkLine}
      ${feedbackTextLine}
      `;
  if (!isOpen) return null;

  const saveFeedBack = () => {
    // Check if feedbackText is not null and not blank
    if (feedbackText && feedbackText.trim() !== "") {
      const subject = ocr === "NA" ? mailFeedbackHeading : mailHelpHeading;
      const message =
        ocr === "NA"
          ? `${mailFeedbackBodyFirstLine}\n${mailFeedbackBodySecondLine}\n${mailFeedbackBodyThirdLine}`
          : mailHelpBody;

      onSelectReportType(subject, message);
      onClose();
      setFeedbackText("");
      setError(false);
    } else {
      setError(true);
      console.error("Feedback text cannot be null or blank.");
    }
  };

  const closeModal = () => {
    onClose();
    setFeedbackText("");
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50">
      <div
        className="bg-white rounded-sm  shadow-xl p-0 mt-10 min-w-[38rem] min-h-[16rem] overflow-auto relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-2 text-gray-500 hover:text-gray-700"
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        {/* content here */}
        <div className="text-[#e27a13] text-[18px] font-bold px-4 py-3 border-b-2">
          {ocr === "NA"
            ? "Let us know your thoughts?"
            : `How can we help you with this incident for vehicle ${ocr}?`}
        </div>
        <div className="p-4 text-black bg-[#f4f4f4]">
          {apiResponse === null ? (
            // If apiResponse is null, show the radio buttons and email input
            <div className=" text-base font-thin" style={{ minWidth: "100%" }}>
              <Form>
                <textarea
                  className="w-full feedback-input-form"
                  rows="6"
                  cols={55}
                  value={feedbackText}
                  onChange={(e) => {
                    e.preventDefault();
                    setFeedbackText(e.target.value);
                  }}
                  placeholder="Please enter details..."
                  required
                ></textarea>
              </Form>
            </div>
          ) : (
            // If apiResponse is true or false, show the corresponding message
            <div className="flex justify-center items-center text-sm font-light min-h-[100px]">
              {apiResponse ? (
                <div className="m-3 text-xl">
                  {ocr === "NA"
                    ? `We've recieved your feedback, thanks!`
                    : `We've recieved your query, thanks!`}
                </div>
              ) : (
                <div className="m-3">
                  {ocr === "NA"
                    ? `There is an issue with sending feedback. Please contact our support team for further assistance.`
                    : `There is an issue with sending query. Please contact our support team for further assistance.`}
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          {apiResponse === null ? (
            // If apiResponse is null, show both buttons
            <div className="d-flex" style={{ cursor: "pointer" }}>
              <div
                className="col-6 feedbackmodal bg-[#fff] flex items-center justify-center font-bold text-black"
                onClick={onClose}
              >
                Cancel
              </div>
              <div
                className="col-6 feedbackmodal text-[#fff] flex items-center justify-center py-3 font-bold"
                onClick={saveFeedBack}
                style={{
                  backgroundColor: feedbackText ? ctaBackgroudColor : "grey",
                }}
              >
                {ocr === "NA" ? "Send feedback" : "Send help request"}
              </div>
            </div>
          ) : (
            // If apiResponse is not null (true or false), show only the close button
            <div
              className="col-12 text-left feedbackmodal flex items-center justify-center cursor-pointer text-[#fff] bg-[#e27a13] py-3 font-bold"
              onClick={closeModal}
            >
              Close
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
