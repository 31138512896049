import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../../CSS/Base/RotatingIcon.css";

const RotatingIcon = ({ colorValue }) => {
  return (
    <div>
      <FontAwesomeIcon
        icon={faRotate}
        className="rotate thick"
        style={{ color: colorValue }}
        size="3x"
      />
    </div>
  );
};

export default RotatingIcon;
