import React from "react";
import "../../CSS/LandingPage.css";
import { Box, Grid, Typography } from "@mui/material"; // Ensure Grid is imported from @mui/material
import ProgressChart from "../../Charts/ProgressChart";
import HomePageBarchart from "../../Charts/HomePageBarchart";
import { Link } from "react-router-dom";

const StatsWidget = ({
  numTotalIncidents,
  numKnownOffendersDetected,
  averageLoss,
  preventedTheftAmount,
  recoveredPercentage,
  numIncidentsToReview,
  chartData,
}) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 2,
          mb: 4,
          color: "#fff",
        }}
      >
        <Typography>Incidents in the past month</Typography>
      </Box>
      <Box sx={{ maxWidth: 1000, margin: "auto" }}>
        <HomePageBarchart chartData={chartData} label={"homepage"} />
      </Box>
      <Box sx={{ width: 1170, mx: "auto", flexGrow: 1, mt: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={1}>
            <Box
              sx={{
                color: "#fff", // Text color
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              INCIDENTS
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              sx={{
                color: "#fff", // Text color
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              KNOWN OFFENDERS DETECTED
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              sx={{
                color: "#fff",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              AVERAGE LOSS
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              sx={{
                color: "#fff",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              PREVENTED THEFT
            </Box>
          </Grid>
          <Grid item xs={12} md={1}>
            <Box
              sx={{
                color: "#fff",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              RECOVERED
            </Box>
          </Grid>
          <Grid item xs={12} md={1}>
            <Box
              sx={{
                color: "#fff",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              TO REVIEW
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box></Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: 1170, mx: "auto", flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              pb: 0,
            }}
          >
            <Box
              sx={{
                color: "#fff", // Text color
                textAlign: "center",
                fontSize: "32px",
              }}
            >
              {numTotalIncidents}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              pb: 0,
            }}
          >
            <Box
              sx={{
                color: "#fff", // Text color
                textAlign: "center",
                fontSize: "32px",
              }}
            >
              {numKnownOffendersDetected}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              pb: 0,
            }}
          >
            <Box
              sx={{
                color: "#fff",
                textAlign: "center",
                fontSize: "32px",
              }}
            >
              ${averageLoss ? averageLoss.toFixed(2) : "0.00"}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              pb: 0,
            }}
          >
            <Box
              sx={{
                color: "#fff",
                textAlign: "center",
                fontSize: "32px",
                pb: 0,
              }}
            >
              <span
                style={{ borderBottom: "3px solid #fff", paddingBottom: "4px" }}
              >
                $
                {preventedTheftAmount ? preventedTheftAmount.toFixed(1) : "0.0"}
                k
              </span>
            </Box>
          </Grid>
          <Grid item xs={12} md={1}>
            <Box
              sx={{
                color: "#fff",
                textAlign: "center",
                fontSize: "24px",
              }}
            >
              <ProgressChart percentage={recoveredPercentage} />
            </Box>
          </Grid>
          <Grid item xs={12} md={1}>
            <Box
              id="stats-num-open-incidents-badge"
              sx={{
                color: "#fff",
                textAlign: "center",
                fontSize: "32px",
              }}
            >
              {numIncidentsToReview}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <Box
              sx={{
                color: "#fff",
                textAlign: "center",
                fontSize: "20px",

              }}
            >
              <a
                id="stats-review-now-button"
                className="btn cta-button"
                href="/scancam/reviewincidents"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                Review now
                <MdKeyboardArrowRight
                  style={{ fontSize: "2.2rem", fontWeight: "bolder" }}
                />
              </a>
            </Box> */}
            <Link
              to={"/scancam/reviewincidents"}
              className="flex text-white text-xl"
            >
              <button className="bg-[#3a3a3a] h-[65px] w-[262px] mt-auto flex justify-center items-center">
                Review Now
                <span
                  id="chevron-arrow-right"
                  style={{
                    borderColor:"#fff",marginLeft:'4px'
                  }}
                ></span>
              </button>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default StatsWidget;

