import { useState, useEffect } from 'react';
import { APP_SERVICES_URL } from "../Constants/constants";
import userSession from '../Constants/auth';

const useApiData = () => {
    const [customersList, setCustomersList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState(null);
    const { CustID, authHeader, userRole } = userSession;
    useEffect(() => {
        if (userRole !== 'site-manager') {
            const fetchData = async (url, setter) => {
                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            Authorization: authHeader, // Replace with your actual token
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();
                    setter(data);
                } catch (error) {
                    setApiError(error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData(`${APP_SERVICES_URL}/senapi/${CustID}/customersList`, setCustomersList);
            fetchData(`${APP_SERVICES_URL}/senapi/${CustID}/statesList`, setStatesList);
            fetchData(`${APP_SERVICES_URL}/senapi/${CustID}/productsList`, setProductsList);
        } else {
            setLoading(false); // No need to fetch data, set loading to false directly
        }
    }, []);

    return {
        customersList,
        statesList,
        productsList,
        loading,
        apiError,
    };
};

export default useApiData;
